import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Container} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const InnerBanner = ({img, title, img1, desc}) => {

    const [imageSource, setImageSource] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                setImageSource('/images/dynamic/esingle.jpg');
            } else {
                setImageSource('/images/dynamic/m-banner.jpg');
            }
        };

        // Call handleResize initially and add event listener for window resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledInnerBanner className='InnerBanner'>
            <Img banner={true} src={img} />
            <Container>
                <div className={'content'}>
                    <h2 className={`anim-active split-up`}>{reactHtmlParser( title )}</h2>
                    <p className={"split-up"}>{reactHtmlParser(desc)}</p>
                </div>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(450 / 1366 * 100%);
  position: relative;
  

  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: calc(50%);
    z-index: 2;
    @media(max-width: 767px){
    top: calc(25%);
  }
  }

  .content{
    display: block;
    justify-content: center;
    
    width: 50%;
  }
  h2 {
    left: 15px;
    color: #ffffff;
    //font-size: 40px;
    font-weight: 400;
    //line-height: 53px;
    z-index: 2;
    margin-bottom: 30px;

    //span {
    //  font-weight: 600;
    //  color: #ffffff;
    //}
  }
  p{
    //font-size: 18px;
    font-weight: 300;
    //line-height: 27px;
  }

  @media (max-width: 767px) {
    .title {
      width: 50%;
    }
    h2{
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
    }
    p{
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  @media (max-width: 767px) {
    padding-top: calc(350 / 375 * 100%);

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export default InnerBanner;
