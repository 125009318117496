import styled from "styled-components";
import TextBoxImage2 from "../TextBoxImage2";
import {Loading} from "../Loading";

const MyComponent = ({data, loading}) => {
    const backgroundColor = ['#498DCB', "#92278F", "#DB6352"]



    return (
        <StyledComponent className={'rides-zig pt-100 '}>

            {
                data && data?.length > 0 &&
                data?.map((e, index) => {
                    if (e.slug === 'uncategorized') {
                        return null
                    }
                    if (e.name === 'Top Banner') {
                        return null
                    }
                    if (e.name === 'Middle Banner') {
                        return null
                    }
                    if (e.name === 'All Products') {
                        return null
                    }
                    return (
                        <TextBoxImage2
                            text={e?.description}
                            image={e?.image_url}
                            svg={index > 13 ? null : `/images/static/rides/${index}.svg`}
                            title={e?.name}
                            slug={e.slug}
                            bgColor={index > 3 ? null : backgroundColor[index-1]}

                        />
                    );
                })
            }
        </StyledComponent>
    );
};
const StyledComponent = styled.div`

`;

export default MyComponent;
