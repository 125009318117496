import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import RideZigzak from "../../components/ride/RideZigzak";
import AttrZigzak from "../../components/attractions/AttrZigzak";
import TringleInnerPage from "../../components/TringleInnerPage";
import TextBoxImage from "../../components/TextBoxImage";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/ride";
import Banner from "../../components/attractions/Banner";
import {ApiParam} from "../../api/network/apiParams";

const MyComponent = () => {

    const text = `Uncover the Perfect Toys <br/>
                            for Every Little Explorer! `

    const dispath = useDispatch()

    // api call
    let param = {
        [ApiParam.cat_slug]: 'attraction' //change to slug later
    }
    useEffect(() => {
        let api_url = apiEndPoints.ATTRACTION
        dispath(fetchData([api_url, param]))
    }, [])

    let getPost = useSelector(state => state.ride)



    const banner = getPost?.posts[0]?.banner;
    const sanc = getPost?.posts[0]?.sanctuary;
    const zigzag = getPost?.posts?.data;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Attractions | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <Banner videoLink={'https://www.youtube.com/embed/pYtmlTJcohk?si=RuCXEnO7fee3sv1l'}
                        img={'/images/dynamic/video-thumb-min.jpg'} title={'Attractions'}/>
                {/*<TringleInnerPage data={sanc}/>*/}

                <AttrZigzak data={zigzag}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
