import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import authReducer from '../redux/auth'
import productsReducer from '../redux/products'
import checkoutSlice from '../redux/checkout'
import ticketSlice from '../redux/ticket'
import homeReducer from '../redux/home'
import mapReducer from '../redux/interactive-map'
import rideReducer from '../redux/ride'
import attractionReducer from '../redux/attraction'
import tasteReducer from '../redux/taste'
import unwindReducer from '../redux/unwind'
import eventReducer from '../redux/event'
import blogReducer from '../redux/blog'
import faqReducer from '../redux/faq'
import globalReducer from '../redux/global'


const store = configureStore({
    reducer: {
        globalReducer: globalReducer,
        posts: postReducer,
        auth: authReducer,
        products: productsReducer,
        checkout: checkoutSlice,
        ticket: ticketSlice,
        home: homeReducer,
        ride: rideReducer,
        attraction: attractionReducer,
        taste: tasteReducer,
        unwind: unwindReducer,
        event: eventReducer,
        blog: blogReducer,
        faq: faqReducer,
        mapReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

