import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";

// page imports
import About from './pages/about';
import PrivacyPolicy from './pages/privacy-policy';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import Taste from "./pages/taste";
import Unwind from "./pages/unwind";
import Momento from "./pages/momento";
import MomentoDetails from './pages/momento/single';

import Manaverse from "./pages/manaverse";
import Rides from "./pages/experience/rides";
import Attractions from "./pages/experience/attractions";
import Faqs from "./pages/faqs";
import Blog from "./pages/blog";
import BlogDetail from './pages/blog/single';

import Events from "./pages/events";
import EventDetail from './pages/events/single';

// animation
import {ImageReveal} from "./components/animations/imageReveal";
import {Parallax, ParallaxImg} from "./components/animations/parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/dist/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {SplitText} from "gsap/SplitText";
import {SplitUp} from "./components/animations/TextAnimation";


import React, {useEffect, useLayoutEffect, useRef} from "react";
import Menu2 from "./components/Menu2";
import Footer from "./components/Footer";
import Terms from "./pages/terms";
import Ticket from "./pages/ticket";
import Login from "./pages/login";
import CreateAccount from "./components/login/CreateAccount";
import Forget_password from "./pages/forget_password";
import Otp from "./components/login/Otp";
import User from "./components/user/User";
import UserUpdate from "./components/user/UserUpdate";
import Order from "./pages/order/order";
import OrderDetails from "./components/order/OrderDetails";
import Account from "./components/login/Account";
import Menu from "./components/Menu";
import product from "./pages/momento/products";
import Cart from "./components/Cart";
import Checkout from "./pages/momento/checkout";
import status from "./pages/order/status";
import checkout from "./pages/checkout";
import PageTransition from "./components/animations/PageTransition";
import {apiEndPoints} from "./api/network/apiEndPoints";
import {getTicketInfo, postTicket} from "./api/redux/ticket";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Loading} from "./components/Loading";
import InteractiveMap from "./pages/interactive_map";
import RefundPolicy from "./pages/refund-policy";
import ThreeSixtyView from "./pages/360-view";
import Origins from "./pages/origins";
import Lore from "./pages/lore";
import TheIslanders from "./pages/the-islanders";
import Mana from "./pages/mana";
import Games from "./pages/games";

function App() {
    const dispatch = useDispatch()
    const Store = useSelector(store => store)
    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    // const location = useLocation();

    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()

    // page transition
    PageTransition()


    // smooth scroll
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 1.7, // how long (in seconds) it takes to "catch up" to the native scroll position
        });
        return () => {
            smoother.kill();
        };
    }, []);


    const sessionIdHandler = () => {
        const param = ['', apiEndPoints.TICKET_TOKEN]
        dispatch(postTicket(param)).then(() => {
            let sessionToken = sessionStorage.getItem('ticketToken')
            if (sessionToken) {
                ticketDetailHandler()
            }
        })
    };

    const ticketDetailHandler = () => {
        let sessionToken = sessionStorage.getItem('ticketToken')
        let date = new Date()
        if (sessionToken) {
            const params = ['', `<?xml version="1.0" ?>
<Envelope>
<Header>
<SourceID>1</SourceID>
<MessageID>12</MessageID>
<MessageType>QueryCustomerItems</MessageType>
<SessionID>${sessionToken}</SessionID>
<TimeStamp>${moment(date).format('yyyy-MM-DD hh:mm:ss')}</TimeStamp>
</Header>
<Body>
<QueryCustomerItems>
<CustomerID>3</CustomerID>
<SalesProgramID>4</SalesProgramID>
<IncludeItemAttributes>YES</IncludeItemAttributes>
</QueryCustomerItems>
</Body>
</Envelope>`]
            dispatch(getTicketInfo(params))
        } else {
            console.log('NO SESSION FOUND!!')
        }
    }


    useEffect(() => {
        // Start the interval when the component mounts
        const interval = setInterval(sessionIdHandler, 500000); // 120000 ms = 2 minutes

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []); // The empty dependency array ensures that the effect runs only once when the component mounts

    useEffect(() => {
        let sessionToken = sessionStorage.getItem('ticketToken')
        if (!sessionToken) {
            sessionIdHandler()
        } else {
            ticketDetailHandler()
        }
    }, [])


    useEffect(() => {
        if (window.innerWidth > 768) {
            gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

            let smoother = ScrollSmoother.create({
                smooth: 2,
                effects: true
            });
            let scroller = ScrollSmoother.create({});
            scroller.effects('.txtbx', {
                speed: 1.3,
                lag: 0,
                top: '0px',
                bottom: '0px'
            });

            const elements = [
                {
                    selector: '.zigzag',
                    distance: 100
                }
                // Add more objects for additional elements
            ];

            const boxes = document.querySelectorAll('.boxxx');

            boxes.forEach((box) => {
                gsap.to(box, {
                    y: '-=100px',
                    scrollTrigger: {
                        trigger: box,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: true,
                    },
                });
            });

            const boxes1 = document.querySelectorAll('.boxxxx');

            boxes1.forEach((box) => {
                gsap.to(box, {
                    y: '-=200px',
                    scrollTrigger: {
                        trigger: box,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: true,
                    },
                });
            });
        }
    }, []);


    // inner-page-location-fix

    useEffect(() => {
        if (location.pathname !== '/') {
            document.body.classList.add('in-inner-page')
        } else {
            document.body.classList.remove('in-inner-page')
        }
        window.scroll(0, 0)

    }, [location.pathname])


    return (
        <>
            <GlobalStyle/>
            <div className="page-transition">
                <img className={'logo'} src="/images/static/logo2.svg" alt=""/>
            </div>

            {Store?.auth?.loading || Store.checkout?.loading || Store.ticket?.detailLoading && <Loading/>}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />


            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">

                    {[
                        "/user/info",
                        "/user/update",
                        "/order/list",
                        "/ticket",
                        "/login",
                        "/sign_up",
                        "/forgetpass",
                        "/otp",
                        "/account",
                        "/terms",
                        "/about",
                        "/privacy-policy",
                        "/refund-policy",
                        "/checkout",
                    ].includes(location.pathname)
                    || location.pathname.startsWith("/momento/")
                    || location.pathname.startsWith("/blog/")
                    || location.pathname.startsWith("/order/status")
                    || location.pathname.startsWith("/payment-gateway")
                    || location.pathname.startsWith("/ticket/")
                    || location.pathname.startsWith("/ticket/")
                    || location.pathname.startsWith("/about")
                    || location.pathname.startsWith("/Terms")
                    || location.pathname.startsWith("/refund-policy")
                    || location.pathname.startsWith("/privacy-policy")
                    || location.pathname.startsWith("/experience/")
                    || location.pathname.startsWith("/interactive-map")
                    || location.pathname.startsWith("/360-view")
                    || location.pathname.startsWith("/mana")
                    || location.pathname.startsWith("/origins")
                    || location.pathname.startsWith("/lore")
                    || location.pathname.startsWith("/the-islanders")
                    || location.pathname.startsWith("/games")
                    || location.pathname.startsWith("/order/details") ? (
                        <Menu login/>
                    ) : (
                        <Menu2/>
                    )}


                    <Switch location={location} key={'location.pathname'}>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                        <Route exact path="/refund-policy" component={RefundPolicy}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/taste" component={Taste}/>
                        <Route exact path="/unwind" component={Unwind}/>
                        <Route exact path="/momento" component={Momento}/>
                        <Route exact path="/momento/products/:slug" component={product}/>
                        <Route exact path={`/momento/product/:slug`} component={MomentoDetails}/>
                        <Route exact path="/momento/products" component={product}/>
                        <Route exact path="/momento/products/checkout" component={Checkout}/>
                        <Route exact path={`/momento/products/:slug`} component={MomentoDetails}/>
                        <Route exact path="/manaverse" component={Manaverse}/>
                        <Route exact path="/experience/rides" component={Rides}/>
                        <Route exact path="/experience/attractions" component={Attractions}/>
                        <Route exact path="/faqs" component={Faqs}/>
                        {/*blog pages*/}
                        <Route exact path={`/blog`} component={Blog}/>
                        <Route exact path={`/blog/:slug`} component={BlogDetail}/>
                        <Route exact path={`/events`} component={Events}/>
                        <Route exact path={`/events/:slug`} component={EventDetail}/>
                        <Route exact path={`/terms`} component={Terms}/>
                        <Route exact path={`/ticket/:date`} component={Ticket}/>
                        <Route exact path={`/ticket`} component={Ticket}/>
                        <Route exact path={`/login`} component={Login}/>
                        <Route exact path={`/sign_up`} component={CreateAccount}/>
                        <Route exact path={`/forgetpass`} component={Forget_password}/>
                        <Route exact path={`/otp`} component={Otp}/>
                        <Route exact path={`/account`} component={Account}/>
                        <Route exact path={`/360-view`} component={ThreeSixtyView}/>
                        <Route exact path={`/origins`} component={Origins}/>
                        <Route exact path={`/lore`} component={Lore}/>
                        <Route exact path={`/the-islanders`} component={TheIslanders}/>
                        <Route exact path={`/mana`} component={Mana}/>
                        <Route exact path={`/games`} component={Games}/>


                        <Route exact path="/user/info" component={User}/>
                        <Route exact path="/user/update" component={UserUpdate}/>

                        <Route exact path="/order/list" component={Order}/>
                        <Route exact path="/order/details/:slug" component={OrderDetails}/>
                        <Route exact path="/payment-gateway-return" component={status}/>

                        <Route exact path="/checkout" component={checkout}/>
                        <Route exact path="/interactive-map" component={InteractiveMap}/>
                        <Route component={Error}/>
                    </Switch>

                    {[
                        "/login",
                        "/sign_up",
                        "/forgetpass",
                        "/otp",

                    ].includes(location.pathname) ? (
                        ''
                    ) : (
                        <Footer/>
                    )}
                </div>
            </div>
            <Cart/>

        </>


    );
}

export default App;
