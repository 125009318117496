import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Link} from "react-router-dom";


const MyComponent = ({
                         img,
                         price,
                         slug,
                         title
                     }) => {

    return (
        <StyledComponent>
            <div className="box-image__single">
                <Link className={'link'} to={`/momento/product/${slug}`}/>
                <div className="box-image__single__image">
                    <Img src={img}/>
                </div>
                <div className="box-image__single__text">
                    <h5>{title}</h5>
                    <h4>{price}</h4>
                </div>
            </div>
        </StyledComponent>
    );
};
const StyledComponent = styled.div`
  position: relative;

  .box-image__single {
    margin-top: 40px;
    border-radius: 10px;
    overflow: hidden;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      z-index: 2;
      cursor: pointer;
    }

    //&:hover {
    //  .global-image {
    //    transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
    //    transform: scale(1.06);
    //  }
    //  .box-image__single__downlaod__btn{
    //    svg{
    //      #Ellipse_636{
    //        r: 30px;
    //      }
    //    }
    //  }
    //}


    &__image {
      position: relative;
      padding-top: calc(320 / 270 * 100%);
      overflow: hidden;
      border-radius: 10px;

      img {
        border: 1px solid #E9E9E9;
        border-radius: 10px;
      }


    }

    &__text {

      margin: 30px 0 0 0;
      padding: 0 0 0 0;

      h5 {
        color: #1A1818;
        //font-size: 18px;
        //line-height: 27px;
        font-weight: 300;
      }

      h4 {
        color: #92278F;
        margin: 20px 0 0 0;
        //font-size: 24px;
        //line-height: 32px;
        font-weight: 400;

      }


    }

  }


  @media (min-width: 1550px) {
    .box-image__single {
      border-radius: 20px;
    }
  }



`;

export default MyComponent;
