import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Button from './Button';
import MobileMenu from './MobileMenu';
import ButtonTel from './ButtonTel';
import { useSelector } from 'react-redux';

const MyComponent = () => {
  const [token, setToken] = useState();
  let storeToken = useSelector((state) => state?.auth?.token);
  const location = useLocation();

  useEffect(() => {
    if (storeToken) {
      setToken(storeToken);
    } else {
      setToken(localStorage.getItem('token'));
    }
  }, [storeToken]);

  return (
    <StyledComponent className='menu'>
      <Container className='menu__desktop'>
        <Row className='menu__top__items'>
          <Col md={4} className='menu__top__items__left'>
            <ul>
              <li>
                <ButtonTel
                  text={'09606889999'}
                  src={'tel:+8809606889999'}
                  icon={
                    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">\n' +
                    '  <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.544,11.307l-3.5-1.5a.75.75,0,0,0-.875.216l-1.55,1.894A11.583,11.583,0,0,1,4.081,6.379l1.894-1.55a.748.748,0,0,0,.216-.875l-1.5-3.5A.755.755,0,0,0,3.831.019L.581.769A.75.75,0,0,0,0,1.5,14.5,14.5,0,0,0,14.5,16a.75.75,0,0,0,.731-.581l.75-3.25a.759.759,0,0,0-.438-.863Z" transform="translate(0 0)" fill="#1a1818"/>\n' +
                    '</svg>\n'
                  }
                  iconhover={'#fff'}
                  borderColor={'#000'}
                  color={'#000'}
                  hoverfill={'#000'}
                />
              </li>
              <li>
                <Link to={'/events'}>Events</Link>
              </li>
              <li>
                <Link to={'/blog'}>Blogs</Link>
              </li>
              <li>
                <Link to={'/faqs'}>FAQs</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className='menu__top__items__middle'>
            <Link to={'/'}>
              <img src={'/images/static/logo/mblogo.svg'} alt='Logo' />
            </Link>
          </Col>
          <Col
            md={4}
            className='menu__top__items__right d-flex justify-content-end'
          >
            <ul>
              <li>
                <Button
                  text={'Get Tickets'}
                  src={'/ticket'}
                  color={'#498DCB'}
                  hoverfill={'#498DCB'}
                  borderColor={'#498DCB'}
                />
              </li>

              {token ? (
                <Link
                  className='menu__top__items__right__profile-icon'
                  to={'/account'}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='46'
                    height='46'
                    viewBox='0 0 46 46'
                  >
                    <g
                      id='Group_19699'
                      data-name='Group 19699'
                      transform='translate(-1362 -28)'
                    >
                      <g id='Group_19542' data-name='Group 19542'>
                        <rect
                          id='Rectangle_126'
                          data-name='Rectangle 126'
                          width='46'
                          height='46'
                          rx='23'
                          transform='translate(1362 28)'
                          fill='#1a1818'
                          opacity='0.1'
                        />
                        <path
                          id='Icon_awesome-user-alt'
                          data-name='Icon awesome-user-alt'
                          d='M10,11.25A5.625,5.625,0,1,0,4.375,5.625,5.626,5.626,0,0,0,10,11.25Zm5,1.25H12.848a6.8,6.8,0,0,1-5.7,0H5a5,5,0,0,0-5,5v.625A1.875,1.875,0,0,0,1.875,20h16.25A1.875,1.875,0,0,0,20,18.125V17.5A5,5,0,0,0,15,12.5Z'
                          transform='translate(1375 41)'
                          fill='#989898'
                        />
                      </g>
                    </g>
                  </svg>
                </Link>
              ) : (
                <Button
                  src={'/login'}
                  text={'Log In'}
                  background={'#000'}
                  borderColor={'#000'}
                  hoverfill={'#92278F'}
                  color={'#fff'}
                />
              )}
            </ul>
          </Col>
        </Row>
        <Container className='menu__bottom'>
          <Row>
            <Col md={12}>
              <ul>
                <li>
                  <a>
                    <span>Experience</span>
                  </a>
                  <div className='submenu'>
                    <ul>
                      <li>
                        <Link to={'/experience/rides'}>Rides</Link>
                      </li>
                      <li>
                        <Link to={'/experience/attractions'}>Attractions</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className={location.pathname === '/taste' ? 'active' : ''}>
                  <Link to={'/taste'}>
                    <span>Taste</span>
                  </Link>
                </li>
                <li className={location.pathname === '/unwind' ? 'active' : ''}>
                  <Link to={'/unwind'}>
                    <span>Unwind</span>
                  </Link>
                </li>
                {/*<li>*/}
                {/*    <Link to={'/momento'}><span>Momento</span></Link>*/}
                {/*</li>*/}
                <li>
                  <a>
                    <span>Manaverse</span>
                  </a>
                  <div className='submenu'>
                    <ul>
                      <li>
                        <Link to={'/interactive-map'}>Interactive Map</Link>
                      </li>
                      <li>
                        <Link to={'/360-view'}>360 View</Link>
                      </li>
                      <li>
                        <Link to={'/mana'}>Mana</Link>
                      </li>
                      <li>
                        <Link to={'/origins'}>Origins</Link>
                      </li>
                      <li>
                        <Link to={'/the-islanders'}>The Islanders</Link>
                      </li>
                      <li>
                        <Link to={'/lore'}>Lore</Link>
                      </li>
                      <li>
                        <Link to={'/games'}>Games</Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      <MobileMenu />
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  .menu__desktop {
    width: 100%;
    margin-top: 27px;
    margin-bottom: 27px;

    .menu__top__items {
      align-items: center;

      &__left {
        width: 100%;
        z-index: 99;

        ul {
          li {
            a {
              font-size: 14px;
              line-height: 16px;
              font-weight: 300;
              color: #1a1818;

              &:hover {
                color: #92278f;
              }
            }

            .btn {
              span {
                display: flex;
                justify-content: space-between;
                align-items: center;
                vertical-align: center;
                border: 1px solid #000;
                border-radius: 30px;
                padding: 15px 30px;
                width: max-content;

                p {
                  margin-left: 10px;

                  a {
                    color: #000;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 300;
                  }
                }
              }
            }
          }
        }
      }

      &__middle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__right {
        ul {
          li {
            margin-right: 0;
          }
        }
      }
    }

    ul {
      display: flex;

      li {
        color: #000;
        align-self: center;
        margin-right: 30px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .menu__bottom {
      position: absolute;
      z-index: 99999;
      right: 0;
      left: 0;
      text-align: center;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #fff;
        padding-bottom: 20px;
        margin-top: 40px;

        li {
          color: #fff;
          align-self: center;
          margin-right: 40px;
          cursor: pointer;
          font-size: 16px;
          line-height: 20px;
          font-weight: 300;
          position: relative;

          &:hover {
            .submenu {
              display: block;
            }
          }

          a {
            padding-bottom: 22px;
            color: #fff;

            &:before {
              content: '';
              background-color: #fcb940;
              height: 50%;
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 0;
              //opacity: 0.5;
              opacity: 0;
            }

            span {
              position: relative;
              z-index: 9999999999;
            }

            &:hover:before {
              opacity: 1;
            }
          }

          .submenu {
            display: none;
            position: absolute;
            left: 0;
            //top: 40px;
            background: #fff;
            padding: 20px;
            width: 245px;
            text-align: initial;
            top: 40px;
            box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);

            ul {
              display: block;
              list-style: none;
              padding: 0;
              margin: 0;

              li {
                padding-bottom: 20px;

                a {
                  color: #000;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 300;
                  text-decoration: none;

                  &:hover {
                    color: #92278f;
                  }

                  &:hover:before {
                    display: none;
                  }
                }
              }
            }
          }

          &.active {
            a:before {
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      display: none;
    }
  }
`;

export default MyComponent;
