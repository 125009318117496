import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Ticketdetails from "../../components/ticket/Ticketdetails";
import {useEffect} from "react";
import {getProfile} from "../../api/redux/auth";
import {useHistory} from "react-router-dom";


const MyComponent = () => {
    const history = useHistory()

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Ticket | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <Ticketdetails/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
