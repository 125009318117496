import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../network/axiosServices";
import { apiEndPoints } from "../../network/apiEndPoints";

const initialState = {
  loading: false,
  message: "",
  cartData: {},
  cartTotal: 0,
  oderList: [],
  oderDetails: {},
  error: "",
  bkashTokenData: {},
};

export const addToCart = createAsyncThunk("addToCart", (params) => {
  let cartParam = [
    `${apiEndPoints.ADD_TO_CART}?user_id=${params?.id}&product_id=${params?.productId}&count=${params?.count}&token=${params?.token}`,
  ];
  return post(cartParam);
});

export const cart = createAsyncThunk("cart", (params) => {
  let cartParam = [
    `${apiEndPoints.CART}?user_id=${params?.id}&token=${params?.token}`,
  ];
  return get(cartParam);
});

export const cartUpdate = createAsyncThunk("cartUpdate", (params) => {
  let cartParam = [
    `${apiEndPoints.CART_UPDATE}?user_id=${params?.id}&token=${params?.token}&product_id=${params?.productId}&count=${params?.count}&flag=${params?.flag}`,
  ];
  return post(cartParam);
});

export const placeOrder = createAsyncThunk("placeOrder", (params) => {
  let cartParam = [
    `${apiEndPoints.PLACE_ORDER}?user_id=${params?.id}&token=${params?.token}&domain_info=${params?.domain}`,
  ];
  return post(cartParam);
});

export const ticketPlaceOrder = createAsyncThunk(
  "ticketPlaceOrder",
  (params) => {
    let cartParam = [`${apiEndPoints.TICKET_PLACE_ORDER}`, params];
    return post(cartParam);
  }
);

// export const getBkashToken = createAsyncThunk("getBkash", (params = "") => {
//   let grantParam = [`${apiEndPoints.BKASH_GRANT}`, params];
//   return get(grantParam);
// });

export const eventTicketPurchase = createAsyncThunk(
  "eventTicketPurchase",
  (params) => {
    let cartParam = [`${apiEndPoints.EVENT_TICKET_PURCHASE}`, params];
    return post(cartParam);
  }
);

export const orderList = createAsyncThunk("orderList", (params) => {
  let cartParam = [
    `${apiEndPoints.ORDER_LIST}?user_id=${params?.id}&token=${params?.token}`,
  ];
  return get(cartParam);
});

export const orderDetails = createAsyncThunk("orderDetails", (params) => {
  let cartParam = [
    `${apiEndPoints.ORDER_DETAIL}?user_id=${params?.id}&token=${params?.token}&order_id=${params?.orderId}`,
  ];
  return get(cartParam);
});

export const ticketVerify = createAsyncThunk("ticketVerify", (params) => {
  let cartParam = [
    `${apiEndPoints.TICKET_ORDER_VERIFY}?user_id=${params?.id}&user_token=${params?.token}&egalaxy_session=${params?.egalaxy_session}&order_id=${params?.orderId}`,
  ];
  return get(cartParam);
});

export const clearCart = createAsyncThunk("clearCart", (params) => {
  return "";
});

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addToCart.pending, (state) => {
      state.loading = true;
      state.message = [];
      state.error = "";
    });
    builder.addCase(addToCart.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.error = "";
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.loading = false;
      state.message = [];
      state.error = action?.response;
    });
    builder.addCase(cart.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(cart.fulfilled, (state, action) => {
      state.loading = false;
      state.cartData = action.payload.data;
      state.cartTotal = action.payload.data?.total;
      state.error = "";
    });
    builder.addCase(cart.rejected, (state, action) => {
      state.loading = false;
      state.cartData = [];
      state.cartTotal = 0;
      state.error = action?.response;
    });
    builder.addCase(cartUpdate.pending, (state) => {
      state.loading = true;
      state.message = [];
      state.error = "";
    });
    builder.addCase(cartUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.error = "";
    });
    builder.addCase(cartUpdate.rejected, (state, action) => {
      state.loading = false;
      state.message = [];
      state.error = action?.response;
    });
    builder.addCase(placeOrder.pending, (state) => {
      state.loading = true;
      state.message = [];
      state.error = "";
    });
    builder.addCase(placeOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.error = "";
    });
    builder.addCase(placeOrder.rejected, (state, action) => {
      state.loading = false;
      state.message = [];
      state.error = action?.response;
    });
    builder.addCase(ticketPlaceOrder.pending, (state) => {
      state.loading = true;
      state.message = [];
      state.error = "";
    });
    builder.addCase(ticketPlaceOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.error = "";
    });
    builder.addCase(ticketPlaceOrder.rejected, (state, action) => {
      state.loading = false;
      state.message = [];
      state.error = action?.response;
    });
    builder.addCase(eventTicketPurchase.pending, (state) => {
      state.loading = true;
      state.message = [];
      state.error = "";
    });
    builder.addCase(eventTicketPurchase.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.data;
      state.error = "";
    });
    builder.addCase(eventTicketPurchase.rejected, (state, action) => {
      state.loading = false;
      state.message = [];
      state.error = action?.response;
    });
    builder.addCase(orderList.pending, (state) => {
      state.loading = true;
      state.orderList = [];
      state.error = "";
    });
    builder.addCase(orderList.fulfilled, (state, action) => {
      state.loading = false;
      state.orderList = action.payload.data;
      state.error = "";
    });
    builder.addCase(orderList.rejected, (state, action) => {
      state.loading = false;
      state.orderList = [];
      state.error = action?.response;
    });
    builder.addCase(orderDetails.pending, (state) => {
      state.loading = true;
      state.orderDetails = [];
      state.error = "";
    });
    builder.addCase(orderDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.orderDetails = action.payload.data;
      state.error = "";
    });
    builder.addCase(orderDetails.rejected, (state, action) => {
      state.loading = false;
      state.orderDetails = [];
      state.error = action?.response;
    });
    builder.addCase(ticketVerify.pending, (state) => {
      state.loading = true;
      state.orderDetails = [];
      state.error = "";
    });
    builder.addCase(ticketVerify.fulfilled, (state, action) => {
      state.loading = false;
      state.orderDetails = action.payload.data;
      state.error = "";
    });
    builder.addCase(ticketVerify.rejected, (state, action) => {
      state.loading = false;
      state.orderDetails = [];
      state.error = action?.response;
    });
    builder.addCase(clearCart.pending, (state) => {
      state.cartData = {};
      state.cartTotal = 0;
      state.oderList = [];
      state.oderDetails = {};
    });
    builder.addCase(clearCart.fulfilled, (state, action) => {
      state.cartData = {};
      state.cartTotal = 0;
      state.oderList = [];
      state.oderDetails = {};
    });
    builder.addCase(clearCart.rejected, (state, action) => {
      state.cartData = {};
      state.cartTotal = 0;
      state.oderList = [];
      state.oderDetails = {};
    });
    //bKash State Handlers
    // builder.addCase(getBkashToken.pending, (state, action) => {
    //   state.loading = true;
    //   state.error = "";
    // });
    // builder.addCase(getBkashToken.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.error = "";
    // });
    // builder.addCase(getBkashToken.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.statusMessage;
    // });
  },
});

export const { setCartData } = checkoutSlice.actions;
export default checkoutSlice.reducer;
