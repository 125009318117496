import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "../Button";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ticketVerify} from "../../api/redux/checkout";
import {getProfile} from "../../api/redux/auth";
import {postTicketOrder} from "../../api/redux/ticket";
import moment from "moment";

const MyComponent = () => {
    const [status, setStatus] = useState()
    const [ticketStatus, setTicketStatus] = useState()
    const [tickets, setTickets] = useState([])
    const [callCounter, setCallCounter] = useState(true)
    const {slug} = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const userData = useSelector(state => state?.auth?.userData?.data);
    const ticketOrder = useSelector(state => state?.ticket?.ticket);

    const parseQueryString = (queryString) => {
        const params = {};
        queryString
            .substring(1)
            .split('&')
            .forEach((param) => {
                const [key, value] = param.split('=');
                params[key] = value;
            });
        return params;
    };

    useEffect(() => {
        const queryParams = parseQueryString(location.search);
        let ticketData = JSON.parse(sessionStorage.getItem('ticketData'))
        setTickets(ticketData?.Envelope?.Body?.ItemList?.Item)
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let params = {
            id: id,
            token: token,
        }

        dispatch(getProfile(params))
        setStatus(queryParams)
    }, [])


    return (
        <StyledComponent>
            <Container className={'order-status'}>
                <Row>
                    {
                        status && status?.status === "success" ?
                            <Col>
                                {
                                    status?.type === 'ticket' ?
                                        <div className={'order-status__content'}>
                                            <h1><img src="/images/static/success-icon.svg" alt=""/></h1>
                                            <p>Check your mail to track the order</p>
                                            <Button text={'Go to Website'} src={'/'} color={'#92278F'}
                                                    borderColor={'#92278F'}
                                                    hoverfill={'#92278F'}/>
                                        </div> :
                                        status?.type === 'ecommerce' ?
                                            <div className={'order-status__content'}>
                                                <h1><img src="/images/static/success-icon.svg" alt=""/></h1>
                                                <h3>Your order has been placed successfully</h3>
                                                <p>Check your mail to track the order</p>
                                                <Button text={'Go to Website'} src={'/'} color={'#92278F'}
                                                        borderColor={'#92278F'}
                                                        hoverfill={'#92278F'}/>
                                            </div> : status?.type === 'event' ?
                                                <div className={'order-status__content'}>
                                                    <h1><img src="/images/static/success-icon.svg" alt=""/></h1>
                                                    <h3>Your order has been placed successfully</h3>
                                                    <p>Check your mail to track the order</p>
                                                    <Button text={'Go to Website'} src={'/'} color={'#92278F'}
                                                            borderColor={'#92278F'}
                                                            hoverfill={'#92278F'}/>
                                                </div> : null
                                }
                            </Col> : status?.status === "cancel" ?
                                <Col>
                                    <div className={'order-status__content'}>
                                        <h1><img src="/images/static/sorry.svg" alt=""/></h1>
                                        <h3>Your Order has been canceled</h3>
                                        <p></p>
                                        <Button text={'Go to Website'} src={'/'} color={'#92278F'} borderColor={'#92278F'}
                                                hoverfill={'#92278F'}/>
                                    </div>
                                </Col> : status?.status === "failed" || status?.status === "fail" ?
                                    <Col>
                                        <div className={'order-status__content'}>
                                            <h1><img src="/images/static/wrong.svg" alt=""/></h1>
                                            <h3>We cannot process your order. Please try again</h3>
                                            <p></p>
                                            <Button text={'Go to Website'} src={'/'} color={'#92278F'}
                                                    borderColor={'#92278F'} hoverfill={'#92278F'}/>
                                        </div>
                                    </Col> : null

                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: 100vh;
  background-color: #FFFFFF;

  .order-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__content {
      text-align: center;

      h1 {
        color: #92278F;
        margin-bottom: 40px;

        img {
          height: 90px;
        }
      }

      h3 {
        color: #1A1818;
        margin-bottom: 10px;
      }

      p {
        color: #1A1818;
        margin-bottom: 40px;
      }
    }
  }

`;

export default MyComponent;
