import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {useDispatch, useSelector} from "react-redux";
import Button from "./Button";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import disableScroll from 'disable-scroll';
import {click} from "@testing-library/user-event/dist/click";

const MyComponent = ({setOffset, data}) => {


    const mobileMenuRaf = useRef()
    const menuRef = useRef()
    const hamburgerRef = useRef();
    const hamburgerCloseRef = useRef();
    const [scrollDirection, setScrollDirection] = useState('down');
    const [showSubmenu, setShowSubmenu] = useState(null);
    const [showSubmenuSub, setShowSubmenuSub] = useState(null);
    const [showSubmenuSubSub, setShowSubmenuSubSub] = useState(null);

    // get all siblings
    const getSiblings = (e) => {
        // for collecting siblings
        let siblings = [];
        // if no parent, return no sibling
        if (!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling = e.parentNode.firstChild;
        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };


    // desktop menu handle
    const handleSubmenuClick = (id, event) => {
        event.stopPropagation();
        // setShowSubmenu(id === showSubmenu ? null : id);
        setShowSubmenu(id === showSubmenu ? null : id);

        let overlay = document.querySelector("body");
        //
        if (id === showSubmenu ? null : id) {
            overlay.classList.add('sub-menu-open');
        } else {
            overlay.classList.remove('sub-menu-open');
        }
        setShowSubmenuSub(null);
        setShowSubmenuSubSub(null);


    };
    const handleSubmenuSubClick = (id, event) => {
        event.stopPropagation();
        // setShowSubmenu(id === showSubmenu ? null : id);
        setShowSubmenuSub(id === showSubmenuSub ? null : id);
        setShowSubmenuSubSub(null);

    };
    const handleSubmenuSubSubClick = (id, event) => {
        event.stopPropagation();
        // setShowSubmenu(id === showSubmenu ? null : id);
        setShowSubmenuSubSub(id === showSubmenuSubSub ? null : id);
    };


    const remove_all_mobile = (e) => {
        var menuOpen = document.querySelectorAll(".Mobile-menu-wrap");
        var hamburger = document.querySelectorAll(".hamburger");
        var menu = document.querySelectorAll(".main_child");
        var menuSub = document.querySelectorAll(".main-child-sub ")
        var menuSubSub = document.querySelectorAll(".main-child-sub-sub");
        hamburger.forEach((i) => {
            i.classList.remove("menu-open");
            gsap.to(menuRef?.current, {
                opacity: 0,
                duration: ".2",
            });
            gsap.to(menuRef?.current, {
                display: "none",
            });
        });
        menuOpen.forEach((i) => {
            i.classList.remove("menu-open");

        });

        menu.forEach((i) => {
            i.classList.remove("submenu-open-mb");

        });
        menuSub.forEach((i) => {
            i.classList.remove("submenu-open-next");

        });
        menuSubSub.forEach((i) => {
            i.classList.remove("submenu-open-next-next");
            i.classList.remove("submenu-open-next");
            i.classList.remove("submenu-open-mb");

        });
    }

    const handleAboutClick = (e) => {

        remove_all_mobile()
    };
    useEffect(() => {

        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";

        let getSiblings = function (e) {
            // for collecting siblings
            let siblings = [];
            // if no parent, return no sibling
            if (!e.parentNode) {
                return siblings;
            }
            // first child of the parent node
            let sibling = e.parentNode.firstChild;
            // collecting siblings
            while (sibling) {
                if (sibling.nodeType === 1 && sibling !== e) {
                    siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
            }
            return siblings;
        };

        // Mobile menu start
        // for mobile submenu


        let getDropdownChildMobile = document.querySelectorAll(".main_child");
        getDropdownChildMobile.forEach((i) => {
            i.addEventListener("click", (r) => {
                // r.target.parentElement.classList.toggle("sub-menu-open");
                if (r.target.parentElement.classList.contains('submenu-open-mb')) {
                    r.target.parentElement.classList.remove("submenu-open-mb");

                } else {
                    // if (document.querySelector(".sub-menu-open")) {
                    //     document
                    //         .querySelector(".sub-menu-open")
                    //         .classList.remove("sub-menu-open");
                    // }

                    r.target.parentElement.classList.toggle("submenu-open-mb");
                    // r.target.parentElement.nextElementSibling.classList.remove('submenu-open')

                    document.querySelector('.submenu-open-mb').addEventListener('click', (ne) => {
                        let parent = ne.target.parentElement;
                        parent.parentElement.classList.remove("submenu-open-mb");
                    })

                }
            });
        });


        let nextStepChild = document.querySelectorAll(".main-child-sub");
        nextStepChild.forEach((i) => {
            i.addEventListener("click", (r) => {
                if (r.target.parentElement.classList.contains('submenu-open-next')) {
                    r.target.parentElement.classList.remove("submenu-open-next");
                } else {
                    r.target.parentElement.classList.toggle("submenu-open-next");
                    document.querySelector('.submenu-open-next').addEventListener('click', (ne) => {
                        let parent = ne.target.parentElement;
                        parent.parentElement.classList.remove("submenu-open-next");
                    })

                }
            });
        });


        let nextStepChildChild = document.querySelectorAll(".main-child-sub-sub");
        nextStepChildChild.forEach((i) => {
            i.addEventListener("click", (r) => {
                // r.target.parentElement.classList.toggle("sub-menu-open");

                if (r.target.parentElement.classList.contains('submenu-open-next-next')) {
                    r.target.parentElement.classList.remove("submenu-open-next-next");


                } else {
                    // if (document.querySelector(".sub-menu-open")) {
                    //     document
                    //         .querySelector(".sub-menu-open")
                    //         .classList.remove("sub-menu-open");
                    // }

                    r.target.parentElement.classList.toggle("submenu-open-next-next");
                    // r.target.parentElement.nextElementSibling.classList.remove('submenu-open')

                    document.querySelector('.submenu-open-next-next').addEventListener('click', (ne) => {
                        let parent = ne.target.parentElement;
                        parent.parentElement.classList.remove("submenu-open-next-next");
                    })

                }
            });
        });


        //menu click on mobile
        hamburgerRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.add("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.add("menu-open");
            gsap.to(menuRef?.current, {
                display: "block",
            });
            gsap.to(menuRef?.current, {
                opacity: 1,
                duration: ".3",
            });
            document.body.style.overflow = "hidden";
            // const target = document.querySelectorAll('.Mobile-menu-wrap, .submenu, .main-child')
            //  disableBodyScroll(target);
        });


        hamburgerCloseRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-open");
            gsap.to(menuRef?.current, {
                opacity: 0,
                duration: ".2",
            });
            gsap.to(menuRef?.current, {
                display: "none",
            });
            document.body.style.overflow = "visible ";
        });

        const menuItems = document.querySelectorAll('.Mobile-menu-wrap ul li a .submenu .sub-list');
        menuItems.forEach(item => {
            document.body.style.overflow = "visible ";
        });

        // Mobile menu end
        //---- on scroll menu fixed action
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }
    }, []);


    // Helper function to close the menu
    function closeMenu() {
        if (menuRef && menuRef?.current) {
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-open");
            gsap.to(menuRef?.current, {
                opacity: 0,
                duration: 0.2,
                // onComplete: () => {
                //     menuRef.current.style.display = "none";
                // },
            });
        }
    }


// Event listener for menu item clicks
    const menuItems = document.querySelectorAll(".Mobile-menu-wrap ul li a");
    menuItems.forEach((item) => {
        item.addEventListener("click", (e) => {
            closeMenu(); // Close the menu when a menu item is clicked
            document.body.style.overflow = "auto"; // Reset body overflow to auto
        });
    });


    return (
        <StyledComponent className="Mobile-menu-wrap menu-bar">
            <Container>
                <Row className="mobile-menu" ref={mobileMenuRaf}>
                    <div className="mobile-munu__head">
                        <Col xs={6} className="hamburger">
                            <span>
                          <svg ref={hamburgerRef} style={{cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg"
                               width="40" height="40"
                               viewBox="0 0 40 40">
                              <defs>
                                <linearGradient id="linear-gradient" y1="0.113" x2="1" y2="0.913"
                                                gradientUnits="objectBoundingBox">
                                  <stop offset="0" stop-color="#5ad1d1"/>
                                  <stop offset="1" stop-color="#498dcb"/>
                                </linearGradient>
                              </defs>
                              <g id="Group_18367" data-name="Group 18367" transform="translate(-16 -25)">
                                <rect id="Rectangle_126" data-name="Rectangle 126" width="40" height="40" rx="10"
                                      transform="translate(16 25)"
                                      fill="url(#linear-gradient)"/>
                                <g id="Group_18366" data-name="Group 18366" transform="translate(2.5 -2.5)">
                                  <line id="Line_3901" data-name="Line 3901" x2="16" transform="translate(25.5 54.5)"
                                        fill="none" stroke="#fff"
                                        stroke-linecap="round" stroke-width="2"/>
                                  <line id="Line_3900" data-name="Line 3900" x2="16" transform="translate(25.5 47.5)"
                                        fill="none" stroke="#fff"
                                        stroke-linecap="round" stroke-width="2"/>
                                  <line id="Line_3899" data-name="Line 3899" x2="16" transform="translate(25.5 40.5)"
                                        fill="none" stroke="#fff"
                                        stroke-linecap="round" stroke-width="2"/>
                                </g>
                              </g>
                            </svg>
                           <Link to="/"><img src={'/images/static/logo-mob.png'}/></Link>
                      </span>
                            <span>
                                 <svg className={'closeing'} ref={hamburgerCloseRef} xmlns="http://www.w3.org/2000/svg"
                                      width="40" height="40" viewBox="0 0 40 40">
                                      <defs>
                                        <linearGradient id="linear-gradient" y1="0.113" x2="1" y2="0.913"
                                                        gradientUnits="objectBoundingBox">
                                          <stop offset="0" stop-color="#5ad1d1"/>
                                          <stop offset="1" stop-color="#498dcb"/>
                                        </linearGradient>
                                      </defs>
                                      <g id="Group_18367" data-name="Group 18367" transform="translate(-16 -25)">
                                        <rect id="Rectangle_126" data-name="Rectangle 126" width="40" height="40"
                                              rx="10" transform="translate(16 25)" fill="url(#linear-gradient)"/>
                                        <g id="Group_19373" data-name="Group 19373" transform="translate(-2.343 0.657)">
                                          <line id="Line_3901" data-name="Line 3901" x2="22.627"
                                                transform="translate(30.343 52.343) rotate(-45)" fill="none"
                                                stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                          <line id="Line_3899" data-name="Line 3899" x2="22.627"
                                                transform="translate(30.343 36.343) rotate(45)" fill="none"
                                                stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                      </g>
                                    </svg>

                                <Link to={"/"}><img src={'/images/static/logo-mob.png'}/></Link>

                        </span>
                        </Col>
                        <Col xs={6} className={'get-ticket-btn'}>
                            <Button text={'Get Tickets'} src={'/ticket'} color={'#498DCB'} hoverfill={'#498DCB'}
                                    borderColor={'#498DCB'}/>
                        </Col>
                    </div>
                    <div>
                        <div className="mobile-menu__items" ref={menuRef}>
                            <ul className="mobile-menu__items__ul">
                                <Accordion defaultActiveKey="">
                                    <li>
                                        <Link to={'/'} onClick={() => handleAboutClick()}>
                                            Home
                                        </Link>
                                    </li>
                                    <li className={'main_child'}>

                                        <span>
                                            Experience
                                            <svg className={'sub-icon'} xmlns="http://www.w3.org/2000/svg" width="20"
                                                 height="20" viewBox="0 0 20 20">
                                                <g id="Group_19374" data-name="Group 19374"
                                                   transform="translate(-2195 -960)" opacity="0.5">
                                                    <g id="Ellipse_4" data-name="Ellipse 4"
                                                       transform="translate(2195 960)" fill="none" stroke="#1a1818"
                                                       stroke-width="1">
                                                        <circle cx="10" cy="10" r="10" stroke="none"/>
                                                        <circle cx="10" cy="10" r="9.5" fill="none"/>
                                                    </g>
                                                    <circle id="Ellipse_268" data-name="Ellipse 268" cx="0.5" cy="0.5"
                                                            r="0.5" transform="translate(2204.5 969.5)" fill="#fcb940"
                                                            opacity="0"/>
                                                    <g id="Group_16184" data-name="Group 16184"
                                                       transform="translate(2201 972.5) rotate(-90)">
                                                        <line id="Line_53" data-name="Line 53" y2="7.519"
                                                              transform="translate(2.5 0)" fill="none" stroke="#1a1818"
                                                              stroke-linecap="round" stroke-width="1"/>
                                                        <g id="g2060" transform="translate(0 8.019) rotate(-90)">
                                                            <path id="path2056" d="M0,0,2.707,2.5"
                                                                  transform="translate(0 2.5)" fill="none"
                                                                  stroke="#1a1818" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-width="1"/>
                                                            <path id="path2058" d="M0,2.5,2.707,0"
                                                                  transform="translate(0)" fill="none" stroke="#1a1818"
                                                                  stroke-linecap="round" stroke-linejoin="round"
                                                                  stroke-width="1"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>

                                        <ul className={'submenu'}>
                                            <div className="main_title">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     viewBox="0 0 20 20">
                                                    <g id="Group_19379" data-name="Group 19379"
                                                       transform="translate(2215 980) rotate(180)">
                                                        <g id="Ellipse_4" data-name="Ellipse 4"
                                                           transform="translate(2195 960)" fill="none" stroke="#92278f"
                                                           stroke-width="1">
                                                            <circle cx="10" cy="10" r="10" stroke="none"/>
                                                            <circle cx="10" cy="10" r="9.5" fill="none"/>
                                                        </g>
                                                        <circle id="Ellipse_268" data-name="Ellipse 268" cx="0.5"
                                                                cy="0.5" r="0.5" transform="translate(2204.5 969.5)"
                                                                fill="#fcb940" opacity="0"/>
                                                        <g id="Group_16184" data-name="Group 16184"
                                                           transform="translate(2201 972.5) rotate(-90)">
                                                            <line id="Line_53" data-name="Line 53" y2="7.519"
                                                                  transform="translate(2.5 0)" fill="none"
                                                                  stroke="#92278f" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            <g id="g2060" transform="translate(0 8.019) rotate(-90)">
                                                                <path id="path2056" d="M0,0,2.707,2.5"
                                                                      transform="translate(0 2.5)" fill="none"
                                                                      stroke="#92278f" stroke-linecap="round"
                                                                      stroke-linejoin="round" stroke-width="1"/>
                                                                <path id="path2058" d="M0,2.5,2.707,0"
                                                                      transform="translate(0)" fill="none"
                                                                      stroke="#92278f" stroke-linecap="round"
                                                                      stroke-linejoin="round" stroke-width="1"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                Experience
                                            </div>
                                            <li className={'sub-list'}>
                                                <Link to="/experience/rides"
                                                      onClick={() => handleAboutClick()}>Rides</Link>
                                            </li>
                                            <li className={'sub-list'}>
                                                <Link to="/experience/attractions"
                                                      onClick={() => handleAboutClick()}>Attractions</Link>
                                            </li>
                                        </ul>
                                    </li>


                                    <li>
                                        <Link to={'/taste'} onClick={() => handleAboutClick()}>Taste</Link>
                                    </li>


                                    <li>
                                        <Link to={'/unwind'} onClick={() => handleAboutClick()}>Unwind</Link>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link to={'/momento'} onClick={() => handleAboutClick()}>Momento</Link>*/}
                                    {/*</li>*/}
                                    <li className={'main_child'}>
                                        <span>
                                            Manaverse
                                            <svg className={'sub-icon'} xmlns="http://www.w3.org/2000/svg" width="20"
                                                 height="20" viewBox="0 0 20 20">
                                                <g id="Group_19374" data-name="Group 19374"
                                                   transform="translate(-2195 -960)" opacity="0.5">
                                                    <g id="Ellipse_4" data-name="Ellipse 4"
                                                       transform="translate(2195 960)" fill="none" stroke="#1a1818"
                                                       stroke-width="1">
                                                        <circle cx="10" cy="10" r="10" stroke="none"/>
                                                        <circle cx="10" cy="10" r="9.5" fill="none"/>
                                                    </g>
                                                    <circle id="Ellipse_268" data-name="Ellipse 268" cx="0.5" cy="0.5"
                                                            r="0.5" transform="translate(2204.5 969.5)" fill="#fcb940"
                                                            opacity="0"/>
                                                    <g id="Group_16184" data-name="Group 16184"
                                                       transform="translate(2201 972.5) rotate(-90)">
                                                        <line id="Line_53" data-name="Line 53" y2="7.519"
                                                              transform="translate(2.5 0)" fill="none" stroke="#1a1818"
                                                              stroke-linecap="round" stroke-width="1"/>
                                                        <g id="g2060" transform="translate(0 8.019) rotate(-90)">
                                                            <path id="path2056" d="M0,0,2.707,2.5"
                                                                  transform="translate(0 2.5)" fill="none"
                                                                  stroke="#1a1818" stroke-linecap="round"
                                                                  stroke-linejoin="round" stroke-width="1"/>
                                                            <path id="path2058" d="M0,2.5,2.707,0"
                                                                  transform="translate(0)" fill="none" stroke="#1a1818"
                                                                  stroke-linecap="round" stroke-linejoin="round"
                                                                  stroke-width="1"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>

                                        <ul className={'submenu'}>
                                            <div className="main_title">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     viewBox="0 0 20 20">
                                                    <g id="Group_19379" data-name="Group 19379"
                                                       transform="translate(2215 980) rotate(180)">
                                                        <g id="Ellipse_4" data-name="Ellipse 4"
                                                           transform="translate(2195 960)" fill="none" stroke="#92278f"
                                                           stroke-width="1">
                                                            <circle cx="10" cy="10" r="10" stroke="none"/>
                                                            <circle cx="10" cy="10" r="9.5" fill="none"/>
                                                        </g>
                                                        <circle id="Ellipse_268" data-name="Ellipse 268" cx="0.5"
                                                                cy="0.5" r="0.5" transform="translate(2204.5 969.5)"
                                                                fill="#fcb940" opacity="0"/>
                                                        <g id="Group_16184" data-name="Group 16184"
                                                           transform="translate(2201 972.5) rotate(-90)">
                                                            <line id="Line_53" data-name="Line 53" y2="7.519"
                                                                  transform="translate(2.5 0)" fill="none"
                                                                  stroke="#92278f" stroke-linecap="round"
                                                                  stroke-width="1"/>
                                                            <g id="g2060" transform="translate(0 8.019) rotate(-90)">
                                                                <path id="path2056" d="M0,0,2.707,2.5"
                                                                      transform="translate(0 2.5)" fill="none"
                                                                      stroke="#92278f" stroke-linecap="round"
                                                                      stroke-linejoin="round" stroke-width="1"/>
                                                                <path id="path2058" d="M0,2.5,2.707,0"
                                                                      transform="translate(0)" fill="none"
                                                                      stroke="#92278f" stroke-linecap="round"
                                                                      stroke-linejoin="round" stroke-width="1"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>

                                                Manaverse
                                            </div>
                                            <li className={'sub-list'}>
                                                <Link to="/interactive-map" onClick={() => handleAboutClick()}>Interactive
                                                    Map</Link>
                                            </li>
                                            {/*<li className={'sub-list'}>*/}
                                            {/*    <Link to="/" onClick={() => handleAboutClick()}>360 View</Link>*/}
                                            {/*</li>*/}
                                            {/*<li className={'sub-list'}>*/}
                                            {/*    <Link to="/" onClick={() => handleAboutClick()}>Mana</Link>*/}
                                            {/*</li>*/}
                                            {/*<li className={'sub-list'}>*/}
                                            {/*    <Link to="/" onClick={() => handleAboutClick()}>Origins</Link>*/}
                                            {/*</li>*/}
                                            {/*<li className={'sub-list'}>*/}
                                            {/*    <Link to="/" onClick={() => handleAboutClick()}>The Islanders</Link>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link to={'/events'} onClick={() => handleAboutClick()}>Events</Link>*/}

                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <Link to={'/blog'} onClick={() => handleAboutClick()}>Blog</Link>*/}

                                    {/*</li>*/}
                                    <li>
                                        <Link to={'/faqs'} onClick={() => handleAboutClick()}>FAQ</Link>
                                    </li>
                                </Accordion>


                            </ul>
                            <div className="svg-icon">
                                <img src="/images/static/m-menu.svg" alt=""/>
                            </div>
                        </div>
                    </div>


                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  @media (min-width: 992px) {
    display: none;
  }

  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 99999;
  display: flex;
  //align-items: center;

  transition: background .4s cubic-bezier(0.4, 0, 0, 1);
  overflow: visible !important;

  //display: none;

  .menu-bar {
    background-color: transparent !important;
    box-shadow: unset !important;
  }

  .forClose {
    display: none !important;

    a {
      img {
        height: 50px !important;
      }
    }
  }

  &.menu-open {
    background: #fff;

    .container {
      background-color: #fff;
      overflow: auto;
      transition: opacity 0.1s cubic-bezier(0.4, 0, 0, 1) 0s;
    }

    .forNormal {
      display: none !important;
    }

    .forClose {
      display: flex !important;
    }
  }

  .mobile-munu__head {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .get-ticket-btn {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }

  .mobile-menu {
    height: 90px;


    .logo {
      display: flex;
      align-items: center;

      span {
        width: 220px !important;
        //height: 30px !important;
      }

      a {
        img {
          width: 119px;
          height: 50px;
        }
      }

    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 30px;

      span {
        //margin-left: 20px;
        position: absolute !important;
        //display: flex;
        //justify-content: space-between;
        width: 100%;

        img {
          margin-left: 20px;
        }

        &:nth-of-type(2) {
          display: none !important;
        }
      }

      &.menu-open span {
        &:nth-of-type(1) {
          display: block !important;
        }

        &:nth-of-type(2) {
          display: block !important;
        }
      }
    }

    &__items {

      display: none;
      position: absolute;
      opacity: 0;
      background-color: #F9F9F9;
      height: calc(100vh - 90px);
      top: 90px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 55px 0px 60px 0px;
      overflow: auto;
      transition: opacity .1s cubic-bezier(0.4, 0, 0, 1);


      &__top {
        ul {
          li {
            a {
              height: 45px;
              border-radius: 25px;
              background-color: #D80028;
              color: #FFFFFF;
              font-size: 18px;
              font-weight: 400;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0 30px;
              text-transform: capitalize;

              margin-bottom: 30px;

              img {
                margin-right: 10px;
              }

              span {
                margin-right: 10px !important;
              }
            }

            &:nth-last-child(1) a {
              margin-bottom: 60px;
              background-color: #4F616B;

              &:hover {
                color: #ffffff !important;
              }
            }

          }
        }
      }

      &__ul {

        li {
          padding-bottom: 25px;
          border-bottom: 1px solid #E8E8E8;
          margin-bottom: 25px;

          a {
            color: #000;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-transform: capitalize;
          }
        }

      }

    }

    &.menu-open {

      .mobile-menu__items {
        display: block;
      }

    }


    .main-child-sub {
      a {
        display: flex;
        justify-content: space-between;
      }
    }

    //accordion
    .accordion-item {
      .accordion-header {
        .accordion-button {
          background-color: transparent;
          color: #4F616B;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0;
          box-shadow: none;
          border: none;
          margin-bottom: 20px;
          padding-bottom: 25px;
          border-bottom: 1px solid #E1E4E6;

          p {
            margin: 0;
            font-size: 22px;
            line-height: 28px;
            text-transform: capitalize;
            font-weight: bold;
            padding-right: 30px;
            width: 100%;
          }

          span {
            &:nth-last-child(2) {
              display: none !important;
            }

            &:nth-last-child(1) {
              display: block !important;
            }
          }


          &.collapsed {
            span {
              &:nth-last-child(2) {
                display: block !important;
              }

              &:nth-last-child(1) {
                display: none !important;
              }
            }


          }


          &.collapsed {
            border-bottom: 1px solid #E1E4E6;
          }
        }


      }

      .accordion-body {
        padding-bottom: 25px;
        padding-top: 20px;

        ul {
          li {
            border: none;
            padding-bottom: 0;
            margin-bottom: 0;

            a {
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
              margin-bottom: 15px;
              display: block;
            }

            &:after {
              display: none;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          //border: none !important;
        }
      }

    }

    .accordion {
      padding-left: 15px;
      padding-right: 15px;

      li {
        &:last-child {
          border-bottom: none !important;
        }
      }
    }

    .social {
      &__icon {
        position: absolute;
        bottom: 40px;
        display: flex;

        &__icons {
          margin-right: 20px;
        }
      }
    }

    .main_child {
      border: none;
      padding-bottom: 0;

      span {
        background-color: transparent;
        color: #000;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid #E8E8E8;
        cursor: pointer;
      }

      a {
        background-color: transparent;
        color: #000;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid #E8E8E8;


        @media (max-width: 767px) {
          img {
            z-index: -1;
            position: relative;
          }
        }

        svg {
          z-index: -1;
        }

        p {
          margin: 0;
          font-size: 22px;
          line-height: 28px;
          text-transform: capitalize;
          font-weight: bold;
          padding-right: 30px;
          width: 100%;
        }

        span {
          &:nth-last-child(2) {
            display: none !important;
          }

          &:nth-last-child(1) {
            display: block !important;
          }
        }


        &.collapsed {
          span {
            &:nth-last-child(2) {
              display: block !important;
            }

            &:nth-last-child(1) {
              display: none !important;
            }
          }


        }


        &.collapsed {
          border-bottom: 1px solid #E1E4E6;
        }
      }

      &.submenu-open-mb {

        .submenu {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);

        }

        .submenu-open-next {
          .mega_sub {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }

        }

        .submenu-open-next-next {
          .mega_sub_sub {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }

        }
      }

      .submenu {
        margin-top: 20px;
        padding: 55px 15px 30px;
        position: fixed;
        right: 0;
        top: 70px;
        left: 0;
        bottom: 0;
        background: #f9f9f9;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: translateX(100%);
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .main_title {
          font-size: 16px;
          line-height: 32px;
          text-align: left;
          display: flex;
          margin-bottom: 60px;
          font-weight: 400;
          color: #92278F;
          align-items: center;

          svg {
            margin-right: 20px;
            position: relative;
            z-index: -1;
          }
        }

        li {
          border: none;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #000;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 25px;
            //display: block;
          }

          &:after {
            display: none;
          }
        }

        .mega_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: white;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
          height: 185vh;

          &:hover {
            //color: #4F616B;
          }
        }

        .mega_sub_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: white;
          z-index: 999;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
        }


      }

      .fotter_menu {
        margin-top: 150px;

        h3 {
          font-size: 32px;
          color: #4F616B;
          line-height: 32px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #4F616B;
        }

        .footer {
          margin-top: 40px;

          a {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            color: #4F616B;
            padding: 0;
            display: block;
            margin: 0 0 10px;
            border: none;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          //border: none !important;
        }
      }

    }

  }

  .mobile-bottom-bar__buttons {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-bottom-bar__account {
    position: fixed;
    width: 100%;
    padding: 110px 20px 60px 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.20);
    visibility: hidden;
    transform: translateY(100%);
    transition: all .6s cubic-bezier(0.87, 0, 0.13, 1);

    svg {
      position: absolute;
      right: 13px;
      top: 50px;
      font-size: 40px;
      color: black;
    }

    p {
      font-size: 16px;
      color: #D0CA10;
      line-height: 22px;
      margin-bottom: 40px;
      font-weight: 600;
    }

    ul {
      width: 100%;

      li {
        a {
          display: flex;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(34, 31, 31, 0.1);
          margin-bottom: 20px;
        }

        &:nth-last-of-type(1) {
          a {
            margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }

    &.open {
      transform: none;
      visibility: visible;
    }
  }

  .has-child-sub.open_submenu > a svg {
    opacity: 1;
    right: 0;
  }

  .has-child-sub-sub a:hover {
    svg {
      opacity: 1 !important;
    }
  }

  .svg-icon {
    position: relative;

    img {
      width: 100%;
      /* max-width: 100%; */
      position: absolute;
      bottom: -60px;
      left: 0;
    }
  }

  @media (max-width: 450px) {
    .mobile-munu__head .dc-btn {
      height: 38px;

      a {
        padding: 0 16px;
      }
    }
  }
`;

export default MyComponent;
