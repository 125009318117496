import React from 'react';
import styled from "styled-components";
import OrderProduct from "../../components/momento/OrderProduct";
import {HelmetProvider, Helmet} from 'react-helmet-async'


const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Momento | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>
            <StyledComponent>
                <OrderProduct/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
