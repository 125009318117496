import styled from "styled-components";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {cart, cartUpdate} from "../../api/redux/checkout";
import {toast} from "react-toastify";

const CartBox = ({cartSingle, loading, setLoading, cartItems, setCartItems, total, setTotal}) => {
    const dispatch = useDispatch()

    //Counter

    const [adultCounter, setAdultCounter] = useState(0);

    useEffect(() => {
        setAdultCounter(parseInt(cartSingle?.count ? cartSingle?.count : cartSingle?.cart_count))
    }, [])

    const handleIncrease = () => {
        let token = localStorage.getItem('token')
        if (token && token !== '') {
            setLoading(true)
            setAdultCounter(adultCounter + 1);
            let token = localStorage.getItem('token')
            let id = localStorage.getItem('id')
            let params = {
                token: token,
                id: id,
                productId: cartSingle?.product_id,
                count: 1,
                flag: 1
            }
            dispatch(cartUpdate(params)).then((res) => {
                if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    dispatch(cart(params)).then(() => {
                        setLoading(false)
                    })
                } else {
                    setLoading(false)
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
        } else {
            setAdultCounter(adultCounter + 1);
            const updatedCart = cartItems.map(item => {
                if (item.id === cartSingle.id) {
                    return {...item, cart_count: (item.cart_count + 1)};
                }
                return item;
            });
            setCartItems(updatedCart)
            const totalPrice = updatedCart.reduce((total, item) => {
                const price = parseFloat(parseFloat(item.price) * item.cart_count); // Convert price to a floating-point number
                return total + price;
            }, 0); // Start with a total of 0
            // Update localStorage with the new cart data
            localStorage.setItem('addToCart', JSON.stringify(updatedCart));
            localStorage.setItem('total', JSON.stringify(totalPrice));
            setTotal(totalPrice)
            toast.success('Cart Updated! 👍', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }



    const handleDecrease = () => {
        let token = localStorage.getItem('token')
        if (adultCounter > 1) {
            if (token && token !== '') {
                setLoading(true)
                setAdultCounter(adultCounter - 1);
                let token = localStorage.getItem('token')
                let id = localStorage.getItem('id')
                let params = {
                    token: token,
                    id: id,
                    productId: cartSingle?.product_id,
                    count: 1,
                    flag: 0
                }
                dispatch(cartUpdate(params)).then((res) => {
                    if (res?.payload?.status === 200) {
                        toast.success(res?.payload?.message, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        dispatch(cart(params)).then(() => {
                            setLoading(false)
                        })

                    } else {
                        setLoading(false)
                        toast.error(res?.payload?.response?.data?.message, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                })
            } else {
                setAdultCounter(adultCounter - 1);
                const updatedCart = cartItems.map(item => {
                    if (item.id === cartSingle.id) {
                        return {...item, cart_count: (item.cart_count - 1)};
                    }
                    return item;
                });
                setCartItems(updatedCart)
                const totalPrice = updatedCart.reduce((total, item) => {
                    const price = parseFloat(parseFloat(item.price) * item.cart_count); // Convert price to a floating-point number
                    return total + price;
                }, 0); // Start with a total of 0
                // Update localStorage with the new cart data
                localStorage.setItem('addToCart', JSON.stringify(updatedCart));
                localStorage.setItem('total', JSON.stringify(totalPrice));
                setTotal(totalPrice)
                toast.success('Cart Updated! 👍', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }

        }

    };

    return (
        <CartBoxStyled>
            <div className={'ticketing'}>
                <div className={'ticketing__img'}>
                    <img src={cartSingle?.image_id}/>
                </div>
                <div className={'ticketing__info'}>
                    <div className={'ticketing__info__details'}>
                        <div className={'ticketing__info__details__list'}>
                            <div className={'ticketing__info__details__list__type d-flex'}>
                                                                    <span>
                                                                        <h4>{cartSingle?.product_name}</h4>
                                                                        <p></p>
                                                                    </span>
                                <p className={'price'}>BDT{cartSingle?.price.toLocaleString('en-US')}</p>
                            </div>
                        </div>

                        <div className={'ticketing__info__details__btn'}>
                            <div className="increase-section__button">
                                <input type="button" value="-"
                                       onClick={() => handleDecrease()}
                                       disabled={adultCounter === 1}/>
                                <span>{adultCounter}</span>
                                <input type="button" value="+"
                                       onClick={() => handleIncrease()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CartBoxStyled>
    )
}

export default CartBox

const CartBoxStyled = styled.div`
  .ticketing {
    display: flex;
    background-color: #ffffff;
    padding: 20px 30px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0 5px 30px;
    width: 100%;
    @media (max-width: 767px) {
      padding: 20px 10px;
    }


    &__img {
      margin-right: 20px;
      width: 70px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__info {
      width: calc(100% - 70px);

      &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #F9F9F9;

        @media (max-width: 767px) {
          input {
            padding-top: 2px;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }


        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          padding-top: 15px;
        }

        &__list {
          &__type {
            span {
              display: block;
              //margin-right: 50px;

              h4 {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: #1A1818;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }

              p {
                color: #989898;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }
            }

            @media (max-width: 1150px) {
              display: block !important;
              .price {
                margin: 0;
              }
            }
          }
        }

        &__btn {
          align-self: flex-end;
          align-items: center;
          display: contents;

          input {
            border-radius: 50%;
            border: none;
            height: 35px;
            width: 35px;
            padding-left: 0;
            padding-right: 0;
            
          }

          .increase-section__button {
            width: 150px;
            justify-content: space-between;
            padding: 0 2px;
            background-color: white;
            border: 1px solid #E8E8E8;
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
              width: 108px;
              height: 40px;
            }

            span {
              color: #1A1818;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
            }

          }
        }

        .price {
          color: #92278F;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          margin-left: 50px;
          margin-right: 50px;
          display: flex;
          align-items: center;
          @media (max-width: 1150px) {
            margin: 10px 0;
          }
        }
      }
    }
  }
`
