import React from 'react';
import styled from "styled-components";
import Img from "../Img";
import Title from "../Title";
import Button from "../Button";
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";


const MyComponent = ({img, date, title, ticket, place, url}) => {
    return (
        <StyledComponent>
            <div className={'event__card'}>
                <div className="event__card__top">
                    <Img src={img} alt={'events'}/>
                    <p>{date}</p>
                </div>
                <div className={'event__card__bottom'}>
                    <h2 className={'etitle split-up'}>{title}</h2>
                    <h3 className={'eticket'}>{ticket}</h3>
                    <h2 className={'eplace split-up'}>{place}</h2>
                    <Button text={'View Details'} background={'transparent'} borderColor={'#000'} borderRadius={'30'}
                            color={'#000'} hoverfill={'#000'} src={url}/>
                </div>
                <Link to={url}/>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .event__card {
    position: relative;
    left: 0;
    background-color: #fff;
    //margin: 120px 0;
    border-radius: 15px;
    cursor: auto;

    &__top {
      position: relative;
      padding-top: calc(180 / 270 * 100%);

      img {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        transform: scale(1.01);
        transition: transform .5s ease-in-out;

        &:hover {
          transition: transform .5s ease-in-out;
          transform: scale(1.06);
        }
      }

      p {
        position: absolute;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #000;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 50px;
        top: 10px;
        right: 10px;
        animation: float 3s ease-in-out infinite;
      }

      @keyframes float {
        0% {
          box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
          transform: translatey(0px);
        }
        50% {
          box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
          transform: translatey(-5px);
        }
        100% {
          box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
          transform: translatey(0px);
        }
      }
    }

    &__bottom {
      margin-top: 20px;
      padding: 0 20px;

      .etitle {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: #000;
      }

      .eticket {
        font-size: 10px;
        line-height: 12px;
        font-weight: 300;
        color: #000;
        padding: 10px 20px;
        background-color: #E8E8E8;
        border-radius: 50px;
        max-width: max-content;
        margin-top: 5px;
      }

      .eplace {
        font-size: 14px;
        line-height: 32px;
        font-weight: 300;
        color: #434343;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      .dc-btn {
        margin-bottom: 30px;
      }
    }
  }
`;

export default MyComponent;