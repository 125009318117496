import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { apiEndPoints } from '../../api/network/apiEndPoints';
import postReducer, { fetchPostDetail } from '../../api/redux/blog';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Loading } from '../../components/Loading';
import Button from '../../components/Button';
import Img from '../../components/Img';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import reactHtmlParser from 'react-html-parser';

const MyComponent = () => {
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.blog);
  const { slug } = useParams();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const api_url = `${apiEndPoints.BLOG_DETAILS}/${slug}`;
    dispatch(fetchPostDetail([api_url]));
  }, [slug]);

  const data = detail?.[0];

  return (
    <HelmetProvider>
      {/*dynamic title */}
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {data?.post_title
            ? data?.post_title
            : 'MannBay Blogs | Mana Bay Water park'}
        </title>
        <meta
          name='description'
          content='In a world that is always on, Mana Bay offers sanctuary.'
        />
      </Helmet>

      <StyledComponent className={'blog-details pb-120'}>
        <Container>
          <Row>
            <Col md={12}>
              <div className='blog-details__wrap'>
                <div className='blog-details__upper'>
                  <Button
                    text={'Back'}
                    borderColor={'#1A1818'}
                    color={'#1A1818'}
                    hoverfill={'#1A1818'}
                    hoverborder={'#1A1818'}
                    src={'/blog'}
                  />
                  <h6 className={'split-up'}>{data?.list?.date}</h6>
                  <h3 className={'split-up'}>{data?.post_title}</h3>
                  <p className={'split-up'} style={{ marginTop: 20 }}>
                    {reactHtmlParser(data?.list?.desc)}
                  </p>
                  <div className='blog-details__upper__image '>
                    <Img src={data?.detail?.banner} alt='' />
                  </div>
                  <p className={'split-up'}>
                    {reactHtmlParser(data?.detail?.top_desc)}
                  </p>
                </div>
                <div className='blog-details__middle '>
                  <div className='blog-details__middle__images '>
                    <Col md={6} className={'pl-0'}>
                      <div className='blog-details__middle__images__image reveal'>
                        <Img src={data?.detail?.left_img} alt='' />
                      </div>
                    </Col>
                    <Col md={6} className={'pr-0'}>
                      <div className='blog-details__middle__images__image reveal'>
                        <Img src={data?.detail?.right_img} alt='' />
                      </div>
                    </Col>
                  </div>

                  <p className={'split-up'}>{data?.detail?.middle_desc}</p>
                </div>
                <div className='blog-details__lower'>
                  <div className='blog-details__lower__image reveal'>
                    <Img src={data?.detail?.video?.banner} alt='' />
                    <ModalVideo
                      channel='youtube'
                      isOpen={open}
                      videoId={data?.detail?.video?.id}
                      onClose={() => setOpen(false)}
                    />
                    <div
                      className='blog-details__lower__image__icon'
                      onClick={() => setOpen(true)}
                    >
                      <svg
                        id='Group_19701'
                        data-name='Group 19701'
                        xmlns='http://www.w3.org/2000/svg'
                        width='70'
                        height='70'
                        viewBox='0 0 70 70'
                      >
                        <circle
                          id='Ellipse_271'
                          data-name='Ellipse 271'
                          cx='35'
                          cy='35'
                          r='35'
                          fill='#fff'
                          opacity='0.9'
                        />

                        <circle
                          id='Ellipse_272'
                          data-name='Ellipse 271'
                          cx='35'
                          cy='35'
                          r='0'
                          fill='#fff'
                          opacity='0.9'
                        />

                        <g id='Media_play_paused' transform='translate(24 23)'>
                          <rect
                            id='Rectangle_1793'
                            data-name='Rectangle 1793'
                            width='24'
                            height='24'
                            fill='#92278f'
                            opacity='0'
                          />
                          <path
                            id='Icon_awesome-play'
                            data-name='Icon awesome-play'
                            d='M13.5,7.19,2.3.222A1.519,1.519,0,0,0,0,1.605V15.538a1.526,1.526,0,0,0,2.3,1.383L13.5,9.956a1.65,1.65,0,0,0,0-2.766Z'
                            transform='translate(5.25 3.748)'
                            fill='#92278f'
                          />
                          <path
                            id='Icon_open-media-pause'
                            data-name='Icon open-media-pause'
                            d='M0,0V14.25H4.75V0ZM9.5,0V14.25h4.75V0Z'
                            transform='translate(5.25 5.25)'
                            fill='#92278f'
                            opacity='0'
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <p className={'split-up'}>{data?.detail?.bottom_desc}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
  padding-top: 75px;

  .blog-details {
    &__upper {
      h6 {
        font-size: 14px;
        line-height: 20px;
        color: #1a1818;
        font-weight: 300;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #1a1818;
      }

      p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        color: #1a1818;
      }

      &__image {
        position: relative;
        padding-top: calc(450 / 870 * 100%);
        margin: 40px 0;
      }
    }

    &__middle {
      &__images {
        display: flex;
        margin: 40px 0;

        &__image {
          position: relative;
          padding-top: calc(450 / 425 * 100%);
        }
      }

      p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        color: #1a1818;
      }
    }

    &__lower {
      &__image {
        margin: 40px 0;
        position: relative;
        padding-top: calc(450 / 879 * 100%);

        &__icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          svg {
            #Ellipse_272 {
              transition: 0.7s all ease;
            }
          }

          &:hover {
            svg {
              g {
                path {
                  fill: #fcb940;
                }
              }
            }

            #Ellipse_272 {
              r: 35px;
              fill: #92278f;
              transition: 0.7s all ease;
            }
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        color: #1a1818;
      }
    }
  }
`;

export default MyComponent;
