import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: '',
    eventList: []
}

export const fetchPosts = createAsyncThunk("fetchData", (params) => {
    return get(params);
});

export const fetchPostDetail = createAsyncThunk("fetchDataDetail", (params) => {
    return get(params);
});

export const fetchEventList = createAsyncThunk("eventList", (params) => {
    return get(params);
});



const postSlice = createSlice({
    name: 'event',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchPosts.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchPosts.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchPosts.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchPostDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchPostDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload.data
            state.detailError = ''
        })
        builder.addCase(fetchPostDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
        builder.addCase(fetchEventList.pending, (state, action) => {
            state.detailLoading = true
            state.eventList = []
            state.detailError = ''
        })
        builder.addCase(fetchEventList.fulfilled, (state, action) => {
            state.detailLoading = false
            state.eventList = action.payload.data
            state.detailError = ''
        })
        builder.addCase(fetchEventList.rejected, (state, action) => {
            state.detailLoading = false
            state.eventList = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
