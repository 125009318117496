import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { apiEndPoints } from '../../api/network/apiEndPoints';
import postReducer, { fetchPostDetail, fetchPosts } from '../../api/redux/blog';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Loading } from '../../components/Loading';
import InnerBanner from '../../components/InnerBanner';
import BlogListing from '../../components/blog/BlogListing';

const MyComponent = () => {
  const dispatch = useDispatch();
  const getPost = useSelector((state) => state.blog);

  useEffect(() => {
    const apiUrl = apiEndPoints.BLOGS;
    dispatch(fetchPosts([apiUrl]));
  }, []);

  const banner = useMemo(() => getPost?.posts[0]?.banner, [getPost]);
  const blogs = useMemo(() => getPost?.posts[0]?.blog, [getPost]);

  if (getPost.loading) return <Loading />;

  return (
    <HelmetProvider>
      {/*dynamic title */}
      <Helmet>
        <meta charSet='utf-8' />
        <title>Manabay Blogs | Mana Bay water park</title>
        <meta
          name='description'
          content='In a world that is always on, Mana Bay offers sanctuary.'
        />
      </Helmet>

      <StyledComponent>
        <InnerBanner title='Manabay Blogs' img={banner?.image} />
        <BlogListing data={blogs} />
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section``;

export default MyComponent;
