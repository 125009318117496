import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "../Button";
import Img from "../Img";
import BuyNowPopup from "./BuyNowPopup";
import {useEffect} from "react";
import {cart} from "../../api/redux/checkout";
import {getProfile} from "../../api/redux/auth";
import {useDispatch} from "react-redux";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')

        if (token && token !== '') {
            let params = {
                id: id,
                token: token,
            }
            dispatch(getProfile(params))
        }
    }, [])

    console.log(data?.meta?._type_b_title)


    return (
        <StyledComponent>
            <Container className="ticket pb-120 pt-120">
                <Row>
                    <Col md={12} className="ticket__header">
                        <h3>{data?.meta?._event_ticket_title ? data?.meta?._event_ticket_title[0] : ''}</h3>
                    </Col>
                    {
                        data?.meta?._type_a_title[0] === '' || data?.meta?._type_a_title === '' ?
                            null :
                            <Col md={4} className={'ticket__box'}>
                                <div className={'ticket__box__regular'}>
                                    <h2>{ReactHtmlParser(data?.meta?._type_a_title)}</h2>
                                    <p>Price</p>
                                    <h3>{data?.meta?._type_a_price}</h3>
                                    <BuyNowPopup description={data?.meta?._type_a_title} label={data?.post_title}
                                                 id={data?.ID} price={data?.meta?._type_a_price}
                                                 img={data?.meta?._event_preview_image}
                                                 type={'_type_a_title'} qty={data?.meta?._type_a_quantity[0]}
                                    />
                                    <img src={data?.meta?._event_ticket_b_svg}/>
                                </div>
                            </Col>
                    }

                    {
                        data?.meta?._type_b_title[0] === '' || data?.meta?._type_b_title === '' ?
                            null :
                            <Col md={4} className={'ticket__box'}>
                                <div className={'ticket__box__platinum'}>
                                    <h2>{ReactHtmlParser(data?.meta?._type_b_title)}</h2>
                                    <p>Price</p>
                                    <h3>{data?.meta?._type_b_price}</h3>
                                    <BuyNowPopup description={data?.meta?._type_b_title} label={data?.post_title}
                                                 id={data?.ID} price={data?.meta?._type_b_price}
                                                 img={data?.meta?._event_preview_image}
                                                 type={'_type_b_title'} qty={data?.meta?._type_b_quantity[0]}
                                    />
                                    <img src={data?.meta?._event_ticket_b_svg}/>
                                </div>
                            </Col>
                    }
                    {
                        data?.meta?._type_c_title[0] === '' || data?.meta?._type_c_title === '' ?
                            null :
                            <Col md={4} className={'ticket__box'}>
                                <div className={'ticket__box__vip'}>
                                    <h2>{ReactHtmlParser(data?.meta?._type_c_title)}</h2>
                                    <p>Price</p>
                                    <h3>{data?.meta?._type_c_price}</h3>
                                    <BuyNowPopup description={data?.meta?._type_b_title} label={data?.post_title}
                                                 id={data?.ID} price={data?.meta?._type_c_price}
                                                 img={data?.meta?._event_preview_image}
                                                 type={'_type_c_title'} qty={data?.meta?._type_c_quantity[0]}
                                    />
                                    <img src={data?.meta?._event_ticket_c_svg}/>
                                </div>
                            </Col>
                    }


                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F9F9F9;

  .ticket {
    &__header {
      margin-bottom: 40px;

      h3 {
        font-size: 40px;
        line-height: 40px;
        font-weight: 300;
        color: #1A1818;
      }
    }

    &__box {
      text-align: center;
      height: 100%;
      &__regular {
        background-color: #fff;
        padding-top: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
        height: 100%;

        h2 {
          color: #92278F;
          font-size: 24px;
          line-height: 40px;
          font-weight: 400;
          padding: 25px;
          background-color: rgba(146, 39, 143, 0.1);
          margin-top: 20px;
          text-align: center;
        }

        p {
          padding-top: 40px;
          color: #1A1818;
          font-size: 14px;
          line-height: 40px;
          font-weight: 400;
          text-align: center;
        }

        h3 {
          color: #1A1818;
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
          text-align: center;
          padding-bottom: 65px;
        }

        img {
          //position: absolute;
          width: 100%;
          //bottom: 0;
          //left: 0;
        }
      }

      &__platinum {
        background-color: #fff;
        padding-top: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

        h2 {
          color: #266A36;
          font-size: 24px;
          line-height: 40px;
          font-weight: 400;
          padding: 25px;
          background-color: rgba(38, 106, 54, 0.1);
          margin-top: 20px;
          text-align: center;
        }

        p {
          padding-top: 40px;
          color: #1A1818;
          font-size: 14px;
          line-height: 40px;
          font-weight: 400;
          text-align: center;
        }

        h3 {
          color: #1A1818;
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
          text-align: center;
          padding-bottom: 65px;
        }

        img {
          width: 100%;
        }
      }

      &__vip {
        background-color: #fff;
        padding-top: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

        h2 {
          color: #12A6C8;
          font-size: 24px;
          line-height: 40px;
          font-weight: 400;
          padding: 25px;
          background-color: rgba(18, 166, 200, 0.1);
          margin-top: 20px;
          text-align: center;
        }

        p {
          padding-top: 40px;
          color: #1A1818;
          font-size: 14px;
          line-height: 40px;
          font-weight: 400;
          text-align: center;
        }

        h3 {
          color: #1A1818;
          font-size: 40px;
          line-height: 40px;
          font-weight: 400;
          text-align: center;
          padding-bottom: 65px;
        }

        img {
          width: 100%;
        }
      }
    }

    @media (max-width: 767px) {
      &__header {
        h3 {
          font-size: 24px;
          line-height: 40px;
          font-weight: 300;
        }
      }

      &__box {
        &__regular {
          margin-bottom: 20px;

          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 400;
          }

          h3 {
            font-size: 24px;
            line-height: 40px;
            font-weight: 400;
          }
        }

        &__platinum {
          margin-bottom: 20px;

          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 400;
          }

          h3 {
            font-size: 24px;
            line-height: 40px;
            font-weight: 400;
          }
        }

        &__vip {
          margin-bottom: 20px;

          h2 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 400;
          }

          h3 {
            font-size: 24px;
            line-height: 40px;
            font-weight: 400;
          }
        }
      }
    }
  }

`;

export default MyComponent;
