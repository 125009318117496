import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {Col, Modal} from "react-bootstrap";
import ProductOrder from "./ProductOrder";
import {cart} from "../api/redux/checkout";
import {Loading} from "./Loading";

const Message = (props, {offset}) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false)
    const cartData = useSelector(state => state?.checkout?.cartData);
    const userToken = useSelector(state => state?.auth?.token)
    const [total, setTotal] = useState(0)
    const [cartItems, setCartItems] = useState([])

    useEffect(() => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let params = {
            token: token,
            id: id,
        }
        if (token && token !== '') {
            dispatch(cart(params))
        }
    }, [])

    useEffect(() => {
        // Function to handle storage changes
        const handleStorageChange = (event) => {
            if (event.key === 'addToCart') {
                const storedCart = JSON.parse(event.newValue) || [];
                setCartItems(storedCart);
            } else if (event.key === 'total') {
                const storedTotal = parseFloat(event.newValue) || 0;
                setTotal(storedTotal);
            }
        };

        // Add event listener to handle changes to localStorage
        window.addEventListener('storage', handleStorageChange);

        // Retrieve initial data from localStorage
        const storedCart = JSON.parse(localStorage.getItem('addToCart')) || [];
        const storedTotal = parseFloat(localStorage.getItem('total')) || 0;

        // Set initial state with retrieved data
        setCartItems(storedCart);
        setTotal(storedTotal);

        // Cleanup: remove the event listener when component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        // close order history based on remove click
        const getAllRemove = document.querySelectorAll('.order__box .content table tbody tr span')
        if (getAllRemove) {
            getAllRemove.forEach(i => {
                i.addEventListener('click', function () {
                    setShow(false)
                });
            })
        }
    })


    return (
        <StyledMessage data-scroll-section offset={offset} className={`title `}
        >
            {
                cartData ? cartData?.cart ? cartData.cart.length > 0 ?
                    <>
                        <div className="cart" onClick={handleShow}>
                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="37" height="33" viewBox="0 0 37 33">*/}
                            {/*    <defs>*/}
                            {/*        <clipPath id="clip-path">*/}
                            {/*            <rect id="Rectangle_5525" data-name="Rectangle 5525" width="30" height="30"*/}
                            {/*                  transform="translate(1313 368)" fill="#434343"/>*/}
                            {/*        </clipPath>*/}
                            {/*    </defs>*/}
                            {/*    <g id="Group_19352" data-name="Group 19352" transform="translate(-1311 -376)">*/}
                            {/*        <g id="Mask_Group_186" data-name="Mask Group 186" transform="translate(-2 11)"*/}
                            {/*           clip-path="url(#clip-path)">*/}
                            {/*            <path id="grocery-store"*/}
                            {/*                  d="M1.268,1.268a1.268,1.268,0,0,0,0,2.535H3.523a.879.879,0,0,1,.834.6l4.179,12.54A3.091,3.091,0,0,1,8.6,18.669l-.344,1.379a3.277,3.277,0,0,0,3.149,4.035H26.619a1.268,1.268,0,0,0,0-2.535H11.408a.685.685,0,0,1-.691-.884l.26-1.037a.812.812,0,0,1,.788-.615H25.351a1.268,1.268,0,0,0,1.2-.867L29.934,8.006a1.268,1.268,0,0,0-1.2-1.669H8.309a.879.879,0,0,1-.834-.6l-1.2-3.6a1.268,1.268,0,0,0-1.2-.867Zm8.873,24.084a2.535,2.535,0,1,0,2.535,2.535A2.535,2.535,0,0,0,10.141,25.351Zm15.211,0a2.535,2.535,0,1,0,2.535,2.535A2.535,2.535,0,0,0,25.351,25.351Z"*/}
                            {/*                  transform="translate(1313 367.155)" fill="#434343"/>*/}
                            {/*        </g>*/}
                            {/*    </g>*/}
                            {/*</svg>*/}
                            <img src="/images/static/cart-icon.svg" alt=""/>
                            <p>{cartData?.cart_count ? cartData?.cart_count !== 0 ? cartData?.cart_count !== "0" ? cartData?.cart_count : 0 : 0 : 0}</p>
                        </div>
                        <Modal show={show} className='faq-modal transparent-modal'>
                            <Modal.Body>
                                <div className="announcement">
                                    <div onClick={handleClose} className="pop-close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.828" height="27.828"
                                             viewBox="0 0 27.828 27.828">
                                            <g id="Group_18986" data-name="Group 18986"
                                               transform="translate(-1241.837 -61.211)">
                                                <line id="Line_3541" data-name="Line 3541" x2="35.355"
                                                      transform="translate(1243.251 62.626) rotate(45)" fill="none"
                                                      stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_12368" data-name="Line 12368" x2="35.355"
                                                      transform="translate(1243.251 87.626) rotate(-45)" fill="none"
                                                      stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="modal-data">
                                        <Col className='announcement__data'>
                                            <ProductOrder cartData={cartData} setLoading={setLoading}
                                                          handleClose={handleClose}/>
                                        </Col>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </> : null : null : null
            }
            {
                cartItems ? cartItems.length > 0 ?

                    <>
                        <div className="cart" onClick={handleShow}>
                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="37" height="33" viewBox="0 0 37 33">*/}
                            {/*    <defs>*/}
                            {/*        <clipPath id="clip-path">*/}
                            {/*            <rect id="Rectangle_5525" data-name="Rectangle 5525" width="30" height="30"*/}
                            {/*                  transform="translate(1313 368)" fill="#434343"/>*/}
                            {/*        </clipPath>*/}
                            {/*    </defs>*/}
                            {/*    <g id="Group_19352" data-name="Group 19352" transform="translate(-1311 -376)">*/}
                            {/*        <g id="Mask_Group_186" data-name="Mask Group 186" transform="translate(-2 11)"*/}
                            {/*           clip-path="url(#clip-path)">*/}
                            {/*            <path id="grocery-store"*/}
                            {/*                  d="M1.268,1.268a1.268,1.268,0,0,0,0,2.535H3.523a.879.879,0,0,1,.834.6l4.179,12.54A3.091,3.091,0,0,1,8.6,18.669l-.344,1.379a3.277,3.277,0,0,0,3.149,4.035H26.619a1.268,1.268,0,0,0,0-2.535H11.408a.685.685,0,0,1-.691-.884l.26-1.037a.812.812,0,0,1,.788-.615H25.351a1.268,1.268,0,0,0,1.2-.867L29.934,8.006a1.268,1.268,0,0,0-1.2-1.669H8.309a.879.879,0,0,1-.834-.6l-1.2-3.6a1.268,1.268,0,0,0-1.2-.867Zm8.873,24.084a2.535,2.535,0,1,0,2.535,2.535A2.535,2.535,0,0,0,10.141,25.351Zm15.211,0a2.535,2.535,0,1,0,2.535,2.535A2.535,2.535,0,0,0,25.351,25.351Z"*/}
                            {/*                  transform="translate(1313 367.155)" fill="#434343"/>*/}
                            {/*        </g>*/}
                            {/*    </g>*/}
                            {/*</svg>*/}
                            <img src="/images/static/cart-icon.svg" alt=""/>
                            <p>{cartItems.length}</p>
                        </div>
                        <Modal show={show} className='faq-modal transparent-modal'>
                            <Modal.Body>
                                <div className="announcement">
                                    <div onClick={handleClose} className="pop-close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27.828" height="27.828"
                                             viewBox="0 0 27.828 27.828">
                                            <g id="Group_18986" data-name="Group 18986"
                                               transform="translate(-1241.837 -61.211)">
                                                <line id="Line_3541" data-name="Line 3541" x2="35.355"
                                                      transform="translate(1243.251 62.626) rotate(45)" fill="none"
                                                      stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_12368" data-name="Line 12368" x2="35.355"
                                                      transform="translate(1243.251 87.626) rotate(-45)" fill="none"
                                                      stroke="#f0ede3" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="modal-data">
                                        <Col className='announcement__data'>
                                            <ProductOrder cartData={cartItems} setLoading={setLoading}
                                                          handleClose={handleClose} total={total} setTotal={setTotal}
                                                          cartItems={cartItems} setCartItems={setCartItems}/>
                                        </Col>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </> : null : null
            }
        </StyledMessage>
    )
};


const StyledMessage = styled.div`
  position: fixed;
  right: 0px;
  top: 35%;
  z-index: 999;

  .cart {

    //padding: 27px 35px 27px 30px;
    padding: 24px 18px;
    cursor: pointer;

    background: #ffffff;
    border-radius: 20px 0 0 20px;

    box-shadow: 1px 10px 31px 0px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 1px 10px 31px 0px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 1px 10px 31px 0px rgba(0, 0, 0, 0.51);

    p {
      color: #FFFFFF;
      font-size: 8px;
      background-color: #92278F;
      width: 16px;
      height: 16px;
      border-radius: 50px;
      text-align: center;
      position: absolute;
      top: 21px;
      right: 13px;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    img {
      height: 33px;
    }
  }


`;


export default Message;














