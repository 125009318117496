import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, Form } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import TimelineSlug from "./TimelineSlug";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getTicketInfo, postTicket } from "../../api/redux/ticket";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import { useParams } from "react-router-dom";

const MyComponent = () => {
  let ticketDetails = useSelector(
    (state) => state?.ticket?.ticketInfo?.Envelope?.Body?.ItemList?.Item
  );
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState("");
  const params = useParams();
  const [selectedTickets, setSelectedTickets] = useState({});
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    let localData = JSON.parse(sessionStorage.getItem("selectedTickets"));

    if (ticketDetails && localData && localData !== {}) {
      setSelectedTickets(localData);
    }

    let localDate = new Date(sessionStorage.getItem("date"));
    if (date && date !== "") {
      setDate(localDate);
    }
    calculateTotalPrice();
  }, [ticketDetails]);

  const sessionIdHandler = () => {
    const param = ["", apiEndPoints.TICKET_TOKEN];
    dispatch(postTicket(param)).then(() => {
      let sessionToken = sessionStorage.getItem("ticketToken");
      if (sessionToken) {
        ticketDetailHandler();
      }
    });
  };

  const ticketDetailHandler = () => {
    let sessionToken = sessionStorage.getItem("ticketToken");
    let date = new Date();
    if (sessionToken) {
      const params = [
        "",
        `<?xml version="1.0" ?>
<Envelope>
<Header>
<SourceID>1</SourceID>
<MessageID>12</MessageID>
<MessageType>QueryCustomerItems</MessageType>
<SessionID>${sessionToken}</SessionID>
<TimeStamp>${moment(date).format("yyyy-MM-DD hh:mm:ss")}</TimeStamp>
</Header>
<Body>
<QueryCustomerItems>
<CustomerID>3</CustomerID>
<SalesProgramID>4</SalesProgramID>
<IncludeItemAttributes>YES</IncludeItemAttributes>
</QueryCustomerItems>
</Body>
</Envelope>`,
      ];
      dispatch(getTicketInfo(params));
    } else {
      console.log("NO SESSION FOUND!!");
    }
  };

  useEffect(() => {
    let sessionToken = sessionStorage.getItem("ticketToken");
    if (!sessionToken) {
      sessionIdHandler();
    } else {
      ticketDetailHandler();
    }
  }, []);

  useEffect(() => {
    if (date && date !== "") {
      sessionStorage.setItem("date", date);
    }
  }, [date]);

  useEffect(() => {
    let localDate = sessionStorage.getItem("date");
    if (localDate) {
      let dateUrl = new Date(localDate);
      setDate(dateUrl);
    }
  }, []);

  useEffect(() => {
    if (params && params?.date) {
      let dateUrl = new Date(
        moment(params?.date, "DD-MM-YYYY").format(
          "ddd MMM DD YYYY HH:mm:ss [GMT]Z [(]Z[)]"
        )
      );
      setDate(dateUrl);
    }
    calculateTotalPrice();
  }, []);

  const calculateDiscountedPrice = (discountType, ticketID) => {
    if (discountType === "bkash") {
      const discountAmount = 20;
      const VAT_PERC = 7.5;

      const priceList = {
        10101301: 6998,
        10101303: 3688,
        10101302: 4689,
      };

      const ticketPrice = priceList[ticketID];
      console.log("ticket price " + ticketPrice);

      if (ticketPrice === undefined) {
        return false;
      }
      const discountedPriceBeforeTax =
        ticketPrice - ticketPrice * (discountAmount / 100);
      // const discountedPrice =
      //   discountedPriceBeforeTax + discountedPriceBeforeTax * (VAT_PERC / 100);

      return discountedPriceBeforeTax;
    }

    return false;
  };

  const getTotalAdultTickets = () => {
    return selectedTickets["10101301"];
  };

  const calculateTotalPrice = (discountType = "none") => {
    let totalPrice = 0;
    let totalAdultTickets = getTotalAdultTickets();
    for (const ticketId in selectedTickets) {
      const ticket = ticketDetails.find(
        (ticket) => ticket?.PLU?._text === ticketId
      );

      // TODO: Rewrite calculateDiscountedPrice function to take into account the dates of promotion
      const discountedPrice = calculateDiscountedPrice(discountType, ticketId);
      // const discountedPrice = false;
      // console.log(discountedPrice);

      if (discountedPrice && totalAdultTickets !== 3) {
        totalPrice += discountedPrice * selectedTickets[ticketId];
      } else {
        totalPrice += ticket?.Price?._text * selectedTickets[ticketId];
      }
    }

    setTotal(totalPrice);

    return totalPrice;
  };

  return (
    <StyledComponent>
      <Container className={"pt-120 pb-120"}>
        <Row>
          <Col lg={6}>
            <TimelineSlug
              ticketDetails={ticketDetails}
              total={total}
              date={date}
              setDate={setDate}
              selectedTickets={selectedTickets}
              setSelectedTickets={setSelectedTickets}
              calculateTotalPrice={calculateTotalPrice}
            />
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <div className={"order__box"}>
              <div className={"title"}>
                <img src={"/images/dynamic/icon.svg"} />
                <h3>Order Summary</h3>
              </div>
              <div className="order__box__content">
                <table>
                  <thead>
                    <tr>
                      <th>Items</th>
                      <th>Qty</th>
                      <th>Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedTickets
                      ? Object.keys(selectedTickets).map((ticketId) => {
                          const ticket = ticketDetails.find(
                            (ticket) => ticket?.PLU?._text === ticketId
                          );
                          return (
                            <tr key={ticket?.PLU?._text}>
                              <td className={"flex"}>{ticket?.Name?._text}</td>
                              <td>X{selectedTickets[ticketId]}</td>
                              <td>
                                BDT{" "}
                                {parseInt(ticket?.Price?._text).toLocaleString(
                                  "en-US"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>

              <div className="subtotal">
                <h6>Subtotal</h6>
                <h6>BDT {total.toLocaleString("en-US")}</h6>
              </div>

              <div className="total">
                <div className="total-left">
                  <h4>Total</h4>
                </div>
                <div className="total-left">
                  <h4>BDT {total.toLocaleString("en-US")}</h4>
                  <p>All taxes and fees included</p>
                </div>
              </div>
            </div>
            <div className="park-opening-time">
              <p>Plan Your Visit: </p>
              <span>Weekend & Public Holidays: 10:00 AM - 8:00 PM</span>
              <br />
              <span>Weekdays: 10:00 AM - 6:00 PM</span>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  background-color: #f9f9f9;

  .timeline {
    margin-bottom: 40px;

    ul {
      li {
        a {
          color: #1a1818;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
        }

        display: inline-flex;
        margin-right: 60px;
        position: relative;
        counter-increment: count;

        &::before {
          cursor: pointer;
          content: counter(count, Numeric);
          position: absolute;
          padding: 13px 18px;
          border: 1px solid #92278f;
          border-radius: 50%;
          color: #92278f;
          top: -55px;
          left: 0;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-size: 12px;
          font-weight: 300;
          line-height: 14px;
          overflow: hidden;
          background-color: #ffffff;
          z-index: 2;
        }

        &::after {
          content: "";
          position: absolute;
          top: -35px;
          right: -95px;
          transform: translateX(-50%);
          border-top: 1px solid rgb(146, 39, 143);
          width: 60px;
          z-index: 1;
        }
      }

      li:last-child::after {
        display: none;
      }
    }
  }

  .tc {
    border-bottom: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: 0;
    }

    .ticket__title {
      h3 {
        color: #1a1818;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 20px;
        //background-color: #F9F9F9;
      }
    }

    .ticket {
      display: flex;
      padding: 20px;
      background-color: #ffffff;
      margin-bottom: 20px;
      width: 100%;
      justify-content: space-around;

      &__img {
        img {
          height: 110px;
          width: 90px;
        }
      }

      &__buy {
        display: block;

        .type {
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          span {
            display: block;
            margin-right: 50px;

            h4 {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              color: #1a1818;
            }

            p {
              width: max-content;
              color: #989898;
              font-size: 12px;
              line-height: 14px;
              font-weight: 300;
            }
          }
        }

        .btn {
          align-self: flex-end;

          input {
            border-radius: 50%;
            border: none;
            height: 25px;
            width: 25px;
          }

          .increase-section__button {
            width: 150px;
            justify-content: space-between;
            padding: 0 2px;
            background-color: white;
            border: 1px solid #e8e8e8;
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;

            span {
              color: #1a1818;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }

        p {
          color: #92278f;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .park-opening-time {
    margin-top: 20px;
    font-size: 12px !important;
    color: black;

    p {
      font-weight: 600;
      margin-bottom: 10px;
    }

    span {
      font-size: 16px;
    }
  }

  .details {
    margin-top: 30px;
    background-color: #f9f9f9;

    h3 {
      color: #1a1818;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      padding-top: 17px;
      padding-bottom: 19px;
      padding-left: 20px;
      background-color: #f9f9f9;
      border: 1px solid #e9e9e9;
    }

    .account__form {
      padding: 20px 30px 40px 30px;
      background-color: #ffffff;
      //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

      .checkbox-single {
        margin-bottom: 25px;
        padding-top: 25px;

        .container {
          padding-bottom: 25px;
          border-bottom: 1px solid #e8e8e8;
          margin-bottom: 25px;
        }
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;

        //.form-group {
        //  &:last-child {
        //    flex: 0 0 100%;
        //    width: 100%;
        //  }
        //}
      }
    }
  }

  .detail {
    margin-top: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

    .visiting {
      justify-content: space-between;
      background-color: #e9e9e9;
      padding: 20px 17px;

      .visiting-title {
        h3 {
          color: #1a1818;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
        }
      }

      .visiting-data {
        display: flex;
        justify-content: space-between;
        align-self: center;
        gap: 20px;

        p {
          color: #1a1818;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          margin-right: 16px;
        }

        ul {
          display: flex;
          justify-content: space-between;
          gap: 20px;

          li {
            position: relative;
            color: #1a1818;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            margin-right: 20px;

            &:last-child {
              margin-right: 0;

              &::before {
                position: absolute;
                content: "";
                top: -1px;
                left: -40px;
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-left: 20px;
                border-radius: 50%;
                background-color: rgb(90, 209, 209);
                opacity: 100%;
              }
            }

            &::before {
              position: absolute;
              content: "";
              top: -1px;
              left: -40px;
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-left: 20px;
              border-radius: 50%;
              opacity: 10%;
              background-color: #ec1b34;
            }
          }
        }
      }
    }
  }

  .order__box {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
    padding: 0;

    h3 {
      color: #fff;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }

    .title {
      position: relative;
      padding: 37px 80px 32px 30px;
      background-color: #92278f;

      img {
        width: 100%;
        position: absolute;
        bottom: -20px;
        left: 0px;
        height: 21px;
        object-fit: cover;
      }
    }

    &__content {
      margin: 50px 30px 0 30px;
      //padding: 0 30px;

      table {
        thead {
          border-bottom: 1px solid #e8e8e8;

          tr {
            th {
              font-size: 16px;
              line-height: 32px;
              color: #434343;
              font-weight: 400;
              padding: 20px 0;

              &:nth-of-type(3) {
                text-align: end;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #e8e8e8;

            .flex {
              display: flex;
              flex-direction: column;
            }

            span {
              a {
                margin: 10px 0;
                font-size: 12px;
                line-height: 14px;
                color: #ec1b34;
                font-weight: 300;
              }
            }

            td {
              padding: 20px 0;
              font-size: 14px;
              line-height: 16px;
              font-weight: 300;
              border-color: #dfe6e5;
              color: #1a1818;

              &:nth-of-type(3) {
                text-align: end;
              }
            }
          }
        }
      }
    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      padding: 30px;

      h6 {
        color: #1a1818;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      padding: 30px;
      background-color: rgb(146, 39, 143, 0.1);

      .total-left {
        text-align: end;
      }

      h4 {
        color: #92278f;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        color: #434343;
        font-weight: 300;
      }
    }
  }
`;

export default MyComponent;
