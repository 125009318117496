import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, video, videoLink}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const [offset, setOffset] = useState(0)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(document.querySelector('.container').offsetLeft)
        })
    }, [])


    return (
        <StyledComponent className={'video-banner'}>
            <div className="video-banner__inner">
                <img onClick={handleShow} src={img ? img : '/images/dynamic/banner.jpg'} alt=""/>
                <div onClick={handleShow} className="video-button">
                    <svg id="Media_play_paused" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24">
                        <rect id="Rectangle_1793" data-name="Rectangle 1793" width="24" height="24" fill="#92278f"
                              opacity="0"/>
                        <path id="Icon_awesome-play" data-name="Icon awesome-play"
                              d="M13.5,7.19,2.3.222A1.519,1.519,0,0,0,0,1.605V15.538a1.526,1.526,0,0,0,2.3,1.383L13.5,9.956a1.65,1.65,0,0,0,0-2.766Z"
                              transform="translate(5.25 3.748)" fill="#92278f"/>
                        <path id="Icon_open-media-pause" data-name="Icon open-media-pause"
                              d="M0,0V14.25H4.75V0ZM9.5,0V14.25h4.75V0Z" transform="translate(5.25 5.25)" fill="#92278f"
                              opacity="0"/>
                    </svg>

                </div>


            </div>

            <div className="video-banner__content">
                <h1 style={{left: offset + 'px'}}>

                    <svg className={'top'} xmlns="http://www.w3.org/2000/svg"
                         width="39" height="124" viewBox="0 0 39 124">
                        <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_5633" data-name="Rectangle 5633" width="124" height="39"
                                      fill="#fff"/>
                            </clipPath>
                        </defs>
                        <g id="Mask_Group_237" data-name="Mask Group 237" transform="translate(39) rotate(90)"
                           clip-path="url(#clip-path)">
                            <path id="Union_19" data-name="Union 19"
                                  d="M166.667,0l16.667,25L200,0ZM133.332,0,150,25,166.667,0ZM100,0l16.669,25L133.332,0ZM66.666,0,83.335,25,100,0ZM33.333,0,50,25,66.666,0ZM0,0,16.667,25,33.333,0Z"
                                  transform="translate(0)" fill="#fff" stroke="rgba(0,0,0,0)"
                                  stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                    </svg>


                    {title ? title : reactHtmlParser(`From Routine <span>To Rejuvenation</span>`)}

                    <svg className={'bottom'} xmlns="http://www.w3.org/2000/svg" width="38" height="124"
                         viewBox="0 0 38 124">
                        <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_5641" data-name="Rectangle 5641" width="124" height="38"
                                      fill="#fff"/>
                            </clipPath>
                        </defs>
                        <g id="Mask_Group_241" data-name="Mask Group 241" transform="translate(38) rotate(90)"
                           clip-path="url(#clip-path)">
                            <path id="Union_21" data-name="Union 21"
                                  d="M166.667,24.36,183.334,0,200,24.36Zm-33.335,0L150,0l16.667,24.36ZM100,24.36,116.668,0l16.664,24.36Zm-33.333,0L83.335,0,100,24.36Zm-33.333,0L50,0,66.666,24.36ZM0,24.36,16.667,0,33.333,24.36Z"
                                  transform="translate(0 13.64)" fill="#fff" stroke="rgba(0,0,0,0)"
                                  stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                    </svg>


                </h1>
            </div>

            {/*modal*/}
            <Modal className={'video-modal'} show={show} onHide={handleClose}>

                <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
                     height="13.423"
                     viewBox="0 0 13.426 13.423">
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                          d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                          transform="translate(-11.285 -11.289)" fill="#fff"/>
                </svg>


                <Modal.Body>
                    {video ? video :
                        <iframe width="560" height="315"
                                src={`${videoLink};controls=0&autoplay=1`}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                    }


                </Modal.Body>

            </Modal>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: calc(100vh - 145px);
  padding-left: 30px;
  padding-right: 30px;
  position: relative;

  overflow: hidden;

  .video-button {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    margin: auto;
    inset: 0;
    border-radius: 50%;
    cursor: pointer;
    z-index: 8;
    transition: .5s ease;

    path {
      transition: .4s ease;
    }


  }

  .video-banner__inner {
    position: relative;
    height: calc(100vh - 145px);
    //cursor: pointer;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      cursor: pointer;
      z-index: 6;
      transition: 1s ease;
      transform: scale(1.01);
    }

    &:hover {
      .video-button {
        background-color: ${hover};
      }

      path {
        fill: #FFF;
      }

      img {
        transform: scale(1.04);
      }
    }

  }

  .video-banner__content {
    h1 {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      padding: 20px 30px;
      //height: 124px;
      font-size: 32px;
      line-height: 28px;
      margin-left: 40px;
      color: #498dcb;
      z-index: 9;

      span {
        display: block;
        color: #92278f;
      }

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        height: 100%;

        &.top {
          left: -29px;
        }

        &.bottom {
          right: -29px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .video-banner__content h1 {
      left: 35px !important;
      font-size: 25px;
      line-height: 25px;
      right: 75px;
    }
  }

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 50vh;
    .video-banner__inner {
      height: 50vh;
    }

    .video-button {
      height: 50px;
      width: 50px;
    }
  }

  @media (max-width: 550px) {
    .video-banner__content h1 {
      padding: 10px 30px;
      left: 2px !important;
      font-size: 18px;
      line-height: 22px;
      right: 40px;
    }

    .video-banner__content h1 svg {
      &.top {
        left: -26px;
      }

      &.bottom {
        right: -25px;
      }
    }
  }



`;

export default MyComponent;
