import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Img from "../Img";
import Button from "../Button";
import {RiEyeFill} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getProfile} from "../../api/redux/auth";
import {Loading} from "../Loading";

const MyComponent = () => {
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    let userData = useSelector(state => state?.auth?.userData?.data);


    useEffect(()=>{
        setLoading(true)
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')

        if (!token) {
            history.push('/login')
        }

        let params = {
            id: id,
            token: token
        }

        dispatch(getProfile(params)).then(res => {
            setLoading(false)
        })

    },[])

    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <StyledComponent>
            <Container className={"overview pb-80 pt-80"}>
                <Row>
                    <Col lg={{span:6, offset:3}} className={"overview__right"}>
                        <div className={"overview__right__box"}>
                            <div className={"title"}>
                                <img src={'/images/dynamic/icon.svg'}/>
                                <h3 className={"split-up"}>User Information</h3>
                            </div>
                            <div className="account__form">
                                <div className="form-group-wrap">
                                    <Form className={'form'} >
                                        <div className="flex">
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled
                                                        value={userData?.first_name}
                                                        className="form-control-lg"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Last name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled
                                                        value={userData?.last_name}
                                                        className="form-control-lg"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Mail Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled
                                                        value={userData?.user_email}
                                                        className="form-control-lg"
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled
                                                        value={userData?.user_mobile}
                                                        className="form-control-lg"
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        disabled
                                                        value={userData?.shipping_address?.address_1}
                                                        className="form-control-lg"
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Postcode</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="postcode"
                                                        disabled
                                                        value={userData?.shipping_address?.postcode}
                                                        className="form-control-lg"
                                                    />
                                                </Form.Group>
                                            </div>


                                        </div>

                                        <Form.Group>
                                            <Button text={'Update Information'} borderColor={'#92278F'}
                                                    color={'#92278F'}
                                                    hoverfill={'#92278F'}
                                                    margin={'15px 0 0 0'}
                                                    src={'/user/update'}
                                            />
                                        </Form.Group>
                                    </Form>


                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .overview{
      &__right{
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
        padding: 0;
        &__box{
          
          h3{
            color: #fff;
            font-size: 24px;
            line-height: 32px;
            font-weight: 400;
          }
          .title{
            position: relative;
           padding: 45px 0 40px 30px;
            background-color: #92278F;
            margin-bottom: 50px;
            
            img{
              width: 100%;
              position: absolute;
              bottom: -15px;
              left: 0;
            }
            
          }
          .account__form{
            padding: 0 30px 40px 30px;
            
          }
          .password-input {
            position: relative;
          }
          .password-input.focused {
            /* Define the focused border color */
            border-color: #92278F;
          }
          .password-toggle-button {
            position: absolute;
            top: 65%;
            right: 15px;
            transform: translateY(-50%);
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
          }
          .number-input.focused {
            /* Define the focused border color */
            border-color: #92278F;
          }
        }
      }
    }
`;

export default MyComponent;
