import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import TringleInnerPage from "../../components/TringleInnerPage";
import AttrZigzak from "../../components/attractions/AttrZigzak";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/ride";
import Banner from "../../components/attractions/Banner";
import {ApiParam} from "../../api/network/apiParams";

const MyComponent = () => {

    const text = `   Uncover the Perfect Toys <br/>
                            for Every Little Explorer! `
    const zigzags = [
        {
            text: "Just like the ancient Waturi people who rode the ocean waves on friendly sea turtles, you can\n" +
                "enjoy this twisting, turning, multi-person slide. On Honu, you can surf the underwater currents\n" +
                "on a five-passenger raft, speeding through an open flume, then streaking along two massive walls.",
            image: "/images/dynamic/taste/sun.jpg",
            svg: "/images/static/rides/1.svg",
            title: "Sun Kissed",
            bgColor: "#498DCB",
        },

        {
            text: "Just like the ancient Waturi people who rode the ocean waves on friendly sea turtles, you can\n" +
                "enjoy this twisting, turning, multi-person slide. On Honu, you can surf the underwater currents\n" +
                "on a five-passenger raft, speeding through an open flume, then streaking along two massive walls.",
            image: "/images/dynamic/taste/cove.jpg",
            svg: "/images/static/rides/1.svg",
            title: "The Cove",
            bgColor: "#92278F",
        },
        {
            text: "Just like the ancient Waturi people who rode the ocean waves on friendly sea turtles, you can\n" +
                "enjoy this twisting, turning, multi-person slide. On Honu, you can surf the underwater currents\n" +
                "on a five-passenger raft, speeding through an open flume, then streaking along two massive walls.",
            image: "/images/dynamic/taste/surf.jpg",
            svg: "/images/static/rides/1.svg",
            title: "Surf Bar",
            bgColor: "#DB6352",
        },
    ];

    const dispath = useDispatch()
    let param = {
        [ApiParam.cat_slug]: 'taste' //change to slug later
    }

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ATTRACTION
        dispath(fetchData([api_url, param]))
    }, [])

    let getPost = useSelector(state => state.ride)



    const banner = getPost?.posts[0]?.banner;
    const sanc = getPost?.posts[0]?.sanctuary;
    const zigzag = getPost?.posts?.data;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Taste | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <Banner videoLink={'https://www.youtube.com/embed/IT0RId_RXrM?si=1em7v_chHB4HGl7N'}
                        img={'/images/dynamic/video-thumb-min.jpg'} title={'Taste'}/>
                <AttrZigzak data={zigzag}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .box-svg__single__upper__icon {
    display: none;
  }
`;

export default MyComponent;
