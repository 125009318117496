import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ProductOrder from "./ProductOrder";
import Button from "./Button";

const MyComponent = ({buttonText, src}) => {


    return (
        <StyledAccount className='account'>
            <div className="account__button">
                <Link to={src}></Link>
                <h3>{ReactHtmlParser(buttonText)}</h3>
            </div>
        </StyledAccount>
    );
};
const StyledAccount = styled.div`

  background: #F9F9F9;
  position: relative;
  .account__button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    text-align: center;
    margin-bottom: 30px;
 
    h3{
      color: #1A1818;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }
    a{
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0px;
      z-index: 2;
      cursor: pointer;
    }
  }
  



  @media (max-width: 767px) {
    .account__button{
      height: 120px;
      margin-bottom: 15px;
    }
  }



`;
export default MyComponent;