import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import {
    useParams,
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/events/InnerBanner";
import Overview from "../../components/events/Overview";
import Ticket from "../../components/events/Ticket";
import Follow from "../../components/events/Follow";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(state => state.event)
    let {slug} = useParams();

    const [imageSource, setImageSource] = useState('');



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.EVENT_DETAILS
        dispatch(fetchPostDetail([api_url + `${slug}`]))
    }, [])


    const banner = getData?.detail[0]?.banner;
    const overview = getData?.detail[0]?.overview;
    const ticket = getData?.detail[0]?.ticket;
    const follow = getData?.detail[0]?.follow;

    const eventDetail = getData?.detail

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                setImageSource(eventDetail?.meta?._event_banner[0]);
            } else {
                setImageSource(eventDetail?.meta?._mobile_banner[0]);
            }
        };

        // Call handleResize initially and add event listener for window resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [eventDetail]);



    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{eventDetail?.post_title ? `${eventDetail?.post_title} | Mana Bay Water Park` : 'Mana Bay water park'}</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <InnerBanner title={eventDetail?.post_title} img={imageSource} desc={eventDetail?.post_content}/>
                <Overview title={eventDetail?.meta?._event_overview_title ? eventDetail?.meta?._event_overview_title[0] : ''} description={eventDetail?.meta?._event_overview_description ? eventDetail?.meta?._event_overview_description[0] : ''} eventDetail={eventDetail}/>
                <Ticket data={eventDetail}/>
                <Follow data={eventDetail} follow={eventDetail?._event_repeater}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
