import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner";
import BoxImageWithText from "../BoxImageWithText";
import Select from "react-select";
import {useSelector} from "react-redux";
import {fetchProductList} from "../../api/redux/products";

const MyComponent = ({catFilter, total, dispatch, loading, setLoading, catSlug, setCatSlug}) => {
    let products = useSelector(state => state?.products?.products);
    let totalProducts = useSelector(state => state?.products?.total);
    const [page, setPage] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the total number of pages


    // Generate an array of page numbers for navigation
    const pageNumbers = [];


    useEffect(() => {
        if (products && totalProducts) {
            const totalPages = Math.ceil(totalProducts / 10);
            const pagesArray = Array.from({length: totalPages}, (_, index) => index + 1);
            setPage(pagesArray)
        }
    }, [products])

    const selectCarType = catFilter && catFilter.length > 0 && catFilter.map(obj => ({
        value: obj?.slug, label: obj?.name
    }))

    const option = [
        {value: 'All', label: 'All Products',},
        {value: 'merch', label: 'Merch',},
        {value: 'candy', label: 'Candy',},
        {value: 'dishes', label: 'Dishes',}
    ];

    function handleChange(selectedOption) {

        // Handle selection change here
        // Get the href value of the selected option
        const href = selectedOption.href;

        // Use the scrollIntoView() method to scroll to the selected section
        window.location.hash = href;
        const section = document.querySelector(selectedOption?.href);
        if (section) {
            const theId = section.offsetTop;
            window.scrollTo({
                top: theId,
                behavior: 'smooth'
            });
        }
    }

    const location = useLocation();
    const pathname = location?.pathname;


    // pagination

    // const pageNumbers = [];
    // for (let i = 1; i <= Math.ceil(20 ); i++) {
    //     pageNumbers.push(i);
    // }
    //
    // const query = router?.query?.page ? router?.query?.page : 1;
    // const handlePaginate = (pageNumber) => {
    //     // window.scrollTo(0,500 );
    //
    //     setLoading(true);
    //     setSelectedYear('');
    //     Router.push(`?page=${pageNumber}`);
    //
    //     let getAllList = document.querySelectorAll('.pagination li a');
    //     let getLi = getAllList[pageNumber];
    //
    //     for (let j = 0; j < getAllList.length; j++) {
    //         getAllList[j].classList.remove('active');
    //         getLi?.classList?.add('active')
    //     }
    //
    //     let getSelectedData = document.querySelectorAll('.find-retainer-filter__single-value');
    //
    //     if (getSelectedData && getSelectedData[0]) {
    //         getSelectedData[0].textContent = ' '
    //     }
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 2000);
    // }
    // const handlePaginatePrev = (pageNumber) => {
    //     window.scrollTo(0, 500);
    //     setSelectedYear('');
    //     setLoading(true);
    //     Router.push(`?page=${parseInt(pageNumber) - 1}`);
    //
    //
    //     let getAllList = document.querySelectorAll('.pagination li a');
    //     let getLi = getAllList[pageNumber - 1];
    //
    //     for (let j = 0; j < getAllList.length; j++) {
    //         getAllList[j].classList.remove('active');
    //         getLi.classList.add('active')
    //
    //     }
    //     let getSelectedData = document.querySelectorAll('.find-retainer-filter__single-value');
    //
    //     if (getSelectedData && getSelectedData[0]) {
    //         getSelectedData[0].textContent = ' '
    //     }
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 2000);
    // }
    // const handlePaginateNext = (pageNumber) => {
    //     setLoading(true);
    //
    //     setSelectedYear('');
    //     Router.push(`?page=${parseInt(pageNumber) + 1}`);
    //
    //     let getAllList = document.querySelectorAll('.pagination li a');
    //
    //     let getLi = getAllList[`${parseInt(pageNumber) + 1}`];
    //
    //     for (let j = 0; j < getAllList.length; j++) {
    //         getAllList[j].classList.remove('active');
    //         getLi?.classList.add('active')
    //
    //     }
    //
    //     let getSelectedData = document.querySelectorAll('.find-retainer-filter__single-value');
    //
    //     if (getSelectedData && getSelectedData[0]) {
    //         getSelectedData[0].textContent = ' '
    //     }
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 2000);
    // }


    const handlePagination = (p) => {
        setLoading(true)
        setCurrentPage(p)
        let params = {
            slug: catSlug,
            page: p,
            limit: 10
        }
        dispatch(fetchProductList(params)).then(() => {
            setLoading(false)
        })
    }

    const handleBack = () => {

        if (page.length > 0 && currentPage !== 1) {
            setLoading(true)
            setCurrentPage(currentPage - 1)
            let params = {
                slug: catSlug,
                page: currentPage - 1,
                limit: 10
            }
            dispatch(fetchProductList(params)).then(() => {
                setLoading(false)
            })
        }
    }

    const handleForward = () => {

        if (page.length > 0 && page.length !== currentPage) {
            setLoading(true)
            setCurrentPage(currentPage + 1)
            let params = {
                slug: catSlug,
                page: currentPage + 1,
                limit: 10
            }
            dispatch(fetchProductList(params)).then(() => {
                setLoading(false)
            })
        }
    }

    return (
        <StyledComponent className={'pt-120 pb-120 '}>
            <Container>
                <Row>
                    <Col md={3}>
                        <div className="momento__left">

                            <div className="momento__left__content-table__mobile">

                                {
                                    catFilter ? catFilter.length > 0 ?
                                        <Select
                                            options={selectCarType}
                                            onChange={handleChange}
                                            placeholder="Select Product"
                                            styles={{
                                                menuPortal: base => ({...base, zIndex: 9999}),

                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: state.isSelected ? 'white' : '#92278F',
                                                    zIndex: 9999999,
                                                    // color: state.isSelected ? '#FFF' : '#221F1F',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    zIndex: 9999999,
                                                    color: '#FFF',
                                                }),
                                                menuList: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected ? '#fff' : '#fff',
                                                    borderRadius: 0,
                                                    fontWeight: '600',
                                                    color: '#FFF',
                                                    fontSize: 16,
                                                    zIndex: 9999999,
                                                }),

                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: '#92278F',
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    color: '#92278F',
                                                }),
                                            }}
                                        /> : null : null
                                }

                            </div>


                            <div className="momento__left__content-table">
                                <h3>Category</h3>
                                <ul className={'scroll-list'}>
                                    {
                                        catFilter ? catFilter.length > 0 ?
                                            catFilter.map((e, index) => {

                                                return (
                                                    <li><Link
                                                        className={location.pathname == `/momento/products/${e.slug}` ? 'active' : ''}
                                                        to={e.slug}
                                                        onClick={() => {
                                                            setLoading(true)
                                                            let params = {
                                                                slug: e.slug,
                                                                page: 1,
                                                                limit: 10
                                                            }
                                                            dispatch(fetchProductList(params)).then(() => {
                                                                setCatSlug(e.slug)
                                                                setLoading(false)
                                                            })
                                                        }}
                                                    >{e.name}</Link></li>
                                                )
                                            }) : null : null
                                    }

                                </ul>
                            </div>

                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="momento__right">
                            <Row>
                                {
                                    products ? products.length > 0 ?
                                        products.map((e) => {
                                            return (
                                                <Col md={4}>
                                                    <BoxImageWithText title={e.name}
                                                        // price={'৳1,999'}
                                                                      price={e.sale_price !== "" ? `৳${e?.sale_price}` : `৳${e?.price}`}
                                                                      img={e.image} slug={e.slug}/>
                                                </Col>
                                            )
                                        }) : null : null
                                }


                            </Row>


                        </div>

                        <Row>
                            <Col lg={12}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">


                                        {page?.length > 1 &&
                                            <>
                                                <li id="page-item" onClick={handleBack} className={"hov"}>
                                                    <div className=" " aria-label="Previous">
                                        <span aria-hidden="true">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                              viewBox="0 0 30 30">
                                              <g id="Arow" transform="translate(30 30) rotate(180)">
                                                <g id="Rectangle_5514" data-name="Rectangle 5514" fill="none"
                                                   stroke="#989898" stroke-width="1">
                                                  <rect width="30" height="30" rx="15" stroke="none"/>
                                                  <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none"/>
                                                </g>
                                                <rect id="Rectangle_5515" data-name="Rectangle 5515" width="1"
                                                      height="1" rx="0.5" transform="translate(14.5 14.5)"
                                                      fill="#92278f" opacity="0"/>
                                                <g id="g2060" transform="translate(19 21) rotate(180)">
                                                  <path id="path2056" d="M0,0,6,6" transform="translate(0 6)"
                                                        fill="none" stroke="#989898" stroke-linecap="round"
                                                        stroke-width="1"/>
                                                  <path id="path2058" d="M0,6,6,0" transform="translate(0)" fill="none"
                                                        stroke="#989898" stroke-linecap="round"
                                                        stroke-width="1"/>
                                                </g>
                                              </g>
                                         </svg>

                                        </span>
                                                    </div>
                                                </li>
                                            </>
                                        }

                                        {
                                            page ? page?.length > 0 ? page?.map((e) => {
                                                return (
                                                    <li id="page-item"><a className="page-link page-num"
                                                                          onClick={() => {
                                                                              handlePagination(e)
                                                                          }}>{e} </a></li>

                                                )
                                            }) : null : null
                                        }
                                        {/*<p>hello</p>*/}

                                        {page?.length > 1 &&
                                            <>
                                                <li id="page-item" className={'hov'}>
                                                    <div className="" onClick={handleForward} aria-label="Next">
                                           <span aria-hidden="true">
                                           <svg id="Arow" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 30 30">
                                              <g id="Rectangle_5514" data-name="Rectangle 5514" fill="none"
                                                 stroke="#989898" stroke-width="1">
                                                <rect width="30" height="30" rx="15" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none"/>
                                              </g>
                                              <rect id="Rectangle_5515" data-name="Rectangle 5515" width="1" height="1"
                                                    rx="0.5" transform="translate(14.5 14.5)" fill="#92278f"
                                                    opacity="0"/>
                                              <g id="g2060" transform="translate(19 21) rotate(180)">
                                                <path id="path2056" d="M0,0,6,6" transform="translate(0 6)" fill="none"
                                                      stroke="#989898" stroke-linecap="round" stroke-width="1"/>
                                                <path id="path2058" d="M0,6,6,0" transform="translate(0)" fill="none"
                                                      stroke="#989898" stroke-linecap="round" stroke-width="1"/>
                                              </g>
                                           </svg>

                                            </span>
                                                    </div>
                                                </li>
                                            </>
                                        }


                                    </ul>
                                </nav>
                            </Col>


                        </Row>


                    </Col>
                </Row>


                {/*<Row>*/}
                {/*    {query < pageNumbers.length ?*/}
                {/*        // pageNumbers.length>1 && data?.list?.length >= 3 ?*/}
                {/*        <Col lg={12}>*/}
                {/*            <nav aria-label="Page navigation example">*/}
                {/*                <ul className="pagination justify-content-center">*/}
                {/*                    {*/}
                {/*                        router?.query?.page >= 2 ?*/}
                {/*                            <li id="page-item"*/}
                {/*                                onClick={() => paginatePrev(router?.query?.page, setSelectedYear)}>*/}
                {/*                                <a className="page-link" href="#" aria-label="Previous">*/}
                {/*                    <span aria-hidden="true">*/}
                {/*                       <svg xmlns="http://www.w3.org/2000/svg" width="9.811" height="18.121"*/}
                {/*                            viewBox="0 0 9.811 18.121">*/}
                {/*                         <path id="Path_253" data-name="Path 253" d="M-1260.242,634.779l8,8,8-8"*/}
                {/*                               transform="translate(643.529 1261.303) rotate(90)" fill="none"*/}
                {/*                               stroke="#ddd" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                               stroke-width="1.5"/>*/}
                {/*                       </svg>*/}
                {/*                    </span>*/}
                {/*                                </a>*/}
                {/*                            </li> :*/}
                {/*                            <li id="page-item">*/}
                {/*                                <a className="page-link" href="#" aria-label="Previous">*/}
                {/*                    <span aria-hidden="true">*/}
                {/*                       <svg xmlns="http://www.w3.org/2000/svg" width="9.811" height="18.121"*/}
                {/*                            viewBox="0 0 9.811 18.121">*/}
                {/*                         <path id="Path_253" data-name="Path 253" d="M-1260.242,634.779l8,8,8-8"*/}
                {/*                               transform="translate(643.529 1261.303) rotate(90)" fill="none"*/}
                {/*                               stroke="#ddd" stroke-linecap="round" stroke-linejoin="round"*/}
                {/*                               stroke-width="1.5"/>*/}
                {/*                       </svg>*/}
                {/*                    </span>*/}
                {/*                                </a>*/}
                {/*                            </li>*/}

                {/*                    }*/}
                {/*                    {*/}
                {/*                        pageNumbers.map(number => (*/}
                {/*                            <li key={number} onClick={() => paginate(number)} id="page-item">*/}
                {/*                                <a className="page-link" href="#">{number} </a></li>*/}
                {/*                        ))*/}
                {/*                    }*/}
                {/*                    {*/}
                {/*                        router?.query?.page < pageNumbers.length ?*/}
                {/*                            <li id="page-item"*/}
                {/*                                onClick={() => paginateNext(router?.query?.page)}>*/}
                {/*                                <a className="page-link" href="#" aria-label="Next">*/}
                {/*                       <span aria-hidden="true">*/}
                {/*                           <svg xmlns="http://www.w3.org/2000/svg" width="9.811" height="18.121"*/}
                {/*                                viewBox="0 0 9.811 18.121">*/}
                {/*                               <path id="Path_254" data-name="Path 254"*/}
                {/*                                     d="M-1260.242,634.779l8,8,8-8"*/}
                {/*                                     transform="translate(-633.718 -1243.182) rotate(-90)"*/}
                {/*                                     fill="none" stroke="#ddd" stroke-linecap="round"*/}
                {/*                                     stroke-linejoin="round" stroke-width="1.5"/>*/}
                {/*                           </svg>*/}
                {/*                        </span>*/}
                {/*                                </a>*/}
                {/*                            </li> :*/}
                {/*                            <li id="page-item">*/}
                {/*                                <a className="page-link" href="#" aria-label="Next">*/}
                {/*                       <span aria-hidden="true">*/}
                {/*                           <svg xmlns="http://www.w3.org/2000/svg" width="9.811" height="18.121"*/}
                {/*                                viewBox="0 0 9.811 18.121">*/}
                {/*                               <path id="Path_254" data-name="Path 254"*/}
                {/*                                     d="M-1260.242,634.779l8,8,8-8"*/}
                {/*                                     transform="translate(-633.718 -1243.182) rotate(-90)"*/}
                {/*                                     fill="none" stroke="#ddd" stroke-linecap="round"*/}
                {/*                                     stroke-linejoin="round" stroke-width="1.5"/>*/}
                {/*                           </svg>*/}
                {/*                        </span>*/}
                {/*                                </a>*/}
                {/*                            </li>*/}
                {/*                    }*/}
                {/*                </ul>*/}
                {/*            </nav>*/}
                {/*        </Col> : ''*/}
                {/*    }*/}

                {/*</Row>*/}

            </Container>
        </StyledComponent>

    );
};

const StyledComponent = styled.section`
  margin-top: 70px;

  .momento__left {
    position: sticky;
    top: 70px;

    &__content-table {
      background: #F9F9F9;
      //padding: 20px 0 20px 20px;
      padding-left: 20px;

      h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #1b1e21;
        border-bottom: 1px solid #E8E8E8;
        padding: 20px 0;

      }

      ul {
        li {
          border-bottom: 1px solid #E8E8E8;
          padding: 20px 0;
          //cursor: pointer;

          a {
            color: #1b1e21;

            &.active {
              color: #18AEAE;
            }
          }

          &:hover {
            color: #18AEAE;
          }
        }
      }
    }

    .momento__left__content-table__mobile {
      display: none;
    }
  }

  .momento__right {
    //z-index: -1;
    position: relative;

    .box-image__single {
      margin-bottom: 30px;
      margin-top: 0;

      //&:nth-child(3n+1){
      //  margin-bottom: 0;
      //}
      //&:nth-last-child(3n+2){
      //  margin-bottom: 0;
      //}
      //&:nth-last-child(3n+3){
      //  margin-bottom: 0;
      //}


    }


  }

  //Navigations

  .pagination {
    align-items: center;

    li {
      cursor: pointer;
    }
  }

  .page-link {
    border: none;
    color: #989898;
  }

  .hov {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0 5px;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: #92278F;
      transition: all .5s ease;
      border-radius: 50px;
      z-index: -1;
    }

    &:hover {
      svg {
        g {
          line {
            stroke: white;
          }
        }
      }

      &:after {
        height: 100%;
        width: 100%;
        z-index: -1;
      }
    }
  }


  .page-num {
    border-radius: 50%;
    background: #F9F9F9;
    height: 30px;
    margin-right: 5px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    //&:last-child{
    //  margin-right: 0;
    //}
  }

  //.navigation{
  //  ul{
  //    li{
  //      cursor: pointer;
  //    }
  //  }
  //}

  .css-yk16xz-control {
    border-radius: 30px !important;
  }

  .css-1pahdxg-control {
    border-radius: 30px !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    padding: 0 30px;
  }

  .css-1wa3eu0-placeholder {
    color: #92278F !important;
  }

  //.css-1nn07jk-menu{
  //  //position: relative;
  //  z-index: 99999;
  //}

  .css-1cuxnry-menu {
    background-color: #fff !important;
    z-index: 999999;
    position: relative;
  }


  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 25px;

    .momento__right {
      .box-image__single {
        margin-bottom: 20px;
      }

      .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }


    .momento__left {
      &__content-table {
        display: none;
      }

      .momento__left__content-table__mobile {
        margin: 40px 0;
        display: block;
      }

    }
  }

`;

export default MyComponent;
