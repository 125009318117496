import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/home";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Img from "../../components/Img";

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT_US
        dispath(fetchData([api_url]))
    }, [])

    let getPost = useSelector(state => state.home)
    const title = getPost?.posts?.data?.post_title;
    const description = getPost?.posts?.data?.post_content;
    const board = getPost?.posts?.data?.custom_img_list[0];


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About Us | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <Container className={'pt-80 pb-80'}>
                    <Row className={'terms'}>
                        <Col md={12} className={'terms__left'}>
                            <div className={'terms__left__content'}>
                                <h3>{title}</h3>
                                <p>{ReactHtmlParser(description)}</p>
                            </div>
                        </Col>

                    </Row>
                    <div className="board">
                        <h3>Management</h3>
                        {/*<Img src={board?._pages_custom_img_file}/>*/}
                        <img src={board?._pages_custom_img_file}/>
                        <p>{board?._pages_custom_img_short_desc}</p>
                    </div>
                </Container>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .terms {
    .col-md-12 {
      padding-left: 0;
    }

    &__left {
      margin-left: 40px;
      //margin-right: 25px;

      &__content {
        margin-bottom: 35px;
        padding-left: 0;


        h3 {
          margin-top: 20px;
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          color: #1A1818;
          margin-bottom: 20px;
        }

        p {
          margin-right: 25px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 300;
          color: #1A1818;


          ol {
            li {
              //position: relative;
              counter-increment: listStyle 1;
              padding-inline-start: 0 !important;
              margin-bottom: 20px;

              p {
                margin-left: 15px;
                margin-bottom: 10px !important;
              }

              &:before {
                content: counter(listStyle, -moz-ethiopic-numeric);
                position: absolute;
                left: 0;
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }
  .board {
    margin-left: 20px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 337px;
    height: 415px;
    //padding-top: calc(415 / 337 * 100%);
    h3{
      color: black;
      margin-bottom: 20px;
    }
    p {
      margin-top: 20px;
      color: black;
    }
  }
`;

export default MyComponent;
