import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Col, Container, Row } from 'react-bootstrap';
import Title from '../../components/Title';

gsap.registerPlugin(ScrollTrigger);

const MyComponent = ({ datas }) => {
  // console.log(datas)

  const data = [
    {
      title: 'Rides',
      num: '17',
      image1: '/images/dynamic/slide.jpg',
      image2: '/images/dynamic/slide2.jpg',
      image3: '/images/dynamic/slide3.jpg',
    },
    {
      title: 'Attractions',
      num: '27',
      image1: '/images/dynamic/t1.jpg',
      image2: '/images/dynamic/r1.jpg',
      image3: '/images/dynamic/b1.jpg',
    },
    {
      title: 'Restaurants',
      num: '9',
      image1: '/images/dynamic/t2.jpg',
      image2: '/images/dynamic/r2.jpg',
      image3: '/images/dynamic/b2.jpg',
    },
    {
      title: 'Food Trucks',
      num: '6',
      image1: '/images/dynamic/t3.jpg',
      image2: '/images/dynamic/r3.jpg',
      image3: '/images/dynamic/b3.jpg',
    },
    {
      title: 'Carbanas',
      num: '15',
      image1: '/images/dynamic/t4.jpg',
      image2: '/images/dynamic/r4.jpg',
      image3: '/images/dynamic/b4.jpg',
    },
    {
      title: 'One Perfect Day',
      num: '20',
      image1: '/images/dynamic/t5.jpg',
      image2: '/images/dynamic/r5.jpg',
      image3: '/images/dynamic/b5.jpg',
    },
  ];

  const pinSlider = useRef();
  const contents = useRef([]);
  const imageGroupRefs = useRef([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useLayoutEffect(() => {
    if (datas) {
      // animation code here
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinSlider.current,
          scrub: true,
          pin: true,
          start: 'top +100',
          end: () => `+=${(contents?.current?.length - 1) * 200}`,
          // markers: true,
          onUpdate: (self) => {
            const index = Math.round(
              self.progress * (contents?.current?.length - 1)
            );
            setCurrentItemIndex(index);
          },
        },
      });

      contents?.current?.forEach((content, index) => {
        if (index !== 0) {
          gsap.set(content, { y: '+=100', autoAlpha: 0 });
          tl.to(content, {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            immediateRender: false,
          });
        }
      });
    }
  }, [datas]);

  useLayoutEffect(() => {
    const imageGroups = document.querySelectorAll('.todos__image__group');
    imageGroups?.forEach((group, index) => {
      if (index === currentItemIndex) {
        group.style.opacity = 1;
        group.style.transition = 'opacity 0.5s ease-in-out';
      } else {
        group.style.opacity = 0;
        group.style.transition = 'opacity 0.5s ease-in-out';
      }
    });
  }, [currentItemIndex]);

  return (
    <StyledComponent>
      <Container className='todos' ref={pinSlider}>
        {datas ? (
          <Row>
            <Col md={6}>
              <div className='todos__image' ref={imageGroupRefs}>
                {datas &&
                  datas?.length > 0 &&
                  datas?.map((e, index) => {
                    return (
                      <div
                        className={`todos__image__group ${
                          currentItemIndex === index ? 'active' : ''
                        }`}
                      >
                        <img
                          className='top-img'
                          src={e?.top_image}
                          alt='Top Image'
                        />
                        <div className='bottom-img'>
                          <img
                            className='left-img'
                            style={{
                              top:
                                document.querySelector('.top-img')
                                  ?.clientHeight +
                                30 +
                                'px',
                            }}
                            src={e?.left_image}
                            alt='Left Image'
                          />
                          <img
                            className='right-img'
                            src={e?.right_image}
                            alt='Right Image'
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Col>
            <Col md={6}>
              <div className='todos__titles'>
                <div className='pinBox'>
                  <div className='pinSlider'>
                    {datas?.map((item, index) => (
                      <div
                        className={`content ${
                          currentItemIndex === index ? 'active' : ''
                        }`}
                        ref={(el) => (contents.current[index] = el)}
                        key={index}
                      >
                        <h1 className={'num'}>{item?.number}</h1>
                        <h1 className={'split-up'}>{item?.title}</h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Container>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
  background-color: rgb(149, 225, 225);
  position: relative;

  .todos {
    padding-top: 55px;
    padding-bottom: 75px;
    position: relative;
    height: 100%;

    &__image {
      &__group {
        position: relative;
        transition: opacity 0.5s ease-in-out;
        height: 100%;

        img {
          opacity: 0;
        }

        .top-img {
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
          height: auto;
          transition: opacity 0.5s ease;
          max-width: 80%;
        }

        .bottom-img {
          display: flex;

          .right-img {
            position: absolute;
            top: 95px;
            transition: opacity 0.5s ease;
            width: 60%;
            overflow: hidden;
            right: 0;
          }

          .left-img {
            position: absolute;
            top: 263px;
            left: 0;
            transition: opacity 0.5s ease;
            width: 33%;
          }
        }

        &.active {
          img {
            opacity: 1;
          }
        }
      }
    }

    &__titles {
      .pinBox {
        .pinSlider {
          //height: 100vh;

          .content {
            display: flex;
            justify-content: right;

            h1 {
              color: #92278f;
              //font-size: 60px;
              //line-height: 60px;
              font-weight: 300;
              margin-bottom: 15px;
            }

            .num {
              color: #fff;
              margin-right: 15px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      &__image {
        &__group {
          .top-img {
            width: 156px;
            height: 111px;
          }

          .bottom-img {
            opacity: 0;
          }
        }
      }

      &__titles {
        .pinBox {
          .pinSlider {
            .content {
              h1 {
                font-size: 40px;
                line-height: 40px;
                font-weight: 300;
                margin-bottom: 15px;
              }

              h2 {
                font-size: 40px;
                font-weight: 400;
                line-height: 40px;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
`;

export default MyComponent;
