import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Button from "../Button";
import {Form} from "react-bootstrap";
import Calendar from "react-calendar";
import {BrowserRouter as Router, Switch, Route, useLocation, useHistory, Link} from 'react-router-dom';
import ButtonA from "../ButtonA";
import Img from "../Img";
import CartBox from "./CartBox";
import {toast, ToastContainer} from "react-toastify";
import {Loading} from "../Loading";
import Address from "./Address";
import {useDispatch} from "react-redux";
import {cart, placeOrder} from "../../api/redux/checkout";
import {BASE_URL, URL_LIVE} from "../../api/network/baseUrl";
import CartBoxLoggedIn from "./CartBoxeLoggedIn";


const Checkout = ({
                      cartData,
                      userData,
                      adultCounter,
                      childCounter,
                      setAdultCounter,
                      setChildCounter,
                      total,
                      setTotal,
                      cartItems,
                      setCartItems,
                      cartTotal
                  }) => {
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [address, setAddress] = useState()
    const [postcode, setPostcode] = useState()
    const dispatch = useDispatch()
    const [otp, setOtp] = useState()
    const [isOtp, setIsOtp] = useState(false)

    useEffect(() => {
        setCartItems(JSON.parse(localStorage.getItem('addToCart')))
        setTotal(JSON.parse(localStorage.getItem('total')))
    }, [])


    // password hidden
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };


    //Timeline Process Start
    const [currentStep, setCurrentStep] = useState('cart');
    const location = useLocation();
    const history = useHistory();

    //get params for each circle
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        if (status) {
            setCurrentStep(status);
        }
    }, [location.search]);


    const [completedSteps, setCompletedSteps] = useState([]);

// Function to update completed steps
    const updateCompletedSteps = (step) => {
        setCompletedSteps((prevSteps) => [...prevSteps, step]);
    };


    const renderCircle = (status) => {
        const stepNumber = ['cart', 'checkout', 'payment'].indexOf(status) + 1;
        const isActive = status === currentStep;
        const isCompletedStep = completedSteps.includes(status);
        const shouldReplaceWithCheckmark = ['cart', 'checkout', 'payment']
            .indexOf(currentStep) >= ['cart', 'checkout', 'payment'].indexOf(status);

        return (
            <div
                className={`circle ${isActive ? 'active' : ''}`}
                onClick={() => handleContinue(status)}
            >
                <div className={`circle-number`}> {/*active-number*/}
                    {/*{isCompletedStep || shouldReplaceWithCheckmark ?  : stepNumber}*/}

                    {/*<span className="checkmark-icon">&#10003;</span>*/}
                </div>
                <div className="circle-label">
                    {status === 'cart' && 'Cart'}
                    {status === 'checkout' && 'Checkout'}
                    {status === 'payment' && 'Payment'}
                </div>
            </div>
        );
    };

    const handleContinue = (status) => {
        const steps = ['cart', 'checkout', 'payment'];
        const currentStepIndex = steps.indexOf(currentStep);
        const nextStepIndex = steps.indexOf(status);

        if (nextStepIndex === currentStepIndex + 1 || nextStepIndex === currentStepIndex - 1) {
            setCurrentStep(status);
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('status', status);
            history.push({search: queryParams.toString()});

            // Update completed steps
            updateCompletedSteps(status);
        }
    };


    const handleOrderPlace = () => {
        setLoading(true)
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let params = {
            token: token,
            id: id,
            domain: URL_LIVE
        }
        dispatch(placeOrder(params)).then(res => {
            dispatch(cart(params))
            setLoading(false)
            // console.log('response, sd', res?.payload?.data?.payment_info?.GatewayPageURL)
            if (res?.payload?.status === "success") {
                window.location.replace(res?.payload?.data?.payment_info?.GatewayPageURL)
                // toast.success(res?.payload?.message, {
                //     autoClose: 5000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "dark",
                // });
            } else {
                toast.error(res?.payload?.response?.data?.message, {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        })
    }

    // make timeline clickable & disable based on status
    const [checkoutClickable, setCheckoutClickable] = useState(false)
    const [paymentClickable, setPaymentClickable] = useState(false)
    useEffect(() => {
        if (currentStep === 'payment') {
            setPaymentClickable(true)
            setCheckoutClickable(true)
        }
        if (currentStep === 'checkout') {
            setCheckoutClickable(true)
        }

    }, [currentStep])


    return (
        <Router>

            <StyledTimeline currentStep={currentStep}>

                {
                    cartTotal !== 0 || total ?
                        <div className="timeline">
                            <div className="circle-wrap">
                                <div className={`circle`}>
                                    <div className={`circle-number`} onClick={() => setCurrentStep('cart')}>
                                        {currentStep === 'checkout' || currentStep === 'payment' ?
                                            <span className="checkmark-icon">&#10003;</span> : <p>1</p>}
                                        <Link to={`/checkout?status=cart`}/>
                                    </div>
                                    <div className="circle-label">
                                        Cart
                                    </div>
                                </div>
                                <div className={`circle`}
                                     style={checkoutClickable ? {pointerEvents: 'unset'} : {pointerEvents: 'none'}}>
                                    <div className={`circle-number`}
                                         onClick={() => setCurrentStep('checkout')}>
                                        {currentStep === 'payment' ? <span className="checkmark-icon">&#10003;</span> :
                                            <p>2</p>}

                                        <Link to={`/checkout?status=checkout`}/>
                                    </div>
                                    <div className="circle-label">
                                        Checkout
                                    </div>
                                </div>
                                <div className={`circle`}
                                     style={paymentClickable ? {pointerEvents: 'unset'} : {pointerEvents: 'none'}}>
                                    <div className={`circle-number`} onClick={() => setCurrentStep('payment')}>
                                        {currentStep === 'payment' ? <span className="checkmark-icon">&#10003;</span> :
                                            <p>3</p>}
                                        <Link to={`/checkout?status=payment`}/>
                                    </div>
                                    <div className="circle-label">
                                        Payment
                                    </div>
                                </div>
                            </div>
                            <div className="show-content">
                                <Switch>
                                    <Route exact path="/checkout">
                                        {/* Render the appropriate component based on currentStep */}
                                        {currentStep === 'cart' && (
                                            <div>
                                                <div>

                                                </div>
                                                <div className={'tc'}>
                                                    <div className={'title'}>
                                                        <h3>Cart</h3>
                                                    </div>
                                                    {
                                                        cartData ? cartData?.cart ? cartData?.cart.length > 0 ?
                                                            cartData?.cart.map((e) => {
                                                                return (
                                                                    <CartBoxLoggedIn cartSingle={e}
                                                                                     adultCounter={adultCounter}
                                                                                     setAdultCounter={setAdultCounter}
                                                                                     loading={loading}
                                                                                     setLoading={setLoading}/>
                                                                )
                                                            }) : null : null : null
                                                    }

                                                    {
                                                        cartItems ? cartItems?.length > 0 ?
                                                            cartItems.map((e) => {
                                                                return (
                                                                    <CartBox cartSingle={e} adultCounter={adultCounter}
                                                                             setAdultCounter={setAdultCounter}
                                                                             cartItems={cartItems}
                                                                             loading={loading} setLoading={setLoading}
                                                                             setCartItems={setCartItems} total={total}
                                                                             setTotal={setTotal}
                                                                    />
                                                                )
                                                            }) : null : null
                                                    }

                                                </div>
                                                <div className="process-btn d-flex justify-content-center">
                                                    {
                                                        cartTotal === 0 && total === 0 ?
                                                            <ButtonA
                                                                color="#dddddd"
                                                                borderColor="#dddddd"
                                                                hoverfill="#dddddd"
                                                                text="Continue"
                                                            /> :
                                                            <ButtonA
                                                                color="#92278F"
                                                                borderColor="#92278F"
                                                                hoverfill="#92278F"
                                                                text="Continue"
                                                                onClick={() => handleContinue('checkout')}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {currentStep === 'checkout' && (
                                            <Address userData={userData} handleContinue={handleContinue}
                                                     firstName={firstName} lastName={lastName}
                                                     email={email} phoneNumber={phoneNumber}
                                                     postcode={postcode} address={address}
                                                     setFirstName={setFirstName} setLastName={setLastName}
                                                     setEmail={setEmail} setPhoneNumber={setPhoneNumber}
                                                     setPostcode={setPostcode} setAddress={setAddress}
                                                     loading={loading} setLoading={setLoading}
                                                     cartData={cartData}
                                                     total={total}
                                                     otp={otp} setOtp={setOtp} isOtp={isOtp} setIsOtp={setIsOtp}
                                            />
                                        )}
                                        {currentStep === 'payment' && (
                                            <div>
                                                <div className={'details'}>
                                                    <h3>Payment</h3>
                                                    <div className={'account__form'}>
                                                        <div className={'form__fields'}>
                                                            <div className="account__form__container">
                                                                <div className="form-group-wrap">
                                                                    <Form className={'form'}>
                                                                        <div className="checkbox-single">
                                                                            <Form.Group>
                                                                                <label className="container">
                                                                                    <input type="checkbox" checked/>
                                                                                    <span className="checkmark"></span>
                                                                                    <span className="label-text">Pay with SSLCommerz</span>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '12px',
                                                                                            lineHeight: '14px',
                                                                                            color: '#989898',
                                                                                            display: 'block',
                                                                                            paddingLeft: '13px',
                                                                                            marginTop: '5px'
                                                                                        }}>(Debit/Credit Cards, Mobile Banking, Internet Banking)</span>
                                                                                </label>
                                                                            </Form.Group>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="process-btn d-flex justify-content-center">
                                                    <ButtonA
                                                        color="#1A1818"
                                                        borderColor="#1A1818"
                                                        hoverfill="#1A1818"
                                                        text="Back"
                                                        onClick={() => handleContinue('checkout')}
                                                    />
                                                    {
                                                        cartData && cartData.total === 0 ?
                                                            <ButtonA
                                                                color="#dddddd"
                                                                borderColor="#dddddd"
                                                                hoverfill="#dddddd"
                                                                text="Place Order"
                                                            /> :
                                                            <ButtonA
                                                                color="#1A1818"
                                                                borderColor="#1A1818"
                                                                hoverfill="#1A1818"
                                                                text="Place Order"
                                                                onClick={handleOrderPlace}
                                                                // src={'/order/status'}
                                                            />
                                                    }

                                                </div>
                                            </div>
                                        )}
                                    </Route>
                                </Switch>
                            </div>
                        </div> : null
                }


                {/*{*/}
                {/*    cartTotal === 0 && total === 0 ? <h2>Your Cart is Empty</h2> : null*/}
                {/*}*/}
                {
                    cartTotal === 0 && !total ? <h2>Your Cart is Empty</h2> : null
                }

            </StyledTimeline>
        </Router>
    );
};

const StyledTimeline = styled.div`


  h2 {
    color: #92278F;
  }

  .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;
    position: relative;

  }

  .checkmark-icon {
    background-color: rgb(146, 39, 143);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }

  .circle-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 60%;
  }

  .circle-wrap:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    width: 90%;
    background: #2D3691;
    opacity: 0.2;
    height: 2px;
    top: 30%;
  }

  .circle-wrap:after {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    height: 2px;
    top: 30%;
    background: #92278F;
    width: ${props => {
      switch (props.currentStep) {
        case 'date':
          return '0%';
        case 'ticket':
          return '35%';
        case 'details':
          return '55%';
        case 'checkout':
          return '45%';
        case 'payment':
          return '90%';
        default:
          return '0%';
      }
    }};
  }


  .circle-wrap:last-child:after {
    display: none !important;
  }


  .circle-number {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #92278F;
    position: relative;
    background: #FFFFFF;
    color: #92278F;
    overflow: hidden;
    transition: 0.3s ease-in-out;


    a {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      //background-color: red;
    }

    p {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .circle-number.active-number {
    background: #92278F;
    color: #fff;
    border: none;
  }

  .circle-label {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #1A1818;
  }

  .circle.active > .circle-label {
    color: #92278F;
  }


  .process-btn {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    gap: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }


  .show-content {
    margin-top: 40px;
    overflow-y: visible;

    .tc {
      border: 1px solid #E8E8E8;
      margin-top: 30px;
      max-height: 600px;
      overflow-y: scroll;
      margin-bottom: 40px;
      //border-top: 1px solid #E8E8E8;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

      .title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
          padding-top: 17px;
          padding-bottom: 19px;
          padding-left: 20px;
          border-bottom: 1px solid #E8E8E8;
          //background-color: #F9F9F9;
        }
      }


    }

    .details {
      margin-top: 30px;
      margin-bottom: 40px;
      background-color: #f9f9f9;
      border: 1px solid #E8E8E8;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

      h3 {
        color: #1A1818;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 20px;
        background-color: #f9f9f9;
        border: 1px solid #E9E9E9;
      }

      .account__form {
        padding: 20px 30px 40px 30px;
        background-color: #ffffff;
        //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

        .checkbox-single {
          margin-bottom: 25px;
          padding-top: 25px;

          .container {
            padding-bottom: 25px;
            border-bottom: 1px solid #E8E8E8;
            margin-bottom: 25px;
          }
        }

        .flex {
          display: flex;
          flex-wrap: wrap;
          //justify-content: space-between;

          //.form-group {
          //  &:last-child {
          //    flex: 0 0 100%;
          //    width: 100%;
          //  }
          //}
        }
      }
    }


    .tc::-webkit-scrollbar {
      display: none;
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .price-box {
      gap: 30px;
    }

    .circle-wrap {
      width: 100%;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .price-box {
      gap: 30px;
    }

    .circle-wrap {
      width: 100%;
    }
  }
`;

export default Checkout;
