import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Img from "../components/Img"
import {Link} from "react-router-dom";

const MyComponent = () => {

    return (
        <StyledComponent className={'footer pb-100 pt-100 '}>
            <Img src={'/images/static/footerbg.jpg'}/>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="footer__head">
                            <div className="footer__head__icon">
                                <img src="/images/static/footerIcon.svg" alt=""/>
                            </div>
                            <div className="footer__head__address">
                                <h6>
                                    <a href={'https://www.google.com/maps/dir/23.7961216,90.4036352/14th+Floor,+Manabay+Head+Office,+Sanmar+Tower-2,+2+Gulshan+Ave,+Dhaka+1212/@23.7950651,90.3995379,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3755c7ee20cf72c5:0xb8a1e6462003b16c!2m2!1d90.4136404!2d23.7949011?entry=ttu'}
                                       target="_blank">Head Office <br/>14th
                                        Floor, Sanmar Tower-2, <br/>
                                        Gulshan 2, Dhaka 1212</a>

                                </h6>
                                <br/>
                                <h6>
                                    <a href={'https://www.google.com/maps/place/Mana+Bay+Waterpark/@23.7987049,90.1025885,10z/data=!4m10!1m2!2m1!1smanabay!3m6!1s0x375453269da1a857:0x93c502dc4a87c263!8m2!3d23.5491875!4d90.6659375!15sCgdtYW5hYmF5kgEKd2F0ZXJfcGFya-ABAA!16s%2Fg%2F11rqs6mfv6?entry=ttu'}
                                       target="_blank">Park Address
                                        <br/>Bausia, Gazaria, Munshiganj, <br/>
                                        Dhaka, Bangladesh
                                    </a>

                                </h6>
                                <br/>
                                <h6>
                                    <a href={'tel:09606889999'}>09606889999</a>
                                </h6>
                                <h6>
                                    <a href={'mailto:info@manabay.com'}>info@manabay.com</a>
                                </h6>
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="footer__menu">
                            <ul>
                                <li><Link to="/experience/rides"> Rides </Link></li>
                                <li><Link to="/experience/attractions"> Attractions </Link></li>
                                <li><Link to="/taste"> Taste </Link></li>
                                <li><Link to="/unwind"> Unwind </Link></li>
                                <li><Link to="/about"> About Us </Link></li>
                                <li><Link to="/Terms"> Terms & Conditions </Link></li>
                                <li><Link to="/privacy-policy"> Privacy Policy </Link></li>
                                <li><Link to="/refund-policy"> Refund Policy </Link></li>
                                {/*<li><Link to="/momento"> Momento </Link></li>*/}
                                {/*<li><Link to="/events"> Events </Link></li>*/}
                            </ul>
                        </div>

                    </Col>
                    <Col md={12}>
                        <div className="footer__social">
                            <ul className="social">
                                <li><Link target={'_blank'} to={{pathname: 'https://www.facebook.com/manabaybd'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_18194" data-name="Group 18194" transform="translate(-549 -5267)">
                                            <g id="Ellipse_123" data-name="Ellipse 123" transform="translate(549 5267)"
                                               fill="none" stroke="#1a1818" stroke-width="1">
                                                <circle cx="15" cy="15" r="14.5" fill="none"/>
                                                <circle className={'social-hover'} cx="15" cy="15" r="0" stroke="none"/>
                                            </g>
                                            <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                                                  d="M9.617,9l.444-2.9H7.283V4.225A1.448,1.448,0,0,1,8.916,2.661h1.263V.2A15.4,15.4,0,0,0,7.937,0,3.535,3.535,0,0,0,4.153,3.9V6.1H1.609V9H4.153v7h3.13V9Z"
                                                  transform="translate(557.821 5274)" fill="#1a1818"/>
                                        </g>
                                    </svg>

                                </Link>
                                </li>
                                <li><Link target={'_blank'} to={{pathname: 'https://www.instagram.com/manabaybd/'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_18195" data-name="Group 18195" transform="translate(-549 -5267)">
                                            <g id="Ellipse_123" data-name="Ellipse 123" transform="translate(549 5267)"
                                               fill="none" stroke="#1a1818" stroke-width="1">
                                                <circle className={'social-hover'} cx="15" cy="15" r="0" stroke="none"/>
                                                <circle cx="15" cy="15" r="14.5" fill="none"/>
                                            </g>
                                            <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
                                                  d="M7,5.648a3.589,3.589,0,1,0,3.589,3.589A3.583,3.583,0,0,0,7,5.648Zm0,5.922A2.333,2.333,0,1,1,9.329,9.236,2.337,2.337,0,0,1,7,11.569ZM11.569,5.5a.837.837,0,1,1-.837-.837A.835.835,0,0,1,11.569,5.5Zm2.377.85a4.142,4.142,0,0,0-1.131-2.933A4.169,4.169,0,0,0,9.882,2.287c-1.156-.066-4.619-.066-5.775,0A4.164,4.164,0,0,0,1.175,3.414,4.156,4.156,0,0,0,.044,6.347c-.066,1.156-.066,4.619,0,5.775a4.142,4.142,0,0,0,1.131,2.933,4.175,4.175,0,0,0,2.933,1.131c1.156.066,4.619.066,5.775,0a4.142,4.142,0,0,0,2.933-1.131,4.169,4.169,0,0,0,1.131-2.933c.066-1.156.066-4.616,0-5.772Zm-1.493,7.012a2.362,2.362,0,0,1-1.331,1.331A15.426,15.426,0,0,1,7,14.974a15.546,15.546,0,0,1-4.126-.281A2.362,2.362,0,0,1,1.54,13.362a15.426,15.426,0,0,1-.281-4.126A15.546,15.546,0,0,1,1.54,5.11,2.362,2.362,0,0,1,2.87,3.78,15.426,15.426,0,0,1,7,3.5a15.546,15.546,0,0,1,4.126.281A2.362,2.362,0,0,1,12.453,5.11a15.426,15.426,0,0,1,.281,4.126A15.417,15.417,0,0,1,12.453,13.362Z"
                                                  transform="translate(557.005 5272.762)" fill="#1a1818"/>
                                        </g>
                                    </svg>

                                </Link>
                                </li>
                                <li><Link target={'_blank'} to={{pathname: 'https://www.tiktok.com/@manabaybd'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                        <g id="Group_18197" data-name="Group 18197" transform="translate(-549 -5267)">
                                            <g id="Ellipse_123" data-name="Ellipse 123" transform="translate(549 5267)"
                                               fill="none" stroke="#1a1818" stroke-width="1">
                                                <circle className={'social-hover'} cx="15" cy="15" r="0" stroke="none"/>
                                                <circle cx="15" cy="15" r="14.5" fill="none"/>
                                            </g>
                                            <path id="Icon_ionic-logo-youtube" data-name="Icon ionic-logo-youtube"
                                                  d="M13.909,6.819A2.126,2.126,0,0,0,11.885,4.6c-1.515-.071-3.06-.1-4.638-.1H6.755c-1.575,0-3.123.027-4.638.1A2.13,2.13,0,0,0,.1,6.825C.027,7.8,0,8.772,0,9.745s.027,1.947.093,2.923A2.133,2.133,0,0,0,2.114,14.9C3.705,14.971,5.338,15,7,15s3.29-.027,4.884-.1a2.133,2.133,0,0,0,2.024-2.229c.066-.976.1-1.95.093-2.926S13.974,7.8,13.909,6.819ZM5.661,12.428V7.054L9.626,9.74Z"
                                                  transform="translate(557 5272.5)" fill="#1a1818"/>
                                        </g>
                                    </svg>

                                </Link>
                                </li>
                            </ul>
                        </div>

                    </Col>
                    <Col md={12} className={'social__contact'}>
                        <p>© Mana Bay Water Park. All Rights Reserved</p>

                        <p><a href={'https://dcastalia.com/'} target={'_blank'}>Design & Developed by
                            <span> Dcastalia</span></a></p>
                    </Col>

                    <Col md={6}>
                        {/*<Img bottom={0} right={0} width="600px" height="97px" src="/images/static/ssl-commerze.svg" alt={'ssl-logo'}/>*/}
                    </Col>

                    <div className="ssl-commerze">
                        <img src="/images/static/ssl-logos.jpeg" alt={'ssl-logo'}/>
                    </div>

                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  //background: url("/images/static/footerbg.svg");
  //background-repeat: no-repeat;
  //object-fit: cover;
  position: relative;
  overflow: hidden;

  .footer__head {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      //margin-bottom: 60px;
    }

    &__address {
      margin: 60px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h6 {
        a {
          color: #1A1818;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          margin-bottom: 10px;

          &:hover {
            color: #92278F;
          }
        }

      }
    }
  }

  .footer__menu {

    border: 1px solid #989898;
    border-width: 0.8px 0;
    padding: 25px 0;
    margin-bottom: 40px;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {


        &:first-child {
          margin-left: 0;
        }

        a {
          cursor: pointer;
          color: #1A1818;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          margin-left: 40px;

          &:hover {
            color: #92278F;
          }
        }

      }
    }
  }

  .footer__social {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        margin-left: 20px;
        margin-bottom: 60px;

        &:first-child {
          margin-left: 0;
        }

        a {
          cursor: pointer;
        }

        svg {

          .social-hover {
            r: 0px;
            fill: #92278F;
            transition: 0.7s all ease;
          }

          path {
            transition: 0.7s all ease;
          }

        }


        &:hover {
          svg {
            .social-hover {
              r: 15px;
              transition: 0.7s all ease;
            }

            g {
              stroke: #92278F;
            }

            line {
              stroke: white;
            }

            path {
              stroke: white;
              fill: #ffffff;
              transition: 0.7s all ease;
            }
          }
        }

      }
    }
  }

  .social__contact {
    p {
      color: #1A1818;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;

      a {
        color: #1A1818;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }

      span {
        &:hover {
          color: #92278F;
        }
      }
    }

    display: flex;
    justify-content: space-between;

  }

  .ssl-commerze {
    position: relative;
    //padding-top: calc(97 / 600 * 100%);
    //padding-bottom: 80px;
    //margin-bottom: 20px;
    padding: 20px;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    //top: 20px;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    padding-bottom: 60px;
    .footer__head {
      &__icon {
        margin-top: 80px;
      }

      &__address {
        margin-top: 40px;
        margin-bottom: 25px;
      }
    }

    .footer__menu {
      //margin-right: 10px;
      //margin-left: 10px;
      margin-bottom: 0;
      padding: 0;

      ul {
        margin: 20px 0;
        //display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        text-align: center;
      }

      li {
        margin-bottom: 15px;

        a {
          margin-left: 0 !important;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .footer__social {
      ul {
        margin-top: 25px;
        margin-bottom: 30px;

        li {
          margin-bottom: 0;
        }
      }
    }

    .social__contact {
      flex-direction: column;
      align-items: center;

      p {
        margin-bottom: 10px;

      }

    }

  }


`;

export default MyComponent;
