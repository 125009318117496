import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import Img from '../Img';
import Button from '../Button';
import { Link } from 'react-router-dom';

const MyComponent = ({ data }) => {
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleData = data?.slice(startIndex, startIndex + itemsPerPage);

  return (
    <StyledComponent className={'blog pt-120 pb-120'}>
      {visibleData && visibleData?.length > 0 ? (
        <Container>
          {visibleData?.map((e) => (
            <div className='blog__single' key={e}>
              <Link to={`blog/${e?.list?.url}`}>
                <Row>
                  <Col md={4}>
                    <div className='blog__single__image'>
                      <Img src={e?.list?.thumb} alt={e?.list?.title} />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className='blog__single__content'>
                      <h6 className={'date split-up'}>{e?.list?.date}</h6>
                      <h3 className={'split-up'}>{e?.list?.title}</h3>
                      <h6 className={'description'}>
                        {e?.list?.short_desc?.substring(0, 50)}
                        {e?.list?.short_desc?.length > 50 ? '...' : ''}
                      </h6>
                      <Button
                        className='your-button-class'
                        text={'Explore'}
                        borderColor={'#1A1818'}
                        color={'#1A1818'}
                        hoverfill={'#1A1818'}
                        hoverborder={'#1A1818'}
                        src={`blog/${e?.list?.url}`}
                      />
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          ))}

          <Row>
            <Col lg={12}>
              <nav aria-label='Page navigation example'>
                <ul className='pagination justify-content-center'>
                  <li id='page-item'>
                    <button
                      className='page-link'
                      disabled={currentPage === 1}
                      aria-label='Previous'
                      onClick={handlePrevPage}
                    >
                      <span aria-hidden='true'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='30'
                          height='30'
                          viewBox='0 0 30 30'
                        >
                          <g id='Arow' transform='translate(30 30) rotate(180)'>
                            <g
                              id='Rectangle_5514'
                              data-name='Rectangle 5514'
                              fill='none'
                              stroke='#989898'
                              stroke-width='1'
                            >
                              <rect
                                width='30'
                                height='30'
                                rx='15'
                                stroke='none'
                              />
                              <rect
                                x='0.5'
                                y='0.5'
                                width='29'
                                height='29'
                                rx='14.5'
                                fill='none'
                              />
                            </g>
                            <rect
                              id='Rectangle_5515'
                              data-name='Rectangle 5515'
                              width='1'
                              height='1'
                              rx='0.5'
                              transform='translate(14.5 14.5)'
                              fill='#92278f'
                              opacity='0'
                            />
                            <g
                              id='g2060'
                              transform='translate(19 21) rotate(180)'
                            >
                              <path
                                id='path2056'
                                d='M0,0,6,6'
                                transform='translate(0 6)'
                                fill='none'
                                stroke='#989898'
                                stroke-linecap='round'
                                stroke-width='1'
                              />
                              <path
                                id='path2058'
                                d='M0,6,6,0'
                                transform='translate(0)'
                                fill='none'
                                stroke='#989898'
                                stroke-linecap='round'
                                stroke-width='1'
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </li>

                  {Array.from({ length: totalPages }, (_, index) => (
                    <li className='page-item' key={index + 1}>
                      <button
                        className={`page-link page-num ${
                          currentPage === index + 1 ? 'active' : ''
                        }`}
                        onClick={() => setCurrentPage(index + 1)}
                        style={{
                          backgroundColor:
                            currentPage === index + 1 ? '#f9f9f9' : 'inherit',
                        }}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}

                  <li id='page-item'>
                    <button
                      className='page-link'
                      disabled={currentPage === totalPages}
                      aria-label='Next'
                      onClick={handleNextPage}
                    >
                      <span aria-hidden='true'>
                        <svg
                          id='Arow'
                          xmlns='http://www.w3.org/2000/svg'
                          width='30'
                          height='30'
                          viewBox='0 0 30 30'
                        >
                          <g
                            id='Rectangle_5514'
                            data-name='Rectangle 5514'
                            fill='none'
                            stroke='#989898'
                            stroke-width='1'
                          >
                            <rect
                              width='30'
                              height='30'
                              rx='15'
                              stroke='none'
                            />
                            <rect
                              x='0.5'
                              y='0.5'
                              width='29'
                              height='29'
                              rx='14.5'
                              fill='none'
                            />
                          </g>
                          <rect
                            id='Rectangle_5515'
                            data-name='Rectangle 5515'
                            width='1'
                            height='1'
                            rx='0.5'
                            transform='translate(14.5 14.5)'
                            fill='#92278f'
                            opacity='0'
                          />
                          <g
                            id='g2060'
                            transform='translate(19 21) rotate(180)'
                          >
                            <path
                              id='path2056'
                              d='M0,0,6,6'
                              transform='translate(0 6)'
                              fill='none'
                              stroke='#989898'
                              stroke-linecap='round'
                              stroke-width='1'
                            />
                            <path
                              id='path2058'
                              d='M0,6,6,0'
                              transform='translate(0)'
                              fill='none'
                              stroke='#989898'
                              stroke-linecap='round'
                              stroke-width='1'
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      ) : (
        <h4>There is no item available right now!</h4>
      )}
    </StyledComponent>
  );
};
const StyledComponent = styled.section`
  h4 {
    color: #92278f;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    padding: 25px;
    background-color: rgba(146, 39, 143, 0.1);
    margin-top: 20px;
    text-align: center;
  }

  .blog__single {
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
    //-webkit-box-shadow: 1px 10px 31px 0px rgba(0, 0, 0, 0.51);
    //-moz-box-shadow: 1px 10px 31px 0px rgba(0, 0, 0, 0.51);

    .link {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      cursor: pointer;
    }

    &:hover {
      .global-image {
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.06);
      }
    }

    &__image {
      // position: relative;
      padding-top: calc(250 / 370 * 100%);
      overflow: hidden;

      .global-image {
        transform: scale(1.01);
        transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
      }
    }

    &__content {
      margin-top: 40px;
      margin-bottom: 50px;
      margin-left: 20px;

      .date {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #1a1818;
      }

      .description {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: #1a1818;
        margin-bottom: 20px;
      }

      h3 {
        margin: 20px 0 25px 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #1a1818;
      }

      .dc-btn {
        z-index: 999;
      }
    }
  }

  .pagination {
    align-items: center;
    margin-top: 60px;
  }

  .page-link {
    border: none;
    color: #989898;
    //a{
    //  transition: 0.7s all ease;
    //
    //  svg{
    //    g{
    //      transition: 0.7s all ease;
    //    }
    //  }
    //}

    &:hover {
      //color: unset;
      svg {
        g {
          fill: #92278f;
          transition: 0.9s all ease;
        }
      }

      background-color: unset;
    }
  }

  .page-num {
    border-radius: 50%;
    background: #f9f9f9;
    height: 30px;
    margin-right: 5px;

    //&:last-child{
    //  margin-right: 0;
    //}
  }

  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    .blog__single {
      margin-bottom: 20px;

      &__image {
        padding-top: calc(250 / 345 * 100%);
      }

      &__content {
        margin-top: 23px;
        margin-bottom: 34px;
        padding: 0 15px;

        h3 {
          margin: 20px 0 25px 0;
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          color: #1a1818;
        }
      }
    }
  }
`;

export default MyComponent;
