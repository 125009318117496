import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchEventList, fetchPostDetail, fetchPosts} from "../../api/redux/event";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import HappeningSlider from "../../components/HappeningSlider";
import Activity_slider from "../../components/events/Activity_slider";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.EVENTS
        dispath(fetchPosts([api_url]))
    }, [])

    useEffect(() => {
        let api_url = apiEndPoints.EVENTS_LIST
        dispath(fetchEventList([api_url]))
    }, [])


    let getPost = useSelector(state => state.event)


    const banner = getPost?.posts?.page_data?.[0]?.banner;
    const happening = getPost?.posts?.page_data?.[0]?.slider;
    const active = getPost?.eventList;


    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Events | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <InnerBanner img={banner?.image} title={banner?.title}/>
                <HappeningSlider data={happening}/>
                {
                    active && active.length > 0 ?
                        <Activity_slider data={active}/> : <h4>No active events at this moment</h4>
                }

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    font-size: 30px;
    font-weight: 400;
    color: #1A1818;
  }
`;

export default MyComponent;
