import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Bonn BF';
    src: url('fonts/BonnBF-Regular.woff2') format('woff2'),
    url('fonts/BonnBF-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'Bonn BF';
    src: url('fonts/BonnBF-ExtraLight.woff2') format('woff2'),
    url('fonts/BonnBF-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Bonn BF';
    src: url('fonts/BonnBF-Thin.woff2') format('woff2'),
    url('BonnBF-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Bonn BF';
    src: url('fonts/BonnBF-Light.woff2') format('woff2'),
    url('fonts/BonnBF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Bonn BF';
    src: url('fonts/BonnBF-Bold.woff2') format('woff2'),
    url('fonts/BonnBF-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Bonn BF';
    src: url('fonts/BonnBF-Medium.woff2') format('woff2'),
    url('fonts/BonnBF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rexton';
    src: url('fonts/RextonBlackBlack.woff2') format('woff2'),
    url('fonts/RextonBlackBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rexton';
    src: url('fonts/RextonBoldBold.woff2') format('woff2'),
    url('fonts/RextonBoldBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rexton Extra';
    src: url('fonts/RextonExtraBoldExtraBold.woff2') format('woff2'),
    url('fonts/RextonExtraBoldExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rexton';
    src: url('fonts/RextonLightLight.woff2') format('woff2'),
    url('fonts/RextonLightLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rexton';
    src: url('fonts/RextonMediumMedium.woff2') format('woff2'),
    url('fonts/RextonMediumMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rexton';
    src: url('fonts/RextonRegularRegular.woff2') format('woff2'),
    url('fonts/RextonRegularRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  body {
    font-family: 'Bonn BF', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover};
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 16px;
      line-height: 26px;
      margin: 0;
    }

    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
    }

    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
    }
  }

  h1 {
    font-size: 60px;
    line-height: 64px;
    font-weight: 400;
    margin: 0;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 72px;
      line-height: 80px;
      margin: 0;
    }

    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 60px;
      line-height: 64px;
      margin: 0;
    }

    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 48px;
      margin: 0;
    }
  }

  h2 {
    font-size: 40px;
    line-height: 44px;
    font-weight: 400;
    margin: 0;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 48px;
      line-height: 52px;
      margin: 0;
    }

    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 40px;
      line-height: 44px;
      margin: 0;
    }

    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    font-weight: 400;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 28px;
      line-height: 32px;
      margin: 0;
    }
    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }
    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 16px;
      margin: 0;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    font-weight: 300;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 20px;
      line-height: 35px;
      margin: 0;
    }

    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 18px;
      line-height: 27px;
      margin: 0;
    }

    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 27px;
      margin: 0;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    margin: 0;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 18px;
      line-height: 28.5px;
      margin: 0;
    }

    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 16px;
      line-height: 19px;
      margin: 0;
    }

    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 19px;
      margin: 0;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin: 0;
    /* Large Screen */
    @media (min-width: 1550px) {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }

    /* Medium Screen */
    @media (max-width: 1549px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
    }

    /* Small Screen */
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
    }
  }

  // p, a, h4, h3, h5, h6 {
  //   color: ${text};
  //   font-weight: 400;
  //   margin: 0;
  // }

  // h1, h2 {
  //     font-family: ${title};
  //   margin: 0;
  // }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }


  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 15px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 15px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: .5 !important;
    pointer-events: none;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }

  //modal start


  .transparent-modal {
    background-color: rgba(0, 0, 0, 0.5);
  !important;
  }

  .modal {
    background: #FBF9F3;
    z-index: 999999;

    .modal-dialog {
      width: 100%;
    }

    .modal-header {
      padding: 20px 0 0px;
      margin-bottom: 10px;
      border: none;


      .container {
        padding: 0;
        margin: 0;


        .row {
          margin: 0;
          width: 100%;

          .header_wrap {
            padding: 0;
            display: flex;
            justify-content: space-between;
            background: #F9F9F9;

            h2 {
              color: #1A1818;
              font-size: 40px;
              font-weight: 400;
              letter-spacing: 0.5px;
            }

          }
        }
      }

      .close_button {
        svg {
          #hover_circle {
            transition: all .3s ease;

          }

          line {
            transition: all .3s ease;

          }
        }

        cursor: pointer;

        &:hover {
          svg {
            #hover_circle {
              r: 24px;
            }

            line {
              stroke: white;
            }
          }
        }
      }
    }

    .modal-dialog {
      margin: 0;

      overflow: hidden;
      max-width: 100%;
    }

    .modal-content {
      border-radius: 0;
      padding: 0px 70px;
      background: #F9F9F9;

      border: none !important;

      .close {
        height: 50px;
        width: 50px;

        background-size: 25px;
        background-position: center;
        padding: 0;
        background-repeat: no-repeat;
        position: absolute;
        right: -34px;

        opacity: 1;
        top: 16px;
        transition: all .3s ease;


        span {
          display: none;
        }

      }
    }

    .modal-body {
      padding: 0;

      .swiper-button-prev, .swiper-button-next {
        display: none;
      }

      .container {
        padding: 0;
        margin: 0;

        .row {

          .col-wrap {
            display: flex;
            justify-content: space-between;
            margin: 25px 0 50px 0;

            .first-col {
              &__single {
                margin-bottom: 20px;

                &:last-child {
                  margin-bottom: 0;
                }

                p {
                  color: #1b1e21;
                  font-size: 14px;
                  line-height: 25px;
                  font-weight: 300;
                }

                h5 {
                  color: #1b1e21;
                  font-size: 14px;
                  line-height: 25px;
                  font-weight: 400;
                }
              }

            }


          }

          .box-svg {
            background: #92278F;
            position: relative;
            height: 300px;

            &__svg {
              img {
                position: absolute;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                transform: translate(43%, 40%);
              }


            }

            &__btn {
              position: absolute;
              bottom: 0;
              margin-bottom: 80px;
              margin-left: 55px;
            }

            .ticker-svg {
              position: absolute;
              top: -18px;
              width: 100%;
              height: auto;

              svg {
                width: 100%;
                flex: 1;
                height: auto;
                object-fit: contain; /* Ensures the image maintains its aspect ratio */
                background-repeat: repeat-x;
              }
            }

          }

        }
      }
    }


    .simplebar-offset, .main_scroll, .custombar, .simplebar-mask, .simplebar-content-wrapper, .simplebar-height-auto-observer-wrapper, .simplebar-scrollbar, simplebar-visible {
      &::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover {
        display: none;
        width: 0;
      }


    }

    .simplebar-scrollbar {
      display: none !important;
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .announcement {

    position: relative;

    .pop-close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 15;

      svg {
        cursor: pointer !important;
        transition: 0.2s ease-in-out;

        &:hover {
          transform: scale(0.8);
          transition: 0.2s ease-in-out;
        }
      }
    }

    &__data {
      h3 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 500;
        color: #F0EDE3;
        margin-bottom: 20px;
      }

      p {
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        color: #F0EDE3;
      }
    }

    @media (max-width: 768px) {
      margin: 120px 15px;
      &__data {
        h3 {
          font-size: 24px;
          line-height: 28px;
        }

        padding: 40px 20px;
      }
    }
  }

  .modal-content {
    height: 100% !important;
  }

  .gph_modal {
    background: #F4F4F4;
    overflow: hidden !important;

    .slider-nav {
      //position: absolute;
      //top: 7px;
      //right: 15px;
      position: absolute;
      left: 0;
      right: 0;
      width: calc(100% - 15px);
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      ul {
        display: flex;
        justify-content: space-between;
        display: flex;
        padding: 0 20px;
      }

      li {
        height: 50px;
        width: 50px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid white;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          color: #ffffff;
          z-index: 2;
          font-size: 20px;
        }
      }
    }

    .hover {
      height: 40px;
      width: 40px;

      svg {
        font-size: 17px;
      }
    }

    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      background: #F4F4F4;

      .btn-close {
        display: none;
      }

      .modal-header {
        padding: 0;
        margin-bottom: 0;
        background: #F4F4F4;

        .h4 {
          color: #222222;
          font-weight: 500;
        }

        .header_wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 15px 20px;

          .close_button {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }
          }

        }

      }

      .custombar {
        position: relative;

        &:after {
          z-index: 1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px;
          background: transparent;
          background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          rgba(244, 244, 244, 1) 100%);
        }
      }

      .modal-content {
        height: 100vh;
        padding: 0;
        background: #F4F4F4;
        border: none;

        .left_col {
          padding: 60px 15px 60px;

          h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            color: #222222;
            margin: 0 0 10px;
          }

          p {
            font-weight: 400;
            color: #222222;

            &.deg {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #FB030C;
              margin: 0 0 40px;
            }
          }
        }

        .right_col {
          padding: 60px 15px 60px;

          .custombar {
            &:after {
              display: none;
            }
          }

          .right_col_img {
            position: relative;
            padding-top: calc(550 / 500 * 100%);
          }
        }

      }
    }

    .simplebar-track.simplebar-vertical {
      display: none;
    }

    @media (max-width: 767px) {
      overflow-y: scroll !important;
      .custombar {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }

      .modal-content {
        overflow: hidden !important;
      }

      .row {
        flex-direction: column-reverse;

        .left_col {
          padding: 0 15px 40px !important;
        }

        .right_col {
          padding: 40px 15px !important;
        }
      }

    }

    @media (min-width: 767px) {
      .main_scroll {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }
    }
  }

  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //  Form group  

  .login__form {
    .form-group {
      .form-control {
        background: transparent;
        border-radius: 30px;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 30px;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
        color: #1A1818;
        height: 100%;
        font-weight: 500;

        &::placeholder {
          color: #989898;
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
        }
      }

    }
  }

  .account__form {
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .form-group {
        flex: 0 0 calc(50% - 15px);
        width: calc(50% - 15px);
      }
    }

    @media (max-width: 991px) {
      .flex {
        .form-group {
          flex: 0 0 100% !important;
          width: 100% !important;
        }
      }
    }

    .form-group {
      margin-bottom: unset;

      .form-control {
        background: transparent;
        border-radius: 30px;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 30px;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
        color: #1A1818;
        height: 100%;
        font-weight: 500;

        &::placeholder {

          color: #989898;
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
        }
      }

      label {
        color: #989898;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        margin-bottom: 10px;
      }

    }
  }


  //.account__form{
  //  .flex {
  //    display: flex;
  //    flex-wrap: wrap;
  //    justify-content: space-between;
  //
  //    .form-group {
  //      flex: 0 0 calc(50% - 15px);
  //      width: calc(50% - 15px);
  //    }
  //  }
  //  .form-group {
  //  
  //
  //    .form-control {
  //      background: transparent;
  //      border-radius: 30px;
  //      padding-top: 17px;
  //      padding-bottom: 17px;
  //      padding-left: 30px;
  //      font-size: 12px;
  //      line-height: 14px;
  //      margin-bottom: 20px;
  //      color: #1A1818;
  //      height: 100%;
  //      font-weight: 500;
  //
  //      &::placeholder {
  //        color: #989898;
  //      }
  //    }
  //
  //  }
  //}

  //  Form group  


  //check start
  /* Hide the browser's default checkbox */

  label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  label input:checked {

  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #32355D;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */

  label:hover input ~ .checkmark {
    background-color: transparent;

  }

  /* When the checkbox is checked, add a blue background */

  label input:checked ~ .checkmark {
    background-color: #92278F;
    border: 1px solid #92278F;

  }

  /* Create the checkmark/indicator (hidden when not checked) */

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */

  label input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */

  label .checkmark:after {
    left: 0 !important;
    top: 0 !important;
    background-image: url(/images/static/tik.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center !important;
    width: 100%;
    height: 100%;
    bottom: 0 !important;
    right: 0 !important;
    transform: none !important;
    border: none !important;
    border-width: none !important;
  }

  .label-text {

    color: #1A1818;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-left: 16px;

    a {
      color: #92278F;
    }


  }

  //check end

  .buyticket {
    border-radius: 10px;

    .form-group {
      width: 100%;
      display: flex;
      align-items: center;

    }

    .react-datepicker__input-container {
      border-radius: 10px !important;
      padding: 0 30px;
      box-shadow: none;
      border: 1px solid #E8E8E8;
      height: 50px;
      display: flex;
      position: relative;

      input {
        background: transparent;
        box-shadow: none;
        border: none;
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        width: 100%;

        &::placeholder {
          border: none;
          outline: none;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

  }


  //text animation 
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  .faq-modal {
    background: unset;
    padding-right: 0 !important;

    .modal-dialog {
      margin-left: auto;
      margin-right: 0;
      margin-top: 0;
      min-height: 100%;

      overflow: hidden;
      max-width: 40%;
      @media (max-width: 767px) {
        max-width: 100%;
      }

      .modal-content {
        padding: 0;

        .modal-body {
          .announcement {
            &__data {
              @media (max-width: 767px) {
                padding: 0;
              }
            }

            @media (max-width: 767px) {
              margin: 0;
            }
          }
        }
      }
    }
  }


  .log {
    background: unset;
    display: flex !important;

    .pop-close {

      position: absolute;
      top: -65px;
      right: -125px;
      z-index: 15;
      @media (max-width: 767px) {
        top: -120px;
        right: -60px;

      }

      svg {
        cursor: pointer !important;
        transition: 0.2s ease-in-out;

        &:hover {
          transform: scale(0.8);
          transition: 0.2s ease-in-out;
        }
      }
    }

    .modal-dialog {
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      overflow: hidden;
      max-width: 50%;
      @media (max-width: 767px) {
        max-width: 100%;
      }

      .modal-content {
        padding: 80px 160px;
        @media (max-width: 767px) {
          padding: 40px 80px;
        }

        .modal-body {
          .logout {
            h3 {
              color: #000;
              text-align: center;
            }

            .log-btns {
              margin-top: 60px;
              display: flex;
              width: 100%;
              justify-content: space-around;
            }
          }
        }
      }
    }

    .hovercenter {
      position: relative;
      overflow: hidden;

      span {
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #161F13;
        transition: all .5s ease;
        border-radius: 50px;
        z-index: -1;
      }

      &:hover {
        svg {
          g {
            line {
              stroke: white;
            }
          }
        }

        &:after {
          height: 100%;
          width: 100%;
          z-index: -1;
        }
      }
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: ${hover};
    z-index: 999999999;
    //display: none;
    //opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 60px;
      opacity: 1;
    }
  }

  .guest {
    background: unset;
    display: flex !important;

    .pop-close {

      position: absolute;
      top: -65px;
      right: -125px;
      z-index: 15;
      @media (max-width: 767px) {
        top: -120px;
        right: -60px;

      }

      svg {
        cursor: pointer !important;
        transition: 0.2s ease-in-out;

        &:hover {
          transform: scale(0.8);
          transition: 0.2s ease-in-out;
        }
      }
    }

    .modal-dialog {
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      overflow: hidden;
      max-width: 50%;
      @media (max-width: 767px) {
        max-width: 100%;
      }

      .modal-content {
        padding: 80px 160px;
        @media (max-width: 767px) {
          padding: 40px 80px;
        }

        .modal-body {
          .logout {
            h3 {
              color: #000;
              text-align: center;
            }

            .log-btns {
              margin-top: 60px;
              display: flex;
              width: 100%;
              justify-content: space-around;
            }
          }
        }
      }
    }

    .hovercenter {
      position: relative;
      overflow: hidden;

      span {
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #161F13;
        transition: all .5s ease;
        border-radius: 50px;
        z-index: -1;
      }

      &:hover {
        svg {
          g {
            line {
              stroke: white;
            }
          }
        }

        &:after {
          height: 100%;
          width: 100%;
          z-index: -1;
        }
      }
    }
  }

  .guest-entry-modal {
    background-color: #FFFFFF;
    color: black;
    position: fixed;
    bottom: 0;
    width: 70%;
    left: 15%;
    right: 15%;
    margin-top: 50px;
    max-height: 100%;

    .header-right {
      padding-right: 40px !important;
      @media (max-width: 768px) {
        padding-right: 15px !important;
        .dc-btn {
          display: block !important;
          height: 39px;

          a {
            padding: 0 15px;
            font-size: 13px;
            letter-spacing: 1px;
          }
        }
      }
    }

    .header-left {
      margin-left: 40px !important;
      @media (max-width: 768px) {
        margin-left: 15px !important;
        h4 {
          margin-bottom: 0 !important;
          font-size: 20px !important;
          padding-right: 5px;
        }
      }
    }

    .modal-dialog {
      height: 100%;
      overflow: auto !important;
    }

    .modal-body {
      padding-right: 25%;
    }

    h3 {
      font-size: 18px;
      font-weight: 300;
      line-height: 27px;
      color: #92278F;
      margin-bottom: 15px;
    }

    .avatar {
      position: absolute;
      right: 0px;
      width: 30%;
      bottom: 0;
    }

    .top-text {
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;
      color: #1a1818;
      margin-bottom: 30px;
    }

    @media (max-width: 991px) {
      width: 95%;
      left: 0;
      right: 0;
      margin: auto;
    }

    @media (max-width: 768px) {
      .avatar {
        display: none;
      }

      .modal-body {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    @media (max-width: 575px) {
      .col-sm-4 {
        margin-bottom: 15px !important;

        &:nth-of-type(even) {
          padding-left: 7.5px;
        }

        &:nth-of-type(odd) {
          padding-right: 7.5px;
        }
      }
    }
  }
  
  .guest-event-modal {
    background-color: #FFFFFF;
    color: black;
    position: fixed;
    bottom: 0;
    width: 70%;
    left: 15%;
    right: 15%;
    margin-top: 50px;
    max-height: 100%;

    .header-right {
      padding-right: 40px !important;
      @media (max-width: 768px) {
        padding-right: 15px !important;
        .dc-btn {
          display: block !important;
          height: 39px;

          a {
            padding: 0 15px;
            font-size: 13px;
            letter-spacing: 1px;
          }
        }
      }
    }

    .header-left {
      margin-left: 40px !important;
      @media (max-width: 768px) {
        margin-left: 15px !important;
        h4 {
          margin-bottom: 0 !important;
          font-size: 20px !important;
          padding-right: 5px;
        }
      }
    }

    .modal-dialog {
      height: 100%;
      overflow: auto !important;
    }
    

    h3 {
      font-size: 18px;
      font-weight: 300;
      line-height: 27px;
      color: #92278F;
      margin-bottom: 15px;
    }

    .avatar {
      position: absolute;
      right: 0px;
      width: 30%;
      bottom: 0;
    }

    .top-text {
      font-size: 14px;
      line-height: 21px;
      font-weight: 300;
      color: #1a1818;
      margin-bottom: 30px;
    }

    @media (max-width: 991px) {
      width: 95%;
      left: 0;
      right: 0;
      margin: auto;
    }

    @media (max-width: 768px) {
      .avatar {
        display: none;
      }

      .modal-body {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    @media (max-width: 575px) {
      .col-sm-4 {
        margin-bottom: 15px !important;

        &:nth-of-type(even) {
          padding-left: 7.5px;
        }

        &:nth-of-type(odd) {
          padding-right: 7.5px;
        }
      }
    }
  }


  .modal-dialog {
    .modal-content {
      padding: 0;
      background-color: #FFFFFF;

      .modal-header {
        padding: 0;
        border-radius: 0;
        display: block;
        margin-bottom: 30px;

        .bottom-img {
          img {
            width: 100%;
          }
        }

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          background-color: #92278F;
          //position: relative; /* Added for positioning pseudo-elements */
          align-items: center;
          position: sticky;
          top: 0;


          h4 {
            font-size: 24px;
            color: #F9F9F9;

            margin-bottom: 15px;
          }

          .header-left {
            margin-left: 70px;
            margin-top: 30px;

            .dc-btn {
              display: none;
              @media (max-width: 500px) {
                display: block;
              }
            }

            @media (max-width: 500px) {
              margin-left: 20px;
              margin-bottom: 20px;
            }
          }

          /* Zigzag at the bottom */

          &::before,
          &::after {
            content: "";
            position: absolute;
            bottom: -15px; /* Adjust as needed */
            left: 0;
            width: 100%;
            height: 15px;
            background: linear-gradient(45deg, #fff 8px, transparent 0%),
            linear-gradient(-45deg, #fff 8px, transparent 0%);
            background-size: 20px;
            background-color: #92278F;
          }

          &::before {
            //background: linear-gradient(135deg, #fff 12px, transparent 0%),
            //linear-gradient(-135deg, #fff 12px, transparent 0%);
          }


          .header-right {
            padding-right: 50px;
            padding-top: 30px;
            padding-bottom: 15px;

            img {
              cursor: pointer;
              margin-left: 30px;
            }

            @media (max-width: 500px) {
              .dc-btn {
                display: none;
              }
            }


          }
        }
      }
    }
  }


  //video modal
  .video-modal {
    background-color: transparent;
    height: 100vh;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
    }

    .modal-content {
      background-color: transparent;
    }

    iframe {
      height: 60vh;
      width: 60vw;
      margin: auto;
      position: absolute;
      inset: 0;
    }

    .close-modal {
      position: absolute;
      top: 40px;
      right: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 99;
    }

    @media (max-width: 768px) {
      .modal-content {
        padding: 0 20px;

        iframe {
          width: 90vw;
          height: 60vh;
        }

        .close-modal {
          top: 80px;
          right: 20px;
        }
      }
    }
    @media (max-width: 550px) {
      .modal-content iframe {
        width: 90vw;
        height: 40vh;
      }
    }
  }

  //experience popup
  .experience-popup {
    background-color: transparent;
    height: 100vh;

    .close-modal {
      position: absolute;
      inset: 30px 30px auto auto;
      z-index: 2;
      cursor: pointer;
    }

    .modal-dialog {
      //height: calc(100vh - 50px);
      background-color: transparent;
      //top: 50px;
      overflow: scroll;
    }

    .modal-content {
      background-color: transparent;
      width: 75%;
      margin: auto;
      margin-top: 50px;
    }

    &__top {
      background-color: #fff;
      width: 100%;
      position: relative;

      &__inner {
        padding: 40px 50px;
      }

      img {
        width: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        left: 0;
      }

      h4 {
        font-size: 24px;
        font-weight: 400;
        width: 100%;
        color: #1a1818;
        margin-bottom: 40px;
      }

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        li {
          width: 33%;
          margin-bottom: 20px;

          h6 {
            color: #1a1818;
            text-transform: uppercase;
            margin-bottom: 5px;
            font-size: 14px;
            line-height: 25px;
            font-weight: 300;
          }

          a {
            text-decoration: underline;
            color: #1a1818;
          }

          p {
            font-weight: 500;
            color: #1a1818;

            a {
              color: #1a1818;
              padding-left: 10px;
              text-decoration: none;
            }
          }
        }
      }
    }

    &__bottom {
      background-color: #92278F;
      padding: 40px 50px;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &__col {
        position: relative;
        width: 50%;

        p {
          font-size: 14px;
          line-height: 21px;
          width: 80%;
        }

        .dc-btn {
          position: absolute;
          bottom: 0;
        }

        img {
          position: relative;
          z-index: 2;
        }
      }

      .shadows {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    //responsive modal
    @media (max-width: 1100px) {
      .modal-content {
        width: 100%;
        padding: 0 20px;
      }
    }

    @media (max-width: 767px) {
      .experience-popup__top {
        &__inner {
          padding: 40px 20px;
        }

        ul li {
          width: 50%;
          //border-bottom: 1px solid #DDD;
        }
      }

      .experience-popup__bottom {
        padding: 40px 20px;

        &__col {
          width: 100%;

          .dc-btn {
            position: relative;
            margin-top: 20px;
          }

          p {
            width: 100%;
          }

          &.text-right {
            text-align: left !important;
            margin-top: 50px;
          }
        }
      }

      .modal-dialog {
        overflow: scroll;
      }

      .shadows {
        width: 30%;
      }
    }

    @media (max-width: 500px) {
      .experience-popup__top {
        .close-modal {
          right: 10px;
        }

        ul li {
          width: 100%;
          //border-bottom: 1px solid #DDD;
        }
      }

      .shadows {
        width: 20%;
      }
    }

  }


  //interactive modal 
  .map-modal {
    background-color: transparent;
    height: 100vh;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
    }


    .modal-body {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
    }

    .map-modal__content {
      background-color: transparent;
      width: 100%;
      margin: auto;
      //display: block;
      //width: 100%;
      display: block;
      height: fit-content;

      ul {
        li {
          height: 40px;
          width: 40px;
          border: 1px solid #FFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          transition: .5s ease;
          cursor: pointer;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 2;

          &:hover {
            background-color: #FCB940;
            border-color: #FCB940
          }
        }
      }

      .slider_prev {
        left: 20px;
        @media (max-width: 767px) {
          left: 10px;
          height: 30px;
          width: 30px;
          img {
            height: 10px;
          }
        }
      }

      .slider_next {
        right: 20px;
        @media (max-width: 767px) {
          right: 10px;
          height: 30px;
          width: 30px;
          img {
            height: 10px;
          }
        }
      }

      &__img {
        .swiper-slide {
          img {
            width: 100%;
            object-fit: cover;
          }
        }

      }
    }

    .modal-content {
      background-color: transparent;
      width: 75%;
      margin: auto;
      //display: block;
      //width: 100%;
      display: flex;
      align-items: center;

    }

    &__content {
      position: relative;

      .close-modal {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 5;
        cursor: pointer;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        background-color: rgba(26, 24, 24, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s ease;

        &:hover {
          background-color: rgb(26, 24, 24);
        }
      }

      &__img {

      }
    }

    @media (max-width: 768px) {
      .modal-content {
        width: 100%;
        padding: 10px;
      }
    }

    .swiper-slide {
      display: flex !important;
      align-items: center;
    }


  }


  //cabana-info-modal
  .cabana-info-modal {
    background-color: transparent;

    .modal-dialog {
      //height: 100vh;
      background-color: transparent;
    }

    .modal-content {
      background-color: transparent;
      //height: 70vh !important;
      width: 65%;
      padding: 0;
      margin: auto;
      margin-top: 15vh;
      margin-bottom: 15vh;
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      cursor: pointer;
    }

    &__inner {
      display: flex;
      height: 100%;
      position: relative;

      &__img {
        width: 65%;
        min-height: 60vh;
        position: relative;


        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &__content {
        width: 35%;
        background-color: ${hover};
        padding: 60px 40px 120px 40px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;

        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          color: #ffffff;
          margin-bottom: 30px;
        }

        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 21px;
          color: #ffffff;
        }

        img {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 2;
          margin: auto;
        }
      }

    }

    @media (max-width: 1224px) {
      .modal-content {
        width: 90%;
      }
    }
    @media (max-width: 991px) {
      .modal-content {
        width: 98%;
      }
    }
    @media (max-width: 767px) {
      .cabana-info-modal__inner {
        flex-wrap: wrap;

        .cabana-info-modal__inner__img {
          min-width: 100%;
        }
      }

      .cabana-info-modal__inner__content {
        min-width: 100%;
        padding: 60px 20px 80px 20px;
      }
    }

  }

  .cabanaMap {
    .modal-body {
      width: 100%;
      padding: 0;
      overflow: hidden;
    }
  }

  .clickable {
    cursor: pointer;
  }

`;



