import { CSSPlugin } from "gsap/CSSPlugin";
import { gsap, TimelineLite } from "gsap/gsap-core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import styled from "styled-components";
import Button from "./Button";
import VisibilitySensor from "react-visibility-sensor";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

gsap.registerPlugin(CSSPlugin);

const DesktopMenu = ({
  setLoginPage,
  setChangePassword,
  searchPlace,
  searchCategory,
  setGlobalSidebar,
  a2z,
  data,
}) => {
  let dateClickRef = useRef(null);
  let calenderRef = useRef(null);
  let calenderLeftRef = useRef(null);
  let calenderItemRef = useRef(null);
  let hasSuggestionRefAll = useRef([]);
  const [A2z, setA2z] = useState("");
  const [dateValidate, setDateValidate] = useState(false);
  const [ticketValidate, setTicketValidate] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState({});
  const history = useHistory();
  let ticketDetails = useSelector(
    (state) => state?.ticket?.ticketInfo?.Envelope?.Body?.ItemList?.Item
  );

  let initDate = new Date();
  // let history = useHistory();

  const getDataFromProps = searchPlace && searchPlace;

  const [tl] = useState(new TimelineLite());
  const [tll] = useState(new TimelineLite());
  const [date, setDate] = useState("");
  const [placePlaceholder, setPlacePlaceholder] = useState("");
  const [placeId, setPlaceId] = useState(""); // place id change to slug

  useEffect(() => {
    // submenus
    hasSuggestionRefAll.current.forEach((el) => {
      el.addEventListener("click", () => {
        const dropdown = el.lastElementChild;
        const isDropdownOpen = dropdown.style.display === "block";

        if (isDropdownOpen) {
          gsap.to(dropdown, {
            display: "block",
            duration: 0.5,
            opacity: 1,
            height: "215px",
          });
        } else {
          gsap.to(dropdown, {
            display: "block",
            duration: 0.5,
            opacity: 1,
            height: "215px",
          });
        }
      });

      let getInnerLi = el.querySelectorAll(".submit-btn");
      getInnerLi.forEach((c) => {
        c.addEventListener("click", () => {
          setTimeout(() => {
            gsap.to(el.lastElementChild, {
              display: "none",
              duration: 0.5,
              opacity: 0,
              height: "0",
            });
          });
        });
      });
    });
  }, []);

  // const handleCalender = (Date, value) => {
  //   if (Date && Date.getDate() === 1 && Date.getMonth() === 10) {
  //     return; // Do not allow selection
  //   }
  //   setDate(Date);
  //   setDateValidate(false);
  // };

  //calendar
 
  const handleCalender = (newDate) => {
    // Check if newDate is a Date object
    if (newDate instanceof Date && newDate.getDate() === 1 && newDate.getMonth() === 10) {
      return; // Do not allow selection of November 1st
    }
    setDate(newDate); // Set the selected date
    setDateValidate(false); // Reset validation
  };

  useEffect(() => {
    // If the current date is November 1st, change it to the next valid date
    if (date instanceof Date) {
      if (date.getDate() === 1 && date.getMonth() === 10) {
        // Set to November 2nd if selected date is November 1st
        setDate(new Date(date.getFullYear(), date.getMonth(), 2));
      }
    }
  }, [date]); // Run this effect every time `date` changes
 
  const goLeft = useRef();
  const goRight = useRef();

  let handleGlobalSidebar = (e) => {
    setGlobalSidebar(e);
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.querySelector(".product-specification-ul li")) {
        if (document.querySelector(".product-specification-ul .active")) {
          document
            .querySelector(".product-specification-ul .active")
            .classList.remove("active");
        }
        document
          .querySelector(".product-specification-ul li")
          .classList.add("active");
      }
    }, 200);

    if (document.querySelector(".product-specification-ul li")) {
      let AlltheLi = document.querySelectorAll(".product-specification-ul li");
      AlltheLi.forEach((i) => {
        i.addEventListener("click", () => {
          document
            .querySelector(".product-specification-ul li")
            .classList.remove("active");
        });
      });
    }
  }, [data]);



  
  // useEffect(() => {
  //   // If the current date is November 1st, change it to the next valid date
  //   if (date.getDate() === 1 && date.getMonth() === 10) {
  //     setDate(new Date(date.getFullYear(), date.getMonth(), 2)); // Move to November 2nd
  //   }
  // }, [date]); // Run this effect every time `date` changes

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleTicket = () => {
    if (!isEmpty(selectedTickets) && date) {
      history.push(
        `/ticket/${moment(date).format("DD-MM-yyyy")}?status=ticket`
      );
    } else {
      if (isEmpty(selectedTickets)) {
        setTicketValidate(true);
      } else {
        setTicketValidate(false);
      }

      if (!date) {
        setDateValidate(true);
      } else {
        setDateValidate(false);
      }
    }
  };

  // people dropdown handle
  useEffect(() => {
    const getPeopleClick = document.querySelector(".place");
    const getPeopleDropdown = document.querySelector(".suggestion-dropdown");
    const tl2 = new TimelineLite();
    // on click to box
    getPeopleClick.addEventListener("click", function () {
      if (!getPeopleClick.classList.contains("dropdown-open")) {
        tl2
          .to(getPeopleDropdown, {
            display: "block",
            duration: 0,
          })
          .to(getPeopleDropdown, {
            opacity: 1,
            height: "265px",
          });
        getPeopleClick.classList.add("dropdown-open");
      } else {
        tl2
          .to(getPeopleDropdown, {
            opacity: 0,
            height: "0",
          })
          .to(getPeopleDropdown, {
            display: "none",
            duration: 0,
          });
        getPeopleClick.classList.remove("dropdown-open");
      }
    });

    // on outside click off dropdown
    window.addEventListener("click", function (e) {
      if (getPeopleClick.classList.contains("dropdown-open")) {
        const tl2 = new TimelineLite();
        if (
          !e.target.matches(
            ".search-menu__inner__item__inner , .search-menu__inner__item__inner div, .search-menu__inner__item__inner img, .search-menu__inner__item__inner label, .search-menu__inner__item__inner input, .place,.suggestion-dropdown , .suggestion-dropdown div, .suggestion-dropdown ul , .suggestion-dropdown li,.increase-section h6, .increase-section input, .increase-section span "
          )
        ) {
          tl2
            .to(getPeopleDropdown, {
              opacity: 0,
              height: "0",
            })
            .to(getPeopleDropdown, {
              display: "none",
              duration: 0,
            });
          getPeopleClick.classList.remove("dropdown-open");
        }
      }
    });
  }, []);

  // Date picker handle
  useEffect(() => {
    const getDateClick = document.querySelector(".date-click-item");
    const getDateDropdown = document.querySelector(".date-pick__wrap");
    const getPeopleDropdown = document.getElementById("peopleSelector");
    const tl2 = new TimelineLite();

    // On click to box
    getDateClick.addEventListener("click", function () {
      if (!getDateClick.classList.contains("calender-open")) {
        tl2
          .to(getPeopleDropdown, {
            zIndex: window.innerWidth <= 990 ? -1 : 1,
            duration: 0,
          })
          .to(getDateDropdown, {
            display: "flex",
            duration: 0,
          })
          .to(getDateDropdown, {
            opacity: 1,
            height: "265px",
            width: "448px",
          })
          .to(".left-content ,.date-pick__wrap, .calender-all-item", {
            opacity: 1,
          });
        getDateClick.classList.add("calender-open");
      } else {
        tl2
          .to(getPeopleDropdown, {
            zIndex: 1,
            duration: 0,
          })
          .to(".left-content, .date-pick__wrap, .calender-all-item", {
            opacity: 0,
          })
          .to(getDateDropdown, {
            opacity: 0,
            height: "0",
            width: 0,
          })
          .to(getDateDropdown, {
            display: "none",
            duration: 0,
          });
        getDateClick.classList.remove("calender-open");
      }
    });

    goLeft.current.addEventListener("click", () => {
      document
        .querySelector(".react-calendar__navigation__prev-button")
        .click();
      let getData = new Date(
        document.querySelector(
          ".react-calendar__navigation__label__labelText--from"
        ).innerHTML
      );
      setDate(getData);
    });

    goRight.current.addEventListener("click", () => {
      document
        .querySelector(".react-calendar__navigation__next-button")
        .click();
      let getData2 = new Date(
        document.querySelector(
          ".react-calendar__navigation__label__labelText--from"
        ).innerHTML
      );
      setDate(getData2);
    });

    // On outside click off dropdown
    window.addEventListener("click", function (e) {
      if (getDateClick.classList.contains("calender-open")) {
        const tl2 = new TimelineLite();
        if (
          !e.target.matches(
            ".date-click-item,.date-click-item img, .date-click-item div, .date-click-item label, .date-click-item input,.date-pick__wrap , .date-pick__wrap div, .date-pick__wrap img, .date-pick__wrap ul , .date-pick__wrap li , .date-pick__wrap p ,.date-pick__wrap svg, .date-pick__wrap g, .date-pick__wrap defs, .date-pick__wrap path, .date-pick__wrap circle,.date-pick__wrap  linearGradient , .date-pick__wrap stop, .date-pick__wrap span, .calender-all-item, .calender-all-item div, .calender-all-item button,  .calender-all-item span,.calender-all-item abbr"
          )
        ) {
          tl2
            .to(getPeopleDropdown, {
              zIndex: 1,
            })
            .to(".left-content, .date-pick__wrap, .calender-all-item", {
              opacity: 0,
            })
            .to(getDateDropdown, {
              opacity: 0,
              height: "0",
              width: 0,
            })
            .to(getDateDropdown, {
              display: "none",
              duration: 0,
            });
          getDateClick.classList.remove("calender-open");
        }
      }
    });
  }, []);

  const isPublicHoliday = () => {
    const publicHolidays = [
      "2024-01-01",
      "2024-02-21",
      "2024-02-26",
      "2024-03-17",
      "2024-03-26",
      "2024-03-29",
      "2024-04-01",
      "2024-04-05",
      "2024-04-07",
      "2024-04-10",
      "2024-04-11",
      "2024-04-12",
      "2024-04-14",
      "2024-05-01",
      "2024-05-09",
      "2024-05-22",
      "2024-06-16",
      "2024-06-17",
      "2024-06-18",
      "2024-06-19",
      "2024-06-20",
      "2024-07-17",
      "2024-08-15",
      "2024-08-26",
      "2024-09-16",
      "2024-10-13",
      "2024-12-16",
      "2024-12-25",
      "2024-12-26",
    ];

    const selectedDate = moment(date).format("yyyy-MM-DD");
    //  console.log(publicHolidays.includes(selectedDate));
    return publicHolidays.includes(selectedDate);
  };

  const checkIfValidDate = (ticketId) => {
    const validDays = {
      10101304: [0, 1, 2, 3, 4],
      10101302: [0, 1, 2, 3, 4, 5, 6],
    };

    // Check if the date is a public holiday for adult weekend ticket type
    if (ticketId === "10101304" && isPublicHoliday()) {
      return false;
    }

    // Check if the date is a public holiday for senior citizen ticket type
    if (ticketId === "10101302" && isPublicHoliday()) {
      return true;
    }

    //extract the day of week from the date
    let day = date.getDay();
    if (validDays[ticketId]) {
      return validDays[ticketId]?.includes(day);
    }

    return true;
  };

  const handleIncreaseQuantity = (ticketId) => {
    if (date) {
      if (checkIfValidDate(ticketId)) {
        setSelectedTickets((prevSelected) => ({
          ...prevSelected,
          [ticketId]: (prevSelected[ticketId] || 0) + 1,
        }));

        // Update localStorage
        const updatedSelectedTickets = {
          ...selectedTickets,
          [ticketId]: (selectedTickets[ticketId] || 0) + 1,
        };
        sessionStorage.setItem(
          "selectedTickets",
          JSON.stringify(updatedSelectedTickets)
        );
        setTicketValidate(false);
      } else {
        toast.error("Date does not match ticket type!", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error("Missing date field! Please select a visit date first.", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleDecreaseQuantity = (ticketId) => {
    setSelectedTickets((prevSelected) => {
      const updatedQuantity = Math.max((prevSelected[ticketId] || 0) - 1, 0);
      const updatedSelected = { ...prevSelected };
      if (updatedQuantity === 0) {
        delete updatedSelected[ticketId];
      } else {
        updatedSelected[ticketId] = updatedQuantity;
      }
      // Update localStorage
      sessionStorage.setItem(
        "selectedTickets",
        JSON.stringify(updatedSelected)
      );
      return updatedSelected;
    });
  };

  const getSelectedItemsText = () => {
    return Object.keys(selectedTickets)
      .map((ticketId, index) => {
        const selectedTicket = ticketDetails.find(
          (ticket) => ticket.PLU._text === ticketId
        );
        const quantity = selectedTickets[ticketId];
        return `${quantity} ${selectedTicket.Name._text}`;
      })
      .join(", ");
  };

  return (
    <StyledSearchMenu
      className="search-menu"
      dateValidate={dateValidate}
      ticketValidate={ticketValidate}
    >
      <div className={"product-specification-head"}>
        <ListGroup as={"ul"} horizontal className={"product-specification-ul"}>
          <ListGroup.Item as="li">
            <span>
              <p>Buy your ticket now</p>
            </span>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <VisibilitySensor offset={{ top: -150 }}>
        {({ isVisible }) => (
          <Form>
            <div className="search-menu__inner">
              <div className="search-menu__inner__item date-pick">
                <div className="date-click-item" ref={dateClickRef}>
                  <img src={"/images/static/date.svg"} alt="date-icon" />
                  <Form.Group className="">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="text"
                      value={date !== "" ? moment(date).format("MMM D") : ""}
                      placeholder={"Pick a date"}
                      readOnly
                    />
                  </Form.Group>
                </div>

                <div className="date-pick__wrap" ref={calenderRef}>
                  <img
                    src={"/images/static/4.svg"}
                    className="calender-shadow"
                    alt=""
                  />
                  <div className="left-content" ref={calenderLeftRef}>
                    <ul>
                      <li ref={goLeft} className={"go-left"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                        >
                          <defs>
                            <linearGradient
                              id="grad"
                              x1="0%"
                              y1="0%"
                              x2="100%"
                              y2="0%"
                            >
                              <stop offset="0%" stop-color="#498dcb" />
                              <stop offset="100%" stop-color="#5ad1d1" />
                            </linearGradient>
                          </defs>
                          <g
                            id="Group_5374"
                            data-name="Group 5374"
                            transform="translate(-783 -225)"
                          >
                            <circle
                              id="Ellipse_117"
                              data-name="Ellipse 117"
                              cx="20"
                              cy="20"
                              r="20"
                              transform="translate(783 225)"
                              fill="url(#grad)"
                            />
                            <path
                              id="Path_2175"
                              data-name="Path 2175"
                              d="M373.172,1487.354l8.182,7.954-8.182,7.954"
                              transform="translate(426.328 -1249.854)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                      </li>
                      <li ref={goRight} className={"go-right"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                        >
                          <defs>
                            <linearGradient
                              id="grad"
                              x1="0%"
                              y1="0%"
                              x2="100%"
                              y2="0%"
                            >
                              <stop offset="0%" stop-color="#498dcb" />
                              <stop offset="100%" stop-color="#5ad1d1" />
                            </linearGradient>
                          </defs>
                          <g
                            id="Group_5374"
                            data-name="Group 5374"
                            transform="translate(-783 -225)"
                          >
                            <circle
                              id="Ellipse_117"
                              data-name="Ellipse 117"
                              cx="20"
                              cy="20"
                              r="20"
                              transform="translate(783 225)"
                              fill="url(#grad)"
                            />
                            <path
                              id="Path_2175"
                              data-name="Path 2175"
                              d="M373.172,1487.354l8.182,7.954-8.182,7.954"
                              transform="translate(426.328 -1249.854)"
                              fill="none"
                              stroke="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                      </li>
                    </ul>
                    <p>
                      {date !== ""
                        ? moment(date).format("MMMM")
                        : moment(initDate).format("MMMM")}
                      <span>
                        {date !== ""
                          ? moment(date).format("YYYY")
                          : moment(initDate).format("YYYY")}{" "}
                      </span>
                    </p>
                  </div>
                  <div className="calender-all-item" ref={calenderItemRef}>
                    <Calendar
                      onChange={handleCalender}
                      value={date}
                      // formatMonthYear={(locale, date) =>(date, ['Jan 20121', 'Feb 2021'])}
                      formatShortWeekday={(locale, value) =>
                        ["S", "M", "T", "E", "T", "F", "S"][value.getDay()]
                      }
                      prev2Label={""}
                      next2Label={""}
                      tileClassName="tile--active"
                      minDate={new Date()}
                      maxDate={
                        new Date(new Date().setMonth(new Date().getMonth() + 1))
                      }
                      tileDisabled={({ date }) => {
                        // Disable November 1st
                        return date.getDate() === 1 && date.getMonth() === 10;
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="search-menu__inner__item" id="peopleSelector">
                <div className="search-menu__inner__item__inner ">
                  {/*ref={hadDropdownRef}*/}
                  <div title={getSelectedItemsText()} className="place">
                    <img src={"images/static/people.svg"} alt="people-icon" />
                    <Form.Group id="hide-tabs">
                      <Form.Label>People</Form.Label>
                      <Form.Control
                        type="text"
                        className="truncate"
                        value={getSelectedItemsText()}
                        placeholder={"Number Of People"}
                        readOnly
                      />
                    </Form.Group>
                  </div>

                  <div className="suggestion-dropdown">
                    <ul>
                      <SimpleBar style={{ maxHeight: "100%", height: "100%" }}>
                        <li>People</li>
                        {ticketDetails
                          ? ticketDetails.map((e) => {
                              const nameText = e?.Name?._text.toLowerCase();
                              const ticketId = e?.PLU?._text;
                              if (
                                (nameText.includes("adult") ||
                                  nameText.includes("child")) &&
                                ticketId !== "101002011" &&
                                ticketId !== "10101306" &&
                                ticketId !== "10101307" &&
                                ticketId !== "1010020119" &&
                                ticketId !== "10101308"
                              ) {
                                return (
                                  <Form.Group
                                    className="increase-section"
                                    key={e.PLU._text}
                                  >
                                    <div className="increase-section__text">
                                      <h6 className="main-text">
                                        {e?.Name?._text}
                                      </h6>
                                      <h6 className="sub-text">
                                        {e?.Description?._text}
                                      </h6>
                                    </div>
                                    <div className="increase-section__button">
                                      <input
                                        type="button"
                                        value="-"
                                        onClick={() =>
                                          handleDecreaseQuantity(e?.PLU?._text)
                                        }
                                        disabled={
                                          selectedTickets[e?.PLU?._text] ===
                                            0 || !selectedTickets[e?.PLU?._text]
                                        }
                                      />
                                      <span>
                                        {selectedTickets[e?.PLU?._text] || 0}
                                      </span>
                                      <input
                                        type="button"
                                        value="+"
                                        onClick={() =>
                                          handleIncreaseQuantity(e?.PLU?._text)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                );
                              } else {
                                return null; // Skip rendering for other cases
                              }
                            })
                          : null}

                        <div className="submit-btn">
                          <Button
                            text={"Submit"}
                            hoverborder={""}
                            hoverfill={"none"}
                            color={"#fff"}
                            borderColor={"#fff"}
                            background={"#5AD1D1"}
                            // onClick={handleSubmit}
                          />
                        </div>
                      </SimpleBar>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="submit-btn">
                <div className={"button"}>
                  <Button
                    text={"Buy a Ticket"}
                    borderColor={"#92278F"}
                    onClick={handleTicket}
                    color={"#92278F"}
                    hoverfill={"#92278F"}
                    hoverborder={"#92278F"}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </VisibilitySensor>
    </StyledSearchMenu>
  );
};

const StyledSearchMenu = styled.section`
  width: 845px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // transform: translateY(37%);
  margin: auto;
  z-index: 9999;

  .search-menu__inner {
    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: 0 10px 10px 10px;
    padding: 0 30px;

    height: 134px;
    align-items: center;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);

    &__item {
      display: inline-flex;
      //align-items: center;
      //border-right: 1px solid #D3D2D1;
      //padding: 0 20px;
      position: relative;
      //flex-wrap:wrap;
      &:after {
        content: "";
        position: absolute;
        left: 5px;
        right: 5px;
        margin: auto;
        top: -10px;
        bottom: -10px;
        background-color: #f4f4f4;
        border-radius: 33px;
        height: 0;
        width: 0;
        transition: all 0.5s ease;
      }

      &__inner {
        width: 270px;
        z-index: 2;
        align-items: center;
        display: none;
        opacity: 0;
        transform: translateY(10px);
        //min-width: 150px;
        border: ${(props) =>
          props.ticketValidate ? "1px solid #e74c3c" : "1px solid #D3D2D1"};
        border-radius: 10px;
        height: 54px;
        //padding: 10px 20px;
        cursor: pointer;

        .place {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          .truncate {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        img {
        }

        &:nth-of-type(1) {
          display: flex;
          opacity: 1;
          transform: translateY(0px);
        }
      }

      &:nth-of-type(1) {
        padding-left: 0;

        &:after {
          left: -20px;
          //width: calc(100% + 10px);
        }

        &:hover {
          &:after {
            width: calc(100% + 10px) !important;
          }
        }
      }

      &:nth-last-child(1) {
        padding-right: 0;
        border: none;
      }

      &:nth-last-child(2) {
        padding-right: 0;
        border: none;
      }

      img {
        //height: 19.5px;
        margin-right: 10px;
        position: relative;
        z-index: 2;
      }

      .form-control {
        border: none;
        padding-left: 0;
        padding-top: 0;
        height: 22px;
        position: relative;
        z-index: 2;
        background-color: transparent;
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        color: #434343;
        // text-transform: capitalize;
        cursor: pointer;

        &::placeholder {
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          color: #d3d2d1;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
        color: #1a1818;
        margin: 0;
        z-index: 2;
        cursor: pointer;
        position: relative;
      }

      //&:hover {
      //  &:after {
      //    height: calc(100% + 20px);
      //    width: calc(100% - 10px);
      //  }
      //
      //}

      &.submit-btn {
        width: 75px;
        margin-left: 13px;
        position: relative;
        cursor: pointer;

        img {
          margin: 0;
        }

        &:after {
          display: none;
        }

        &:hover {
          .submit-btn__inner:after {
            height: 65px !important;
            width: 65px !important;
          }
        }
      }
    }
  }

  .left-content {
    opacity: 0;
    width: 120px;

    .go-left {
      transform: rotate(180deg);
    }

    ul {
      display: flex;

      li {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        //background-color: #D3D2D1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.4s ease;

        &:nth-of-type(1) {
          margin-right: 10px;
        }

        img {
          margin: 0;
        }

        &:hover {
          background-color: #989898;
        }
      }
    }

    p {
      font-size: 25px;
      font-weight: bold;
      line-height: 35px;
      color: #434343;
      margin: 25px 0 0 0;
      text-transform: capitalize;
      width: 50%;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 28px;
      }

      span {
        color: #989898;
        display: flex;
      }
    }
  }

  .date-pick {
    z-index: 1;
    //cursor: pointer;
    label {
      cursor: pointer;
    }

    .error-field {
      border: 1px solid #e74c3c !important;
    }

    .date-click-item {
      display: flex;
      cursor: pointer;
      border: ${(props) =>
        props.dateValidate ? "1px solid #e74c3c" : "1px solid #D3D2D1"};
      border-radius: 10px;
      height: 54px;
      width: 270px;
      align-items: center;
      padding: 10px 20px;
      // margin: 0 30px;

      img {
        //width: 35px;
        height: 25px;
      }
    }

    .form-control {
      cursor: pointer;
    }

    .react-calendar__tile--active {
      border-radius: 30px;
      height: 30px;
      width: 30px;
      background: rgb(73, 141, 203);
      background: linear-gradient(
        360deg,
        rgba(73, 141, 203, 1) 0%,
        rgba(90, 209, 209, 1) 100%
      );
    }

    .react-calendar__tile:disabled {
      background-color: unset;
    }

    .react-calendar__tile:hover {
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }

    .react-calendar__tile--now {
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background-color: unset;
    }

    .react-calendar__month-view__weekdays {
      color: #989898;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      border-bottom: none !important;
    }

    .react-calendar__month-view__weekdays__weekday {
      margin-top: 15px;
    }

    .react-calendar__viewContainer {
      margin-top: -20px;
    }

    .react-calendar__month-view__days {
      color: #989898;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      opacity: 0;
    }

    .month-view__days__day {
      font-family: "Bonn BF";
    }

    .calender-all-item {
      opacity: 0;

      .react-calendar {
        font-family: Bonn BF;
        border: none;
        width: 205px;

        &__navigation {
          //visibility: hidden;
          display: none;
        }

        &__month-view {
          &__weekdays {
            border-bottom: 1px solid #d3d2d1;
          }
        }

        &__title--active {
          border-radius: 25px;
        }
      }
    }

    &__wrap {
      position: absolute;
      top: 65px;
      border-radius: 20px;
      width: 0px;
      display: flex;
      justify-content: space-around;
      z-index: 5;
      overflow: hidden;
      background-color: white;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 28px 0;
      display: none;
      height: 0px;
      left: 25px;

      img {
        transform: translate(-30%, 50%);
        z-index: 2;
      }

      //background: white;
      .left-content {
        z-index: 2;
      }

      .calender-shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  .suggestion-dropdown {
    position: absolute;
    top: 65px;
    background-color: #fff;
    left: -3px;
    right: -12px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 5px 0 10px 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: 99;
    //height: 265px !important;

    .simplebar-scrollable-y {
      //max-height: 100% !important;
      //height: 100% !important;
    }

    .increase-section {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px;
      border-bottom: 1px solid #f9f9f9;
      //height: 54px;

      input {
        border-radius: 50%;
        border: none;
        height: 25px;
        width: 25px;
      }

      &__text {
        .main-text {
          color: #1a1818;
          font-size: 14px;
          line-height: 19px;
          font-weight: 300;
        }

        .sub-text {
          color: #989898;
          font-size: 12px;
          line-height: 16px;
          font-weight: 300;
        }
      }

      &__button {
        width: 100px;
        justify-content: space-between;
        padding: 0 2px;
        background-color: white;
        border: 1px solid #e8e8e8;
        border-radius: 50px;
        height: 30px;
        display: flex;
        align-items: center;

        span {
          color: #1a1818;
          font-weight: 300;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    .submit-btn {
      height: 30px;
      padding: 15px 20px;

      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        display: flex;
      }

      .dc-btn {
        height: 30px !important;
        //margin: 10px 20px;
      }
    }

    &.hasCategory {
      li:nth-of-type(1) {
        margin: 5px 0 10px 0;
        padding: 10px 20px 12px 20px;
        color: #434343;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        border-bottom: 1px solid rgba(211, 210, 209, 0.4);
        border-top: 1px solid rgba(211, 210, 209, 0.4);
      }
    }

    .simplebar-scrollable-y {
      height: 100%;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #434343;
      text-transform: capitalize;
      border-bottom: 1px solid rgba(211, 210, 209, 0.4);
      border-top: 1px solid rgba(211, 210, 209, 0.4);
      margin: 5px 0 10px 0;
      padding: 13px 20px 13px 20px;
      line-height: 16px;

      &:nth-of-type(1) {
        border-top: 0;
        margin-top: 0;
      }
    }

    ul {
      height: 100%;

      li {
        font-size: 14px;
        font-weight: 300;
        color: #434343;
        text-transform: capitalize;
        //cursor: pointer;
        line-height: 17px;
        border-bottom: 1px solid #f9f9f9;
        padding: 15px 20px;

        &:hover {
          background-color: rgba(221, 221, 221, 0.24);
        }
      }
    }
  }

  .simplebar-track.simplebar-vertical {
    width: 5px;
  }

  .product-specification-head {
    .product-specification-ul {
      .list-group-item {
        padding: 0;
        border: 0;
        background-color: #ffffff;
        border-radius: 10px 10px 0 0;
        width: 300px;

        //&.active {
        //  background-color: #F9F9F9;
        //
        //  a, span {
        //    color: #1A1818;
        //    font-size: 14px;
        //    line-height: 16px;
        //    font-weight: 500;
        //  }
        //}

        span {
          font-size: 16px;
          line-height: 22px;
          //font-weight: bold;
          padding: 23px 35px 20px 40px;
          display: flex;
          color: #221f1f;
          height: 100%;

          p {
            font-weight: 500;
          }
        }

        a {
          font-size: 16px;
          line-height: 22px;
          font-weight: bold;
          padding: 23px 35px 20px 40px;
          display: flex;
          color: #221f1f;
          height: 100%;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            height: 0;
            //background-color: #221F1F;
            width: 100%;
            transition: height 0.2s ease-in;
          }

          &:hover {
            span {
              //z-index: 1;
              //color: #ffffff
            }

            //&:before {
            //  height: 100%;
            //  z-index: 1;
            //}
          }
        }
      }
    }
  }

  .button {
    .dc-btn {
      width: 190px;
    }
  }

  @media (max-width: 1325px) {
    width: 75%;
  }

  @media (max-width: 1055px) {
    width: 85%;
    .date-click-item {
      margin: 0 20px;
    }
  }
  @media (max-width: 991px) {
    transform: translateY(37%);

    .search-menu__inner {
      flex-direction: column;
      height: 306px;
      justify-content: normal;
      padding: 30px 0 0 0;
      border-radius: 0 0 10px 10px;

      &__item {
        padding: 0;
        margin: 0 0 30px 0;
      }
    }

    .date-pick {
      &__wrap {
        left: -5px;
        height: 265px !important;
        width: 340px !important;
        padding: 25px;
      }
    }

    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          width: 100%;
        }
      }
    }

    .submit-btn {
      width: 270px;

      .dc-btn {
        width: 270px;

        a {
          background: rgb(146, 39, 143);
          background: linear-gradient(
            180deg,
            rgba(146, 39, 143, 1) 0%,
            rgba(118, 63, 151, 1) 100%
          );

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;

export default DesktopMenu;
