import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import Img from "./Img";
import BoxWithSvg from "./BoxWithSvg";
import SimpleBar from "simplebar-react";
import Button from "./Button";
import {BsX} from "react-icons/bs";
import {click} from "@testing-library/user-event/dist/click";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({
                         title,
                         text,
                         svg,
                         buttonText,
                         image,
                         bgColor,
                         zigzag,
                         handleSlug
                     }) => {


    const detailRef = useRef(null);
    const closeRef = useRef(null);
    const [postHeight, setHeight] = useState(0);
    const [loading, setLoading] = useState(false);
    const [slug, setSlug] = useState({
        postSlug: "",
        postTitle: "",
    });
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setModalData(e)
    }


    useEffect(() => {
        const getHeight = detailRef.current.offsetHeight;
        setHeight(getHeight);

        const handleCloseClick = () => {
            detailRef.current.classList.remove("show-detail");
        };

        closeRef.current?.addEventListener("click", handleCloseClick);

        return () => {
            closeRef.current?.removeEventListener("click", handleCloseClick);
        };
    }, []);

    const handleButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        detailRef.current.classList.add("show-detail");
        closeRef.current.addEventListener('click', () => {
            detailRef.current.classList.remove('show-detail')
        })

        handleSlug(slug.postSlug, slug.postTitle);
    };

    const [isMobile, setIsMobile] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsMobile(windowWidth <= 767);
    }, [windowWidth]);



    return (

        <StyledComponent className={'rides-zig pb-100'} bgColor={bgColor}>
            <Container>
                <Row>
                    <Col lg={8}>
                        <div className="rides-zig__image">
                            <Img src={image ? image : '/images/dynamic/ride/1.jpg'}/>
                        </div>

                        <div className='concern-detail' ref={detailRef}>
                            <div className="close-detail" ref={closeRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                     viewBox="0 0 42 42">
                                    <g id="Group_19304" data-name="Group 19304"
                                       transform="translate(-1041 -1459)">
                                        <circle id="Ellipse_270" data-name="Ellipse 269" cx="21" cy="21" r="21"
                                                transform="translate(1041 1459)" fill="#1a1818"/>
                                        <g id="Group_19303" data-name="Group 19303"
                                           transform="translate(1052.454 1437.22) rotate(45)">
                                            <g id="Group_19302" data-name="Group 19302"
                                               transform="translate(-978.5 -1456)">
                                                <g id="Group_19301" data-name="Group 19301">
                                                    <line id="Line_3978" data-name="Line 3978" y2="10"
                                                          transform="translate(1015.5 1474.5)" fill="none"
                                                          stroke="#fff" stroke-linecap="round"
                                                          stroke-width="2"/>
                                                    <line id="Line_3979" data-name="Line 3979" y2="10"
                                                          transform="translate(1020.5 1479.5) rotate(90)"
                                                          fill="none" stroke="#fff" stroke-linecap="round"
                                                          stroke-width="2"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            {/*<SimpleBar style={{maxHeight: '100vh'}}>*/}
                            <div
                                className={`col-wrap ${zigzag?.length === 2 ? 'item-two' : ''} ${zigzag?.length === 3 ? 'item-three' : ''}`}>
                                <Row>
                                    {zigzag && zigzag?.map(item => (
                                        <div key={item?._multipop_file_id} className="col-sm-6 single-box"
                                             onClick={() => handleShow(item)}>
                                            <div className="single-box__img">
                                                <Img src={item?._multipop_file}/>
                                            </div>
                                            <div className="single-box__content">
                                                <p>{reactHtmlParser(item?._multipop_title)}</p>
                                            </div>
                                        </div>
                                    ))}


                                </Row>

                            </div>
                            {/*</SimpleBar>*/}
                        </div>

                    </Col>
                    <Col lg={4}>
                        <div className="box-svg__single">
                            <div className="box-svg__single__upper">
                                <div className="box-svg__single__upper__text">
                                    <h3 className={'split-up'}>{title ? title : 'Aqualoop'}</h3>
                                    <p className={''}>{text ? text : `Just like the ancient Waturi people who rode the ocean waves on friendly sea turtles, you can
                                                        enjoy this twisting, turning, multi-person slide. On Honu, you can surf the underwater currents
                                                        on a five-passenger raft, speeding through an open flume, then streaking along two massive
                                                        walls.`}</p>
                                </div>
                                <div className="box-svg__single__upper__icon">
                                    <div className={'modal_click'}
                                         onClick={handleButtonClick}>
                                        <a className="button button-small" href={''}
                                        >
                                            <i className="material-icons">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                     viewBox="0 0 12 12">
                                                    <g id="Group_18239" data-name="Group 18239"
                                                       transform="translate(-1009.5 -1473.5)">
                                                        <line id="Line_3896" data-name="Line 3896" y2="10"
                                                              transform="translate(1015.5 1474.5)" fill="none"
                                                              stroke="#fff"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3897" data-name="Line 3897" y2="10"
                                                              transform="translate(1020.5 1479.5) rotate(90)"
                                                              fill="none"
                                                              stroke="#fff"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </svg>
                                            </i>
                                            <span
                                                className="label-hidden">{" "}
                                                {buttonText ? buttonText : "More Details"}{" "}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-svg__bottom-icon">
                            <img src={svg ? svg : '/images/static/rides/1.svg'} alt=""/>
                        </div>
                        {
                            isMobile ?
                                <div className='concern-detail' ref={detailRef}>

                                    <div className="close-detail" ref={closeRef}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                                             viewBox="0 0 42 42">
                                            <g id="Group_19304" data-name="Group 19304"
                                               transform="translate(-1041 -1459)">
                                                <circle id="Ellipse_269" data-name="Ellipse 269" cx="21" cy="21" r="21"
                                                        transform="translate(1041 1459)" fill="#1a1818"/>
                                                <g id="Group_19303" data-name="Group 19303"
                                                   transform="translate(1052.454 1437.22) rotate(45)">
                                                    <g id="Group_19302" data-name="Group 19302"
                                                       transform="translate(-978.5 -1456)">
                                                        <g id="Group_19301" data-name="Group 19301">
                                                            <line id="Line_3978" data-name="Line 3978" y2="10"
                                                                  transform="translate(1015.5 1474.5)" fill="none"
                                                                  stroke="#fff" stroke-linecap="round"
                                                                  stroke-width="2"/>
                                                            <line id="Line_3979" data-name="Line 3979" y2="10"
                                                                  transform="translate(1020.5 1479.5) rotate(90)"
                                                                  fill="none" stroke="#fff" stroke-linecap="round"
                                                                  stroke-width="2"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <SimpleBar style={{maxHeight: '100%'}}>
                                        <div className="col-wrap">
                                            <Row>
                                                {zigzag && zigzag?.map(item => (
                                                    <div key={item?._multipop_file_id} className="col-sm-6 single-box"
                                                         onClick={() => handleShow(item)}>
                                                        <div className="single-box__img">
                                                            <Img src={item?._multipop_file}/>
                                                        </div>
                                                        <div className="single-box__content">
                                                            <p>{reactHtmlParser(item?._multipop_title)}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Row>

                                        </div>
                                    </SimpleBar>
                                </div>
                                : ''
                        }
                    </Col>
                </Row>
            </Container>


            {/*popup */}
            <Modal className={'experience-popup'} show={show} onHide={handleClose}>

                <Modal.Body>

                    <div className="experience-popup__top">

                        <svg className={'close-modal'} onClick={handleClose} xmlns="http://www.w3.org/2000/svg"
                             width="42" height="42"
                             viewBox="0 0 42 42">
                            <g id="Group_19304" data-name="Group 19304"
                               transform="translate(-1041 -1459)">
                                <circle id="Ellipse_270" data-name="Ellipse 269" cx="21" cy="21" r="21"
                                        transform="translate(1041 1459)" fill="#1a1818"/>
                                <g id="Group_19303" data-name="Group 19303"
                                   transform="translate(1052.454 1437.22) rotate(45)">
                                    <g id="Group_19302" data-name="Group 19302"
                                       transform="translate(-978.5 -1456)">
                                        <g id="Group_19301" data-name="Group 19301">
                                            <line id="Line_3978" data-name="Line 3978" y2="10"
                                                  transform="translate(1015.5 1474.5)" fill="none"
                                                  stroke="#fff" stroke-linecap="round"
                                                  stroke-width="2"/>
                                            <line id="Line_3979" data-name="Line 3979" y2="10"
                                                  transform="translate(1020.5 1479.5) rotate(90)"
                                                  fill="none" stroke="#fff" stroke-linecap="round"
                                                  stroke-width="2"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <div className="experience-popup__top__inner">
                            <h4>{modalData?._multipop_title}</h4>
                            <ul>
                                <li>
                                    <h6>LOCATION</h6>
                                    <p>{modalData?._multipop_location ? modalData?._multipop_location : '.'}</p>
                                    {/*<p>{modalData?._multipop_location} <a href="#">Explore Map</a></p>*/}
                                </li>
                                <li>
                                    <h6>HEIGHT REQUIREMENT</h6>
                                    <p>{modalData?._multipop_height_req ? modalData?._multipop_height_req : '.'}</p>
                                </li>
                                <li>
                                    <h6>RIDE TYPE</h6>
                                    <p>{modalData?._multipop_multipop_type ? modalData?._multipop_multipop_type : '.'}</p>
                                </li>
                                <li>
                                    <h6>HOURS</h6>
                                    <p>{modalData?._multipop_hrs_req ? modalData?._multipop_hrs_req : '.'}</p>
                                </li>
                                <li>
                                    <h6>WEIGHT REQUIREMENT</h6>
                                    <p>{modalData?._multipop_weght_req ? modalData?._multipop_weght_req : '.'}</p>
                                </li>
                                <li>
                                    <h6>RIDER SAFETY</h6>
                                    <a href={modalData?._multipop_pop_right_bottom_img} target="_blank">Get Details</a>
                                </li>
                            </ul>
                        </div>


                        {/*<img*/}
                        {/*    src={modalData?._multipop_file ? modalData?._multipop_file : "/images/static/pupup-shape.svg"}*/}
                        {/*    alt=""/>*/}
                    </div>


                    <div className="experience-popup__bottom">

                        <div className="experience-popup__bottom__col">
                            <p>{reactHtmlParser(modalData?._multipop_short_desc ? modalData?._multipop_short_desc : '.')}</p>
                            <Button borderColor={'#FFF'} color={'#FFF'} src={'/ticket'} text={'Buy Tickets'}/>
                        </div>

                        <div className="experience-popup__bottom__col text-right">
                            <div style={{height: "300px"}}>

                            </div>
                        </div>

                        <img className={'shadows'} src="/images/static/popup-shadow.svg" alt=""/>
                    </div>


                </Modal.Body>

            </Modal>
        </StyledComponent>


    );
};
const StyledComponent = styled.section`


  .container {
    overflow: hidden;

    .col-lg-4 {
      background: #FFFFFF;
      overflow: hidden;
    }

    .col-lg-8 {
      background: #FFFFFF;
      overflow: hidden;
    }
  }

  position: relative;

  :nth-child(even) {
    .row {
      flex-direction: row-reverse;
    }
  }

  .rides-zig__image {
    position: relative;
    padding-top: calc(550 / 770 * 100%);
    height: 100%;

  }

  .box-svg__single {

    position: relative;
    background: ${props => props.bgColor || '#92278F'};
    height: 100%;
    padding: 100px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__upper {

      &__text {
        h3 {
          font-weight: 400;
          margin-bottom: 20px;
          text-align: center;
        }

        p {
          font-weight: 300;
          text-align: center;

        }
      }

      &__icon {
        margin-top: 130px;
        margin-bottom: 60px;
        display: flex;
        justify-content: center;

        &:hover #clip-path {
          display: none;

        }

        &:hover #More_Details {
          opacity: 1;
          transform: scaleX(1.5);
        }

        &:hover #More_Details_Button {
          transform: scale(1.2);
        }
      }
    }

  }

  .box-svg__bottom-icon {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

    position: absolute;
    //top: 0;
    bottom: 0;


  }

  .box-svg__bottom-icon img {
    flex: 1;
    height: auto;
    object-fit: contain; /* Ensures the image maintains its aspect ratio */
    background-repeat: repeat-x; /* Repeat the image horizontally */
  }


  a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;


  }

  a.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    //padding: 13px ;
    padding: 17px 9px 17px 17px;
    border: 1px solid #FFF;
    border-radius: 50px;
    background: transparent;
    transition: 0.7s all ease;
    height: 47px;
    box-sizing: border-box;

    .material-icons {
      //font-size: 24px;
      display: flex;
      //transition: 0.3s all ease;
    }

    .label-hidden {
      max-width: 0;
      //width: 0;
      opacity: 0;
      max-height: 40px;
      white-space: nowrap;
      transition: 1s all ease;
      color: #ffffff;
      padding-left: 8px;

      display: flex;
      justify-content: space-between;

    }

    &:hover {
      border-radius: 50px;
      padding: 17px 17px 17px 17px;

      .label-hidden {
        max-height: 40px;
        opacity: 1;
        transition: 1s all ease;
        //width: 150px;
        max-width: 150px;
      }
    }
  }

  //modal

  .concern-detail {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    width: 50%;
    background-color: #F9F9F9;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.7s cubic-bezier(0.33, 1, 0.68, 1);
    overflow-y: auto;

    &.show-detail {
      //background-color: rgb(43, 51, 130);
      transform: none;
      opacity: 1;
      width: calc(100% - 30px);
      overflow: hidden;
      height: 100%;

    }

    .close-detail {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 10px;

      svg {
        font-size: 18px;
        color: rgb(128, 196, 43);
        z-index: 2;
      }

      &:after {
        content: '';
        background-color: rgb(128, 196, 43);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        margin: auto;
        height: 0px;
        width: 0;
        transition: all .3s ease;
      }

      &:hover {
        &:after {
          height: 100%;
          width: 100%;
        }

        svg {
          color: #ffffff;
        }
      }
    }

    .concern-detail__top {
      //width: 70%;
    }

    .header_wrap__title {
      h2 {
        color: #1A1818;
        //font-size: 40px;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin: 30px 0 0 40px;
      }
    }

    .col-wrap {
      //display: flex;
      //justify-content: space-between;
      //padding: 30px 30px 40px 40px;
      height: 100%;

      .row {
        height: 100%;
      }

      .first-col {
        &__single {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            color: #1b1e21;
            //font-size: 14px;
            //line-height: 25px;
            font-weight: 300;
          }

          h5 {
            color: #1b1e21;
            //font-size: 14px;
            //line-height: 25px;
            font-weight: 400;
          }
        }

      }

      .single-box {
        min-height: 50%;

        &:nth-of-type(1), &:nth-of-type(2) {
          margin-bottom: 5px;
        }

        &:nth-of-type(even) {
          padding-left: 5px;
        }

        &:nth-of-type(odd) {
          padding-right: 5px;
        }

        &__img {
          height: calc(100% - 85px);
          position: relative;
          cursor: pointer;
        }

        &__content {
          background-color: ${props => props.bgColor || '#92278F'};
          height: 80px;
          display: flex;
          align-items: center;
          //justify-content: center;
          padding-left: 30px;
          padding-right: 10px;
          cursor: pointer;

          p {
            font-size: 18px;
            line-height: 22px;
            font-weight: 300;
            color: #ffffff;
          }
        }
      }

      //item change based on condition 
      &.item-two {
        .single-box {
          width: 50%;
          margin-bottom: 0;
          min-height: 100%;
        }

        .single-box__img {
          min-height: calc(100% - 80px);
        }
      }

      &.item-three {
        .single-box {
          &:nth-of-type(1) {
            min-width: 100%;
          }

          &:nth-of-type(2) {
            padding-right: 5px;
          }

          &:nth-of-type(3) {
            padding-left: 5px;
          }
        }
      }

      @media (max-width: 767px) {
        .single-box__img {
          height: 250px;
        }
      }
    }

    .box-svg {
      background: #92278F;
      position: relative;
      height: 365px;

      &__svg {
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          transform: translate(35%, 20%);
        }


      }

      &__btn {
        position: absolute;
        bottom: 0;
        margin-bottom: 80px;
        margin-left: 55px;
      }

      .ticker-svg {
        position: absolute;
        top: -23px;
        width: 100%;
        height: auto;

        svg {
          width: 100%;
          flex: 1;
          height: auto;
          object-fit: contain; /* Ensures the image maintains its aspect ratio */
          background-repeat: repeat-x;
        }
      }

    }

    .concern-detail__content {
      margin-top: 80px;
      margin-bottom: 150px;
      padding-right: 20px;

      h4 {
        //font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 0 30px 0;
        text-transform: uppercase;
      }

      ul {
        padding-left: 15px;
        margin-bottom: 35px;

        li {
          list-style: disc;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 18px;
        }
      }

    }


    .simplebar-horizontal {
      visibility: hidden !important;
    }

    .simplebar-content {
      overflow: hidden !important;
    }

    .simplebar-vertical {
      opacity: 0;
      visibility: hidden !important;
    }

    //.simplebar-content-wrapper {
    //  overflow: hidden !important;
    //  
    //}
    //.simplebar-content-wrapper::-webkit-scrollbar {
    //  width: 0 !important;
    //  background-color: transparent !important;
    //}

    &:hover {
      .simplebar-vertical {
        opacity: 1;
      }
    }

    //.simplebar-scrollable-x {
    //  max-height: unset !important;
    //}


    @media (max-width: 767px) {
      width: 95%;
      //padding: 60px 10px 80px 30px;
      .concern-detail__content {
        margin-top: 40px;

        .file {
          margin-top: 80px;

          input {
            height: 48px;

            &:before {
              top: 15px;
            }
          }
        }

        .dc-btn {
          margin-top: 50px;
        }
      }

      .concern-detail__top {
        width: calc(100% - 20px);

        h2 {
          font-size: 22px !important;
          margin-bottom: 30px;
          line-height: 30px;
        }
      }

      .close-detail {
        top: 20px;
        right: 20px;
      }
    }
  }


  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }

    :nth-child(even) {
      .row {
        flex-direction: column-reverse;
      }
    }

    .box-svg__single {
      margin-bottom: 0 !important;
      padding-bottom: 90px !important;
    }

    .modal_click {
      margin-bottom: 60px;
    }

    //.rides-zig__image {
    //  padding-top: calc(130%);
    //}
    //.container {
    //  overflow-x: hidden;
    //}
    .simplebar-mask {
      right: -15px !important;
    }

    .box-svg__btn {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      bottom: unset !important;

    }

    .concern-detail {
      padding: 0 !important;
    }

    .box-svg__svg {
      visibility: hidden;
    }

    .col-wrap {
      flex-direction: column;
    }

    .first-col {
      margin-bottom: 20px;
    }

    position: relative;


    //.simplebar-content {
    //  position: fixed;
    //  margin-right: 15px;
    //}
    .box-svg {
      height: 135px !important;
    }

    .box-svg__btn {
      margin-bottom: 50px !important;
      margin-left: 0 !important;
    }


    .col-wrap {
      background-color: rgb(249, 249, 249);
    }

  }

  @media (max-width: 992px) {
    .box-svg__single {
      margin-bottom: 100px;
      //padding: 80px 20px 0 20px;
    }

    a.button .label-hidden {
      opacity: 1;
      max-width: 150px;
      padding-right: 10px;
    }

    .box-svg__single__upper__icon {
      margin-top: 60px;
    }

    .box-svg {
      height: 285px !important;
    }
  }


  //  

  @media (max-width: 1199px) {
    .box-svg__single {
      //padding: 60px 20px 0 20px !important;
    }

    .box-svg {
      height: 285px !important;
    }
  }

  @media (min-width: 1800px) {
    .box-svg {
      height: 520px !important;
    }

    .box-svg__btn {
      margin-bottom: 150px !important;
    }
  }

  @media (min-width: 1500px) {
    .box-svg {
      height: 520px !important;
    }

    .box-svg__btn {
      margin-bottom: 200px !important;
    }
  }

  //modify media by rashed
  @media (max-width: 767px) {
    .rides-zig__image {
      height: 100vh;
    }
  }

  &:nth-of-type(even) {

    .single-box {
      min-height: 50%;

      &:nth-of-type(1), &:nth-of-type(2) {
        margin-bottom: 5px;
      }

      &:nth-of-type(odd) {
        padding-left: 5px;
      }

      &:nth-of-type(even) {
        padding-right: 5px;
      }

    }
  }

`;


export default MyComponent;
