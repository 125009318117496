import React, {useState} from 'react';
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Col, Container, Row, Card} from "react-bootstrap";
import Title from "../Title";
import Accordion from "react-bootstrap/Accordion";
import {text, title, hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const FeaturesBenefits = ({data}) => {
    const [application, setApplication] = useState('All Season');
    const [activeKey, setActiveKey] = useState(0);

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey === activeKey ? 0 : selectedKey);
    };


    return (
        <StyledComponent className='features_aminites pt-120 '>
            <Container>
                <div className="filter_wrapper fade-up">
                    <Row>
                        {
                            data && data?.length > 0 &&
                            data?.map((e) => {
                                return (

                                    <Col md={6} key={e}>
                                        <Accordion defaultActiveKey={0}>
                                            <Accordion.Item eventKey={e}>
                                                <Accordion.Header>
                                                    <div className="main">
                                                        {e?.title}
                                                    </div>
                                                    <span>
                                        <svg id="Component_48_1" data-name="Component 48 – 1"
                                             xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                             viewBox="0 0 30 30">
                                              <circle id="Ellipse_259" data-name="Ellipse 259" cx="15" cy="15" r="14"
                                                      fill="#e9e9e9" stroke="#1a1818"/>
                                                  <circle id="Ellipse_260" data-name="Ellipse 259" cx="15" cy="15" r="0"
                                                          fill="#e9e9e9"/>

                                              <g id="Group_5920" data-name="Group 5920" transform="translate(10 10)">
                                                <line id="Line_1068" data-name="Line 1068" x2="10"
                                                      transform="translate(0 5)" fill="none" stroke="#262626"
                                                      stroke-linecap="round" stroke-width="1.5"/>
                                                <line id="Line_1069" data-name="Line 1069" x2="10"
                                                      transform="translate(5) rotate(90)" fill="none" stroke="#262626"
                                                      stroke-linecap="round" stroke-width="1.5"/>
                                              </g>
                                            </svg>




                                            </span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>{reactHtmlParser(e?.description)}</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </div>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`

  padding-bottom: 90px;


  .filter_wrapper {

    &.last {
      border-top: 1px solid #E8E8E8;
      margin-top: 30px;
      padding-top: 30px;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #262626;
      margin: 0 0 20px;

      .icon {
        display: none;
      }
    }

    .accordion-button:not(.collapsed) {
      color: #E60002;

      svg {
        line, path {
          stroke: white;
          fill: white;
        }

        #Ellipse_260 {
          r: 15px;

        }

        #Line_1069 {
          display: none;
        }
      }

      span {
        //transform: rotate(180deg);
      }
    }

    .accordion-body {
      padding-top: 20px;

      border-top: 1px solid #E8E8E8;

      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        margin: 0;
        color: #1A1818;
        padding: 0 30px 30px 30px;
      }


      .filter__indicator {
        padding: 0;
      }

      .filter__menu {
        //padding-left: 20px;
        //margin-left: 20px;
      }

      .filter__placeholder, .filter__single-value {
        font-size: 16px;
        font-weight: 100;
        color: #000000;
      }

      .filter__control {
        border: none;
        padding: 0 0 0 0px;
        min-height: auto;
        height: auto;
        margin-top: 20px;

        .filter__value-container {
          padding: 0;
        }
      }

      ul {
        margin: 20px 0;
        padding-left: 20px;

        li {
          cursor: pointer;
          font-size: 16px;
          font-weight: 100;
          color: #000000;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .accordion-header {
      //margin-bottom: 20px;
      button {
        border: none;
        background: transparent;
        padding: 0;
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px;

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }

        .main {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding: 30px;
          color: #1A1818;


        }

        span {
          display: flex;
          transition: all 0.7s cubic-bezier(0.87, 0, 0.13, 1);
        }
      }
    }

    .accordion-item {
      background: #F9F9F9;
      margin-bottom: 30px;

      //&:last-child {
      //  margin-bottom: 0;
      //}

      .accordion-button {

        svg {
          line, path {
            transition: all 0.25s ease-in-out;

          }

          #Ellipse_260 {
            transition: all 0.25s ease-in-out;
            fill: #92278F;


          }
        }

        &:hover {
          svg {
            line, path {
              stroke: white;
              fill: white;
            }

            #Ellipse_260 {
              r: 15px;

            }
          }
        }
      }

      &.gray {
        .accordion-button {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .content_wrapper {
    width: 100%;
    @media (max-width: 767px) {
      margin-top: 60px;
    }

    h3 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #262626;
      margin-bottom: 60px;
    }

    .status_bar_wrap {
      .item_single {
        align-items: center;
        display: flex;
        margin-bottom: 40px;

        &:last-child {
          margin: 0;
        }

        .title_single {
          width: 25%;

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            color: #011E62;
            margin: 0 0px 0 0;
          }
        }

        .status_bar {
          width: 100%;

          span {
            display: none;
          }
        }
      }
    }
  }


  @media (max-width: 767px) {
    padding-bottom: 60px;
    .pr-0 {
      padding-right: 15px !important;
    }

    .accordion-item {
      margin-bottom: 20px;
    }

  }
`

export default FeaturesBenefits;