import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {orderDetails} from "../../api/redux/checkout";
import {Loading} from "../Loading";

const MyComponent = ({
                         title
                     }) => {
    const dispatch = useDispatch()
    const {slug} = useParams()
    const [loading, setLoading] = useState(false)
    let orderDetail = useSelector(state => state?.checkout?.orderDetails);

    useEffect(() => {
        let param = slug
        setLoading(true)
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let params = {
            orderId: slug,
            token: token,
            id: id
        }
        dispatch(orderDetails(params)).then(() => {
            setLoading(false)
        })
    }, [slug])

    return (
        <StyledComponent>
            <Container className={"order pb-80 pt-80"}>
                <Row>
                    <Col lg={{span: 6, offset: 3}}>
                        <div className={"order__box"}>
                            <div className={"title"}>
                                <img src={'/images/dynamic/icon.svg'}/>
                                <h3 className={""}>{title ? title : 'Order History'}</h3>
                            </div>
                            <div className="order__box__content">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Items</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orderDetail ? orderDetail?.items ? orderDetail?.items.length > 0 ?
                                            orderDetail?.items.map((e) => {
                                                return (
                                                    <tr>
                                                        <td className={'flex'}>{e?.name}</td>
                                                        <td>X{e?.quantity}</td>
                                                        <td>BDT {e.price}</td>
                                                    </tr>
                                                )
                                            }) : null : null : null
                                    }
                                    </tbody>

                                </table>
                            </div>

                            {
                                orderDetail ? orderDetail?.items ? orderDetail.items.length > 0 ?
                                orderDetail?.items.map((e)=>{
                                    return (
                                        <>
                                            <div className="subtotal">
                                                <h6>Subtotal</h6>
                                                <h6>BDT{e?.subtotal}</h6>
                                            </div>

                                            <div className="total">
                                                <div className="total-left">
                                                    <h4>Total</h4>
                                                </div>
                                                <div className="total-left">
                                                    <h4>BDT {e.total.toLocaleString('en-US') }</h4>
                                                    <p>All taxes and fees included</p>
                                                </div>

                                            </div>
                                        </>
                                    )
                                    }) : null : null : null
                            }

                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .order {


    &__box {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
      padding: 0;

      h3 {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
      }

      .title {
        position: relative;
        padding: 45px 0 40px 30px;
        background-color: #92278F;

        img {
          width: 100%;
          position: absolute;
          bottom: -15px;
          left: 0;
        }

      }


      &__content {
        margin: 50px 30px 0 30px;
        //padding: 0 30px;

        table {
          thead {

            border-bottom: 1px solid #E8E8E8;

            tr {
              th {
                font-size: 16px;
                line-height: 32px;
                color: #434343;
                font-weight: 400;
                padding: 20px 0;

                &:nth-of-type(3) {
                  text-align: end;
                }
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #E8E8E8;

              .flex {
                display: flex;
                flex-direction: column;
              }

              span {
                a {
                  margin: 10px 0;
                  font-size: 12px;
                  line-height: 14px;
                  color: #EC1B34;
                  font-weight: 300;
                }
              }

              td {
                padding: 20px 0;
                font-size: 14px;
                line-height: 16px;
                font-weight: 300;
                border-color: #DFE6E5;
                color: #1A1818;

                &:nth-of-type(3) {
                  text-align: end;
                }

              }
            }
          }
        }


      }

      .subtotal {
        display: flex;
        justify-content: space-between;
        padding: 30px;

        h6 {
          color: #1A1818;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        background-color: rgb(146, 39, 143, 0.1);

        .total-left {
          text-align: end;
        }

        h4 {
          color: #92278F;
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
        }

        p {
          font-size: 12px;
          line-height: 16px;
          color: #434343;
          font-weight: 300;
        }
      }

    }
  }
`;

export default MyComponent;
