import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Img from "../Img";
import reactHtmlParser from "react-html-parser";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data, follow}) => {
    return (
        <StyledComponent>
            <Container className={'follow pb-120 pt-120'}>
                <Row>
                    <Col md={6} className={'follow__left'}>
                        <h2 className={"split-up"}>{data?.meta?._follow[0]}</h2>
                        <p className={"split-up"}>{data?.meta?._follow_description[0]}</p>
                        <ul>
                            {
                                follow && follow?.length>0 &&
                                follow?.map((e)=>{
                                    return(
                                        <li key={e}>
                                            <div className={"icon-text"}>
                                                <p className={" p1"}>{e?._event_title}</p>
                                                <p className={"p2 split-up"}>{ReactHtmlParser(e?._event_desc)}</p>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Col>
                    <Col md={{span:5, offset:1}} >
                        <div className="follow__right reveal">
                            <Img src={data?.meta?._follow_this_image[0]}/>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .follow{
      &__left{
        h2{
          //font-size: 40px;
          //line-height: 48px;
          font-weight: 400;
          color: #1A1818;
        }
        p{
          //font-size: 18px;
          line-height: 27px !important;
          font-weight: 300;
          color: #1A1818;
          margin-top: 40px;
          margin-bottom: 40px;
        }
        ul{
          li{
            display: flex;
            align-self: flex-start;
            padding-bottom: 25px;
            padding-top: 25px;
            border-bottom: 1px solid #E8E8E8;
            &:first-child{
              padding-top: 0;
            }
            svg{
              margin-right: 15px;
            }
           .icon-text{
              display: block;
              .p1{
                margin-top: 0;
                margin-bottom: 0;
                //font-size: 14px;
                //line-height: 27px;
                font-weight: 400;
                color: #1A1818 !important;
              }
             .p2{
               margin-top: 20px;
               margin-bottom: 0;
             }
             
            }
          }
        }
      }
      &__right{
        position: relative;
        padding-top: calc(410 / 500 * 100%);
        height: 100%;
        width: 100%;
        img{
          height: auto;
        }
      }
      @media(max-width: 767px){
        &__left{
          margin-bottom: 60px;
          h2{
            //font-size: 24px;
            line-height: 27px !important;
            font-weight: 400;
          }
          p{
            //font-size: 16px;
            line-height: 24px !important;
            font-weight: 300;
          }
        }
      }
    }
`;

export default MyComponent;
