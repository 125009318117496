import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import AccountButton from "../AccountButton";
import {clearToken} from "../../api/redux/auth";
import {useDispatch, useSelector} from "react-redux";
import Button from "../Button";
import {Link, useHistory} from "react-router-dom";
import ProductOrder from "../ProductOrder";
import {loading} from "../../api/redux/global";
import {clearCart} from "../../api/redux/checkout";

const MyComponent = () => {
    const [token, setToken] = useState()
    const dispatch = useDispatch()
    const loadingState = useSelector(state => state.auth)
    const history = useHistory()

    useEffect(() => {
        let token = localStorage.getItem('token')
        if (!token) {
            history.push('/login')
        }

    }, [])


    useEffect(() => {
        setTimeout(() => {
            dispatch(loading)
            // console.log('in the accout')
        })
    })


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <StyledAccount className='account pt-120 pb-120 '>
            <Container>
                <div className="account_single">
                    <Row>
                        <Col md={12}>
                            <div className="account__text">
                                <h2>Account</h2>
                            </div>
                        </Col>
                        <Col md={4}>
                            <AccountButton buttonText={'User Information'} src={'/user/info'}/>
                        </Col>

                        <Col md={4}>
                            <AccountButton buttonText={'Order History'} src={'order/list'}/>
                        </Col>

                        <Col md={4}>
                            <div className={'logout'} onClick={handleShow}>
                                <h3>Logout</h3>

                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal show={show} className='log transparent-modal'>
                    <Modal.Body>
                        <div className="announcement">
                            <div className="pop-close" onClick={handleClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.828" height="27.828"
                                     viewBox="0 0 27.828 27.828">
                                    <g id="Group_18986" data-name="Group 18986"
                                       transform="translate(-1241.837 -61.211)">
                                        <line id="Line_3541" data-name="Line 3541" x2="35.355"
                                              transform="translate(1243.251 62.626) rotate(45)" fill="none"
                                              stroke="#92278F" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_12368" data-name="Line 12368" x2="35.355"
                                              transform="translate(1243.251 87.626) rotate(-45)" fill="none"
                                              stroke="#92278F" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </div>
                            <div className="modal-data">
                                <div className={'logout'}>
                                    <h3>Are you sure you want to logout?</h3>
                                    <div className={'log-btns'}>
                                        <Button text={'Cancel'} src={'/account'} color={'#92278F'}
                                                borderColor={'#92278F'} hoverfill={'#92278F'}/>
                                        <Button text={'logout'} src={'/'}
                                                onClick={() => {
                                                    dispatch(clearToken())
                                                    dispatch(clearCart())
                                                    localStorage.clear()
                                                }}
                                                color={'#92278F'} borderColor={'#92278F'} hoverfill={'#92278F'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="account_single">
                    <Row>
                        <Col md={12}>
                            <div className="account__text">
                                <h2>Explore</h2>
                            </div>
                        </Col>
                        <Col md={4}>
                            <AccountButton buttonText={'Rides'}/>
                        </Col>

                        <Col md={4}>
                            <AccountButton buttonText={'Attractions'}/>
                        </Col>
                        <Col md={4}>
                            <AccountButton buttonText={'Taste'}/>
                        </Col>
                        <Col md={4}>
                            <AccountButton buttonText={'Unwind'}/>
                        </Col>
                        <Col md={4}>
                            <AccountButton buttonText={'Momento'}/>
                        </Col>
                        <Col md={4}>
                            <AccountButton buttonText={'Events'}/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </StyledAccount>
    );
};
const StyledAccount = styled.section`
  .account_single {
    margin-bottom: 60px;
  }

  .account__text {
    margin-bottom: 35px;
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    color: #1A1818;
  }


  @media (max-width: 767px) {
    .account_single {
      margin-bottom: 30px;
    }
  }

  .logout {
    display: flex;
    justify-content: center;
    padding: 60px 106px;
    background-color: #F9F9F9;
    color: #1A1818;
    cursor: pointer;

    .log-btns {
      display: flex;
    }

    @media (max-width: 767px) {
      h3 {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }

  }


`;
export default MyComponent;
