import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import CabanaModal from "./CabanaModal";
import moment from "moment";
import { checkTicketStatus } from "../../api/redux/ticket";
import xmljs from "xml-js";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { hover } from "../../styles/globalStyleVars";
import CabanaMapModal from "./CabanaMapModal";

const Tickets = ({
  ticketDetails,
  selectedTickets,
  setSelectedTickets,
  adultChildTicketSelected,
  setAdultChildTicketSelected,
  date,
}) => {
  const [showVip, setShowVip] = useState(false);
  const [showStandard, setShowStandard] = useState(false);
  const [cabanaType, setCabanaType] = useState("");
  const [selectedCabana, setSelectedCabana] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [showMapModal, setShowMapModal] = useState(false);
  const [checkVip, setCheckVip] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setCheckVip(e);
  };

  const success = (msg) =>
    toast.success(msg, {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const error = (msg) =>
    toast.error(msg, {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const isPublicHoliday = () => {
    const publicHolidays = [
      "2024-01-01",
      "2024-02-21",
      "2024-02-26",
      "2024-03-17",
      "2024-03-26",
      "2024-03-29",
      "2024-04-01",
      "2024-04-05",
      "2024-04-07",
      "2024-04-10",
      "2024-04-11",
      "2024-04-12",
      "2024-04-14",
      "2024-05-01",
      "2024-05-09",
      "2024-05-22",
      "2024-06-16",
      "2024-06-17",
      "2024-06-18",
      "2024-06-19",
      "2024-06-20",
      "2024-07-17",
      "2024-08-15",
      "2024-08-26",
      "2024-09-16",
      "2024-10-13",
      "2024-12-16",
      "2024-12-25",
      "2024-12-26",
    ];

    const selectedDate = moment(date).format("yyyy-MM-DD");
    //  console.log(publicHolidays.includes(selectedDate));
    return publicHolidays.includes(selectedDate);
  };

  const checkIfValidDate = (ticketId) => {
    const validDays = {
      10101304: [0, 1, 2, 3, 4],
      10101302: [0, 1, 2, 3, 4, 5, 6],
    };

    // Check if the date is a public holiday for adult weekend ticket type
    console.log(isPublicHoliday());
    if (ticketId === "10101304" && isPublicHoliday()) {
      return false;
    }

    // Check if the date is a public holiday for senior citizen ticket type
    if (ticketId === "10101302" && isPublicHoliday()) {
      return true;
    }

    //extract the day of week from the date
    let day = date.getDay();
    console.log(day);
    console.log(ticketId);
    if (validDays[ticketId]) {
      console.log(validDays[ticketId]?.includes(day));
      return validDays[ticketId]?.includes(day);
    }

    return true;
  };

  const handleIncreaseQuantity = (ticketId) => {
    if (date) {
      if (checkIfValidDate(ticketId)) {
        setSelectedTickets((prevSelected) => ({
          ...prevSelected,
          [ticketId]: (prevSelected[ticketId] || 0) + 1,
        }));

        // Update localStorage
        const updatedSelectedTickets = {
          ...selectedTickets,
          [ticketId]: (selectedTickets[ticketId] || 0) + 1,
        };
        sessionStorage.setItem(
          "selectedTickets",
          JSON.stringify(updatedSelectedTickets)
        );
      } else {
        toast.error("Date does not match ticket type!", {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error("Missing date field! Please select a visit date first.", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleDecreaseQuantity = (ticketId) => {
    setSelectedTickets((prevSelected) => {
      const updatedQuantity = Math.max((prevSelected[ticketId] || 0) - 1, 0);
      const updatedSelected = { ...prevSelected };
      if (updatedQuantity === 0) {
        delete updatedSelected[ticketId];
      } else {
        updatedSelected[ticketId] = updatedQuantity;
      }
      // Update localStorage
      sessionStorage.setItem(
        "selectedTickets",
        JSON.stringify(updatedSelected)
      );
      return updatedSelected;
    });
  };

  const removeItemsFromSelectedTickets = () => {
    selectedCabana.forEach((cabana) => {
      // Check if the PLU exists in selectedTickets and remove it
      if (selectedTickets.hasOwnProperty(cabana.PLU)) {
        delete selectedTickets[cabana.PLU];
      }
    });
  };

  useEffect(() => {
    if (selectedCabana && selectedCabana.length > 0) {
      sessionStorage.setItem("selectedCabana", JSON.stringify(selectedCabana));
    }

    // Schedule the removal after 5 minutes
    const removalTimeout = setTimeout(
      removeItemsFromSelectedTickets,
      5 * 60 * 1000
    ); // 5 minutes in milliseconds

    // Clean up the timeout to prevent it from running if selectedCabana changes again
    return () => clearTimeout(removalTimeout);
  }, [selectedCabana]);

  useEffect(() => {
    const storedSelectedCabana = sessionStorage.getItem("selectedCabana");
    if (storedSelectedCabana) {
      setSelectedCabana(JSON.parse(storedSelectedCabana));
    }
  }, []);

  const foundVIP = ticketDetails?.some((e) => {
    const nameText = e?.Name?._text.toLowerCase();
    return nameText.includes("vip");
  });

  const foundStandard = ticketDetails?.some((e) => {
    const nameText = e?.Name?._text.toLowerCase();
    return nameText.includes("vip");
  });

  const availabilityCheck = (e, isSelected) => {
    const session = sessionStorage.getItem("ticketToken");
    const now = new Date();

    const adultCount = Object.keys(selectedTickets).reduce(
      (total, ticketId) => {
        const ticket = ticketDetails.find(
          (ticket) => ticket?.PLU?._text === ticketId
        );
        const nameText = ticket?.Name?._text.toLowerCase();
        if (nameText.includes("adult")) {
          return total + selectedTickets[ticketId];
        }
        return total;
      },
      0
    );

    const childCount = Object.keys(selectedTickets).reduce(
      (total, ticketId) => {
        const ticket = ticketDetails.find(
          (ticket) => ticket?.PLU?._text === ticketId
        );
        const nameText = ticket?.Name?._text.toLowerCase();
        if (nameText.includes("child")) {
          return total + selectedTickets[ticketId];
        }
        return total;
      },
      0
    );

    const totalTicketCount = adultCount + childCount;

    // Calculate the maximum allowed cabanas as a multiple of 4
    const maxCabanas = Math.ceil(totalTicketCount / 4);

    if (!isSelected) {
      if (selectedCabana.length >= maxCabanas) {
        // If the user has reached or exceeded the maximum allowed cabanas, prevent further selection.
        // alert(`You can select a maximum of ${maxCabanas} cabanas.`);
        error(`You can select a maximum of ${maxCabanas} cabanas.`);
        return; // Exit the function without adding another cabana.
      } else {
        const param = [
          "",
          `<?xml version="1.0" encoding="UTF-8"?>
                <Envelope>
                    <Header>
                        <SourceID>1</SourceID>
                        <MessageID/>
                        <MessageType>QueryEvents</MessageType>
                        <SessionID>${session}</SessionID>
                        <TimeStamp>${moment(now).format(
                          "yyyy-MM-DD hh:mm:ss"
                        )}</TimeStamp>
                    </Header>
                    <Body>
                        <QueryEvents>
                            <TranslationLanguageID>0</TranslationLanguageID>
                            <GroupEventsByEventID>YES</GroupEventsByEventID>
                            <QueryEventGroup>
                            <QueryEvent>
                                    <EventRangeBeginDate>${moment(date).format(
                                      "yyyy-MM-DD"
                                    )} 00:00:00</EventRangeBeginDate>
                                    <EventRangeEndDate>${moment(date).format(
                                      "yyyy-MM-DD"
                                    )} 23:59:59</EventRangeEndDate>
                                    <EventTypeID>${
                                      e?.EventTypeID?._text
                                    }</EventTypeID>
                                    <ResourceID>${
                                      e?.SectionID?._text
                                    }</ResourceID>
                                    <Quantity>1</Quantity>
                                    <PLU>${e?.PLU?._text}</PLU>
                                    <EventGroupID>${
                                      e?.PLU?._text
                                    }</EventGroupID>
                                </QueryEvent>
                            </QueryEventGroup>
                        </QueryEvents>
                    </Body>
                </Envelope>`,
        ];
        dispatch(checkTicketStatus(param)).then((res) => {
          let xmlData;
          try {
            const jsonData = xmljs.xml2json(res?.payload, {
              compact: true,
              spaces: 4,
            });
            xmlData = jsonData;
          } catch (error) {
            console.error("Error converting XML to JSON:", error);
            // setJsonOutput('Error converting XML to JSON');
          }

          let obj = JSON.parse(xmlData);
          let status = obj?.Envelope?.Body?.Events?.Event;
          if (status && status?.Available?._text === "1") {
            let add = {
              PLU: status?.EventGroups?.EventGroup?.EventGroupID?._text,
              quantity: 1,
              eventId: status?.EventID?._text,
              resourseId: status?.ResourceID?._text,
              cavana_flag: 1,
            };
            // Check if the item exists in selectedCabana
            const isItemInSelectedCabana = selectedCabana.some(
              (item) => item.PLU === add.PLU
            );

            if (isSelected) {
              // If status is true, remove the item if it exists in selectedCabana
              if (isItemInSelectedCabana) {
                setSelectedCabana((prevItemsArray) =>
                  prevItemsArray.filter((item) => item.PLU !== add.PLU)
                );
                // Decrease the quantity or handle any other action as needed
                handleDecreaseQuantity(add.PLU);
                error("Cabana Removed!");
              }
            } else {
              // If status is false, add the item if it doesn't exist in selectedCabana
              if (!isItemInSelectedCabana) {
                setSelectedCabana((prevItemsArray) => [...prevItemsArray, add]);
                // Increase the quantity or handle any other action as needed
                handleIncreaseQuantity(
                  status?.EventGroups?.EventGroup?.EventGroupID?._text
                );
                success("Cabana Selected ✅");
              }
            }
          } else {
            error("Something Went Wrong!");
          }
        });
      }
    } else {
      // Find the index of the item with a specific PLU in selectedCabana
      const indexOfItemToRemove = selectedCabana.findIndex(
        (item) => item.PLU === e?.PLU?._text
      );

      if (indexOfItemToRemove !== -1) {
        // Remove the item from selectedCabana
        selectedCabana.splice(indexOfItemToRemove, 1);
        // Update the state with the modified selectedCabana
        setSelectedCabana([...selectedCabana]);
        // Decrease the quantity or handle any other action as needed
        handleDecreaseQuantity(e?.PLU?._text);
        error("Cabana Removed!");
      }
    }
  };

  const cabanaConfirmHandler = () => {
    selectedCabana.forEach((e) => {
      handleDecreaseQuantity(e.PLU);
    });

    selectedCabana.forEach((e) => {
      setTimeout(() => {
        handleIncreaseQuantity(e.PLU);
      }, 400);
    });
  };

  return (
    <StyledTickets>
      <div className={"tc"}>
        <div className={"title"}>
          <h3>Full Day Entry</h3>
        </div>
        <div className={"ticketing"}>
          <div className={"ticketing-top"}>
            <div className={"ticketing__img"}>
              <img src={"/images/dynamic/ticket.jpg"} />
            </div>
            <div className={"ticketing__info"}>
              {ticketDetails
                ? ticketDetails.map((e) => {
                    const nameText = e?.Name?._text.toLowerCase();
                    const ticketId = e?.PLU?._text;
                    if (
                      (nameText.includes("adult") ||
                        nameText.includes("child")) &&
                      ticketId !== "101002011" &&
                      ticketId !== "10101306" &&
                      ticketId !== "10101307" &&
                      ticketId !== "1010020119" &&
                      ticketId !== "10101308"
                    ) {
                      return (
                        <div
                          className={"ticketing__info__details"}
                          key={e.PLU._text}
                        >
                          <div className={"ticketing__info__details__list"}>
                            <div
                              className={
                                "ticketing__info__details__list__type d-flex"
                              }
                            >
                              <span>
                                <h4>{e?.Name?._text}</h4>
                                <p>{e?.Description?._text}</p>
                              </span>
                              <p className={"price"}>
                                BDT
                                {parseInt(e?.Price?._text).toLocaleString(
                                  "en-US"
                                )}
                              </p>
                            </div>
                          </div>

                          <div className={"ticketing__info__details__btn"}>
                            <div className="increase-section__button">
                              <input
                                type="button"
                                value="-"
                                onClick={() => {
                                  handleDecreaseQuantity(e?.PLU?._text);
                                  if (
                                    selectedTickets[e?.PLU?._text] !== 0 ||
                                    selectedTickets[e?.PLU?._text]
                                  ) {
                                    setAdultChildTicketSelected(false);
                                  } else {
                                    setAdultChildTicketSelected(true);
                                  }
                                }}
                                disabled={
                                  selectedTickets[e?.PLU?._text] === 0 ||
                                  !selectedTickets[e?.PLU?._text]
                                }
                              />
                              <span>{selectedTickets[e?.PLU?._text] || 0}</span>
                              <input
                                type="button"
                                value="+"
                                onClick={() => {
                                  handleIncreaseQuantity(e?.PLU?._text);
                                  setAdultChildTicketSelected(true);
                                }}
                                // disabled={selectedTickets[e?.PLU?._text] === 0 || !selectedTickets[e?.PLU?._text]}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null; // Skip rendering for other cases
                    }
                  })
                : null}
            </div>
          </div>
        </div>
      </div>

      <div className={"cabana"}>
        <div className={"title"}>
          <h3>Add-on: Cabana </h3>
        </div>
        <div className={"add-ons"}>
          <div className={"ticketing__img"}>
            <img src={"/images/dynamic/cabana.png"} />
          </div>
          <div className={"ticketing__info"}>
            {foundVIP ? (
              <div className="guest-entry">
                <p>
                  <span>
                    VIP Cabanas <small> BDT 16,447</small>
                  </span>{" "}
                  <img
                    onClick={() => handleShow("vip")}
                    className={"info-popup"}
                    src="/images/static/info.svg"
                    alt=""
                  />
                </p>

                <div className={"action-btns"}>
                  <img
                    onClick={() => {
                      setCabanaType("vip");
                      setShowMapModal(true);
                    }}
                    className={"cabana-popup"}
                    src="/images/static/cabana-map.svg"
                    alt=""
                  />

                  {/*cabana map modal*/}
                  <CabanaMapModal
                    modalShow={showMapModal}
                    setModalShow={setShowMapModal}
                    ticketDetails={ticketDetails}
                    cabanaType={cabanaType}
                    setCabanaType={setCabanaType}
                    date={date}
                    selectedCabana={selectedCabana}
                    setSelectedCabana={setSelectedTickets}
                    availabilityCheck={availabilityCheck}
                    selectedTickets={selectedTickets}
                    setSelectedTickets={setSelectedTickets}
                    cabanaConfirmHandler={cabanaConfirmHandler}
                  />

                  <div
                    onClick={() => {
                      setCabanaType("vip");
                      setShowVip(true);
                    }}
                    className="arrow-button"
                  >
                    <img
                      src={"/images/static/right-arrow.svg"}
                      alt="right-arrow"
                    />
                    {showVip ? (
                      <CabanaModal
                        modalShow={showVip}
                        setModalShow={setShowVip}
                        ticketDetails={ticketDetails}
                        cabanaType={cabanaType}
                        setCabanaType={setCabanaType}
                        date={date}
                        selectedCabana={selectedCabana}
                        setSelectedCabana={setSelectedTickets}
                        availabilityCheck={availabilityCheck}
                        cabanaConfirmHandler={cabanaConfirmHandler}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            {foundStandard ? (
              <div className="guest-entry">
                <p>
                  <span
                    onClick={() => {
                      setCabanaType("premium");
                      setShowStandard(true);
                    }}
                  >
                    Premium Cabanas <small> BDT 13,158</small>
                  </span>{" "}
                  <img
                    onClick={handleShow}
                    className={"info-popup"}
                    src="/images/static/info.svg"
                    alt=""
                  />
                </p>

                <div className={"action-btns"}>
                  <img
                    onClick={() => {
                      setCabanaType("premium");
                      setShowMapModal(true);
                    }}
                    className={"cabana-popup"}
                    src="/images/static/cabana-map.svg"
                    alt=""
                  />
                  <div
                    onClick={() => {
                      setCabanaType("premium");
                      setShowStandard(true);
                    }}
                    className="arrow-button"
                  >
                    <img
                      src={"/images/static/right-arrow.svg"}
                      alt="right-arrow"
                    />
                    {showStandard ? (
                      <CabanaModal
                        modalShow={showStandard}
                        setModalShow={setShowStandard}
                        ticketDetails={ticketDetails}
                        cabanaType={cabanaType}
                        setCabanaType={setCabanaType}
                        selectedCabana={selectedCabana}
                        setSelectedCabana={setSelectedTickets}
                        availabilityCheck={availabilityCheck}
                        cabanaConfirmHandler={cabanaConfirmHandler}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {/*      {*/}
            {/*          ticketDetails*/}
            {/*              ? ticketDetails.map((e) => {*/}
            {/*                  const nameText = e?.Name?._text.toLowerCase();*/}
            {/*                  if (!nameText.includes('adult') && !nameText.includes('child')) {*/}
            {/*                      return (*/}
            {/*                          <div className={'ticketing__info__details'} key={e.PLU._text}>*/}
            {/*                              <div className={'ticketing__info__details__list'}>*/}
            {/*                                  <div className={'ticketing__info__details__list__type d-flex'}>*/}
            {/*<span>*/}
            {/*  <h4>{e?.Name?._text}</h4>*/}
            {/*  <p>{e?.Description?._text}</p>*/}
            {/*</span>*/}
            {/*                                      <p className={'price'}>BDT{parseInt(e?.Price?._text).toLocaleString('en-US')}</p>*/}
            {/*                                  </div>*/}
            {/*                              </div>*/}
            {/*                              <div className={'ticketing__info__details__btn'}>*/}
            {/*                                  <div className="increase-section__button">*/}
            {/*                                      <div*/}
            {/*                                          title={adultChildTicketSelected ? '' : 'Please add full day entry ticket'}>*/}
            {/*                                          <input*/}
            {/*                                              type="button"*/}
            {/*                                              value="-"*/}
            {/*                                              onClick={() => {*/}
            {/*                                                  if (adultChildTicketSelected) {*/}
            {/*                                                      handleDecreaseQuantity(e?.PLU?._text)*/}
            {/*                                                  }*/}
            {/*                                              }}*/}
            {/*                                              disabled={selectedTickets[e?.PLU?._text] === 0 || !selectedTickets[e?.PLU?._text] || !adultChildTicketSelected}*/}
            {/*                                          />*/}
            {/*                                      </div>*/}
            {/*                                      <span>{selectedTickets[e?.PLU?._text] || 0}</span>*/}
            {/*                                      <div*/}
            {/*                                          title={adultChildTicketSelected ? '' : 'Please add full day entry ticket'}>*/}
            {/*                                          <input*/}
            {/*                                              type="button"*/}
            {/*                                              value="+"*/}
            {/*                                              disabled={!adultChildTicketSelected}*/}
            {/*                                              onClick={() => {*/}
            {/*                                                  if (adultChildTicketSelected) {*/}
            {/*                                                      handleIncreaseQuantity(e?.PLU?._text)*/}

            {/*                                                  }*/}
            {/*                                              }}*/}
            {/*                                          />*/}
            {/*                                      </div>*/}

            {/*                                  </div>*/}
            {/*                              </div>*/}
            {/*                          </div>*/}
            {/*                      );*/}
            {/*                  } else {*/}
            {/*                      return null; // Skip rendering for items with 'adult' or 'child'*/}
            {/*                  }*/}
            {/*              })*/}
            {/*              : null*/}
            {/*      }*/}
          </div>
        </div>
      </div>

      {/*modal on click cabana info icon*/}
      <Modal show={show} onHide={handleClose} className={"cabana-info-modal"}>
        <Modal.Body>
          {/*cabana-view.jpg*/}

          <img
            onClick={handleClose}
            className="close-btn"
            src="/images/static/close-white.svg"
            alt=""
          />

          <div className="cabana-info-modal__inner">
            <div className="cabana-info-modal__inner__img">
              {checkVip === "vip" ? (
                <img src="/images/dynamic/premium-cabana-min.jpg" alt="" />
              ) : (
                <img src="/images/dynamic/regular-cabana-min.jpg" alt="" />
              )}
            </div>
            <div className="cabana-info-modal__inner__content">
              <h4> {checkVip === "vip" ? "VIP Cabana" : "Premium Cabanas"} </h4>
              <p>
                {checkVip === "vip"
                  ? `Indulge in the ultimate vacation experience at VIP Cabana. Immerse yourself in a
                            haven of luxury and relaxation, where every moment is a celebration of opulence. Lounge
                            in style, soak up the sun, and revel in personalised service, surrounded by exclusive
                            amenities. Whether it’s a romantic escape or a well-deserved retreat, your dream holiday
                            awaits in this paradise of unrivalled bliss.`
                  : "Elevate your water park adventure with our Premium cabanas. Enjoy privacy, shade, and personalized service while you bask in the excitement of Mana Bay. Whether it's a family gathering or a day with friends, our cabanas ensuring your day is both relaxing and unforgettable."}
              </p>

              <img src="/images/static/popup-bottom-shapw.svg" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </StyledTickets>
  );
};

export default Tickets;

const StyledTickets = styled.div`
  .title {
    border: 1px solid #e9e9e9;
  }

  .ticketing {
    display: flex;

    background-color: #ffffff;

    box-shadow: rgba(0, 0, 0, 0.08) 0 5px 30px;

    .ticketing-top {
      display: inline-flex;
      width: 100%;
      padding: 20px 30px 20px 20px;
      border-bottom: 1px solid #e8e8e8;
      @media (max-width: 767px) {
        padding: 20px 10px;
      }
    }

    &__img {
      margin-right: 20px;

      img {
        height: 100%;
        width: 110px;
      }
    }

    &__info {
      //width: 100% !important;
      width: calc(100% - 80px);

      &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #f9f9f9;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          padding-top: 15px;
        }

        &__list {
          &__type {
            span {
              display: block;
              //margin-right: 50px;

              h4 {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: #1a1818;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }

              p {
                color: #989898;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }
            }

            @media (max-width: 1150px) {
              display: block !important;
              .price {
                margin: 0;
              }
            }
          }
        }

        &__btn {
          align-self: flex-end;
          align-items: center;
          display: contents;

          input {
            border-radius: 50%;
            border: none;
            height: 30px;
            width: 30px;
            background: #e8e8e8;
            display: flex;
            padding: 0;
            align-items: center;
            justify-content: center;
          }

          .increase-section__button {
            width: 150px;
            justify-content: space-between;
            padding: 0 5px;
            background-color: white;
            border: 1px solid #e8e8e8;
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
              width: 108px;
              height: 40px;
            }

            span {
              color: #1a1818;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }

        .price {
          color: #92278f;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          margin-left: 50px;
          margin-right: 50px;
          display: flex;
          align-items: center;
          @media (max-width: 1150px) {
            margin: 10px 0;
          }
        }
      }
    }
  }

  .add-ons {
    display: inline-flex;
    background-color: #ffffff;
    padding: 20px 30px 20px 20px;
    //box-shadow: rgba(0, 0, 0, 0.08) 0 5px 30px;
    width: 100%;
    @media (max-width: 767px) {
      padding: 20px 10px;
    }

    &__img {
      margin-right: 20px;
      width: 70px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__info {
      width: calc(100% - 70px);

      &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #f9f9f9;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          padding-top: 15px;
        }

        &__list {
          &__type {
            span {
              display: block;
              //margin-right: 50px;

              h4 {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: #1a1818;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }

              p {
                color: #989898;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }
            }

            @media (max-width: 1150px) {
              display: block !important;
              .price {
                margin: 0;
              }
            }
          }
        }

        &__btn {
          align-self: flex-end;
          align-items: center;
          display: contents;

          input {
            border-radius: 50%;
            border: none;
            height: 30px;
            width: 30px;
            background: #e8e8e8;
            padding: 0px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 767px) {
              padding-top: 2px;
            }
          }

          .increase-section__button {
            width: 150px;
            justify-content: space-between;
            padding: 0 5px;
            background-color: white;
            border: 1px solid #e8e8e8;
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
              width: 108px;
              height: 40px;
            }

            span {
              color: #1a1818;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }

        .price {
          color: #92278f;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          margin-left: 50px;
          margin-right: 50px;
          display: flex;
          align-items: center;
          @media (max-width: 1150px) {
            margin: 10px 0;
          }
        }
      }
    }

    .guest-entry {
      cursor: pointer;
      color: #1a1818;
      font-size: 20px;
      font-weight: 400;
      padding: 10px 0px 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      //margin-bottom: 15px;

      &:not(:nth-last-child(1)) {
        border-bottom: 1px solid #f9f9f9;
      }

      p {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }

      small {
        color: ${hover};
        margin-top: 2px;
        display: block;
      }

      .action-btns {
        display: flex;

        .cabana-popup {
          margin-right: 15px;
        }

        @media (max-width: 420px) {
          width: 100%;
          margin-top: 10px;
          .cabana-popup {
            height: 30px;
          }
        }
      }

      .arrow-button {
        border: none;
        background-color: #e8e8e8;
        border-radius: 50%;
        line-height: 0;
        //padding: 9.5px 12px;
        outline: none;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 420px) {
          width: 30px;
          height: 30px;
        }
      }

      .info-popup {
        padding-left: 10px;
        height: 13px;
        margin-top: -18px;
      }
    }
  }

  .cabana {
    margin-top: 50px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }
`;
