import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import Img from "../Img";
import Button from "../Button";
import {SwiperSlide} from "swiper/react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


const MyComponent = ({data}) => {
    gsap.registerPlugin(ScrollTrigger);

    // parallax animation
    useEffect(() => {
        gsap.utils.toArray(".boxxxx").forEach((section, i) => {
            gsap.to(section, {
                yPercent: -30,
                scrollTrigger: {
                    trigger: section,
                    // start: () => i ? "top bottom" : "top top",
                    // end: "bottom top",
                    scrub: true,
                    // invalidateOnRefresh: true // to make it responsive
                }
            })
        });
        gsap.utils.toArray(".boxxx").forEach((section, i) => {
            gsap.to(section, {
                yPercent: -20,
                scrollTrigger: {
                    trigger: section,
                    // start: () => i ? "top bottom" : "top top",
                    // end: "bottom top",
                    scrub: true,
                    // invalidateOnRefresh: true // to make it responsive
                }
            })
        });
    }, [data])


    // circle animation
    useEffect(() => {
        // document.querySelector()
        gsap.to('.anim-circle.even', {
            duration: 1,
            // transform: 'rotate(360deg)',
            x: '-60%',
            rotate: '360deg',
            scrollTrigger: {
                trigger: '.zigzag',
                // markers: true,
                scrub: true
            }
        })
        // document.querySelector()
        gsap.to('.anim-circle.odd', {
            duration: 1,
            // transform: 'rotate(360deg)',
            x: '60%',
            rotate: '360deg',
            scrollTrigger: {
                trigger: '.zig-zig-rotate',
                // markers: true,
                scrub: true
            }
        })
    }, [data])


    return (
        <StyledComponent className="pt-200 parallax bg zig-zig-rotate">
            {/*<img src={'/images/dynamic/water-wave.jpg'}/>*/}
            <Container>
                {
                    data && data?.length > 0 &&
                    data?.map((e) => {
                        return (
                            <Row className="zigzag" key={e}>
                                <img className={'anim-circle even'} src="/images/static/anim-circle.svg" alt=""/>
                                <img className={'anim-circle odd'} src="/images/static/anim-circle.svg" alt=""/>
                                <Col md={4} className={"zigzag__text txtbx"}>
                                    <Title text={e?.title} color={"#fff"} icon={e?.svg}/>
                                    <p className={'split-up'}>{e?.desc}
                                    </p>
                                    <div className={"buttons"}>
                                        {e?.first_button &&
                                            <Button text={e?.first_button} src={e?.first_url}/>
                                        }
                                        {e?.second_button &&
                                            <Button text={e?.second_button} src={e?.second_url}/>
                                        }


                                    </div>
                                </Col>
                                <Col md={{span: 7, offset: 1}} className={"zigzag__img"}>
                                    <div className={"zigzag__img-wrapper"}>
                                        <Img src={e?.main_image}/>
                                        <div className={"zigzag__img__parallax__bottom-img boxxx"}>
                                            <Img src={e?.bottom_image}/>
                                        </div>
                                        <div className={"zigzag__img__parallax__bottomleft-img boxxxx"}>
                                            <Img src={e?.left_image}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                }


            </Container>
        </StyledComponent>

    );
};

const StyledComponent = styled.section`
  //overflow: hidden;
  background: url("/images/dynamic/water-wave.jpg");
  position: relative;
  overflow: hidden;

  .zigzag {
    padding-bottom: 335px;

    .zigzag__img {
      .zigzag__img-wrapper {
        position: relative;
        padding-top: calc(601 / 670 * 100%);
      }

      &__parallax {
        position: relative;

        &__bottom-img {
          position: absolute;
          width: 280px;
          height: 270px;
          bottom: -130px;
          right: 0;

          &::after {
            content: '';
            top: 15px;
            right: -10px;
            position: absolute;
            bottom: -10px;
            background: #fff;
            opacity: 0.8;
            width: 10px;
          }

          &::before {
            content: "";
            top: 40px;
            position: absolute;
            bottom: -10px;
            background: #fff;
            opacity: 0.8;
            width: 265px;
            right: 0;
          }
        }

        &__bottomleft-img {
          position: absolute;
          width: 270px;
          height: 270px;
          bottom: 50px;
          left: -135px;
        }
      }
    }

    .zigzag__text {
      p {
        color: #fff;
        //font-size: 14px;
        line-height: 20px;
        margin-top: 38px;
        margin-bottom: 58px;
        font-weight: 300;
      }

      .buttons {
        display: flex;
        flex-direction: column;

        .dc-btn {
          margin-bottom: 20px;
        }
      }

    }

    &:nth-child(even) {
      //margin-top: 200px;
      flex-direction: row-reverse;

      .zigzag__text {
        margin-right: 0 !important;
        margin-left: 8.333333% !important;
        text-align: right !important;

        .buttons {
          align-items: end;
        }

        .title {
          h2 {
            &::after {
              left: unset !important;
              right: 0 !important;
            }
          }
        }
      }

      .zigzag__img {
        margin-left: 0 !important;

        &__img-wrapper {
          position: relative;
          padding-top: calc(601 / 670 * 100%);
        }

        &__parallax {
          position: relative;

          &__bottom-img {
            position: absolute;
            width: 280px;
            height: 270px;
            bottom: -130px;
            left: 0;

            &::after {
              content: '';
              top: 15px;
              left: -10px;
              position: absolute;
              bottom: -10px;
              background: #fff;
              width: 10px;
            }

            &::before {
              content: "";
              top: 0;
              position: absolute;
              bottom: -10px;
              background: #fff;
              width: 265px;
              left: 0;
            }
          }

          &__bottomleft-img {
            position: absolute;
            width: 280px;
            height: 270px;
            bottom: 50px;
            right: -135px !important;
            left: unset;
          }
        }
      }
    }

    @media (max-width: 767px) {
      &:last-child {
        padding-bottom: 160px;
      }

      //overflow: hidden;
      padding-bottom: 160px;
      .zigzag__img {
        margin-right: 8.33%;
        margin-top: 60px;
        position: relative;
        //margin-right: 8.33%;
        &__parallax {
          position: relative;

          &__bottom-img {
            position: absolute;
            width: 160px;
            height: 155px;
            bottom: -75px;
            right: -20px;

            &::after {
              content: '';
              top: 15px;
              right: -10px;
              position: absolute;
              bottom: -10px;
              background: #fff;
              width: 10px;
            }

            &::before {
              content: "";
              top: 0;
              position: absolute;
              bottom: -10px;
              background: rgb(255, 255, 255);
              width: 148px;
              right: 0;
            }
          }

          &__bottomleft-img {
            opacity: 0;
          }
        }
      }

      &:nth-child(even) {
        .zigzag__img {
          margin-left: 8.33% !important;
          margin-right: 0 !important;

          &__parallax {
            position: relative;

            &__bottom-img {
              position: absolute;
              width: 160px;
              height: 155px;
              bottom: -75px;
              left: -20px;

              &::after {
                content: '';
                top: 15px;
                right: -10px;
                position: absolute;
                bottom: -10px;
                background: #fff;
                width: 10px;
              }

              &::before {
                content: "";
                top: 0;
                position: absolute;
                bottom: -10px;
                background: rgb(255, 255, 255);
                width: 148px;
                right: 0;
              }
            }

            &__bottomleft-img {
              opacity: 0;
            }
          }
        }
      }

      .zigzag__text {
        //margin-right: 8.33%;
        .title {
          h2 {
            margin: 0;
          }
        }

        p {
          margin-top: 30px;
          margin-bottom: 40px;
        }

        .buttons {
          display: flex;
          flex-direction: inherit;
          //justify-content: space-between;
          gap: 15px;

          .dc-btn {
            //width: 100%;
            &:nth-child(2) {
              a {
                background-color: rgb(252, 185, 64);

                span {
                  color: #1A1818 !important;
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: 991px) and (max-width: 1490px) {
      .zigzag__img {
        &__parallax {
          &__bottom-img {
            position: absolute;
            width: 240px;
            height: 230px;
            bottom: -110px;
            right: 0px;

            &::after {
              content: '';
              top: 15px;
              right: -10px;
              position: absolute;
              bottom: -10px;
              background: #fff;
              width: 10px;
            }

            &::before {
              content: "";
              top: 0;
              position: absolute;
              bottom: -10px;
              background: rgb(255, 255, 255);
              width: 225px;
              right: 0;
            }
          }

          &__bottomleft-img {
            width: 230px;
            height: 230px;
            bottom: 50px;
            left: -110px;
          }
        }
      }

      &:nth-child(even) {
        .zigzag__img {
          &__parallax {
            &__bottom-img {
              position: absolute;
              width: 240px;
              height: 230px;
              bottom: -110px;

              &::after {
                content: '';
                top: 15px;
                right: -10px;
                position: absolute;
                bottom: -10px;
                background: #fff;
                width: 10px;
              }

              &::before {
                content: "";
                top: 0;
                position: absolute;
                bottom: -10px;
                background: rgb(255, 255, 255);
                width: 225px;
                right: 0;
              }
            }

            &__bottomleft-img {
              width: 230px;
              height: 230px;
              bottom: 50px;
              right: -110px !important;
            }
          }
        }
      }
    }
  }

  .anim-circle {
    position: absolute;
    top: calc(30% - 70px);
    left: 0;
    transform: translateX(-100%);
    height: 200px;
    z-index: 6;

    &:nth-of-type(2) {
      top: calc(60% - 70px);
      left: auto;
      right: 0;
      transform: translateX(100%);
    }

    @media (max-width: 768px) {
      height: 90px;
      &:nth-of-type(1) {
        top: 35% !important;
      }

      &:nth-of-type(2) {
        top: 65% !important;
      }
    }
  }


`;

export default MyComponent;
