import React from 'react';
import styled from 'styled-components';
import {text} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const Title = ({
                   text,
                   fontSize,
                   fontWeight,
                   color,
                   letterSpacing,
                   lineHeight,
                   textTransform,
                   margin,
                   padding,
                   borderColor,
                   bigTitle,
                   marginMb,
                   icon
               }) => {


    return (

    <StyledTitle className={`title fade-up split-up`}
                     fontSize={fontSize}
                     fontWeight={fontWeight}
                     color={color}
                     lineHeight={lineHeight}
                     LetterSpacing={letterSpacing}
                     textTransform={textTransform}
                     margin={margin}
                     marginMb={marginMb}
                     padding={padding}
                     bigTitle={bigTitle}
                     borderColor={borderColor}>

            {icon ?
                <div className="icon">
                    <img src={icon} alt=""/>
                </div> :

                <div className="icon">
                    <img src={'images/static/titleicon/titleicon.svg'} alt=""/>
                </div>

            }


            {text ? <h2 className={'fade-up'}>{ReactHtmlParser(text)} </h2> : ''}


        </StyledTitle>

    )
};


const StyledTitle = styled.div`
  position: relative;
  width: 100%;


  .icon {
    margin-bottom: 20px;
  }

  h2 {
    font-weight: ${props => props.fontWeight || '300'};
    margin: ${props => props.margin || '0px'};
    color: ${props => props.color || text};
    text-transform: ${props => props.textTransform || 'capitalize'};
    font-size: ${p => p.bigTitle ? '60' : p.fontSize || '40'}px;
    line-height: ${p => p.bigTitle ? '60' : p.lineHeight || '44'}px;

    span {
      color: #FCB940;
    }

  }


  @media (max-width: 767px) {
    h2 {
      font-size: ${p => p.bigTitle ? '32' : '24'}px;
      line-height: ${p => p.bigTitle ? '36' : '30'}px;
      margin: ${p => p.marginMb || '0 0 40px 0'};
    }
  }
`;


export default Title;














