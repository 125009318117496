export const apiEndPoints = {
  PAGE_DATA: "page-data",
  POSTS: "posts",
  SIGNUP: "wp-json/customer/add",
  GUEST_SIGNUP: "wp-json/customer/signin-send-otp",
  LOGIN: "wp-json/customer/login",
  OTP_LOGIN: "wp-json/customer/ecom-signin-send-otp",
  OTP_LOGIN_VERIFY: "wp-json/customer/signin-verify-otp",
  GUEST_LOGIN: "wp-json/customer/signin-verify-otp",
  GET_PROFILE: "wp-json/customer/get_profile",
  UPDATE_PROFILE: "wp-json/customer/update-profile",
  GET_CATEGORIES: "wp-json/categories/list",
  PRODUCT_LIST: "wp-json/products/category-details",
  PRODUCT_DETAIL: "wp-json/product/by-slug",
  //   BKASH_GRANT: "wp-json/payment/bkash_grant",
  ADD_TO_CART: "wp-json/carts/add",
  CART: "wp-json/carts/all",
  CART_UPDATE: "wp-json/carts/update",
  PLACE_ORDER: "wp-json/carts/order-place",
  TICKET_PLACE_ORDER: "wp-json/carts/ticket_order_place",
  TICKET_ORDER_VERIFY: "wp-json/carts/ticket_details",
  ORDER_LIST: "wp-json/orderinfo/list",
  ORDER_DETAIL: "wp-json/orderinfo/details",
  FORGOT_PASSWORD: "wp-json/customer/forget",
  PASSWORD_SET: "wp-json/customer/forget-password-set",
  HOME: "wp-json/page/home",
  INTERACTIVE_MAP: "wp-json/page-data/single",
  TERMS_CONDITIONS: "wp-json/page/terms-conditions",
  PRIVACY_POLICY: "wp-json/page/privacy-policy-2",
  ABOUT_US: "wp-json/page-data/single?slug=about-us-2",
  REFUND: "wp-json/page/refund-policy",
  RIDE: "wp-json/page/rides",
  // ATTRACTION: 'wp-json/page/attraction',
  ATTRACTION: "wp-json/get_multipop_post/by-category",
  UNWIND: "wp-json/page/unwind",
  TASTE: "wp-json/page/taste",
  FAQ: "wp-json/page/faq",
  EVENTS: "wp-json/page/events",
  EVENT_DETAILS: "wp-json/get_event_post/single?slug=",
  EVENTS_LIST: "wp-json/get_event_post/all",
  BLOGS: "wp-json/page/blogs",
  BLOG_DETAILS: "wp-json/blogs",
  EVENT_TICKET_PURCHASE: "wp-json/carts/event_ticket_order_place",
  TICKET_TOKEN: `<?xml version="1.0"?>
                    <Envelope>
                        <Header>
                            <SourceID>1</SourceID>
                            <MessageID>131</MessageID>
                            <MessageType>Authenticate</MessageType>
                            <TimeStamp>2009-06-25 14:36:30</TimeStamp>
                        </Header>
                        <Body>
                            <Authenticate>
                                <Username>EGALAXY</Username>
                                <Password>Gateway2023</Password>
                                <PasswordEncrypted>NO</PasswordEncrypted>
                            </Authenticate>
                        </Body>
                    </Envelope>`,
};
