import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Img from "../Img";
import Button from "../Button";
import { RiEyeFill, RiEyeCloseFill } from 'react-icons/ri';
import {useDispatch} from "react-redux";
import {Loading} from "../Loading";
import {passwordSet} from "../../api/redux/auth";
import {toast} from "react-toastify";


const MyComponent = () => {
    const [code, setCode] = useState()
    const [password, setPassword] = useState()
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(0)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
        let em = localStorage.getItem('email')
        setEmail(em)
    }, [])


    // password hidden
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const submitHandler = () => {
        if(code && code !== '' && email && email !=='' && password && password !== ''){
            setLoading(true)
            let formData = new FormData()
            formData.append('user_email', email)
            formData.append('new_password', password)
            formData.append('otp_code', code)
            dispatch(passwordSet(formData)).then((res)=>{
                if(res?.payload?.status === 200){
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setLoading(false)
                    localStorage.clear()
                    setTimeout(()=>{
                        history.push('/login')
                    }, 1400)
                } else {
                    setLoading(false)
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
        }

    }



    return (
        <StyledLogIn className='login'>
            <Container fluid style={{paddingLeft: offset + 15 + 'px'}} >
                <Row>
                    <Col md={5}>
                        <div className="login__form">
                            <h2>Update password</h2>
                            <p>We’ve sent a verification code to {email}</p>
                                <div className="form-group-wrap">
                                    <Form className={'form'}>
                                        <div className="flex">
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control type="text" placeholder="Verification Code"
                                                                  name='code'
                                                                  className="number-input"
                                                                  value={code}
                                                                  onChange={event => setCode(event.target.value)}
                                                                  onFocus={(e) => e.target.classList.add('focused')}
                                                                  onBlur={(e) => e.target.classList.remove('focused')}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control
                                                        type={showPassword ? 'text' : 'password'}
                                                        placeholder="Enter Password"
                                                        name='password'
                                                        className="password-input"
                                                        value={password}
                                                        onChange={(e)=>{setPassword(e.target.value)}}
                                                        onFocus={(e) => e.target.classList.add('focused')}
                                                        onBlur={(e) => e.target.classList.remove('focused')}
                                                    />
                                                    <button
                                                        type='button'
                                                        onClick={togglePasswordVisibility}
                                                        className="password-toggle-button"
                                                    >
                                                        {showPassword ?

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.711" viewBox="0 0 16 13.711">
                                                                <path id="Icon_ionic-md-eye-off" data-name="Icon ionic-md-eye-off" d="M10.252,7.385a3.627,3.627,0,0,1,3.638,3.61,3.473,3.473,0,0,1-.261,1.321l2.125,2.107a8.536,8.536,0,0,0,2.5-3.428,8.617,8.617,0,0,0-10.9-4.91L8.92,7.646A3.6,3.6,0,0,1,10.252,7.385ZM2.978,5.418l1.66,1.646.336.332a8.5,8.5,0,0,0-2.724,3.6A8.629,8.629,0,0,0,13.437,15.8l.307.3,2.132,2.107.925-.918L3.9,4.5ZM7,9.406l1.128,1.118a2.045,2.045,0,0,0-.057.468,2.171,2.171,0,0,0,2.182,2.164,2.052,2.052,0,0,0,.471-.057l1.128,1.118a3.619,3.619,0,0,1-5.238-3.228A3.567,3.567,0,0,1,7,9.406Zm3.135-.561,2.292,2.274.014-.114a2.171,2.171,0,0,0-2.182-2.164Z" transform="translate(-2.25 -4.5)" fill="#ccc"/>
                                                            </svg>
                                                            : <RiEyeFill />}
                                                    </button>
                                                </Form.Group>

                                            </div>
                                        </div>
                                    </Form>
                                </div>

                                <div className="form__bottom">
                                    <Button text={'Reset Password'}
                                            borderColor={'#92278F'}
                                            color={'#92278F'}
                                            onClick={submitHandler}
                                            hoverfill={'#92278F'}/>

                                    {/*<Link className={'text'} to={''} >Resend Verifications</Link>*/}
                                </div>

                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}} className={'pr-0'} >
                        <div className="login__image">
                            <Img src={'/images/dynamic/login/login.jpg'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledLogIn>
    )
        ;
};
const StyledLogIn = styled.section`
  .login__form {
    padding: 100px 0 50px 0;

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 300;
      color: #1A1818;
      margin-bottom: 10px;
    }  
    p {
         font-size: 14px;
         line-height: 16px;
         font-weight: 300;
         color: #1A1818;
       }
    
    .form-group-wrap{
      margin: 40px 0;
    }
    
  }
  .login__image {
    position: relative;
    padding-top: calc(651 / 598 * 100%);
    height: 100%;
  }
  
  .form__bottom{
    display: flex;
    flex-direction: column;
    
    .dc-btn{
      margin-bottom: 20px;
    }
    .text{
      color: #434343;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      border-bottom: 1px solid #707070;
      width: max-content;
      
    }
  }
  
  .password-input {
    position: relative;
  }
  .password-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }
  .password-toggle-button {
    position: absolute;
    top: 45%;
    right: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .number-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }
  
  
  
  @media(max-width: 767px){
    .row {
      flex-direction: column-reverse;
    }
    .login__form{
      padding: 30px 0 50px 0;

      .login__form__container{

        gap: 20px;
        .form__bottom{
          display: grid;
          
        }
      }
      
    }
  }
  
  
  
`;
export default MyComponent;
