import React, {useRef, useState} from 'react';
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Col, Container, Row, Card, Modal} from "react-bootstrap";
import Calendar from "react-calendar";
import ButtonA from "./ButtonA";

const FeaturesBenefits = ({date, data, setDate}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    let calenderItemRef = useRef(null);

    const handleCalender = (Date, value) => {
        setDate(Date)

    }

    return (
        <StyledComponent className='features_aminites pt-120 '>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className={'detail'}>
                            <div className={'visiting d-flex'}>
                                <div className={'visiting-title'}>
                                    <h3>Visiting Day</h3>
                                </div>
                                <div className={'visiting-data'}>
                                    <span>
                                        <p>Cavana Status:</p>
                                    </span>
                                    <span className={'status'}>
                                        <ul>
                                       <li>
                                           Not Avaialable
                                       </li>
                                       <li>
                                           Selected
                                       </li>
                                   </ul>
                                    </span>

                                </div>
                            </div>
                            <div className={'calendar'}>
                                <div className="calender-all-item" ref={calenderItemRef}>
                                    <Calendar
                                        onChange={handleCalender}
                                        value={date}
                                        // formatMonthYear={(locale, date) =>(date, ['Jan 20121', 'Feb 2021'])}
                                        formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
                                        prev2Label={''}
                                        next2Label={''}
                                        tileClassName="tile--active"
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="process-btn d-flex justify-content-center">
                            {/*<ButtonA*/}
                            {/*    color="#92278F"*/}
                            {/*    borderColor="#92278F"*/}
                            {/*    hoverfill="#92278F"*/}
                            {/*    text="Continue"*/}
                            {/*    onClick={() => handleContinue('ticket')}*/}
                            {/*/>*/}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`

  .detail {
    margin-top: 20px;


    .visiting {
      justify-content: space-between;
      background-color: #E9E9E9;
      padding: 20px 17px;

      .visiting-title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
        }
      }

      .visiting-data {
        display: flex;
        justify-content: space-between;
        align-self: center;
        gap: 20px;

        p {
          color: #1A1818;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          margin-right: 16px;
        }

        .status {
          ul {
            display: flex;
            justify-content: space-between;
            //gap: 20px;

            li {
              position: relative;
              color: #1A1818;
              font-size: 12px;
              line-height: 14px;
              font-weight: 300;
              margin-right: 20px;

              &:last-child {
                margin-right: 0;

                &::before {
                  position: absolute;
                  content: "";
                  top: -5px;
                  left: -25px;
                  border-radius: 50%;
                  background-color: rgb(90, 209, 209);
                  opacity: 100%;
                }
              }

              &::before {
                position: absolute;
                content: "";
                top: -5px;
                left: -25px;
                padding: 10px !important;
                //left: -40px;
                display: inline-block;
                width: 5px !important;
                height: 5px !important;
                //margin-left: 20px;
                margin-left: 0 !important;
                border-radius: 50%;
                opacity: 50%;
                background-color: #EC1B34;
              }

              &:after {
                display: none !important;
              }
            }
          }
        }
      }
    }

    .calendar {
      background-color: #fff;
      //padding: 40px 33px 53px 50px;

      .calender-all-item {

        .react-calendar {
          width: 100% !important;
          max-width: 100% !important;
          border: none;

          .react-calendar__navigation {
            margin: 25px;

            .react-calendar__navigation__prev2-button {
              display: none;
            }
          }
        }

        .react-calendar__navigation__label__labelText {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
        }

        .react-calendar__navigation__prev-button {
          font-size: 0;
          background: none;
          border: none;
          cursor: pointer;
          position: relative;
        }

        .react-calendar__navigation__prev-button::before {
          content: ""; /* Required for pseudo-elements */
          display: inline-block; /* Ensure the element takes up space */
          width: 40px; /* Set the width of your custom arrow container (including the background) */
          height: 40px; /* Set the height of your custom arrow container (including the background) */
          background-color: #ffffff; /* Set the background color for the circular background */
          border-radius: 50%; /* Make the background circular */
          position: absolute; /* Position the pseudo-element absolutely within the button */
          top: 50%; /* Vertically center the pseudo-element */
          left: 50%; /* Horizontally center the pseudo-element */
          transform: translate(-50%, -50%);
          box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08); /* Move the pseudo-element to center it properly */
        }


        .react-calendar__navigation__prev-button::after {
          content: "";
          display: inline-block;
          height: 16px;
          width: 8px;
          background-image: url('/images/static/prev.svg');
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-60%, -50%);
        }

        .react-calendar__navigation__next-button {
          font-size: 0;
          background: none;
          border: none;
          cursor: pointer;
          position: relative;
        }

        .react-calendar__navigation__next-button::before {
          content: ""; /* Required for pseudo-elements */
          display: inline-block; /* Ensure the element takes up space */
          width: 40px; /* Set the width of your custom arrow container (including the background) */
          height: 40px; /* Set the height of your custom arrow container (including the background) */
          background-color: #ffffff; /* Set the background color for the circular background */
          border-radius: 50%; /* Make the background circular */
          position: absolute; /* Position the pseudo-element absolutely within the button */
          top: 50%; /* Vertically center the pseudo-element */
          left: 50%; /* Horizontally center the pseudo-element */
          transform: translate(-50%, -50%);
          box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08); /* Move the pseudo-element to center it properly */
        }

        .react-calendar__navigation__next-button::after {
          content: "";
          display: inline-block;
          height: 16px;
          width: 8px;
          background-image: url('/images/static/next.svg');
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-60%, -50%);
        }


        .react-calendar__month-view__weekdays {
          color: #1A1818;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
          margin: 0 50px;
        }

        .react-calendar__tile--now {
          color: #1A1818;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: "Bonn BF";
        }

        .react-calendar__month-view__days {
          margin-left: 50px;
          margin-right: 50px;
        }

        .react-calendar__month-view__days__day {
          color: #1A1818;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
          padding: 13px 13px;
          margin-bottom: 15px;
        }

        .react-calendar__month-view__days__day:disabled {
          padding: 10px;
          color: #1A1818 !important;
          font-size: 24px;
          line-height: 29px;
          position: relative;
        }

        .react-calendar__month-view__days__day:disabled::after {
          content: ""; /* Required for pseudo-elements */
          display: inline-block; /* Ensure the element takes up space */
          width: 50px; /* Set the width of your custom arrow container (including the background) */
          height: 50px; /* Set the height of your custom arrow container (including the background) */
          background-color: #EC1B34; /* Set the background color for the circular background */
          border-radius: 50%; /* Make the background circular */
          position: absolute; /* Position the pseudo-element absolutely within the button */
          top: 50%; /* Vertically center the pseudo-element */
          left: 50%; /* Horizontally center the pseudo-element */
          transform: translate(-50%, -50%);
          opacity: 50%;
          color: #fff;
          //box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);/* Move the pseudo-element to center it properly */
        }


        .react-calendar__month-view__days__day--neighboringMonth {
          display: none;
        }

        .react-calendar__month-view__days {
          //gap: 16px;

          .react-calendar__month-view__weekdays__weekday {
            //padding: 35px 58px;
          }

          .react-calendar__tile {
            background-color: #fff;
          }


          .react-calendar__tile--now {
            border-radius: 50%;
            background-color: unset !important;
            //background: rgb(90,209,209) !important;
            padding: 23px;
            color: white;
          }

          .react-calendar__tile {
            //padding: 35px 58px;
            color: #989898;
            font-size: 24px;
            line-height: 29px;
            font-weight: 400;
          }

          .react-calendar__tile--active {
            background: rgb(90, 209, 209) !important;
            border-radius: 50%;
            padding: 20px;
            color: white;
          }

          .react-calendar__navigation__prev2-button {
            display: none;
          }
        }
      }
    }

  }
`

export default FeaturesBenefits;