import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useParams} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Col, Container, Row} from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner";
import MomentoList from "../../components/momento/MomentoList";
import {useDispatch, useSelector} from "react-redux";
import {fetchCategories, fetchProductList} from "../../api/redux/products";
import {Loading} from "../../components/Loading";

const MyComponent = () => {
    const routerParams = useParams()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [catSlug, setCatSlug] = useState()
    const [catFilter, setCatFilter] = useState()
    let categories = useSelector(state => state?.products?.categories);
    let total = useSelector(state => state?.products?.total);


    useEffect(() => {
        dispatch(fetchCategories())
        setLoading(true)
        const {slug} = routerParams
        setCatSlug(slug)
        let params = {
            slug: slug,
            limit: 10,
            page: 1
        }
        dispatch(fetchProductList(params)).then(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (categories && categories.length > 0) {
            let removeObject = ['uncategorized', 'middle-banner', 'banner']
            setCatFilter(categories.filter((obj) => !removeObject.includes(obj.slug)))
        }
    }, [categories])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Accessories | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                {
                    categories ? categories.length > 0 ?
                        categories.map((e) => {
                            if (e.slug === catSlug) {
                                return <InnerBanner img={e.image_url} title={e.name}/>
                            }
                        }) : null : null
                }
                <MomentoList catFilter={catFilter} total={total} dispatch={dispatch} loading={loading}
                             setLoading={setLoading} catSlug={catSlug} setCatSlug={setCatSlug}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`



`;

export default MyComponent;
