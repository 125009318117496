import React, {useRef} from 'react';
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Col, Container, Row} from "react-bootstrap";
// import Title from "../Title";
// import SubTitle from "../SubTitle";
// import BoxImageWithText from "../BoxImageWithText";
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination, A11y} from "swiper";
import Title from "./Title";
import Button from "./Button";
import Img from "./Img";
// import imge from "/images/dynamic/home/momento03.jpg"

const ParentingTools = ({explore, data}) => {

    return (
        <StyledComponent className={`parenting-tools pt-120 pb-120 `}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="headings">
                            <div className="headings__title">
                                <Title text={data?.title ? data?.title : 'Happening Now'} color={'#1A1818'} fontWeight={'300'} fontSize={'40'}
                                       icon={data?.svg ? data?.svg :  'images/static/titleicon/titleicon.svg'}/>
                            </div>
                            {
                                explore ?
                                    <div className="headings__btn">
                                        <Button text={'Explore All'} borderColor={'#1A1818'} color={'#1A1818'}
                                                hoverfill={'#1A1818'} src={'/events'}
                                                hoverborder={'#1A1818'} />
                                    </div>
                                    : ''
                            }


                            <div className="headings__nav-mobile">
                                <ul className={'navigation'}>
                                    <li id={'parenting-prev'}>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                             viewBox="0 0 40 40">
                                            <g id="Slider_Icon" data-name="Slider Icon"
                                               transform="translate(40 40) rotate(180)">
                                                <g id="Ellipse_5" data-name="Ellipse 5" fill="none" stroke="none"
                                                   stroke-width="1">
                                                    <circle cx="20" cy="20" r="19" stroke="#707070"/>
                                                    <circle className={'btnhover'} cx="20" cy="20" r="0" fill="none"/>
                                                </g>


                                                <circle id="Ellipse_268" data-name="Ellipse 268" cx="1" cy="1" r="1"
                                                        transform="translate(19 19)" fill="#ec1b34" opacity="0"/>
                                                <g id="Group_16184" data-name="Group 16184"
                                                   transform="translate(12 25) rotate(-90)">
                                                    <line id="Line_53" data-name="Line 53" y2="15.038"
                                                          transform="translate(5 0)"
                                                          fill="none" stroke="#707070" stroke-linecap="round"
                                                          stroke-width="1"/>
                                                    <g id="g2060" transform="translate(0 16.038) rotate(-90)">
                                                        <path id="path2056" d="M0,0,5.414,5" transform="translate(0 5)"
                                                              fill="none"
                                                              stroke="#707070" stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="1"/>
                                                        <path id="path2058" d="M0,5,5.414,0" transform="translate(0)"
                                                              fill="none"
                                                              stroke="#707070" stroke-linecap="round"
                                                              stroke-linejoin="round"
                                                              stroke-width="1"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>


                                    </li>
                                    <li id={'parenting-next'}>
                                        <svg id="Slider_Icon" data-name="Slider Icon" xmlns="http://www.w3.org/2000/svg"
                                             width="40"
                                             height="40" viewBox="0 0 40 40">
                                            <g id="Ellipse_4" data-name="Ellipse 4" fill="none" stroke="none"
                                               stroke-width="1">
                                                <circle cx="20" cy="20" r="19" stroke="#707070"/>
                                                <circle className={'btnhover'} cx="20" cy="20" r="0" fill="none"/>
                                            </g>
                                            <circle id="Ellipse_268" data-name="Ellipse 268" cx="1" cy="1" r="1"
                                                    transform="translate(19 19)" fill="#ec1b34" opacity="0"/>
                                            <g id="Group_16184" data-name="Group 16184"
                                               transform="translate(12 25) rotate(-90)">
                                                <line id="Line_53" data-name="Line 53" y2="15.038"
                                                      transform="translate(5 0)"
                                                      fill="none" stroke="#707070" stroke-linecap="round"
                                                      stroke-width="1"/>
                                                <g id="g2060" transform="translate(0 16.038) rotate(-90)">
                                                    <path id="path2056" d="M0,0,5.414,5" transform="translate(0 5)"
                                                          fill="none"
                                                          stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1"/>
                                                    <path id="path2058" d="M0,5,5.414,0" transform="translate(0)"
                                                          fill="none"
                                                          stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="1"/>
                                                </g>
                                            </g>
                                        </svg>


                                    </li>
                                </ul>
                            </div>


                        </div>
                    </Col>

                    <div className="desktop-nav">
                        <ul className={'navigation navigation-desktop'}>
                            <li id={'parenting-prev'}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                    <g id="Slider_Icon" data-name="Slider Icon" transform="translate(40 40) rotate(180)">
                                        <g id="Ellipse_5" data-name="Ellipse 5" fill="none" stroke="none" stroke-width="1">
                                            <circle cx="20" cy="20" r="19" stroke="#707070"/>
                                            <circle className={'btnhover'} cx="20" cy="20" r="0" fill="none"/>
                                        </g>


                                        <circle id="Ellipse_268" data-name="Ellipse 268" cx="1" cy="1" r="1"
                                                transform="translate(19 19)" fill="#ec1b34" opacity="0"/>
                                        <g id="Group_16184" data-name="Group 16184"
                                           transform="translate(12 25) rotate(-90)">
                                            <line id="Line_53" data-name="Line 53" y2="15.038" transform="translate(5 0)"
                                                  fill="none" stroke="#707070" stroke-linecap="round" stroke-width="1"/>
                                            <g id="g2060" transform="translate(0 16.038) rotate(-90)">
                                                <path id="path2056" d="M0,0,5.414,5" transform="translate(0 5)" fill="none"
                                                      stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="1"/>
                                                <path id="path2058" d="M0,5,5.414,0" transform="translate(0)" fill="none"
                                                      stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="1"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>


                            </li>
                            <li id={'parenting-next'}>
                                <svg id="Slider_Icon" data-name="Slider Icon" xmlns="http://www.w3.org/2000/svg" width="40"
                                     height="40" viewBox="0 0 40 40">
                                    <g id="Ellipse_4" data-name="Ellipse 4" fill="none" stroke="none" stroke-width="1">
                                        <circle cx="20" cy="20" r="19" stroke="#707070"/>
                                        <circle className={'btnhover'} cx="20" cy="20" r="0" fill="none"/>
                                    </g>
                                    <circle id="Ellipse_268" data-name="Ellipse 268" cx="1" cy="1" r="1"
                                            transform="translate(19 19)" fill="#ec1b34" opacity="0"/>
                                    <g id="Group_16184" data-name="Group 16184" transform="translate(12 25) rotate(-90)">
                                        <line id="Line_53" data-name="Line 53" y2="15.038" transform="translate(5 0)"
                                              fill="none" stroke="#707070" stroke-linecap="round" stroke-width="1"/>
                                        <g id="g2060" transform="translate(0 16.038) rotate(-90)">
                                            <path id="path2056" d="M0,0,5.414,5" transform="translate(0 5)" fill="none"
                                                  stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1"/>
                                            <path id="path2058" d="M0,5,5.414,0" transform="translate(0)" fill="none"
                                                  stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1"/>
                                        </g>
                                    </g>
                                </svg>


                            </li>
                        </ul>
                    </div>

                    <Col>
                        {
                            data?.slider ?
                                <Swiper
                                    modules={[Autoplay, Pagination, Navigation, A11y]}
                                    slidesPerView={1}
                                    // slidesPerGroup={1}
                                    // spaceBetween={30}
                                    loop={true}
                                    speed='2000'
                                    grabCursor={true}
                                    navigation={{
                                        prevEl: '#parenting-prev',
                                        nextEl: '#parenting-next',
                                    }}

                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            // spaceBetween: 30,
                                            slidesPerGroup: 1

                                        },
                                        768: {
                                            slidesPerView: 1,
                                            // spaceBetween: 30,
                                            slidesPerGroup: 1

                                        },
                                        1024: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                            slidesPerGroup: 1

                                        },
                                    }}
                                >

                                    {
                                        data?.slider?.length > 0 &&
                                        data?.slider?.map((e, i)=>{
                                            return(<SwiperSlide key={i}>
                                                <div className="slider-images">
                                                    <Img src={e?.happening} alt={'slider'} />
                                                </div>
                                            </SwiperSlide>)
                                        })
                                    }

                                </Swiper> : ''
                        }

                    </Col>

                    <Col md={12}>
                        <div className="mobile_explore_btn">
                            <Button text={'Explore All'} borderColor={'#1A1818'} src={'/events'}/>
                        </div>
                    </Col>

                </Row>
                <Row>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  //position: relative;
  //z-index: 1;
  
  .headings {
    display: flex;
    justify-content: space-between;
    margin: 0 0 40px 0;
    align-items: flex-end;
    
    &__nav-mobile {
      display: none;
    }


  }

  .headings__btn{
    height: 51px;
    width: 150px;
    a{
      span{
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
      }
    }
  }

  .box-image__single {
    margin-top: 0 !important;
  }

  
  .slider-images{
    position: relative;
    padding-top: calc(500 /1150 * 100%);
  }
  
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .row {
    position: relative;

    .swiper-initialized {
      //margin: 0 15px 0 15px;
    }

    .navigation {
      display: inline-flex;

      li {
        cursor: pointer;

        &:first-child {
          position: absolute;
          left: -4%;
          top: calc(56%);
          z-index: 9;
          transform: translateY(-50%);

        }

        &:last-child {
          position: absolute;
          right: -4%;
          left: unset;
          top: calc(56%);
          z-index: 9;
          transform: translateY(-50%);

        }

        svg {

          .btnhover {
            r: 0px;
            fill: #FCB940;
            transition: 0.7s all ease;
          }

          path{
            transition: 0.7s all ease;
          }

        }

        &:first-child {
          margin-right: 20px;
        }

        &:hover {
          svg {
            .btnhover {
              r: 19px;
              transition: 0.7s all ease;
            }

            g{
              stroke: #FCB940;
            }

            line {
              stroke: white;
            }

            path{
              stroke: white;
              fill: #ffffff;
              transition: 0.7s all ease;
            }
          }
        }


      }
    }
  }
  
  
  .mobile_explore_btn{
    margin-top: 20px;
    display: none;
  }

  @media (max-width: 768px) {

    .headings{
      margin: 0;
    }

    .slider-images{
      padding-top: calc(180 / 345 * 100%);
    }
    
    .headings__btn {
      display: none;
    }
    .headings__nav-mobile{
      display: block;
    }
    .desktop-nav{
      display: none;
    }
    
    .mobile_explore_btn{
      display: block;
      margin-top: 30px;
      .dc-btn{
        //width: 100%;
        height: 60px;
       
        a{
          background: transparent;
          span{
            color: #1A1818;
          }
        }
      }
    }

    //.swiper {
    //  padding-right: 25%;
    //}
    
    .row {
      .navigation {
        padding: 0 15px;
        margin-bottom: 40px;

        li {
          &:first-child {
            position: relative;
            left: unset;
            transform: unset;
            top: 0;
          }

          &:last-child {
            position: relative;
            left: 0px;
            right: unset;
            transform: unset;
            top: 0;
          }
        }
      }
    }
  }
 
`;

export default ParentingTools;
