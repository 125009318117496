import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import Img from "./Img";
import {Link} from "react-router-dom";


const MyComponent = ({
                         title,
                         text,
                         svg,
                         buttonText,
                         image,
                         bgColor,
                         data, src,
                         slug
                     }) => {

    return (

        <StyledComponent className={'rides-zig pb-100'} bgColor={bgColor}>
            <Container>
                <Row>
                    <Col lg={8}>
                        <div className="rides-zig__image reveal">
                            <Img src={image ? image : '/images/dynamic/ride/1.jpg'}/>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="box-svg__single">
                            <div className="box-svg__single__upper">
                                <div className="box-svg__single__upper__text">
                                    <h3 className={'split-up'}>{title ? title : 'Aqualoop'}</h3>
                                    <p className={''}>{text ? text : `Just like the ancient Waturi people who rode the ocean waves on friendly sea turtles, you can
                                                        enjoy this twisting, turning, multi-person slide. On Honu, you can surf the underwater currents
                                                        on a five-passenger raft, speeding through an open flume, then streaking along two massive
                                                        walls.`}</p>
                                </div>
                                <div className="box-svg__single__upper__icon">
                                    <div className={'modal_click'}>
                                        <Link className="button button-small"
                                           to={`/momento/products/${slug}`}
                                        >
                                            <i className="material-icons">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                     viewBox="0 0 12 12">
                                                    <g id="Group_18239" data-name="Group 18239"
                                                       transform="translate(-1009.5 -1473.5)">
                                                        <line id="Line_3896" data-name="Line 3896" y2="10"
                                                              transform="translate(1015.5 1474.5)" fill="none"
                                                              stroke="#fff"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_3897" data-name="Line 3897" y2="10"
                                                              transform="translate(1020.5 1479.5) rotate(90)"
                                                              fill="none"
                                                              stroke="#fff"
                                                              stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </svg>
                                            </i>
                                            <span
                                                className="label-hidden"> {buttonText ? buttonText : 'More Details'} </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-svg__bottom-icon">
                            <img src={svg ? svg : '/images/static/rides/1.svg'} alt=""/>
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>


    );
};
const StyledComponent = styled.section`


  .container {
    overflow: hidden;

    .col-lg-4 {
      background: #FFFFFF;
      overflow: hidden;
    }

    .col-lg-8 {
      background: #FFFFFF;
      overflow: hidden;
    }
  }

  position: relative;

  :nth-child(even) {
    .row {
      flex-direction: row-reverse;
    }
  }

  .rides-zig__image {
    position: relative;
    padding-top: calc(550 / 770 * 100%);
    height: 100%;

  }

  .box-svg__single {

    position: relative;
    background: ${props => props.bgColor || '#92278F'};
    height: 100%;
    padding: 80px 40px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__upper {

      &__text {
        h3 {
          //display: flex;
          //justify-content: center;
          //font-size: 32px;
          //line-height: 40px;
          font-weight: 400;
          margin-bottom: 20px;
          text-align: center;
        }

        p {
          //font-size: 14px;
          //line-height: 21px;
          font-weight: 300;
          text-align: center;

        }
      }

      &__icon {
        margin-top: 130px;
        margin-bottom: 60px;
        display: flex;
        justify-content: center;

        &:hover #clip-path {
          display: none;

        }

        &:hover #More_Details {
          opacity: 1;
          transform: scaleX(1.5);
        }

        &:hover #More_Details_Button {
          transform: scale(1.2);
        }
      }
    }

  }

  .box-svg__bottom-icon {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

    position: absolute;
    //top: 0;
    bottom: 0;


  }

  .box-svg__bottom-icon img {
    flex: 1;
    height: auto;
    object-fit: contain; /* Ensures the image maintains its aspect ratio */
    background-repeat: repeat-x; /* Repeat the image horizontally */
  }


  a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;


  }

  a.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    //padding: 13px ;
    padding: 17px 9px 17px 17px;
    border: 1px solid #FFF;
    border-radius: 50px;
    background: transparent;
    transition: 0.7s all ease;
    height: 47px;
    box-sizing: border-box;

    .material-icons {
      //font-size: 24px;
      display: flex;
      //transition: 0.3s all ease;
    }

    .label-hidden {
      max-width: 0;
      //width: 0;
      opacity: 0;
      max-height: 40px;
      white-space: nowrap;
      transition: 1s all ease;
      color: #ffffff;
      padding-left: 8px;

      display: flex;
      justify-content: space-between;

    }

    &:hover {

      border-radius: 50px;
      padding: 17px 17px 17px 17px;

      .label-hidden {
        max-height: 40px;
        opacity: 1;
        transition: 1s all ease;
        //width: 150px;
        max-width: 150px;
        //padding-right: 10px;


      }
    }
  }

  //modal

  .concern-detail {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    width: 50%;
    background-color: #F9F9F9;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.7s cubic-bezier(0.33, 1, 0.68, 1);
    overflow-y: auto;

    &.show-detail {
      //background-color: rgb(43, 51, 130);
      transform: none;
      opacity: 1;
      width: calc(100% - 30px);
      overflow: hidden;
      height: 100%;

    }

    .close-detail {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 40px;
      top: 40px;

      svg {
        font-size: 18px;
        color: rgb(128, 196, 43);
        z-index: 2;
      }

      &:after {
        content: '';
        background-color: rgb(128, 196, 43);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        margin: auto;
        height: 0px;
        width: 0;
        transition: all .3s ease;
      }

      &:hover {
        &:after {
          height: 100%;
          width: 100%;
        }

        svg {
          color: #ffffff;
        }
      }
    }

    .concern-detail__top {
      //width: 70%;
    }

    .header_wrap__title {
      h2 {
        color: #1A1818;
        //font-size: 40px;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin: 30px 0 0 40px;
      }
    }

    .col-wrap {
      display: flex;
      justify-content: space-between;
      padding: 30px 30px 40px 40px;

      .first-col {
        &__single {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            color: #1b1e21;
            //font-size: 14px;
            //line-height: 25px;
            font-weight: 300;
          }

          h5 {
            color: #1b1e21;
            //font-size: 14px;
            //line-height: 25px;
            font-weight: 400;
          }
        }

      }


    }

    .box-svg {
      background: #92278F;
      position: relative;
      height: 330px;

      &__svg {
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          transform: translate(35%, 20%);
        }


      }

      &__btn {
        position: absolute;
        bottom: 0;
        margin-bottom: 80px;
        margin-left: 55px;
      }

      .ticker-svg {
        position: absolute;
        top: -18px;
        width: 100%;
        height: auto;

        svg {
          width: 100%;
          flex: 1;
          height: auto;
          object-fit: contain; /* Ensures the image maintains its aspect ratio */
          background-repeat: repeat-x;
        }
      }

    }

    .concern-detail__content {
      margin-top: 80px;
      margin-bottom: 150px;
      padding-right: 20px;

      h4 {
        //font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 0 30px 0;
        text-transform: uppercase;
      }

      ul {
        padding-left: 15px;
        margin-bottom: 35px;

        li {
          list-style: disc;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 18px;
        }
      }

    }


    .simplebar-horizontal {
      visibility: hidden !important;
    }

    .simplebar-vertical {
      opacity: 0;
      visibility: hidden !important;
    }

    //.simplebar-content-wrapper {
    //  overflow: hidden !important;
    //  
    //}
    //.simplebar-content-wrapper::-webkit-scrollbar {
    //  width: 0 !important;
    //  background-color: transparent !important;
    //}

    &:hover {
      .simplebar-vertical {
        opacity: 1;
      }
    }


    @media (max-width: 767px) {
      width: 95%;
      //padding: 60px 10px 80px 30px;
      . concern-detail__content {
        margin-top: 40px;

        .file {
          margin-top: 80px;

          input {
            height: 48px;

            &:before {
              top: 15px;
            }
          }
        }

        .dc-btn {
          margin-top: 50px;
        }
      }

      .concern-detail__top {
        width: calc(100% - 20px);

        h2 {
          font-size: 22px !important;
          margin-bottom: 30px;
          line-height: 30px;
        }
      }

      .close-detail {
        top: 20px;
        right: 20px;
      }
    }
  }


  @media (max-width: 767px) {
    .rides-zig__image {
      padding-top: calc(130%);
    }

    .simplebar-mask {
      right: -15px !important;
    }

    .box-svg__btn {
      top: 20%;
      left: 30%;
      margin-bottom: 0;
      margin-left: 0;

    }

    .concern-detail {
      padding: 0 !important;
    }

    .box-svg__svg {
      visibility: hidden;
    }

    .col-wrap {
      flex-direction: column;
    }
  }

  @media (max-width: 992px) {
    .box-svg__single {
      margin-bottom: 100px;
      padding: 80px 20px 0 20px;
    }

    a.button .label-hidden {
      opacity: 1;
      max-width: 150px;
      padding-right: 10px;
    }

    .box-svg__single__upper__icon {
      margin-top: 60px;
    }
  }


  //  

  @media (max-width: 1199px) {
    .box-svg__single {
      padding: 60px 20px 0 20px !important;
    }
  }

`;


export default MyComponent;
