import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Img from "../Img";
import Button from "../Button";
import {RiEyeFill, RiEyeCloseFill} from 'react-icons/ri';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from "react-redux";
import {submitGuestSignIn, submitGuestSignup, submitSignup} from "../../api/redux/auth";
import {Loading} from "../Loading";
import {addToCart, cart} from "../../api/redux/checkout";


const MyComponent = () => {

    const [offset, setOffset] = useState(0)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [agreement, setAgreement] = useState(false)
    const [newsLetter, setNewsLetter] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const [address, setAddress] = useState()
    const [postcode, setPostcode] = useState()
    const [otp, setOtp] = useState()
    const [isOtp, setIsOtp] = useState(false)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        postcode: '',

    });


    const [errors, setErrors] = useState({});

    const validateField = (fieldName, value) => {
        switch (fieldName) {
            case 'firstName':
                if (value.trim() === '') {
                    return 'First name is required';
                }
                break;
            case 'lastName':
                if (value.trim() === '') {
                    return 'Last name is required';
                }
                break;
            case 'email':
                if (!value.match(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/)) {
                    return 'Invalid email address';
                }
                break;
            case 'phoneNumber':
                if (!value.match(/^(019|018|017|013|014|015|011|016)\d{8}$/)) {
                    return 'Invalid phone number';
                }
                break;
            case 'address':
                if (value.trim() === '') {
                    return 'Address is required';
                }
                break;
            case 'postcode':
                if (value.trim() === '') {
                    return 'Postcode is required';
                }
                break;
            default:
                break;
        }
        return '';
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const fieldError = validateField(name, value);

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: fieldError,
        });
    };


    // password hidden
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleAgreement = (event) => {
        setAgreement(event.target.checked);
    };

    const handleNewsLetter = (event) => {
        setNewsLetter(event.target.checked);
    };

    const submitHandler = () => {

        let formIsValid = true;
        for (const fieldName in formData) {
            const fieldError = validateField(fieldName, formData[fieldName]);
            if (fieldError) {
                formIsValid = false;
                setErrors({
                    ...errors,
                    [fieldName]: fieldError,
                });
            }
        }

        if (firstName && lastName && email && phoneNumber && address && postcode && agreement && formIsValid) {
            let formData = new FormData()
            formData.append('user_login', email)
            formData.append('user_email', email)
            formData.append('display_name', firstName)
            formData.append('user_first_name', firstName)
            formData.append('user_last_name', lastName)
            formData.append('user_agreement', agreement.toString())
            formData.append('user_mobile', phoneNumber)
            formData.append('user_newsletter', newsLetter.toString())
            formData.append('del_addr_text', address)
            formData.append('del_addr_country', 'Bangladesh')
            formData.append('del_addr_state', 'Dhaka')
            formData.append('del_addr_city', 'Dhaka')
            formData.append('del_addr_post_code', postcode)

            dispatch(submitGuestSignup(formData)).then(res => {
                if (res?.payload?.status === 200) {
                    setIsOtp(true)
                    setOtp("")
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            setLoading(false)
            toast.error('Please review the form and complete all required fields before trying again.', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }


    }


    const otpVerify = () => {
        let formData = new FormData()

        if (otp && otp !== '') {
            formData.append('user_email', email)
            formData.append('user_login', email)
            formData.append('user_email_phone', email)
            formData.append('display_name', firstName)
            formData.append('otp', otp)
            formData.append('user_first_name', firstName)
            formData.append('user_last_name', lastName)
            formData.append('user_agreement', agreement.toString())
            formData.append('user_mobile', phoneNumber)
            formData.append('user_newsletter', newsLetter.toString())
            formData.append('del_addr_text', address)
            formData.append('del_addr_country', 'Bangladesh')
            formData.append('del_addr_state', 'Dhaka')
            formData.append('del_addr_city', 'Dhaka')
            formData.append('del_addr_post_code', postcode)

            dispatch(submitGuestSignIn(formData)).then(res => {
                if (res?.payload?.status === 200) {
                    setIsOtp(false)
                    let localCartData = JSON.parse(localStorage.getItem('addToCart'))
                    let token = localStorage.getItem('token')
                    let id = localStorage.getItem('id')
                    localCartData && localCartData.length > 0 && localCartData.map((item, index) => {
                        let params = {
                            token: token,
                            id: id,
                            count: item.cart_count,
                            productId: item?.id
                        }
                        dispatch(addToCart(params)).then((res) => {
                            if (res?.payload?.status === 200) {
                                dispatch(cart(params)).then(() => {
                                })
                                if(localCartData.length === (index+1)){
                                    localStorage.removeItem('addToCart')
                                    localStorage.removeItem('total')
                                    setTimeout(()=>{
                                        window.dispatchEvent(new StorageEvent('storage', { key: 'addToCart', newValue: JSON.stringify([]) }));
                                        window.dispatchEvent(new StorageEvent('storage', { key: 'total', newValue: 0 }));

                                    }, 800)
                                }
                            } else {
                                localStorage.removeItem('addToCart')
                                localStorage.removeItem('total')
                            }
                        })
                    })
                    setTimeout(() => {
                        history.push('/')
                    }, 800)
                    setIsOtp(false)
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            toast.error('Please review the form and complete all required fields before trying again.', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    return (
        <StyledLogIn className='login'>
            <Container fluid style={{paddingLeft: offset + 15 + 'px'}}>
                <Row>
                    <Col md={5}>
                        <div className="account__form">
                            <h2>Create your Profile</h2>
                            <div className="account__form__create-line">
                                <p>Already have an Mana Bay profile? Sign In, &nbsp;</p>
                                <Link to={'/login'}>Sign In</Link>
                            </div>

                            <div className="account__form__container">
                                <div className="form-group-wrap">
                                    <Form className={'form'}>
                                        {
                                            !isOtp ?
                                                <div className="flex">
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Control
                                                                type="text"
                                                                name="firstName"
                                                                value={formData.firstName}
                                                                placeholder="First Name"
                                                                onChange={e => {
                                                                    setFirstName(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.firstName ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.firstName && (
                                                                <div className="error-message">{errors.firstName}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Control
                                                                type="text"
                                                                name="lastName"
                                                                value={formData.lastName}
                                                                placeholder="Last Name"
                                                                onChange={e => {
                                                                    setLastName(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.lastName ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.lastName && (
                                                                <div className="error-message">{errors.lastName}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Control
                                                                type="text"
                                                                name="email"
                                                                value={formData.email}
                                                                placeholder="Mail Address"
                                                                onChange={e => {
                                                                    setEmail(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.email ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.email && (
                                                                <div className="error-message">{errors.email}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Control
                                                                type="number"
                                                                name="phoneNumber"
                                                                value={formData.phoneNumber}
                                                                placeholder="01XXXXXXXXX"
                                                                onChange={e => {
                                                                    setPhoneNumber(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.phoneNumber ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.phoneNumber && (
                                                                <div className="error-message">{errors.phoneNumber}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Control
                                                                type="text"
                                                                name="address"
                                                                value={formData.address}
                                                                placeholder="Address"
                                                                onChange={e => {
                                                                    setAddress(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.address ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.address && (
                                                                <div className="error-message">{errors.address}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Control
                                                                type="number"
                                                                name="postcode"
                                                                value={formData.postcode}
                                                                placeholder="Postcode"
                                                                onChange={e => {
                                                                    setPostcode(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.postcode ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.postcode && (
                                                                <div className="error-message">{errors.postcode}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>


                                                </div> :
                                                <div className="flex">
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicOtp">
                                                            <Form.Control
                                                                required
                                                                placeholder="OTP"
                                                                type="text"
                                                                name="otp"
                                                                value={otp}
                                                                onChange={event => setOtp(event.target.value)}
                                                                className={`form-control-lg ${isOtp ? 'slide-animation' : ''}`}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                        }

                                        {
                                            !isOtp ?
                                                <>
                                                    <div className="checkbox-single">

                                                        <Form.Group>
                                                            <label className="container">
                                                                <input type="checkbox" checked={agreement}
                                                                       onChange={handleAgreement}/>
                                                                <span className="checkmark"></span>
                                                                <span className="label-text">I agree to Mana Bay <a
                                                                    href="/terms">terms and conditions</a> and  <a
                                                                    href="">privacy policy</a></span>
                                                            </label>
                                                        </Form.Group>
                                                    </div>
                                                    <Form.Group>
                                                        <label className="container">
                                                            <input type="checkbox" checked={newsLetter}
                                                                   onChange={handleNewsLetter}/>
                                                            <span className="checkmark"></span>
                                                            <span className="label-text">Please contact me by phone, email or SMS to assist with my enquiry</span>
                                                        </label>
                                                    </Form.Group>
                                                </> : null
                                        }

                                        <Form.Group>
                                            <Button text={'Create an Account'} borderColor={'#92278F'}
                                                    color={'#92278F'}
                                                    onClick={isOtp ? otpVerify : submitHandler}
                                                    src={'/sign_up'}
                                                    hoverfill={'#92278F'}/>
                                        </Form.Group>
                                    </Form>


                                </div>


                            </div>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}} className={'pr-0 pl-0'}>
                        <div className="login__image">
                            <Img src={'/images/dynamic/login/login.jpg'}/>
                        </div>
                    </Col>
                </Row>
                {/*<ToastContainer*/}
                {/*    position="top-right"*/}
                {/*    autoClose={5000}*/}
                {/*    hideProgressBar={false}*/}
                {/*    newestOnTop={false}*/}
                {/*    closeOnClick*/}
                {/*    rtl={false}*/}
                {/*    pauseOnFocusLoss*/}
                {/*    draggable*/}
                {/*    pauseOnHover*/}
                {/*    theme="light"*/}
                {/*/>*/}
            </Container>
        </StyledLogIn>
    )
        ;
};
const StyledLogIn = styled.section`
  .account__form {
    padding: 100px 0 50px 0;

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 300;
      color: #1A1818;
    }

    &__create-line {
      display: flex;
      margin: 15px 0 40px 0;

      p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #1A1818;
      }

      a {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #92278F;

      }
    }
  }

  .login__image {
    position: relative;
    padding-top: calc(651 / 598 * 100%);
    height: 100%;
  }

  .login__form__container {
    width: 100%;
    //height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //gap: 170px;
    .form__bottom {
      display: grid;

      .link {
        color: #1A1818;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }

    //a{
    //  color: #1A1818;
    //  margin-bottom: 40px;
    //  text-align: end;
    //
    //}
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-animation {
    animation: slideIn 0.5s ease forwards;
  }


  .password-input {
    position: relative;
  }

  .password-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }

  .password-toggle-button {
    position: absolute;
    top: 45%;
    right: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .number-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }

  .checkbox-single {
    margin-bottom: 25px;
  }

  .dc-btn {
    margin-top: 40px;
  }


  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }

    .login__form {
      padding: 30px 0 50px 0;

      .login__form__container {
        .form__bottom {
          display: grid;

        }
      }

    }
  }
  .error-message{
    color: #e74c3c;
    top: -20px;
    font-size: 12px;

  }


  .password-toggle-button {
    svg {
      color: #CCCCCC;
    }
  }

`;
export default MyComponent;
