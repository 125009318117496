import styled from "styled-components";

const Origins = () => {
    return (
        <StyledSection>
            {/*make this view more attractive*/}

            <div className="empty-view">
                <div className="empty-view__inner">
                    <div className="empty-view__inner__content">
                        <img src="/images/static/logo.png" alt="logo" />

                        <h2>Origins</h2>
                        <p>Coming Soon</p>
                    </div>
                </div>
            </div>
        </StyledSection>
    )
}

export default Origins

const StyledSection = styled.section`
  .empty-view {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    img {
      width: 120px;
      height: 100px;
    }

    .empty-view__inner {
      width: 100%;
      max-width: 1200px;
      padding: 0 15px;

      .empty-view__inner__content {
        text-align: center;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        h2 {
          font-size: 40px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 20px;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
`
