import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Img from "../Img";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({title, description, eventDetail}) => {
    return (
        <StyledComponent>
            <Container className={"overview pb-120 pt-120"}>
                <Row>
                    <Col md={6} className={'overview__left'}>
                        <h2 className={"split-up"}>{title}</h2>
                        <h4 className={"split-up"}>{reactHtmlParser(description)}</h4>
                    </Col>
                    <Col md={{span:5, offset:1}} className={"overview__right"}>
                        <div className={"overview__right__box"}>

                            <div className={"title"}>

                                <h3 className={"split-up"}>{title}</h3>
                            </div>
                            <img src={'/images/dynamic/icon.svg'}/>
                            <div className={"list"}>
                                <ul>
                                    <li>
                                        <p className={"split-up"}>EVENT</p>
                                        <h4 className={"split-up"}>{eventDetail?.meta?._event_type ? eventDetail?.meta?._event_type[0] : ''}</h4>
                                    </li>
                                    <li>
                                        <p className={"split-up"}>DATE</p>
                                        <h4 className={"split-up"}>{ReactHtmlParser(eventDetail?.meta?._event_date_time[0])}</h4>
                                    </li>
                                    <li>
                                        <p className={"split-up"}>VENUE</p>
                                        <h4 className={"split-up"}>{eventDetail?.meta?._event_vanue}</h4>
                                    </li>
                                    <li>
                                        <p className={"split-up"}>AVAILABLE TICKET</p>
                                        {/*<h4 className={"split-up"}>{data?.card?.ticket}</h4>*/}
                                        <h4 className={"split-up"}>{parseInt(eventDetail?.meta?._type_a_quantity[0])+parseInt(eventDetail?.meta?._type_b_quantity[0])+parseInt(eventDetail?.meta?._type_c_quantity[0])}</h4>
                                    </li>
                                </ul>
                                    <Button text={'Get Direction'} color={'#92278F'} borderColor={"#92278F"} hoverBackground={"#fff"} hoverfill={"#92278F"} target='_blank'
                                            externals={eventDetail?.meta?._event_location[0]} src={eventDetail?.meta?._event_location[0]}
                                    />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .overview{
      &__left{
        h2{
          color: #000;
          //font-size: 40px;
          font-weight: 400;
          //line-height: 48px;
          margin-bottom: 30px;
        }
        h4{
          color: #000;
          //font-size: 18px;
          font-weight: 300;
          //line-height: 27px;
          margin-bottom: 30px;
        }
      }
      &__right{
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
        padding: 0;
        &__box{
          
          h3{
            color: #fff;
            //font-size: 24px;
            //line-height: 32px;
            font-weight: 400;
          }
          .title{
            position: relative;
            padding: 21px 70px 16px 40px;
            background-color: #92278F;
          }
          img{
            //width: 100%;
            //position: absolute;
            //bottom: -20px;
            //left: 0;
            width: 100%;
            margin-top: -10px;
          }
          .list{
            padding-left: 30px;
            padding-bottom: 45px;
            ul{
              padding-top: 45px;
              padding-bottom: 40px;
              li{
                margin-top: 20px;
                p{
                  color: #92278F;
                  //font-size: 14px;
                  //line-height: 25px;
                  font-weight: 300;
                }
                h4{
                  color: #000;
                  //font-size: 16px;
                  //line-height: 24px;
                  font-weight: 400;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
`;

export default MyComponent;
