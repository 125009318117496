import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import {Col, Container, Row} from "react-bootstrap";
import BoxWithSvg from "../BoxWithSvg";
import Button from "../Button";
import Img from "../Img";
import TextBoxImage from "../TextBoxImage";


const MyComponent = ({data}) => {


    const handleSlug = (postSlug, postTitle) => {
        // Handle slug logic
    };


    return (
        <StyledComponent className={'rides-zig pt-100 '}>
            {
                data && data?.length > 0 &&
                data?.map((e) => {
                    return (
                        <TextBoxImage key={e}
                                      text={e?.post_content}
                                      image={e?.meta?.multipop_banners_img?.[0]}
                                      svg={e?.svg}
                                      title={e?.post_title}
                                      bgColor={e?.bgColor} handleSlug={handleSlug}
                                      zigzag={e?._multipop_repeater}
                        />
                    );
                })
            }
        </StyledComponent>
    );
};
const StyledComponent = styled.div`

`;

export default MyComponent;
