import {Form, Modal} from "react-bootstrap";
import ButtonA from "../ButtonA";
import styled from "styled-components";
import {useEffect, useState} from "react";
import {clearToken, submitGuestSignIn, submitGuestSignup, submitSignup, updateProfile} from "../../api/redux/auth";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import Button from "../Button";
import {useHistory} from "react-router-dom";
import {addToCart, cart} from "../../api/redux/checkout";

const Address = ({
                     userData,
                     handleContinue,
                     firstName,
                     lastName,
                     email,
                     phoneNumber,
                     postcode,
                     address,
                     setAddress,
                     setEmail,
                     setPhoneNumber,
                     setFirstName,
                     setLastName,
                     setPostcode,
                     loading,
                     setLoading,
                     total,
                     otp,
                     setOtp,
                     isOtp,
                     setIsOtp
                 }) => {
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        firstName: userData?.first_name || '',
        lastName: userData?.last_name || '',
        email: userData?.user_email || '',
        phoneNumber: userData?.user_mobile || '',
        address: userData?.shipping_address?.address_1 || '',
        postcode: userData?.shipping_address?.postcode || '',

    });
    const [errors, setErrors] = useState({});

    const validateField = (fieldName, value) => {
        switch (fieldName) {
            case 'firstName':
                if (value.trim() === '') {
                    return 'First name is required';
                }
                break;
            case 'lastName':
                if (value.trim() === '') {
                    return 'Last name is required';
                }
                break;
            case 'email':
                if (!value.match(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/)) {
                    return 'Invalid email address';
                }
                break;
            case 'phoneNumber':
                if (!value.match(/^(019|018|017|013|014|015|011|016)\d{8}$/)) {
                    return 'Invalid phone number';
                }
                break;
            case 'address':
                if (value.trim() === '') {
                    return 'Address is required';
                }
                break;
            case 'postcode':
                if (value.trim() === '') {
                    return 'Postcode is required';
                }
                break;
            default:
                break;
        }
        return '';
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const fieldError = validateField(name, value);

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: fieldError,
        });
    };

    useEffect(() => {
        if (userData) {
            setFirstName(userData?.first_name)
            setLastName(userData?.last_name)
            setEmail(userData?.user_email)
            setPhoneNumber(userData?.user_mobile)
            setAddress(userData?.shipping_address?.address_1)
            setPostcode(userData?.shipping_address?.postcode)

        }
        setFormData({
            firstName: userData?.first_name || '',
            lastName: userData?.last_name || '',
            email: userData?.user_email || '',
            phoneNumber: userData?.user_mobile || '',
            oldPassword: '',
            password: '',
            address: userData?.shipping_address?.address_1 || '',
            postcode: userData?.shipping_address?.postcode || '',
        })
    }, [userData])

    const addressUpdate = () => {
        let formIsValid = true;
        for (const fieldName in formData) {
            const fieldError = validateField(fieldName, formData[fieldName]);
            if (fieldError) {
                formIsValid = false;
                setErrors({
                    ...errors,
                    [fieldName]: fieldError,
                });
            }
        }


        let token = localStorage.getItem('token')
        if (userData && token && token !== "") {
            if (firstName === userData?.first_name && lastName && userData?.last_name && email === userData?.user_email
                && phoneNumber === userData?.user_mobile && address === userData?.shipping_address?.address_1 &&
                postcode === userData?.shipping_address?.postcode
            ) {
                handleContinue('payment')
            } else {
                if (formIsValid) {
                    let token = localStorage.getItem('token')
                    let id = localStorage.getItem('id')
                    let formData = new FormData()

                    formData.append('user_first_name', firstName)
                    formData.append('user_last_name', lastName)
                    formData.append('user_email', email)
                    formData.append('user_login', email)
                    formData.append('user_mobile', phoneNumber)
                    formData.append('token', token)
                    formData.append('user_id', id)
                    formData.append('del_addr_text', address)
                    formData.append('del_addr_country', 'Bangladesh')
                    formData.append('del_addr_state', 'Dhaka')
                    formData.append('del_addr_city', 'Dhaka')
                    formData.append('del_addr_post_code', postcode)

                    dispatch(updateProfile(formData)).then(res => {
                        setLoading(false)
                        if (res?.payload?.status === 200) {
                            toast.success(res?.payload?.message, {
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                            handleContinue('payment')
                        } else {
                            toast.error(res?.payload?.response?.data?.message, {
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                        }
                    })
                }
            }
        } else {
            let formData = new FormData()

            if (firstName && lastName && email && phoneNumber && address && postcode) {
                formData.append('user_login', email)
                formData.append('user_email', email)
                formData.append('display_name', firstName)
                formData.append('user_first_name', firstName)
                formData.append('user_last_name', lastName)
                formData.append('user_agreement', 'false')
                formData.append('user_mobile', phoneNumber)
                formData.append('user_newsletter', 'false')
                formData.append('del_addr_text', address)
                formData.append('del_addr_country', 'Bangladesh')
                formData.append('del_addr_state', 'Dhaka')
                formData.append('del_addr_city', 'Dhaka')
                formData.append('del_addr_post_code', postcode)

                dispatch(submitGuestSignup(formData)).then(res => {
                    if (res?.payload?.status === 200) {
                        setIsOtp(true)
                        setOtp("")
                        toast.success(res?.payload?.message, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    } else {
                        toast.error(res?.payload?.response?.data?.message, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                setLoading(false)
                toast.error('Please review the form and complete all required fields before trying again.', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }


        }

    }

    const otpVerify = () => {
        let formData = new FormData()

        if (otp && otp !== '') {
            formData.append('user_email', email)
            formData.append('user_login', email)
            formData.append('user_email_phone', email)
            formData.append('display_name', firstName)
            formData.append('otp', otp)
            formData.append('user_first_name', firstName)
            formData.append('user_last_name', lastName)
            formData.append('user_agreement', 'false')
            formData.append('user_mobile', phoneNumber)
            formData.append('user_newsletter', 'false')
            formData.append('del_addr_text', address)
            formData.append('del_addr_country', 'Bangladesh')
            formData.append('del_addr_state', 'Dhaka')
            formData.append('del_addr_city', 'Dhaka')
            formData.append('del_addr_post_code', postcode)

            dispatch(submitGuestSignIn(formData)).then(res => {
                if (res?.payload?.status === 200) {
                    setIsOtp(false)

                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    let localCartData = JSON.parse(localStorage.getItem('addToCart'))
                    let token = localStorage.getItem('token')
                    let id = localStorage.getItem('id')
                    localCartData && localCartData.length > 0 && localCartData.map((item, index) => {
                        let params = {
                            token: token,
                            id: id,
                            count: item.cart_count,
                            productId: item?.id
                        }
                        dispatch(addToCart(params)).then((res) => {
                            setLoading(false)
                            setIsOtp(false)
                            handleContinue('payment')
                            if (res?.payload?.status === 200) {
                                dispatch(cart(params)).then(() => {
                                })
                                if (localCartData.length === (index + 1)) {
                                    localStorage.removeItem('addToCart')
                                    localStorage.removeItem('total')
                                    setTimeout(() => {
                                        window.dispatchEvent(new StorageEvent('storage', {
                                            key: 'addToCart',
                                            newValue: JSON.stringify([])
                                        }));
                                        window.dispatchEvent(new StorageEvent('storage', {key: 'total', newValue: 0}));

                                    }, 800)
                                }
                                toast.success(res?.payload?.message, {
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                });
                            } else {
                                toast.error(res?.payload?.response?.data?.message, {
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "dark",
                                });
                            }
                        })
                    })


                } else {
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            setLoading(false)
            toast.error('Please review the form and complete all required fields before trying again.', {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <StyledAddress>
            <div>
                <div className={'details'}>
                    <h3>Details</h3>
                    <div className={'account__form'}>
                        <div className={'form__fields'}>
                            <div className="account__form__container">
                                <div className="form-group-wrap">
                                    <Form className={'form'}>
                                        {
                                            !isOtp ?
                                                <div className="flex">
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>First name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="firstName"
                                                                value={formData.firstName}
                                                                onChange={e => {
                                                                    setFirstName(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.firstName ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.firstName && (
                                                                <div className="error-message">{errors.firstName}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Last name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lastName"
                                                                value={formData.lastName}
                                                                onChange={e => {
                                                                    setLastName(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.lastName ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.lastName && (
                                                                <div className="error-message">{errors.lastName}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Mail Address</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={e => {
                                                                    setEmail(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.email ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.email && (
                                                                <div className="error-message">{errors.email}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="phoneNumber"
                                                                value={formData.phoneNumber}
                                                                placeholder="01XXXXXXXXX"
                                                                onChange={e => {
                                                                    setPhoneNumber(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.phoneNumber ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.phoneNumber && (
                                                                <div
                                                                    className="error-message">{errors.phoneNumber}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Address</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="address"
                                                                value={formData.address}
                                                                onChange={e => {
                                                                    setAddress(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.address ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.address && (
                                                                <div className="error-message">{errors.address}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label>Postcode</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="postcode"
                                                                value={formData.postcode}
                                                                onChange={e => {
                                                                    setPostcode(e.target.value)
                                                                    handleChange(e)
                                                                }}
                                                                className={errors.postcode ? 'form-control-lg has-error' : 'form-control-lg'}
                                                            />
                                                            {errors.postcode && (
                                                                <div className="error-message">{errors.postcode}</div>
                                                            )}
                                                        </Form.Group>
                                                    </div>


                                                </div> :
                                                <div className="flex">
                                                    <div className="form-group">
                                                        <Form.Group controlId="formBasicOtp">
                                                            <Form.Label>OTP<span
                                                                className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                name="otp"
                                                                value={otp}
                                                                onChange={event => setOtp(event.target.value)}
                                                                className="form-control-lg"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                        }

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="process-btn d-flex justify-content-center">
                    <ButtonA
                        color="#1A1818"
                        borderColor="#1A1818"
                        hoverfill="#1A1818"
                        text="Back"
                        onClick={() => handleContinue('cart')}
                    />
                    {
                        !firstName || firstName === '' || !postcode || postcode === '' || !address || address === '' ?
                            <ButtonA
                                color="#dddddd"
                                borderColor="#dddddd"
                                hoverfill="#dddddd"
                                text="Continue"
                            /> :
                            <ButtonA
                                color="#92278F"
                                borderColor="#92278F"
                                hoverfill="#92278F"
                                text="Continue"
                                onClick={() => {
                                    if (isOtp) {
                                        otpVerify()
                                    } else {
                                        addressUpdate()
                                    }
                                }}
                                // onClick={handleShow}
                            />
                    }

                    <Modal show={show} className='guest transparent-modal'>
                        <Modal.Body>
                            <div className="announcement">
                                <div className="pop-close" onClick={handleClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.828" height="27.828"
                                         viewBox="0 0 27.828 27.828">
                                        <g id="Group_18986" data-name="Group 18986"
                                           transform="translate(-1241.837 -61.211)">
                                            <line id="Line_3541" data-name="Line 3541" x2="35.355"
                                                  transform="translate(1243.251 62.626) rotate(45)" fill="none"
                                                  stroke="#92278F" stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_12368" data-name="Line 12368" x2="35.355"
                                                  transform="translate(1243.251 87.626) rotate(-45)" fill="none"
                                                  stroke="#92278F" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                                <div className="modal-data">
                                    <div className={'logout'}>
                                        <h3>Are you sure you want to logout?</h3>
                                        <div className={'log-btns'}>
                                            <Button text={'Cancel'} src={'/account'} color={'#92278F'}
                                                    borderColor={'#92278F'} hoverfill={'#92278F'}/>
                                            <Button text={'logout'} src={'/'}
                                                    onClick={() => {
                                                    }}
                                                    color={'#92278F'} borderColor={'#92278F'} hoverfill={'#92278F'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </StyledAddress>
    )
}

export default Address


const StyledAddress = styled.div`
  .details {
    margin-top: 30px;
    background-color: #f9f9f9;
    border: 1px solid #E8E8E8;


    h3 {
      color: #1A1818;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      padding-top: 17px;
      padding-bottom: 19px;
      padding-left: 20px;
      background-color: #f9f9f9;
      border: 1px solid #E9E9E9;
    }

    .account__form {
      padding: 20px 30px 40px 30px;
      background-color: #ffffff;
      //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

      .checkbox-single {
        margin-bottom: 25px;
        padding-top: 25px;

        .container {
          padding-bottom: 25px;
          border-bottom: 1px solid #E8E8E8;
          margin-bottom: 25px;
        }
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;

        //.form-group {
        //  &:last-child {
        //    flex: 0 0 50%;
        //  }
        //}
      }
    }
  }

  .error-message {
    color: #e74c3c;
    top: -20px;
    font-size: 12px;

  }

  .logout {
    display: flex;
    justify-content: center;
    padding: 60px 106px;
    background-color: #F9F9F9;
    color: #1A1818;
    cursor: pointer;

    .log-btns {
      display: flex;
    }
  }
`
