import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from '../../styles/globalStyleVars'
import {Img} from "../Img";
import Button from "../Button";
import React from "react";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {


    return (
        <StyledSocial className='home-social pt-200 pb-200'>
            <Img src={'images/static/social.jpg'}/>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="home-social__heading">
                            <h2>{reactHtmlParser(data?.title)}</h2>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className="home-social__isnta-text">
                            <div className="home-social__isnta-text__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5"
                                     viewBox="0 0 21.5 21.5">
                                    <g id="Icon_feather-instagram" data-name="Icon feather-instagram"
                                       transform="translate(-2.25 -2.25)">
                                        <path id="Path_7757" data-name="Path 7757"
                                              d="M8,3H18a5,5,0,0,1,5,5V18a5,5,0,0,1-5,5H8a5,5,0,0,1-5-5V8A5,5,0,0,1,8,3Z"
                                              fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="1.5"/>
                                        <path id="Path_7758" data-name="Path 7758"
                                              d="M20.022,15.348a4,4,0,1,1-3.37-3.37,4,4,0,0,1,3.37,3.37Z"
                                              transform="translate(-3.022 -2.978)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                        <path id="Path_7759" data-name="Path 7759" d="M26.25,9.75h0"
                                              transform="translate(-7.75 -2.25)" fill="none" stroke="#fff"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    </g>
                                </svg>

                            </div>
                            <div className="home-social__isnta-text__text">
                                <h5>Instagram</h5>
                            </div>
                        </div>
                        <div className="home-social__insta-images">
                            <a target={'_blank'} href={data?.instagram?.url}>
                                <span className={'hover-icon'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                         viewBox="0 0 21.5 21.5">
                                        <g id="Icon_feather-instagram" data-name="Icon feather-instagram"
                                           transform="translate(-2.25 -2.25)">
                                            <path id="Path_7757" data-name="Path 7757"
                                                  d="M8,3H18a5,5,0,0,1,5,5V18a5,5,0,0,1-5,5H8a5,5,0,0,1-5-5V8A5,5,0,0,1,8,3Z"
                                                  fill="none" stroke="#fff" stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  stroke-width="1.5"/>
                                            <path id="Path_7758" data-name="Path 7758"
                                                  d="M20.022,15.348a4,4,0,1,1-3.37-3.37,4,4,0,0,1,3.37,3.37Z"
                                                  transform="translate(-3.022 -2.978)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                            <path id="Path_7759" data-name="Path 7759" d="M26.25,9.75h0"
                                                  transform="translate(-7.75 -2.25)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                        </g>
                                    </svg>
                                </span>
                            </a>

                            {
                                data?.instagram && data?.instagram?.image && data.instagram.image.length > 0 ?
                                    data.instagram.image.map((item)=>{
                                        return(
                                            <Col xs={6}>
                                                <div className="home-social__insta-images__single">
                                                    <Img src={item?.image} alt=""/>
                                                </div>
                                            </Col>
                                        )
                                    }) : null
                            }
                        </div>
                    </Col>
                    <Col lg={7} className={'rev'}>
                        <Row>
                            <Col xs={7}>
                                <div className="home-social__utube">
                                    <div className="home-social__utube__text">
                                        <div className="home-social__utube__text__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.003"
                                                 viewBox="0 0 20 15.003">
                                                <path id="Icon_ionic-logo-youtube" data-name="Icon ionic-logo-youtube"
                                                      d="M19.87,7.813a3.037,3.037,0,0,0-2.891-3.172c-2.164-.1-4.372-.141-6.626-.141h-.7c-2.25,0-4.462.039-6.626.141A3.044,3.044,0,0,0,.137,7.821C.039,9.212,0,10.6,0,11.993s.039,2.782.133,4.176A3.047,3.047,0,0,0,3.02,19.354c2.274.105,4.606.152,6.978.148s4.7-.039,6.978-.148a3.048,3.048,0,0,0,2.891-3.184c.094-1.395.137-2.786.133-4.18S19.964,9.208,19.87,7.813ZM8.087,15.826V8.149l5.665,3.836Z"
                                                      transform="translate(0 -4.5)" fill="#fff"/>
                                            </svg>
                                        </div>
                                        <div className="home-social__utube__text__text">
                                            <h5>Youtube</h5>
                                        </div>
                                    </div>

                                    <div className="home-social__utube__images">
                                        <a href={data?.youtube?.url}>
                                            <span className={'hover-icon'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                         viewBox="0 0 20 15.003">
                                                <path id="Icon_ionic-logo-youtube" data-name="Icon ionic-logo-youtube"
                                                      d="M19.87,7.813a3.037,3.037,0,0,0-2.891-3.172c-2.164-.1-4.372-.141-6.626-.141h-.7c-2.25,0-4.462.039-6.626.141A3.044,3.044,0,0,0,.137,7.821C.039,9.212,0,10.6,0,11.993s.039,2.782.133,4.176A3.047,3.047,0,0,0,3.02,19.354c2.274.105,4.606.152,6.978.148s4.7-.039,6.978-.148a3.048,3.048,0,0,0,2.891-3.184c.094-1.395.137-2.786.133-4.18S19.964,9.208,19.87,7.813ZM8.087,15.826V8.149l5.665,3.836Z"
                                                      transform="translate(0 -4.5)" fill="#fff"/>
                                            </svg>
                                            </span>
                                        </a>
                                        <div className="home-social__utube__images__big">
                                            <Img src={data?.youtube?.image} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={5}>
                                <div className="home-social__facebook">
                                    <div className="home-social__facebook__text">
                                        <div className="home-social__facebook__text__icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.569" height="16"
                                                 viewBox="0 0 8.569 16">
                                                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                                                      d="M9.617,9l.444-2.9H7.283V4.225A1.448,1.448,0,0,1,8.916,2.661h1.263V.2A15.4,15.4,0,0,0,7.937,0,3.535,3.535,0,0,0,4.153,3.9V6.1H1.609V9H4.153v7h3.13V9Z"
                                                      transform="translate(-1.609)" fill="#fff"/>
                                            </svg>

                                        </div>
                                        <div className="home-social__facebook__text__text">
                                            <h5>Facebook</h5>
                                        </div>
                                    </div>

                                    <div className="home-social__facebook__images">
                                        <a href={data?.facebook?.url}>
                                            <span className={'hover-icon'}>
                                                 <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                      viewBox="0 0 8.569 16">
                                                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
                                                      d="M9.617,9l.444-2.9H7.283V4.225A1.448,1.448,0,0,1,8.916,2.661h1.263V.2A15.4,15.4,0,0,0,7.937,0,3.535,3.535,0,0,0,4.153,3.9V6.1H1.609V9H4.153v7h3.13V9Z"
                                                      transform="translate(-1.609)" fill="#fff"/>
                                            </svg>
                                            </span>
                                        </a>
                                        <div className="home-social__facebook__images__small">
                                            <Img src={data?.facebook?.image} alt=""/>
                                        </div>

                                    </div>

                                </div>
                            </Col>
                        </Row>


                    </Col>

                </Row>
            </Container>

        </StyledSocial>
    )
        ;
};
const StyledSocial = styled.section`
  position: relative;


  .home-social__heading {
    h2 {
      //font-size: 40px;
      //line-height: 50px;
      font-weight: 300;
      margin: 0 0 70px 0;
    }
  }

  .home-social__isnta-text {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      margin-right: 10px;

      svg {
        display: flex;
      }
    }

    &__text {
      h5 {
        color: #ffffff;
        font-size: 18px;
        line-height: 27px;
        font-weight: 300;

      }


    }

  }

  .home-social__insta-images {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: unset;

      left: 5px;
      right: 5px;
      //bottom: -5px;
      top: 0;
      background-color: rgba(42, 38, 39, 0.8);

      opacity: 0;
      transition: all 0.4s ease 0s;

      z-index: 1;

    }

    a {
      position: absolute;
      inset: 0;
      z-index: 5;
      transition: all 0.4s ease 0s;
      cursor: pointer;
      text-decoration: none;
      background-color: transparent;

      .hover-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.4s ease 0s;
      }

    }

    &:hover {
      .hover-icon {
        opacity: 1;
        transition: all 0.4s ease 0s;
      }

      &:before {
        opacity: 1;
        transition: all 0.4s ease 0s;
      }
    }

    &__single {
      position: relative;
      padding-top: calc(188 / 231 * 100%);


    }

    .col-img::before {
      content: "";
      bottom: -5px;
      left: -5px;
      position: absolute;
      right: 10px;
      background: #92278F;
      height: 6px;
    }

    .col-img::after {
      content: '';
      top: 10px;
      left: -5px;
      position: absolute;
      bottom: 0px;
      background: #92278F;
      width: 5px;
    }


    .top-border::before {
      content: "";
      //left: 0px;
      top: -6px;
      position: absolute;
      right: -5px;
      background: #FCB940;
      height: 6px;
      left: 10px;
    }

    .top-border::after {
      content: '';
      top: 0;
      right: -5px;
      position: absolute;
      bottom: 10px;
      background: #FCB940;
      width: 5px;
    }

    .col-6 {
      margin-bottom: 10px;

      &:nth-child(even) {
        padding-right: 5px;
        padding-left: 5px;
      }

      &:nth-child(odd) {
        padding-left: 5px;
        padding-right: 5px;
      }

      &:nth-child(4),
      &:nth-child(5) {
        margin-bottom: 0 !important;
      }


    }


  }

  .home-social__utube {
    &__text {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        margin-right: 10px;

        svg {
          //vertical-align: unset;
          display: flex;
        }
      }

      &__text {
        h5 {
          color: #ffffff;
          font-size: 18px;
          line-height: 27px;
          font-weight: 300;

        }

      }

    }

    &__images {
      //display: flex;
      //flex-wrap: wrap;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(42, 38, 39, 0.8);

        opacity: 0;
        transition: all 0.4s ease 0s;

        z-index: 1;

      }

      a {
        position: absolute;
        inset: 0;
        z-index: 5;
        transition: all 0.4s ease 0s;
        cursor: pointer;
        text-decoration: none;
        background-color: transparent;

        .hover-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: all 0.4s ease 0s;
        }
      }

      &:hover {
        .hover-icon {
          opacity: 1;
          transition: all 0.4s ease 0s;

        }

        &:before {
          opacity: 1;
          transition: all 0.4s ease 0s;
        }
      }

      &__big {
        position: relative;
        padding-top: calc(385 / 390 * 100%);

        &:before {
          content: '';
          top: -6px;
          left: -5px;
          position: absolute;
          bottom: 10px;
          background: #92278F;
          width: 5px;
        }

        &:after {
          content: "";
          top: -6px;
          position: absolute;
          right: 10px;
          background: #92278F;
          height: 6px;
          left: 0;
        }

      }

    }
  }

  .home-social__facebook {
    &__text {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        margin-right: 10px;

        svg {
          //vertical-align: unset;
          display: flex;
        }
      }

      &__text {
        h5 {
          color: #ffffff;
          font-size: 18px;
          line-height: 27px;
          font-weight: 300;

        }

      }

    }

    &__images {
      //display: flex;
      //flex-wrap: wrap;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(42, 38, 39, 0.8);

        opacity: 0;
        transition: all 0.4s ease 0s;

        z-index: 1;

      }

      a {
        position: absolute;
        inset: 0;
        z-index: 5;
        transition: all 0.4s ease 0s;
        cursor: pointer;
        text-decoration: none;
        background-color: transparent;

        .hover-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: all 0.4s ease 0s;
        }
      }

      &:hover {
        .hover-icon {
          opacity: 1;
          transition: all 0.4s ease 0s;
        }

        &:before {
          opacity: 1;
          transition: all 0.4s ease 0s;
        }
      }


      &__small {
        position: relative;
        padding-top: calc(385 / 270 * 100%);

        &:before {
          content: '';
          bottom: -6px;
          right: -5px;
          position: absolute;
          top: 10px;
          background: #FCB940;
          width: 5px;
        }

        &:after {
          content: "";
          bottom: -6px;
          position: absolute;
          left: 10px;
          background: #FCB940;
          height: 6px;
          right: 0;
        }


      }


      //
      .col-5 {
        padding-left: 5px;
        //padding-right: 0;
      }


    }
  }

  .rev {
    .row {
      margin: 0;
    }

    .col-7 {
      padding-left: 10px;
      padding-right: 5px;
    }

    .col-5 {
      padding-left: 5px;
      padding-right: 15px;
    }

  }


  .col-lg-7 {
    padding-left: 10px;
    padding-right: 5px;
  }


  //.col-lg-5 {
  //  padding-left: 5px;
  //
  //}


  @media (max-width: 992px) {
    margin-top: 80px;
    .home-social__heading {
      h2 {
        margin: 0 0 30px 0;

      }
    }

    .col-lg-5 {
      padding-left: 15px;
    !important;
    }

    .home-social__insta-images {
      margin-bottom: 43px;
    }
  }


`;
export default MyComponent;
