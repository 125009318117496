import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Status from "../../components/order/status";
import {HelmetProvider, Helmet} from 'react-helmet-async'

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>
            <StyledComponent>
                <Status/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
