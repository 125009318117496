import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";
import {apiEndPoints} from "../../network/apiEndPoints";


const initialState = {
    loading: false,
    categories: [],
    products: [],
    productDetail: {},
    total: 0,
    error: '',
}

export const fetchCategories = createAsyncThunk("fetchCategories", (params) => {
    let catParams = [apiEndPoints.GET_CATEGORIES]
    return get(catParams);
});

export const fetchProductList = createAsyncThunk("fetchProductList", (params) => {
    let listParams = [`${apiEndPoints.PRODUCT_LIST}?slug=${params.slug}&post_per_page=${params.limit}&pageNum=${params.page}`]
    return get(listParams);
});

export const fetchProductDetail = createAsyncThunk("fetchProductDetail", (params) => {
    let listParams = [`${apiEndPoints.PRODUCT_DETAIL}/${params}`]
    return get(listParams);
});


const productsSlice = createSlice({
    name: 'products',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.loading = true
            state.categories = []
            state.error = ''
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.loading = false
            state.categories = action.payload.data
            state.error = ''
        })
        builder.addCase(fetchCategories.rejected, (state, action) => {
            state.loading = false
            state.categories = []
            state.error = action
        })
        builder.addCase(fetchProductList.pending, (state) => {
            state.loading = true
            state.products = []
            state.total = 0
            state.error = ''
        })
        builder.addCase(fetchProductList.fulfilled, (state, action) => {
            state.loading = false
            state.products = action.payload.data.products
            state.total = action.payload.data.total_products
            state.error = ''
        })
        builder.addCase(fetchProductList.rejected, (state, action) => {
            state.loading = false
            state.products = []
            state.total = 0
            state.error = action
        })
        builder.addCase(fetchProductDetail.pending, (state) => {
            state.loading = true
            state.productDetail = []
            state.error = ''
        })
        builder.addCase(fetchProductDetail.fulfilled, (state, action) => {
            state.loading = false
            state.productDetail = action.payload.data
            state.error = ''
        })
        builder.addCase(fetchProductDetail.rejected, (state, action) => {
            state.loading = false
            state.products = []
            state.error = action
        })
    }
})


export default productsSlice.reducer
