import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import RideZigzak from "../../components/ride/RideZigzak";
import Sanctuary from "../../components/home/Sanctuary";
import TringleInnerPage from "../../components/TringleInnerPage";
import AttrZigzak from "../../components/attractions/AttrZigzak";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchData} from "../../api/redux/ride";
import Banner from "../../components/attractions/Banner";
import {ApiParam} from "../../api/network/apiParams";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    // useEffect(() => {
    //     let api_url = apiEndPoints.RIDE
    //     dispath(fetchData([api_url]))
    // }, [])

    let param = {
        [ApiParam.cat_slug]: 'ride' //change to slug later
    }
    useEffect(() => {
        let api_url = apiEndPoints.ATTRACTION
        dispath(fetchData([api_url, param]))
    }, [])

    let getPost = useSelector(state => state.ride)



    const banner = getPost?.posts[0]?.banner;
    const sanc = getPost?.posts[0]?.sanctuary;
    const zigzag = getPost?.posts?.data;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Rides | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>

                <Banner img={'/images/dynamic/video-thumb-min.jpg'}
                        videoLink={'https://www.youtube.com/embed/tOPJwjUb7hw?si=Q9QUxSroB_jF45bG'} title={'Rides'}/>
                {/*<TringleInnerPage data={sanc}/>*/}
                <AttrZigzak data={zigzag}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
