import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Form, Row } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "simplebar-react/dist/simplebar.min.css";
import "react-calendar/dist/Calendar.css";
import Evento from "../Evento";
import { useSelector } from "react-redux";

const MyComponent = ({ data }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  let ticketDetails = useSelector(
    (state) => state?.ticket?.ticketInfo?.Envelope?.Body?.ItemList?.Item
  );

  useEffect(() => {
    // Check if data is available and the videoRef exists before attempting to play
    if (data && videoRef.current) {
      const video = videoRef.current;

      // Add an event listener to handle video errors
      video.addEventListener("error", handleVideoError);

      // Try to play the video
      video
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
          // Handle the error, e.g., display a message to the user
        });
    }
  }, [data]);

  const handleVideoError = (error) => {
    console.error("Video error:", error);
    // Handle the video error, e.g., display a message to the user
  };

  // useEffect(() => {
  //     if (data && videoRef && videoRef?.current) {
  //         const video = videoRef?.current;
  //         video.play();
  //         setIsPlaying(true);
  //     }
  //
  // }, [data, videoRef]);

  const toggleVideoPlayPause = () => {
    if (data && videoRef && videoRef?.current) {
      const video = videoRef.current;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleVideoMute = () => {
    if (data && videoRef && videoRef?.current) {
      const video = videoRef.current;
      video.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <section style={{ position: "relative" }}>
      <StyledComponent className="banner-video">
        {data && (
          <div className="video-container">
            {/* {data &&
                            <video ref={videoRef} id="banner-video" loop autoPlay muted={isMuted}>
                                <source src={data} type="video/mp4"/>
                            </video>
                        }


                        <div className="play-pause-button" onClick={toggleVideoPlayPause}>
                            {!isPlaying ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <rect
                                        id="Rectangle_1793"
                                        data-name="Rectangle 1793"
                                        width="24"
                                        height="24"
                                        fill="#fff"
                                        opacity="0"
                                    />
                                    <path
                                        id="Icon_awesome-play"
                                        data-name="Icon awesome-play"
                                        d="M13.5,7.19,2.3.222A1.519,1.519,0,0,0,0,1.605V15.538a1.526,1.526,0,0,0,2.3,1.383L13.5,9.956a1.65,1.65,0,0,0,0-2.766Z"
                                        transform="translate(5.25 3.748)"
                                        fill="#fff"
                                    />
                                </svg>
                            ) : (
                                <svg id="Media_play_paused" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24">
                                    <rect id="Rectangle_1793" data-name="Rectangle 1793" width="24" height="24" fill="#fff"
                                          opacity="0"/>
                                    <path id="Icon_awesome-play" data-name="Icon awesome-play"
                                          d="M13.5,7.19,2.3.222A1.519,1.519,0,0,0,0,1.605V15.538a1.526,1.526,0,0,0,2.3,1.383L13.5,9.956a1.65,1.65,0,0,0,0-2.766Z"
                                          transform="translate(5.25 3.748)" fill="#fff" opacity="0"/>
                                    <path id="Icon_open-media-pause" data-name="Icon open-media-pause"
                                          d="M0,0V14.25H4.75V0ZM9.5,0V14.25h4.75V0Z" transform="translate(5.25 5.25)"
                                          fill="#fff"/>
                                </svg>

                            )}
                        </div>
                        <div className="mute-buttons">
                            {isMuted ? (
                                <svg
                                    className="mute-button"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    onClick={toggleVideoMute}
                                >
                                    <path
                                        id="Icon_metro-volume-mute2"
                                        data-name="Icon metro-volume-mute2"
                                        d="M17.571,12.53v1.326H16.245l-1.674-1.674L12.9,13.856H11.571V12.53l1.674-1.674L11.571,9.182V7.856H12.9L14.571,9.53l1.674-1.674h1.326V9.182L15.9,10.856Zm-8.5,5.326a.5.5,0,0,1-.354-.146L4.864,13.856H3.071a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5H4.864L8.717,4a.5.5,0,0,1,.854.354v13a.5.5,0,0,1-.5.5Z"
                                        transform="translate(2.429 1.144)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Icon_metro-volume-mute"
                                        data-name="Icon metro-volume-mute"
                                        d="M9.071,17.856a.5.5,0,0,1-.354-.146L4.864,13.856H3.071a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5H4.864L8.717,4a.5.5,0,0,1,.854.354v13a.5.5,0,0,1-.5.5Z"
                                        transform="translate(2.429 1.144)"
                                        fill="#fff"
                                        opacity="0"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="mute-button"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    onClick={toggleVideoMute}
                                >
                                    <path
                                        id="Icon_metro-volume-mute2"
                                        data-name="Icon metro-volume-mute2"
                                        d="M17.571,12.53v1.326H16.245l-1.674-1.674L12.9,13.856H11.571V12.53l1.674-1.674L11.571,9.182V7.856H12.9L14.571,9.53l1.674-1.674h1.326V9.182L15.9,10.856Zm-8.5,5.326a.5.5,0,0,1-.354-.146L4.864,13.856H3.071a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5H4.864L8.717,4a.5.5,0,0,1,.854.354v13a.5.5,0,0,1-.5.5Z"
                                        transform="translate(2.429 1.144)"
                                        fill="#fff"
                                        opacity="0"
                                    />
                                    <path
                                        id="Icon_metro-volume-mute"
                                        data-name="Icon metro-volume-mute"
                                        d="M9.071,17.856a.5.5,0,0,1-.354-.146L4.864,13.856H3.071a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5H4.864L8.717,4a.5.5,0,0,1,.854.354v13a.5.5,0,0,1-.5.5Z"
                                        transform="translate(2.429 1.144)"
                                        fill="#fff"
                                    />
                                </svg>
                            )}
                            <svg
                                className="unmute-button"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                onClick={toggleVideoMute}
                            >
                                <path
                                    id="Icon_metro-volume-high"
                                    data-name="Icon metro-volume-high"
                                    d="M9.071,17.856a.5.5,0,0,1-.354-.146L4.864,13.856H3.071a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,.5-.5H4.864L8.717,4a.5.5,0,0,1,.854.354v13a.5.5,0,0,1-.5.5ZM16.717,9a.5.5,0,0,1-.854-.354v6.692a.5.5,0,0,1,.854-.354Zm-3.5-3a.5.5,0,0,1-.354-.854L13.363,9.5a.5.5,0,0,1,.707,0l.5.5a.5.5,0,0,1,0,.707l-.5.5a.5.5,0,0,1-.707,0L12.863,10.5a.5.5,0,0,1,.354-.854Z"
                                    transform="translate(2.429 1.144)"
                                    fill="#fff"
                                    opacity="0"
                                />
                            </svg>
                        </div> */}
            <img
              src="/images/static/home-banner-new.png"
              alt="banner"
              style={{ width: "100%", maxHeight: "650px", objectFit: "cover" }}
            ></img>
          </div>
        )}
      </StyledComponent>
      {ticketDetails && ticketDetails.length > 0 ? <Evento /> : null}
    </section>
  );
};

const StyledComponent = styled.section`
  padding-bottom: 120px;
  //   height: 75vh;
  position: relative;
  margin-bottom: 120px;
  //margin-bottom: 500px;

  #banner-video {
    padding: 0 30px 0 30px;
  }

  video {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .play-pause-button {
    position: absolute;
    bottom: 20px;
    right: 85px;
    cursor: pointer;
    z-index: 1;
  }

  .mute-buttons {
    position: absolute;
    bottom: 20px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .mute-button {
    width: 24px;
    height: 24px;
    fill: #fff;
    cursor: pointer;
  }

  .unmute-button {
    width: 24px;
    height: 24px;
    fill: #fff;
    cursor: pointer;
    opacity: 0;
  }

  .container {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    transform: translateY(50%);
    z-index: 1;
  }

  @media (max-width: 767px) {
    #banner-video {
      padding: 0;
    }

    .play-pause-button {
      opacity: 0;
    }

    .mute-buttons {
      opacity: 0;
    }
  }
`;

export default MyComponent;
