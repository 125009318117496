import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Button from "../Button";
import {Form} from "react-bootstrap";
import Calendar from "react-calendar";
import {BrowserRouter as Router, Switch, Route, useLocation, useHistory} from 'react-router-dom';
import ButtonA from "../ButtonA";
import Img from "../Img";


const Timelines = () => {

    const [date, setDate] = useState('')
    let calenderItemRef = useRef(null)
    const router = useLocation()

    const handleCalender = (Date, value) => {
        setDate(Date)

    }


    //Counter

    const [adultCounter, setAdultCounter] = useState(0);
    const [childCounter, setChildCounter] = useState(0);

    const handleIncrease = (type) => {
        if (type === 'adult') {
            setAdultCounter(adultCounter + 1);
        } else if (type === 'child') {
            setChildCounter(childCounter + 1);
        }
    };


    const handleDecrease = (type) => {
        if (type === 'adult' && adultCounter > 0) {
            setAdultCounter(adultCounter - 1);
        } else if (type === 'child' && childCounter > 0) {
            setChildCounter(childCounter - 1);
        }
    };

    // password hidden
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };


    const [currentStep, setCurrentStep] = useState('ticket');
    const location = useLocation();
    const history = useHistory();


    //Add params in url
    const handleContinue = (status) => {
        setCurrentStep(status);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('status', status);
        history.push({search: queryParams.toString()});
    };

    //get params for each circle
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        if (status) {
            setCurrentStep(status);
        }
    }, [location.search]);

    const renderCircle = (status) => {
        const isActive = status === currentStep;
        const stepNumber = status === 'product' ? 1 : status === 'checkout' ? 2 : 3;


        return (
            <div
                className={`circles ${isActive ? 'active' : ''}`}
                onClick={() => handleContinue(status)}
            >
                <div className={`circles-number ${isActive ? 'active-number' : ''}`}>
                    {stepNumber}
                </div>
                <div className="circles-label">
                    {status === 'product' && 'product'}
                    {status === 'checkout' && 'checkout'}
                    {status === 'payment' && 'Payment'}
                </div>
            </div>
        );
    };

    return (
        <Router>
            <StyledTimelines>
                <div className="custom-timeline">
                    <div className="circles-wrap">
                        {renderCircle('product')}
                        {renderCircle('checkout')}
                        {renderCircle('payment')}
                    </div>
                    <div className="show-contents">
                        <Switch>
                            <Route exact path="/product">
                                {/* Render the appropriate component based on currentStep */}
                                {currentStep === 'product' && (
                                    <div>
                                        <p>Process 1</p>
                                    </div>
                                )}
                                {currentStep === 'checkout' && (
                                    <div>
                                        <p>Process 2</p>
                                    </div>
                                )}
                                {currentStep === 'payment' && (
                                    <div>
                                        <p>Process 3</p>
                                    </div>
                                )}
                            </Route>
                        </Switch>
                    </div>
                </div>
            </StyledTimelines>
        </Router>
    );
};

const StyledTimelines = styled.div`


  .circles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;

  }

  .circles-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 60%;
  }

  .circles-wrap:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    width: 90%;
    background: #2D3691;
    opacity: 0.2;
    height: 2px;
    top: 30%;
  }

  .circles-wrap:after {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    width: 25%;
    background: #E8E8E8;
    height: 2px;
    top: 30%;
  }

  .circles-wrap:last-child:after {
    display: none !important;
  }


  .circles-number {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #92278F;
    position: relative;
    background: #FFFFFF;
    color: #92278F;
    transition: 0.3s ease-in-out;
  }

  .circles-number.active-number {
    background: #92278F;
    color: #fff;
    border: none;
  }

  .circles-label {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #1A1818;
  }

  .circles.active > .circles-label {
    color: #92278F;
  }


  .process-btn {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    gap: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }


  .show-contents {
    margin-top: 40px;

    .tc {
      border: 1px solid #E8E8E8;
      margin-top: 30px;
      //border-top: 1px solid #E8E8E8;

      .title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
          padding-top: 17px;
          padding-bottom: 19px;
          padding-left: 20px;
          border-bottom: 1px solid #E8E8E8;
          //background-color: #F9F9F9;
        }
      }

      .ticketing {
        display: flex;
        padding-left: 20px;
        padding-right: 30px;
        background-color: #ffffff;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 30px;
        width: 100%;

        &__img {
          margin-right: 20px;
          width: 70px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        &__info {
          width: calc(100% - 70px);

          &__details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #F9F9F9;

            &:last-child {
              border-bottom: 0;
              padding-bottom: 0;
              padding-top: 15px;
            }

            &__list {
              &__type {
                span {
                  display: block;
                  margin-right: 50px;

                  h4 {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    color: #1A1818;
                  }

                  p {
                    width: max-content;
                    color: #989898;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 300;
                  }
                }
              }
            }

            &__btn {
              align-self: flex-end;

              input {
                border-radius: 50%;
                border: none;
                height: 25px;
                width: 25px;
              }

              .increase-section__button {
                width: 150px;
                justify-content: space-between;
                padding: 0 2px;
                background-color: white;
                border: 1px solid #E8E8E8;
                border-radius: 50px;
                height: 40px;
                display: flex;
                align-items: center;
                @media (max-width: 767px) {
                  width: 108px;
                  height: 40px;
                }

                span {
                  color: #1A1818;
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 19px;
                }

              }
            }

            .price {
              color: #92278F;
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
            }
          }
        }
      }

    }

    .details {
      margin-top: 30px;
      background-color: #f9f9f9;
      border: 1px solid #E8E8E8;

      h3 {
        color: #1A1818;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 20px;
        background-color: #f9f9f9;
        border: 1px solid #E9E9E9;
      }

      .account__form {
        padding: 20px 30px 40px 30px;
        background-color: #ffffff;
        //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

        .checkbox-single {
          margin-bottom: 25px;
          padding-top: 25px;

          .container {
            padding-bottom: 25px;
            border-bottom: 1px solid #E8E8E8;
            margin-bottom: 25px;
          }
        }

        .flex {
          display: flex;
          flex-wrap: wrap;
          //justify-content: space-between;

          .form-group {
            &:last-child {
              flex: 0 0 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .detail {
      margin-top: 20px;

      .visiting {
        justify-content: space-between;
        background-color: #E9E9E9;
        padding: 20px 17px;

        .visiting-title {
          h3 {
            color: #1A1818;
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
          }
        }

        .visiting-data {
          display: flex;
          justify-content: space-between;
          align-self: center;
          gap: 20px;

          p {
            color: #1A1818;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            margin-right: 16px;
          }

          .status {
            ul {
              display: flex;
              justify-content: space-between;
              //gap: 20px;

              li {
                position: relative;
                color: #1A1818;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0;

                  &::before {
                    position: absolute;
                    content: "";
                    top: -5px;
                    left: -25px;
                    border-radius: 50%;
                    background-color: rgb(90, 209, 209);
                    opacity: 100%;
                  }
                }

                &::before {
                  position: absolute;
                  content: "";
                  top: -5px;
                  left: -25px;
                  padding: 10px !important;
                  //left: -40px;
                  display: inline-block;
                  width: 5px !important;
                  height: 5px !important;
                  //margin-left: 20px;
                  margin-left: 0 !important;
                  border-radius: 50%;
                  opacity: 10%;
                  background-color: #EC1B34;
                }

                &:after {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .price-box {
      gap: 30px;
    }

    .circle-wrap {
      width: 100%;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .price-box {
      gap: 30px;
    }

    .circle-wrap {
      width: 100%;
    }
  }
`;

export default Timelines;
