import React from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Container} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";

const InnerBanner = ({img, title}) => {
    return (
        <StyledInnerBanner className='InnerBanner'>
            <Img banner={true} src={img}/>
            <Container>
                <h2 className={`anim-active fade-up`}>{reactHtmlParser( title )}</h2>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(350 / 1366 * 100%);
  position: relative;


  //&:after {
  //  content: '';
  //  position: absolute;
  //  background-color: rgba(0, 0, 0, 0.25);
  //  height: 100%;
  //  width: 100%;
  //  left: 0;
  //  top: 0;
  //}

  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 40px;
    z-index: 2;
  }

  h2 {
    left: 15px;
    color: #ffffff;
    font-size: 40px;
    font-weight: 400;
    line-height: 53px;
    z-index: 2;
    
    display: flex;
    justify-content: center;

    //span {
    //  font-weight: 600;
    //  color: #ffffff;
    //}
  }

  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    padding-top: calc(350 / 375 * 100%);
    
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export default InnerBanner;
