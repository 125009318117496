import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";
import {apiEndPoints} from "../../network/apiEndPoints";


const initialState = {
    loading: false,
    detailLoading: false,
    token: '',
    message: '',
    id: '',
    userData: {},
}

export const submitSignup = createAsyncThunk("signup", (params) => {
    let postBody = [
        apiEndPoints.SIGNUP,
        params
    ]
    return post(postBody);
});

export const submitGuestSignup = createAsyncThunk("guestSignup", (params) => {
    let postBody = [
        apiEndPoints.GUEST_SIGNUP,
        params
    ]
    return post(postBody);
});

export const submitGuestSignIn = createAsyncThunk("guestSignIn", (params) => {
    let postBody = [
        apiEndPoints.GUEST_LOGIN,
        params
    ]
    return post(postBody);
});

export const submitSignIn = createAsyncThunk("signIn", (params) => {
    let postBody = [
        apiEndPoints.LOGIN,
        params
    ]
    return post(postBody);
});


export const submitOtpSignIn = createAsyncThunk("otpSignIn", (params) => {
    let postBody = [
        apiEndPoints.OTP_LOGIN,
        params
    ]
    return post(postBody);
});

export const submitOtpSignInVerify = createAsyncThunk("otpSignInVerify", (params) => {
    let postBody = [
        apiEndPoints.OTP_LOGIN_VERIFY,
        params
    ]
    return post(postBody);
});

export const getProfile = createAsyncThunk("getProfile", (params) => {
    let url = [
        `${apiEndPoints.GET_PROFILE}?user_id=${params.id}&token=${params.token}`
    ]
    return get(url);
});

export const updateProfile = createAsyncThunk("updateProfile", (params) => {
    let postBody = [
        apiEndPoints.UPDATE_PROFILE,
        params
    ]
    return post(postBody);
});

export const forgotPassword = createAsyncThunk("forgotPassword", (params) => {
    let postBody = [
        apiEndPoints.FORGOT_PASSWORD,
        params
    ]
    return post(postBody);
});

export const passwordSet = createAsyncThunk("passwordSet", (params) => {
    let postBody = [
        apiEndPoints.PASSWORD_SET,
        params
    ]
    return post(postBody);
});

export const clearToken = createAsyncThunk("clearToken", (params) => {
    return '';
});


const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: builder => {
        builder.addCase(submitSignup.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(submitSignup.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.error = ''
        })
        builder.addCase(submitSignup.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(submitGuestSignup.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(submitGuestSignup.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.error = ''
        })
        builder.addCase(submitGuestSignup.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(submitSignIn.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(submitSignIn.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.token = action?.payload?.data?.token ? action.payload?.data?.token : ''
            state.error = ''
        })
        builder.addCase(submitSignIn.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(submitOtpSignIn.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(submitOtpSignIn.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.error = ''
        })
        builder.addCase(submitOtpSignIn.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(submitGuestSignIn.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(submitGuestSignIn.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.token = action?.payload?.data?.token ? action.payload?.data?.token : ''
            state.userData = action?.payload
            state.id = action?.payload?.data?.id ? action.payload?.data?.id : ''
            localStorage.setItem('token', action?.payload?.data?.token ? action.payload?.data?.token : '')
            localStorage.setItem('id', action?.payload?.data?.id ? action.payload?.data?.id : '')
            state.error = ''
        })
        builder.addCase(submitGuestSignIn.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(submitOtpSignInVerify.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(submitOtpSignInVerify.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.token = action?.payload?.data?.token ? action.payload?.data?.token : ''
            state.userData = action?.payload
            state.id = action?.payload?.data?.id ? action.payload?.data?.id : ''
            localStorage.setItem('token', action?.payload?.data?.token ? action.payload?.data?.token : '')
            localStorage.setItem('id', action?.payload?.data?.id ? action.payload?.data?.id : '')
            state.error = ''
        })
        builder.addCase(submitOtpSignInVerify.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(getProfile.pending, (state) => {
            state.loading = true
            state.userData = {}
            state.error = ''
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.loading = false
            state.userData = action.payload
            state.error = ''
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            state.loading = false
            state.userData = {}
            state.error = action?.response
        })
        builder.addCase(updateProfile.pending, (state) => {
            state.loading = true
            state.message = ''

            state.error = ''
        })
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.token = action?.payload?.data?.token ? action.payload?.data?.token : ''
            state.userData = action?.payload
            state.id = action?.payload?.data?.id ? action.payload?.data?.id : ''
            localStorage.setItem('token', action?.payload?.data?.token ? action.payload?.data?.token : '')
            localStorage.setItem('id', action?.payload?.data?.id ? action.payload?.data?.id : '')
            state.error = ''
        })
        builder.addCase(updateProfile.rejected, (state, action) => {
            state.loading = false
            state.message = []
            state.error = action?.response
        })
        builder.addCase(clearToken.pending, (state) => {
            state.token = ''
            state.id = ''
            state.userData = {}
        })
        builder.addCase(clearToken.fulfilled, (state, action) => {
            localStorage.removeItem('date')
            sessionStorage.removeItem('selectedTickets')
            state.token = ''
            state.id = ''
            state.userData = {}
        })
        builder.addCase(clearToken.rejected, (state, action) => {
            state.token = ''
            state.id = ''
            state.userData = {}
        })
        builder.addCase(forgotPassword.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.error = ''
        })
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.loading = false
            state.message = ''
            state.error = action?.response
        })
        builder.addCase(passwordSet.pending, (state) => {
            state.loading = true
            state.message = ''
            state.error = ''
        })
        builder.addCase(passwordSet.fulfilled, (state, action) => {
            state.loading = false
            state.message = action.payload
            state.error = ''
        })
        builder.addCase(passwordSet.rejected, (state, action) => {
            state.loading = false
            state.message = ''
            state.error = action?.response
        })
    }
})


export default authSlice.reducer
