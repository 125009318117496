import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

const MyComponent = ({data}) => {
    return (
        <StyledComponent>
            <div className="ocean">
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
            <Container className="sanctuary">
                <div className="content">
                    <div className="background">
                        <div className="background__top">
                            <svg xmlns="http://www.w3.org/2000/svg" width="148.412" height="126.488" viewBox="0 0 148.412 126.488">
                                <path id="Path_6585" data-name="Path 6585" d="M182.032,125H35.367L108.7,0Z" transform="translate(-34.493 0.987)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.2"/>
                            </svg>
                        </div>
                        <div className="background__bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" width="148.412" height="126.488" viewBox="0 0 148.412 126.488">
                                <path id="Path_6585" data-name="Path 6585" d="M182.032,125H35.367L108.7,0Z" transform="translate(-34.493 0.987)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.2"/>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="148.412" height="126.488" viewBox="0 0 148.412 126.488">
                                <path id="Path_6585" data-name="Path 6585" d="M182.032,125H35.367L108.7,0Z" transform="translate(-34.493 0.987)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.2"/>
                            </svg>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <p className={'split-up'} >{data?.subtitle}</p>
                        <h2 className={'split-up'}>{data?.title}</h2>
                    </div>
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #5AD1D1;
  position: relative;
  z-index: -1;

  .ocean {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #5AD1D1;

    .wave {
      background: url('/images/static/wave-01.svg') repeat-x;
      position: absolute;
      top: -130px;
      width: 6400px;
      height: 140px;
      animation: wave 10s -3s linear infinite;
      transform: translate3d(0, 0, 0);
      opacity: 20%;
    }

    .wave:nth-of-type(2) {
      background: url('/images/static/wave-02.svg') repeat-x;
      top: -130px;
      animation: wave 18s linear reverse infinite;
      //opacity: 1;
      //left: -250px;
      opacity: 20%;
    }

    @keyframes wave {
      0% {
        margin-left: 0;
      }
      100% {
        margin-left: -1600px;
      }
    }

    @keyframes swell {
      0%, 100% {
        transform: translate3d(0, -25px, 0);
      }
      50% {
        transform: translate3d(0, 5px, 0);
      }
    }

  }


  .sanctuary {
    padding-top: 180px;
    padding-bottom: 220px;
    .content {
      position: relative;
      //padding-bottom: 210px;

      .background {
        position: relative;

        &__top {
          //position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__bottom {
          //position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .content-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        width: 100%;
        transform: translate(-50%, -50%);

        h2 {
          font-size: 60px;
          line-height: 60px;
          font-weight: 400;
          color: #fff;
        }

        p {
          font-size: 40px;
          line-height: 60px;
          font-weight: 300;
          color: #fff;
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 100px;
      padding-bottom: 120px;
      .content {
        //padding-top: 100px;
        padding-bottom: 100px;

        .content-wrapper {
          h2 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
          }

          p {
            font-size: 18px;
            line-height: 30px;
            font-weight: 300;
          }
        }
      }
    }
  }
`;

export default MyComponent;
