import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Button from "../Button";
import {Form} from "react-bootstrap";
import Calendar from "react-calendar";
import Address from "../checkout/Address";
import {useDispatch, useSelector} from "react-redux";
import {cart} from "../../api/redux/checkout";
import {getProfile} from "../../api/redux/auth";

const Timeline = () => {


    // let dateClickRef = useRef(null)
    // let calenderRef = useRef(null)
    // let calenderLeftRef = useRef(null)
    // let hasSuggestionRefAll = useRef([])
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState('')
    let calenderItemRef = useRef(null)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [address, setAddress] = useState()
    const [postcode, setPostcode] = useState()
    const dispatch = useDispatch()
    const userData = useSelector(state => state?.auth?.userData?.data);

    useEffect(() => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let params = {
            token: token,
            id: id,
        }
        if (token && token !== '') {
            dispatch(getProfile(params))
        }
    }, [])


    const handleCalender = (Date, value) => {
        setDate(Date)

    }


    //Counter

    const [adultCounter, setAdultCounter] = useState(0);
    const [childCounter, setChildCounter] = useState(0);

    const handleIncrease = (type) => {
        if (type === 'adult') {
            setAdultCounter(adultCounter + 1);
        } else if (type === 'child') {
            setChildCounter(childCounter + 1);
        }
    };


    const handleDecrease = (type) => {
        if (type === 'adult' && adultCounter > 0) {
            setAdultCounter(adultCounter - 1);
        } else if (type === 'child' && childCounter > 0) {
            setChildCounter(childCounter - 1);
        }
    };

    // password hidden
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };


    //Timeline process
    const [currentStep, setCurrentStep] = useState(1);

    const handleContinue = (step) => {
        setCurrentStep(step);
    };

    const renderCircle = (step) => {
        const isActive = step === currentStep;
        return (
            <div
                className={`circle ${isActive ? 'active' : ''}`}
                onClick={() => handleContinue(step)}
            >
                <div className={`circle-number ${isActive ? 'active-number' : ''}`}>{step}</div>
                <div className="circle-label">
                    {step === 1 && 'Ticket'}
                    {step === 2 && 'Date'}
                    {step === 3 && 'Details'}
                    {step === 4 && 'Payment'}
                </div>
            </div>
        );
    };

    return (
        <StyledTimeline>
            <div className="timeline">
                <div className="circle-wrap">
                    {renderCircle(1)}
                    {renderCircle(2)}
                    {renderCircle(3)}
                    {renderCircle(4)}
                </div>
                <div className={'show-content'}>
                    {
                        currentStep === 1 &&
                        <div>
                            <div className={'tc'}>
                                <div className={'title'}>
                                    <h3>Full Day Entry</h3>
                                </div>
                                <div className={'ticketing'}>
                                    <div className={'ticketing__img'}>
                                        <img src={'/images/dynamic/ticket.jpg'}/>
                                    </div>
                                    <div className={'ticketing__list'}>
                                        <div className={'ticketing__list__type'}>
                                            <span>
                                                <h4>Adults</h4>
                                                <p>Height: >5 Feet</p>
                                            </span>
                                        </div>
                                        <div className={'ticketing__list__price'}>
                                            <p>BDT1000</p>
                                        </div>
                                    </div>
                                    <div className={'ticketing__btn'}>
                                        <div className="increase-section__button">
                                            <input type="button" value="-"
                                                   onClick={() => handleDecrease('adult')}
                                                   disabled={adultCounter === 0}/>
                                            <span>{adultCounter}</span>
                                            <input type="button" value="+"
                                                   onClick={() => handleIncrease('adult')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        currentStep === 2 &&
                        <div>
                            <Address userData={userData} handleContinue={handleContinue}
                                     firstName={firstName} lastName={lastName}
                                     email={email} phoneNumber={phoneNumber}
                                     postcode={postcode} address={address}
                                     setFirstName={setFirstName} setLastName={setLastName}
                                     setEmail={setEmail} setPhoneNumber={setPhoneNumber}
                                     setPostcode={setPostcode} setAddress={setAddress}
                                     loading={loading} setLoading={setLoading}
                            />

                        </div>
                    }
                    {
                        currentStep === 3 &&
                        <div>
                            <div className={'detail'}>
                                <div className={'visiting d-flex'}>
                                    <div className={'visiting-title'}>
                                        <h3>Visiting Day</h3>
                                    </div>
                                    <div className={'visiting-data'}>
                                    <span>
                                        <p>Cavana Status:</p>
                                    </span>
                                        <span className={'status'}>
                                        <ul>
                                       <li>
                                           Not Avaialable
                                       </li>
                                       <li>
                                           Selected
                                       </li>
                                   </ul>
                                    </span>

                                    </div>
                                </div>
                                <div className={'calendar'}>
                                    <div className="calender-all-item" ref={calenderItemRef}>
                                        <Calendar
                                            onChange={handleCalender}
                                            value={date}
                                            // formatMonthYear={(locale, date) =>(date, ['Jan 20121', 'Feb 2021'])}
                                            formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
                                            prev2Label={''}
                                            next2Label={''}
                                            tileClassName="tile--active"
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        currentStep === 4 && <div>
                            <div className={'details'}>
                                <h3>Payment</h3>
                                <div className={'account__form'}>
                                    <div className={'form__fields'}>
                                        <div className="account__form__container">
                                            <div className="form-group-wrap">
                                                <Form className={'form'}>
                                                    <div className="checkbox-single">

                                                        <Form.Group>
                                                            <label className="container">
                                                                <input type="checkbox"/>
                                                                <span className="checkmark"></span>
                                                                <span className="label-text">Pay with Visa Card</span>
                                                            </label>
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <label className="container">
                                                                <input type="checkbox"/>
                                                                <span className="checkmark"></span>
                                                                <span className="label-text">Pay with SSLCommerz</span>
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        lineHeight: '14px',
                                                                        color: '#989898',
                                                                        display: 'block',
                                                                        paddingLeft: '13px',
                                                                        marginTop: '5px'
                                                                    }}>(Debit/Credit Cards, Mobile Banking, Internet Banking)</span>
                                                            </label>
                                                        </Form.Group>
                                                    </div>
                                                </Form>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={'process-btn d-flex justify-content-center'}>
                    {currentStep > 1 && (
                        <Button color={'#1A1818'} borderColor={'#1A1818'} hoverfill={'#1A1818'} text={'Back'}
                                onClick={() => handleContinue(currentStep - 1)}/>
                    )}
                    {currentStep < 4 && (
                        <Button color={'#92278F'} borderColor={'#92278F'} hoverfill={'#92278F'} text={'Continue'}
                                onClick={() => handleContinue(currentStep + 1)}/>
                    )}

                </div>
            </div>

        </StyledTimeline>
    );
};

const StyledTimeline = styled.div`


  .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;

  }

  .circle-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 60%;
  }

  .circle-wrap:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    width: 85%;
    background: #2D3691;
    opacity: 0.2;
    height: 2px;
    top: 30%;
  }

  .circle-wrap:after {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    width: 25%;
    background: #E8E8E8;
    height: 2px;
    top: 30%;
  }

  .circle-wrap:last-child:after {
    display: none !important;
  }


  .circle-number {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #92278F;
    position: relative;
    background: #FFFFFF;
    color: #92278F;
    transition: 0.3s ease-in-out;
  }

  .circle-number.active-number {
    background: #92278F;
    color: #fff;
    border: none;
  }

  .circle-label {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #1A1818;
  }

  .circle.active > .circle-label {
    color: #92278F;
  }


  .process-btn {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    gap: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }


  .show-content {
    margin-top: 40px;

    .tc {
      border: 1px solid #E8E8E8;
      //border-top: 1px solid #E8E8E8;
      .ticket__title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
          padding-top: 17px;
          padding-bottom: 19px;
          padding-left: 20px;
          border-bottom: 1px solid #E8E8E8;
          //background-color: #F9F9F9;
        }
      }

      .ticket {
        display: flex;
        padding: 20px;
        background-color: #FFFFFF;
        width: 100%;
        justify-content: space-around;

        &__img {
          img {
            height: 110px;
            width: 90px;
          }
        }

        &__buy {
          display: block;

          .type {
            margin-right: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            span {
              display: block;
              margin-right: 50px;

              h4 {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: #1A1818;
              }

              p {
                width: max-content;
                color: #989898;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
              }
            }
          }

          .btn {
            align-self: flex-end;

            input {
              border-radius: 50%;
              border: none;
              height: 25px;
              width: 25px;

            }

            .increase-section__button {
              width: 150px;
              justify-content: space-between;
              padding: 0 2px;
              background-color: white;
              border: 1px solid #E8E8E8;
              border-radius: 50px;
              height: 40px;
              display: flex;
              align-items: center;


              span {
                color: #1A1818;
                font-weight: 300;
                font-size: 14px;
                line-height: 19px;
              }

            }
          }

          p {
            color: #92278F;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
          }
        }
      }
    }

    .details {
      margin-top: 30px;
      background-color: #f9f9f9;
      border: 1px solid #E8E8E8;

      h3 {
        color: #1A1818;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 20px;
        background-color: #f9f9f9;
        border: 1px solid #E9E9E9;
      }

      .account__form {
        padding: 20px 30px 40px 30px;
        background-color: #ffffff;
        //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

        .checkbox-single {
          margin-bottom: 25px;
          padding-top: 25px;

          .container {
            padding-bottom: 25px;
            border-bottom: 1px solid #E8E8E8;
            margin-bottom: 25px;
          }
        }

        .flex {
          display: flex;
          flex-wrap: wrap;
          //justify-content: space-between;

          .form-group {
            &:last-child {
              flex: 0 0 100%;
              width: 100%;
            }
          }
        }
      }
    }

    .detail {
      margin-top: 20px;

      .visiting {
        justify-content: space-between;
        background-color: #E9E9E9;
        padding: 20px 17px;

        .visiting-title {
          h3 {
            color: #1A1818;
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
          }
        }

        .visiting-data {
          display: flex;
          justify-content: space-between;
          align-self: center;
          gap: 20px;

          p {
            color: #1A1818;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            margin-right: 16px;
          }

          .status {
            ul {
              display: flex;
              justify-content: space-between;
              //gap: 20px;

              li {
                position: relative;
                color: #1A1818;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0;

                  &::before {
                    position: absolute;
                    content: "";
                    top: -5px;
                    left: -25px;
                    border-radius: 50%;
                    background-color: rgb(90, 209, 209);
                    opacity: 100%;
                  }
                }

                &::before {
                  position: absolute;
                  content: "";
                  top: -5px;
                  left: -25px;
                  padding: 10px !important;
                  //left: -40px;
                  display: inline-block;
                  width: 5px !important;
                  height: 5px !important;
                  //margin-left: 20px;
                  margin-left: 0 !important;
                  border-radius: 50%;
                  opacity: 10%;
                  background-color: #EC1B34;
                }

                &:after {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }


  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    .price-box {
      gap: 30px;
    }

    .circle-wrap {
      width: 100%;
    }
  }


  /* small mobile :320px. */
  @media (max-width: 767px) {
    .price-box {
      gap: 30px;
    }

    .circle-wrap {
      width: 100%;
    }
  }
`;

export default Timeline;
