import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Img from "./Img";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({title, data}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsMobile(windowWidth <= 767);
    }, [windowWidth]);
    return (
        <StyledComponent>

            <Container fluid className="sanctuary">
                <Row>
                    <Col>
                        <img
                            className={'sanc-btm'}
                            src={windowWidth > 767 ? (isMobile ? '/images/static/rides/katamob.svg' : '/images/static/rides/katakata3.svg') : '/images/static/rides/katamob.svg'}
                            alt=""
                        />
                        <div className="content">
                            <div className="background">
                                <div className="background__top">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="148.412" height="126.488" viewBox="0 0 148.412 126.488">
                                        <path id="Path_6585" data-name="Path 6585" d="M182.032,125H35.367L108.7,0Z" transform="translate(-34.493 0.987)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.2"/>
                                    </svg>
                                </div>
                                <div className="background__bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="148.412" height="126.488" viewBox="0 0 148.412 126.488">
                                        <path id="Path_6585" data-name="Path 6585" d="M182.032,125H35.367L108.7,0Z" transform="translate(-34.493 0.987)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.2"/>
                                    </svg>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="148.412" height="126.488" viewBox="0 0 148.412 126.488">
                                        <path id="Path_6585" data-name="Path 6585" d="M182.032,125H35.367L108.7,0Z" transform="translate(-34.493 0.987)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.2"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <p>{data?.title}</p>
                                <h2 className={'split-up'}> {data?.subtitle ? data?.subtitle : data?.description}</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #f9f9f9;
  position: relative;
  padding: 40px 30px;

  .sanc-btm{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -263px;
    left: 0;
    right: 0;
    
  }
  .sanctuary {
    padding-top: 130px;
    padding-bottom: 130px;
    background: #5AD1D1;
    .content {
      position: relative;
      .background {
        position: relative;

        &__top {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__bottom {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .content-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        width: 100%;
        transform: translate(-50%, -50%);

        h2 {
          font-size: 60px;
          line-height: 60px;
          font-weight: 400;
          color: #fff;
        }

        p {
          font-size: 40px;
          line-height: 60px;
          font-weight: 300;
          color: #fff;
        }
      }
    }

    @media (max-width: 767px) {
      //img{
      //  bottom: -359px;
      //}
      margin: 30px 0;
      .content {
        //padding-top: 100px;
        //padding-bottom: 100px;

        .content-wrapper {
          h2 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
          }

          p {
            font-size: 18px;
            line-height: 30px;
            font-weight: 300;
          }
        }
      }
    }
  }
`;

export default MyComponent;
