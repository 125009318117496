import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {orderList} from "../../api/redux/checkout";
import moment from "moment";
import {Loading} from "../Loading";

const MyComponent = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    let orders = useSelector(state => state?.checkout?.orderList);
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')

        if(token && token !== ''){
            setLoading(true)
            let params = {
                token: token,
                id: id
            }
            dispatch(orderList(params)).then(()=>{
                setLoading(false)
            })
        } else {
            history.push('/login')
        }
    }, [])
    return (
        <StyledComponent>
            <Container className={"order pb-80 pt-80"}>
                <Row>
                    <Col lg={{span: 6, offset: 3}}>
                        <div className={"order__box"}>
                            <div className={"title"}>
                                <img src={'/images/dynamic/icon.svg'}/>
                                <h3 className={"split-up"}>Order History</h3>
                            </div>
                            <div className="order__box__listing">
                                <ul>
                                    {
                                        orders ? orders.length > 0 ? orders.map((e)=>{
                                            return(
                                                <li>
                                                    <div className="left">
                                                        <h3>OrderID: #{e.id}</h3>
                                                        <p> Order Data : <span className={'date'}>{moment(e.date_created).format('DD MMM YYYY')}</span></p>
                                                    </div>
                                                    <div className="right">
                                                        <Link to={`/order/details/${e.id}`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                                                 viewBox="0 0 40 40">
                                                                <g id="Group_19609" data-name="Group 19609"
                                                                   transform="translate(-905 -342)">
                                                                    <g id="Ellipse_449" data-name="Ellipse 449"
                                                                       transform="translate(905 342)" fill="#fff" stroke="#989898"
                                                                       stroke-width="1">
                                                                        <circle className={'btnhover'} cx="20" cy="20" r="20" stroke="none"/>
                                                                        <circle cx="20" cy="20" r="19.5" fill="none"/>
                                                                    </g>
                                                                    <g id="g2060" transform="translate(929.248 368) rotate(180)">
                                                                        <path id="path2056" d="M0,0,6.5,6"
                                                                              transform="translate(0 6)"
                                                                              fill="none" stroke="#989898" stroke-linecap="round"
                                                                              stroke-linejoin="round" stroke-width="1"/>
                                                                        <path id="path2058" d="M0,6,6.5,0" transform="translate(0)"
                                                                              fill="none" stroke="#989898" stroke-linecap="round"
                                                                              stroke-linejoin="round" stroke-width="1"/>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </li>
                                            )
                                        }) : null : null
                                    }

                                </ul>


                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .order {


    &__box {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
      padding: 0;

      h3 {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
      }

      .title {
        position: relative;
        padding: 45px 0 40px 30px;
        background-color: #92278F;

        img {
          width: 100%;
          position: absolute;
          bottom: -15px;
          left: 0;
        }

      }

      &__listing {
        margin-top: 30px;
        padding: 0 30px;
      
        
          ul {
            li {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              padding: 25px 0;
              border-bottom: 1px solid #E8E8E8;
              &:last-child{
                border-bottom: none;
              }
              .left{
                h3 {
                  font-size: 24px;
                  line-height: 30px;
                  font-weight: 400;
                  color: #1A1818;
                  margin-bottom: 15px;
                }

                p {
                  font-size: 14px;
                  color: #989898;
                  line-height: 16px;
                  font-weight: 300;

                  span {
                    color: #434343;
                  }
                }
              }
              .right{
                svg {

                  .btnhover {
                    r: 0px;
                    fill: #92278F;
                    transition: 0.7s all ease;
                  }

                  path{
                    transition: 0.7s all ease;
                  }

                }
                &:hover {
                  svg {
                    .btnhover {
                      r: 19px;
                      transition: 0.7s all ease;
                    }

                    g{
                      stroke: #92278F;
                    }

                    line {
                      stroke: white;
                    }

                    path{
                      stroke: white;
                      fill: #ffffff;
                      transition: 0.7s all ease;
                    }
                  }
                }
              }
               
            }
          }
        

      


      }


    }
  }
`;

export default MyComponent;
