import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";


import Slider from "react-slick";


const ProjectDetails = ({images, thumb}) => {


    // hide slider

    const [isGreaterThan767px, setIsGreaterThan767px] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsGreaterThan767px(window.innerWidth > 991);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial check on component mount
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(window.innerWidth);

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (isGreaterThan767px && images?.length > 0) {
            if (nav1 && nav2) {
                nav1.slickGoTo(0);
                nav2.slickGoTo(0);
            }
        }
    }, [nav1, nav2]);


    const settings1 = {
        asNavFor: nav2,
        ref: (c) => setNav1(c),
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: true,
    };

    const settings2 = {
        asNavFor: nav1,
        ref: (c) => setNav2(c),
        slidesToShow: width < 768 ? 3 : 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        infinite: true,
    };


    const goToPrevSlide = () => {
        if (nav1 && nav2) {
            nav1.slickPrev();
            nav2.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (nav1 && nav2) {
            nav1.slickNext();
            nav2.slickNext();
        }
    };

    // const [Width, setWidth] = useState(90)
    //
    // useEffect(() => {
    //     setWidth(window.innerWidth)
    //
    //     window.addEventListener('load', function () {
    //         setWidth(window.innerWidth)
    //         console.log(Width)
    //
    //
    //     })
    //     window.addEventListener('resize', function () {
    //         setWidth(window.innerWidth)
    //         console.log(Width)
    //
    //
    //     })
    //     console.log(Width)
    // }, [])
    const [currentSlide, setCurrentSlide] = useState(0);


    // slider hide


    return (
        <StyledComponent className='project_details'>

            <div className=" slider-container row">
                <div className="main_slider_wrapper">
                    {images?.length > 0 &&

                        <Slider className={'main-slider'}
                                asNavFor={nav2}
                                arrows={false}
                                ref={(slider1) => setNav1(slider1)}
                                nextArrow={'.next'}
                                prevArrow={'.prev'}
                                infinite={false}
                                beforeChange={(oldIndex, newIndex) => setCurrentSlide(newIndex)}
                                
                        >


                            {images?.map((e, i) => (

                                <div key={i} className="single_item">
                                    <div className="image_wrapper">
                                        <img src={e} alt={''}/>
                                    </div>
                                </div>


                            ))

                            }


                        </Slider>
                    }
                    <div className="counter">
                        {
                            !isGreaterThan767px ? <p>Slide {currentSlide + 1} / {images?.length}</p> : ''
                        }
                    </div>
                </div>

                {isGreaterThan767px ?
                    <div className="navigation_bar">
                        {images?.length > 0 &&
                            <Slider className="thumbnail-slider"
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={4}
                                    infinite={false}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    arrows={false}
                                    loop={false}
                                    nextArrow={'.next'}
                                    prevArrow={'.prev'}
                                    slideGap={20}
                                    responsive={[
                                        {
                                            breakpoint: 768,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 1,
                                            },
                                        },
                                        {
                                            breakpoint: 1200,
                                            settings: {
                                                slidesToShow: 3,
                                                slidesToScroll: 1,
                                            },
                                        },
                                    ]}


                                // vertical={Width > 768 ? true : false}

                                // ref={(slider1) => setNav1(slider1)}

                            >

                                {images?.map((e, i) => (
                                    <div key={i} className="single_item">
                                        <div className="image_wrapper">
                                            <img src={e} alt={''}/>
                                        </div>

                                    </div>
                                ))}


                            </Slider>
                        }

                        <ul className={'navigation'}>
                            <li id={'parenting-prev'} className={'prev'} onClick={goToPrevSlide}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                     viewBox="0 0 40 40">
                                    <g id="Slider_Icon" data-name="Slider Icon"
                                       transform="translate(40 40) rotate(180)">
                                        <g id="Ellipse_5" data-name="Ellipse 5" fill="none" stroke="none"
                                           stroke-width="1">
                                            <circle cx="20" cy="20" r="19" stroke="#707070"/>
                                            <circle className={'btnhover'} cx="20" cy="20" r="0" fill="none"/>
                                        </g>


                                        <circle id="Ellipse_268" data-name="Ellipse 268" cx="1" cy="1" r="1"
                                                transform="translate(19 19)" fill="#ec1b34" opacity="0"/>
                                        <g id="Group_16184" data-name="Group 16184"
                                           transform="translate(12 25) rotate(-90)">
                                            <line id="Line_53" data-name="Line 53" y2="15.038"
                                                  transform="translate(5 0)"
                                                  fill="none" stroke="#707070" stroke-linecap="round"
                                                  stroke-width="1"/>
                                            <g id="g2060" transform="translate(0 16.038) rotate(-90)">
                                                <path id="path2056" d="M0,0,5.414,5" transform="translate(0 5)"
                                                      fill="none"
                                                      stroke="#707070" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="1"/>
                                                <path id="path2058" d="M0,5,5.414,0" transform="translate(0)"
                                                      fill="none"
                                                      stroke="#707070" stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                      stroke-width="1"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>


                            </li>
                            <li id={'parenting-next'} className={'next'} onClick={goToNextSlide}>
                                <svg id="Slider_Icon" data-name="Slider Icon" xmlns="http://www.w3.org/2000/svg"
                                     width="40"
                                     height="40" viewBox="0 0 40 40">
                                    <g id="Ellipse_4" data-name="Ellipse 4" fill="none" stroke="none"
                                       stroke-width="1">
                                        <circle cx="20" cy="20" r="19" stroke="#707070"/>
                                        <circle className={'btnhover'} cx="20" cy="20" r="0" fill="none"/>
                                    </g>
                                    <circle id="Ellipse_268" data-name="Ellipse 268" cx="1" cy="1" r="1"
                                            transform="translate(19 19)" fill="#ec1b34" opacity="0"/>
                                    <g id="Group_16184" data-name="Group 16184"
                                       transform="translate(12 25) rotate(-90)">
                                        <line id="Line_53" data-name="Line 53" y2="15.038"
                                              transform="translate(5 0)"
                                              fill="none" stroke="#707070" stroke-linecap="round"
                                              stroke-width="1"/>
                                        <g id="g2060" transform="translate(0 16.038) rotate(-90)">
                                            <path id="path2056" d="M0,0,5.414,5" transform="translate(0 5)"
                                                  fill="none"
                                                  stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1"/>
                                            <path id="path2058" d="M0,5,5.414,0" transform="translate(0)"
                                                  fill="none"
                                                  stroke="#707070" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1"/>
                                        </g>
                                    </g>
                                </svg>


                            </li>
                        </ul>

                    </div> : ''}

            </div>

        </StyledComponent>
    );
};
const StyledComponent = styled.div`
  position: relative;
  margin-bottom: 120px;


  .flex_display {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  .navigation_bar {
    //overflow: hidden;
    flex: 0 0 100%;
    //width: 100%;
    min-width: 100%;
    padding: 40px;
    height: 180px;
    background: #F9F9F9;
    position: relative;

    div {
      height: 100%;
    }

    .slick-slide {
      padding-bottom: 0px;
    }

    //
    //.slick-track {
    //  display: flex;
    //}

    .image_wrapper {
      position: relative;
      padding-top: 100%;

      //display: flex;
      //align-items: center;
      //justify-content: center;
      //margin-bottom: 30px;
      //height: 100%;
      width: 100%;


      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .single_item {
      border: 2px solid transparent;

    }

    .thumbnail-slider .slick-slide {
      margin-right: 10px; // Adjust the value as per your requirements
    }


  }

  .main_slider_wrapper {
    flex: 0 0 100%;
    width: 100%;

    .image_wrapper {
      //padding-top: calc(410 / 440 * 100%);
      //position: relative;
    }

    .counter {
      display: flex;
      width: 100%;
      justify-content: center;

      p {
        background: #92278F;
        width: max-content;
        padding: 5px 18px;
        border-radius: 30px;
      }
    }

  }


  .formobile {
    display: none;
  }

  .navigation {
    display: inline-flex;

    li {
      cursor: pointer;

      &:first-child {
        position: absolute;
        left: -50px;
        top: 30px;
        bottom: 0;
        height: fit-content;
        margin: auto;
        z-index: 9;
        transform: translateY(-50%);

      }

      &:last-child {
        position: absolute;
        right: -50px;
        top: 30px;
        bottom: 0;
        margin: auto;
        height: fit-content;
        z-index: 9;
        transform: translateY(-50%);

      }

      svg {

        .btnhover {
          r: 0px;
          fill: #FCB940;
          transition: 0.7s all ease;
        }

        path {
          transition: 0.7s all ease;
        }

      }

      &:first-child {
        margin-right: 20px;
      }

      &:hover {
        svg {
          .btnhover {
            r: 19px;
            transition: 0.7s all ease;
          }

          g {
            stroke: #FCB940;
          }

          line {
            stroke: white;
          }

          path {
            stroke: white;
            fill: #ffffff;
            transition: 0.7s all ease;
          }
        }
      }


    }
  }

  @media (max-width: 767px) {

    .formobile {
      display: block;

      &.content_wrapper {
        flex-direction: column;

        .flex {
          h3 {
            margin-bottom: 10px;
          }
        }

        .list {
          margin-bottom: 30px;
        }
      }
    }

    .pr-0 {
      padding-right: 15px !important;
    }

    .flex_display {
      height: auto;
      flex-wrap: unset;
      flex-direction: column-reverse;
    }

    .main_slider_wrapper {
      padding: 0;
      width: 100%;
      flex: 0 0 100%;
    }

    .navigation_bar {
      display: none;

      .image_wrapper {
        margin: 0;
      }
    }

    .hideInphone {
      display: none;
    }

  }

  @media (max-width: 991px) {

    margin-bottom: 20px !important;


    .navigation {
      visibility: hidden;
    }

    .navigation_bar {
      visibility: hidden;

    }

  }

  .navigation_bar .slick-track {
    display: flex;
    width: 100% !important;
  }

  .navigation_bar .slick-current .single_item {
    border-color: #92278F;
  }




`

export default ProjectDetails;
