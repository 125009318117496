import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPosts, fetchPostDetail } from "../../api/redux/post";
import { apiEndPoints } from "../../api/network/apiEndPoints";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { Img } from "../../components/Img";
import Title from "../../components/Title";
import InnerBanner from "../../components/InnerBanner";
import { Loading } from "../../components/Loading";
import BannerVideo from "../../components/home/BannerVideo";
import Todo_scroll from "../../components/home/TodoScroll";
import Zigzag from "../../components/home/Zigzag";
import Sanctuary from "../../components/home/Sanctuary";
import Momento from "../momento";
import MomentoSlider from "../../components/home/MomentoSlider";
import HomeMap from "../../components/home/HomeMap";
import Social from "../../components/home/Social";
import Footer from "../../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HappeningSlider from "../../components/HappeningSlider";
import { fetchData } from "../../api/redux/home";
import { fetchProductList } from "../../api/redux/products";

const Home = () => {
  const ticketData = useSelector((state) => state?.posts?.ticket);

  const dispath = useDispatch();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.HOME;
    dispath(fetchData([api_url]));
    // let api_product = `${apiEndPoints.PRODUCT_LIST}/all/10/1`
    let params = {
      slug: "all",
      limit: 10,
      page: 1,
    };
    dispath(fetchProductList(params));
  }, []);

  let getPost = useSelector((state) => state.home);

  let getProduct = useSelector((state) => state.products);

  const video = getPost?.posts[0]?.banner?.video;
  const happening = getPost?.posts[0]?.happening;
  const sanc = getPost?.posts[0]?.sanctuary;
  const zigzag = getPost?.posts[0]?.zigzag;
  const scroll = getPost?.posts[0]?.scroll;
  const map = getPost?.posts[0]?.map;
  const social = getPost?.posts[0]?.social;
  const products = getProduct?.products;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mana Bay water park</title>
        <meta
          name="description"
          content="In a world that is always on, Mana Bay offers sanctuary."
        />
      </Helmet>

      <StyledSection>
        <BannerVideo data={video} />
        {happening && happening?.slider && happening?.slider?.length > 0 ? (
          <HappeningSlider explore data={happening} />
        ) : null}
        <Sanctuary data={sanc} />
        <Todo_scroll datas={scroll} />
        <Zigzag data={zigzag} />
        {/*<MomentoSlider data={products}/>*/}
        <HomeMap data={map} />
        <Social data={social} />
      </StyledSection>
    </HelmetProvider>
  );
};

const StyledSection = styled.section`
  height: auto;
  position: relative;
  margin-bottom: 120px;
`;

export default Home;
