import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { Form } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-datepicker/dist/react-datepicker.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
  useParams,
  Link,
} from "react-router-dom";
import ButtonA from "../ButtonA";
import Img from "../Img";
import Tickets from "./Tickets";
import { useDispatch, useSelector } from "react-redux";
import Address from "../ticket/Address";
import { getProfile } from "../../api/redux/auth";
import { URL_LIVE } from "../../api/network/baseUrl";
import {
  cart,
  placeOrder,
  ticketPlaceOrder,
  getBkashToken,
} from "../../api/redux/checkout";
import { toast } from "react-toastify";
import moment from "moment";

const Timeline = ({
  ticketDetails,
  total,
  date,
  setDate,
  calculateTotalPrice,
  selectedTickets,
  setSelectedTickets,
}) => {
  let calenderItemRef = useRef(null);
  const router = useLocation();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState();
  const [postcode, setPostcode] = useState();
  const [otp, setOtp] = useState();
  const [isOtp, setIsOtp] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.userData?.data);
  const salesProgram = useSelector(
    (state) =>
      state?.ticket?.ticketInfo?.Envelope?.Body?.SalesProgram?.SalesProgramID
        ?._text
  );
  const [adultChildTicketSelected, setAdultChildTicketSelected] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("ssl");

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("id");
    let params = {
      token: token,
      id: id,
    };
    if (token && token !== "") {
      dispatch(getProfile(params));
    }
    calculateTotalPrice();
  }, []);

  useEffect(() => {
    if (paymentMethod === "bkash") {
      console.log("here");
      calculateTotalPrice("bkash");
      // calculateTotalPrice();
    } else {
      calculateTotalPrice();
    }
  }, [paymentMethod]);

  // const handleCalender = (Date, value) => {
  //   if (Date.getDate() === 1 && Date.getMonth() === 10) {
  //     return; // Do not allow selection
  //   }
  //   setDate(Date);
  //   sessionStorage.setItem("date", Date);
  //   sessionStorage.removeItem("selectedCabana");
  //   sessionStorage.removeItem("selectedTickets");
  //   setSelectedTickets({});
  // };

  const handleCalender = (newDate) => {
    // Check if newDate is a Date object
    if (newDate instanceof Date && newDate.getDate() === 1 && newDate.getMonth() === 10) {
      return; // Do not allow selection of November 1st
    }
    setDate(newDate); // Set the selected date
    sessionStorage.setItem("date", Date);
    sessionStorage.removeItem("selectedCabana");
    sessionStorage.removeItem("selectedTickets");
    setSelectedTickets({});
  };

  useEffect(() => {
    // If the current date is November 1st, change it to the next valid date
    if (date instanceof Date) {
      if (date.getDate() === 1 && date.getMonth() === 10) {
        // Set to November 2nd if selected date is November 1st
        setDate(new Date(date.getFullYear(), date.getMonth(), 2));
      }
    }
  }, [date, setDate]); // Run this effect every time `date` changes


  // order_date

  const handleOrderPlace = () => {
    setLoading(true);
    let token = localStorage.getItem("token");
    let id = localStorage.getItem("id");
    let storedDateString = sessionStorage.getItem("date");

    if (!storedDateString) {
      toast.error("Missing date field", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setCurrentStep("date");
      window.location.replace(`/ticket?status=date`);
    } else {
      let localDate = new Date(sessionStorage.getItem("date"));
      let tickets = sessionStorage.getItem("selectedTickets");
      let selectedCabana = JSON.parse(sessionStorage.getItem("selectedCabana"));
      let egalaxyToken = sessionStorage.getItem("ticketToken");
      let params = {
        token: token,
        id: id,
        domain: URL_LIVE,
      };
      let formData = new FormData();

      formData.append("user_id", id);
      formData.append("user_token", token);
      formData.append("egalaxy_session", egalaxyToken);
      formData.append("egalaxy_note", "tickets");
      formData.append("ticket_event_title", salesProgram);
      formData.append("discount", 0);
      formData.append("tax", 0);
      formData.append(
        "total",
        paymentMethod === "ssl"
          ? calculateTotalPrice()
          : paymentMethod === "bkash"
          ? calculateTotalPrice("bkash")
          : calculateTotalPrice()
        // calculateTotalPrice()
      );
      formData.append("order_date", moment(localDate).format("yyyy-MM-DD"));
      formData.append("payment_method", paymentMethod);
      formData.append("mobile", phoneNumber);
      // formData.append('domain_info', URL_LIVE)

      const orderline = Object.keys(selectedTickets).map((ticketId) => {
        const selectedTicket = ticketDetails.find(
          (ticket) => ticket.PLU._text === ticketId
        );

        // Check if the selectedTicket name contains 'cabana'
        if (selectedTicket.Name._text.toLowerCase().includes("cabana")) {
          const matchingCabana =
            selectedCabana &&
            selectedCabana !== "" &&
            selectedCabana.find((cabana) => cabana.PLU === ticketId);
          if (matchingCabana) {
            return {
              plu: parseInt(ticketId),
              detail_type: 1,
              description: selectedTicket.Name._text,
              qty: selectedTickets[ticketId],
              amount: parseFloat(selectedTicket.Price._text),
              total:
                parseFloat(selectedTicket.Price._text) *
                selectedTickets[ticketId],
              tax_amount: 0.0,
              taxid: 1,
              tax_unit_amount: 0.0,
              tax_description: "ticket_tax",
              eventid: matchingCabana.eventId,
              resource_id: matchingCabana.resourseId,
              ticket_datetime: `${moment(localDate).format(
                "yyyy-MM-DD"
              )} 0:00:00`,
              cavana_flag: 1,
            };
          }
        } else {
          return {
            plu: parseInt(ticketId),
            detail_type: 1,
            description: selectedTicket.Name._text,
            qty: selectedTickets[ticketId],
            eventid: 0,
            amount: parseFloat(selectedTicket.Price._text),
            total:
              parseFloat(selectedTicket.Price._text) *
              selectedTickets[ticketId],
            tax_amount: 0.0,
            taxid: 1,
            tax_unit_amount: 0.0,
            ticket_datetime: `${moment(localDate).format(
              "yyyy-MM-DD"
            )} 0:00:00`,
            tax_description: "ticket_tax",
          };
        }
      });

      const ordercontact = {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        street1: userData?.shipping_address?.address_1,
        street2: userData?.shipping_address?.address_1,
        city: userData?.shipping_address?.city,
        state: userData?.shipping_address?.state,
        zip: userData?.shipping_address?.postcode,
        country: userData?.shipping_address?.country,
        phone: userData?.user_mobile,
        email: userData?.user_email,
        notes: "e-galaxy ticket purchase",
      };

      const orderObject = {
        orderline: orderline,
        ordercontact: [ordercontact],
      };

      if (selectedCabana && selectedCabana.length > 0) {
        const orderData = selectedCabana.map((item) => ({
          event_id: item.eventId,
          section_id: item.resourseId,
          qty: item.quantity,
          plu: item.PLU,
        }));
        formData.append("hold_data", JSON.stringify(orderData));
      }

      formData.append("order_data", JSON.stringify(orderObject));

      // ssl commer payment getway
      dispatch(ticketPlaceOrder(formData)).then((res) => {
        console.log(res);
        setLoading(false);

        if (res?.payload?.status === "success") {
          sessionStorage.removeItem("date");
          sessionStorage.removeItem("selectedTickets");
          sessionStorage.removeItem("selectedCabana");

          if (paymentMethod === "bkash") {
            window.location.replace(res?.payload?.data?.payment_info?.bkashURL);
          } else {
            window.location.replace(
              res?.payload?.data?.payment_info?.GatewayPageURL
            );
          }
        } else {
          toast.error(res?.payload?.response?.data?.message, {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    }
  };

  // password hidden
  const [showPassword1, setShowPassword1] = useState(false);
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const [currentStep, setCurrentStep] = useState("date");
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  //get params for each circle
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");
    if (status) {
      setCurrentStep(status);
    }
  }, [location.search]);

  const [completedSteps, setCompletedSteps] = useState([]);

  // Function to update completed steps
  const updateCompletedSteps = (step) => {
    setCompletedSteps((prevSteps) => [...prevSteps, step]);
  };

  const renderCircle = (status) => {
    const stepNumber =
      ["date", "ticket", "details", "payment"].indexOf(status) + 1;
    const isActive = status === currentStep;
    const isCompletedStep = completedSteps.includes(status);
    const shouldReplaceWithCheckmark =
      ["date", "ticket", "details", "payment"].indexOf(currentStep) >=
      ["date", "ticket", "details", "payment"].indexOf(status);

    return (
      <div
        className={`circle ${isActive ? "active" : ""}`}
        onClick={() => handleContinue(status)}
      >
        <div
          className={`circle-number ${
            isActive || isCompletedStep || shouldReplaceWithCheckmark
              ? "active-number"
              : ""
          }`}
        >
          {isCompletedStep || shouldReplaceWithCheckmark ? (
            <span className="checkmark-icon">&#10003;</span>
          ) : (
            stepNumber
          )}
        </div>
        <div className="circle-label">
          {status === "date" && "Date"}
          {status === "ticket" && "Ticket"}
          {status === "details" && "Details"}
          {status === "payment" && "Payment"}
        </div>
      </div>
    );
  };

  const handleContinue = (status) => {
    const steps = ["date", "ticket", "details", "payment"];
    const currentStepIndex = steps.indexOf(currentStep);
    const nextStepIndex = steps.indexOf(status);

    if (
      nextStepIndex === currentStepIndex + 1 ||
      nextStepIndex === currentStepIndex - 1
    ) {
      setCurrentStep(status);
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("status", status);
      history.push({ search: queryParams.toString() });

      // Update completed steps
      updateCompletedSteps(status);
    }
  };

  // clickable & disable timeline based on status
  const [ticketClickable, setTicketClickable] = useState(false);
  const [detailsClickable, setDetailsClickable] = useState(false);
  const [paymentClickable, setPaymentClickable] = useState(false);
  useEffect(() => {
    if (currentStep === "ticket") {
      setTicketClickable(true);
    }
    if (currentStep === "details") {
      setDetailsClickable(true);
      setTicketClickable(true);
    }
    if (currentStep === "payment") {
      setDetailsClickable(true);
      setTicketClickable(true);
      setPaymentClickable(true);
    }
  }, [currentStep]);

  useEffect(() => {
    if (Object.keys(selectedTickets).length !== 0) {
      setAdultChildTicketSelected(true);
    }
    calculateTotalPrice();
  }, [selectedTickets]);

  return (
    <Router>
      <StyledTimeline currentStep={currentStep}>
        <div className="timeline">
          <div className="circle-wrap">
            <div className={`circle`}>
              <div
                className={`circle-number`}
                onClick={() => setCurrentStep("date")}
              >
                {currentStep === "ticket" ||
                currentStep === "details" ||
                currentStep === "payment" ? (
                  <span className="checkmark-icon">&#10003;</span>
                ) : (
                  <p>1</p>
                )}
                <Link to={`/ticket?status=date`} />
              </div>
              <div className="circle-label">Date</div>
            </div>
            <div
              className={`circle`}
              style={
                ticketClickable
                  ? { pointerEvents: "unset" }
                  : { pointerEvents: "none" }
              }
            >
              <div
                className={`circle-number`}
                onClick={() => setCurrentStep("ticket")}
              >
                {currentStep === "details" || currentStep === "payment" ? (
                  <span className="checkmark-icon">&#10003;</span>
                ) : (
                  <p>2</p>
                )}

                <Link to={`/ticket?status=ticket`} />
              </div>
              <div className="circle-label">Ticket</div>
            </div>
            <div
              className={`circle`}
              style={
                detailsClickable
                  ? { pointerEvents: "unset" }
                  : { pointerEvents: "none" }
              }
            >
              <div
                className={`circle-number`}
                onClick={() => setCurrentStep("details")}
              >
                {currentStep === "payment" ? (
                  <span className="checkmark-icon">&#10003;</span>
                ) : (
                  <p>3</p>
                )}
                <Link to={`/ticket?status=details`} />
              </div>
              <div className="circle-label">Details</div>
            </div>
            <div
              className={`circle`}
              style={
                paymentClickable
                  ? { pointerEvents: "unset" }
                  : { pointerEvents: "none" }
              }
            >
              <div
                className={`circle-number`}
                onClick={() => setCurrentStep("payment")}
              >
                <p>4</p>
                <Link to={`/ticket?status=payment`} />
              </div>
              <div className="circle-label">Payment</div>
            </div>
          </div>
          <div className="show-content">
            {currentStep === "date" && (
              <div>
                <div className={"detail"}>
                  <div className={"visiting d-flex"}>
                    <div className={"visiting-title"}>
                      <h3>Visiting Day</h3>
                    </div>
                  </div>
                  <div className={"calendar"}>
                    <div className="calender-all-item">
                      <Calendar
                        onChange={handleCalender}
                        value={date && date}
                        formatShortWeekday={(locale, value) =>
                          ["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
                        }
                        prev2Label={""}
                        next2Label={""}
                        tileClassName="tile--active"
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().setMonth(new Date().getMonth() + 1)
                          )
                        }
                        tileDisabled={({ date }) => {
                          // Disable November 1st
                          return date.getDate() === 1 && date.getMonth() === 10;
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="process-btn d-flex justify-content-center">
                  {!date || date === "" ? (
                    <ButtonA
                      color="#dddddd"
                      borderColor="#dddddd"
                      hoverfill="#dddddd"
                      text="Continue"
                    />
                  ) : (
                    <ButtonA
                      color="#92278F"
                      borderColor="#92278F"
                      hoverfill="#92278F"
                      text="Continue"
                      onClick={() => handleContinue("ticket")}
                    />
                  )}
                </div>
              </div>
            )}
            {currentStep === "ticket" && (
              <div>
                <Tickets
                  ticketDetails={ticketDetails}
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  adultChildTicketSelected={adultChildTicketSelected}
                  setAdultChildTicketSelected={setAdultChildTicketSelected}
                  date={date}
                />
                <div className="process-btn d-flex justify-content-center">
                  <ButtonA
                    color="#1A1818"
                    borderColor="#1A1818"
                    hoverfill="#1A1818"
                    text="Back"
                    onClick={() => handleContinue("date")}
                  />

                  {Object.keys(selectedTickets).length === 0 ||
                  !adultChildTicketSelected ? (
                    <div
                      title={
                        adultChildTicketSelected
                          ? ""
                          : "Please add full day entry ticket (Minimum 1 Adult)"
                      }
                    >
                      <ButtonA
                        color="#dddddd"
                        borderColor="#dddddd"
                        hoverfill="#dddddd"
                        text="Continue"
                      />
                    </div>
                  ) : selectedTickets.hasOwnProperty("10101301") ||
                    selectedTickets.hasOwnProperty("10101304") ||
                    selectedTickets.hasOwnProperty("10100312") ||
                    selectedTickets.hasOwnProperty("10101302") ? (
                    <ButtonA
                      color="#92278F"
                      borderColor="#92278F"
                      hoverfill="#92278F"
                      text="Continue"
                      onClick={() => handleContinue("details")}
                    />
                  ) : (
                    <div
                      title={
                        adultChildTicketSelected
                          ? ""
                          : "Please add full day entry ticket (Minimum 1 Adult)"
                      }
                    >
                      <ButtonA
                        color="#dddddd"
                        borderColor="#dddddd"
                        hoverfill="#dddddd"
                        text="Continue"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === "details" && (
              <Address
                userData={userData}
                handleContinue={handleContinue}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phoneNumber={phoneNumber}
                postcode={postcode}
                address={address}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setPhoneNumber={setPhoneNumber}
                setPostcode={setPostcode}
                setAddress={setAddress}
                loading={loading}
                setLoading={setLoading}
                total={total}
                otp={otp}
                setOtp={setOtp}
                isOtp={isOtp}
                setIsOtp={setIsOtp}
                seletedTickets={selectedTickets}
              />
            )}
            {currentStep === "payment" && (
              <div>
                <div className={"details"}>
                  <h3>Payment</h3>
                  <div className={"account__form"}>
                    <div className={"form__fields"}>
                      <div className="account__form__container">
                        <div className="form-group-wrap">
                          <Form className={"form"}>
                            <div className="checkbox">
                              <Form.Group>
                                <label className="container">
                                  <input
                                    type="radio"
                                    checked={paymentMethod === "ssl"}
                                    onChange={(e) => setPaymentMethod("ssl")}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="label-text">
                                    Pay with SSLCommerz
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      color: "#989898",
                                      display: "block",
                                      paddingLeft: "13px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    (Debit/Credit Cards, Mobile Banking,
                                    Internet Banking)
                                  </span>
                                </label>
                              </Form.Group>
                              <Form.Group style={{ marginTop: "5px" }}>
                                <label className="container">
                                  <input
                                    type="radio"
                                    checked={paymentMethod === "bkash"}
                                    onChange={(e) => setPaymentMethod("bkash")}
                                  />
                                  <span className="checkmark"></span>
                                  <span className="label-text">
                                    Pay with bKash
                                  </span>
                                  <span
                                    className="label-text"
                                    style={{ marginLeft: 10 }}
                                  >
                                    <img
                                      src="/images/static/bkash.png"
                                      alt="bkashlogo"
                                      height={20}
                                      width={20}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      color: "#989898",
                                      display: "block",
                                      paddingLeft: "13px",
                                      marginTop: "5px",
                                      display: "hidden",
                                    }}
                                  >
                                    (20% discount only applicable on Weekends
                                    and Public Holidays price of 6998 BDT)
                                  </span>
                                </label>
                                <Form.Group
                                  style={{
                                    marginTop: "15px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <label className="container">
                                    <input
                                      type="checkbox"
                                      onClick={toggleCheckbox}
                                    />
                                    <span className="checkmark"></span>
                                    <span
                                      className="terms-conditions"
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        color: "#000",
                                        fontWeight: 600,
                                        display: "block",
                                        paddingLeft: "13px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      I have read and accepted the{" "}
                                      <a href={"/Terms"} target="_blank">
                                        <span>Terms & Conditions</span>
                                      </a>
                                      ,{" "}
                                      <a
                                        href={"/refund-policy"}
                                        target="_blank"
                                      >
                                        <span>Refund Policy</span>
                                      </a>
                                      ,{" "}
                                      <a
                                        href={"/privacy-policy"}
                                        target="_blank"
                                      >
                                        <span>Privacy Policy</span>
                                      </a>
                                    </span>
                                  </label>
                                </Form.Group>
                              </Form.Group>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="process-btn d-flex justify-content-center">
                  <ButtonA
                    color="#1A1818"
                    borderColor="#1A1818"
                    hoverfill="#1A1818"
                    text="Back"
                    onClick={() => handleContinue("details")}
                  />
                  {Object.keys(selectedTickets).length === 0 ? (
                    <ButtonA
                      color="#dddddd"
                      borderColor="#dddddd"
                      hoverfill="#dddddd"
                      text="Place Order"
                    />
                  ) : paymentMethod === "ssl" && isChecked ? (
                    <ButtonA
                      color="#1A1818"
                      borderColor="#1A1818"
                      hoverfill="#1A1818"
                      text="Place Order"
                      onClick={handleOrderPlace}
                      // src={'/order/status'}
                    />
                  ) : paymentMethod === "bkash" && isChecked ? (
                    <ButtonA
                      color="#1A1818"
                      borderColor="#1A1818"
                      hoverfill="#1A1818"
                      text="Place Order"
                      onClick={handleOrderPlace}
                      // src={'/order/status'}
                    />
                  ) : (
                    <ButtonA
                      color="#dddddd"
                      borderColor="#dddddd"
                      hoverfill="#dddddd"
                      text="Place Order"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </StyledTimeline>
    </Router>
  );
};

const StyledTimeline = styled.div`


  .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;

  }

  .checkmark-icon {
    background-color: rgb(146, 39, 143);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }

  .circle-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 60%;
  }

  .circle-wrap:before {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    background: #2D3691;
    opacity: 0.2;
    height: 2px;
    top: 30%;
    width: 90%;
  }


  .circle-wrap:after {
    position: absolute;
    content: '';
    left: 15px;
    right: 0;
    height: 2px;
    top: 30%;
    background: #92278F;
    width: ${(props) => {
      switch (props.currentStep) {
        case "date":
          return "0%";
        case "ticket":
          return "35%";
        case "details":
          return "55%";
        case "payment":
          return "90%";
        default:
          return "0%";
      }
    }};
  }


  .circle-wrap:last-child:after {
    display: none !important;
  }

  .circle-number {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #92278F;
    position: relative;
    background: #FFFFFF;
    color: #92278F;
    transition: 0.3s ease-in-out;
    position: relative;
    overflow: hidden;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  .circle-number.active-number {
    background: #92278F;
    color: #fff;
    border: none;
  }

  .circle-label {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #1A1818;
  }

  .circle.active > .circle-label {
    color: #92278F;
  }


  .process-btn {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    gap: 20px;
    margin-top: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }


  .show-content {
    margin-top: 40px;

    .tc {
      margin-top: 30px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

      .title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          padding-top: 17px;
          padding-bottom: 19px;
          font-weight: 400;
          padding-left: 20px;
          border-bottom: 1px solid #E8E8E8;
        }
      }

    }

    .cabana {
      margin-top: 30px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

      .title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          padding-top: 17px;
          font-weight: 400;
          padding-bottom: 19px;
          padding-left: 20px;
          border-bottom: 1px solid #E8E8E8;
        }
      }

    }


    .details {
      margin-top: 30px;
      background-color: #f9f9f9;
      border: 1px solid #E8E8E8;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);

      h3 {
        color: #1A1818;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 20px;
        background-color: #f9f9f9;
        border: 1px solid #E9E9E9;
      }

      .account__form {
        padding: 20px 30px 40px 30px;
        background-color: #ffffff;
        //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

        .checkbox-single {
          margin-bottom: 25px;
          padding-top: 25px;

          .container {
            padding-bottom: 0 !important;
            border-bottom: 0 !important;
            margin-bottom: 0 !important;

            .terms-conditions {
              a {
                color: #92278F;
              }

              span {
                cursor: pointer;

                &:hover {
                  color: #92278F;
                  font-weight: 500;
                  transition: 0.3s ease-in-out;
                }
              }
            }
          }
        }

        .flex {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .detail {
      margin-top: 20px;

      .visiting {
        justify-content: space-between;
        background-color: #E9E9E9;
        padding: 20px 17px;

        .visiting-title {
          h3 {
            color: #1A1818;
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
          }
        }

        .visiting-data {
          display: flex;
          justify-content: space-between;
          align-self: center;
          gap: 20px;

          p {
            color: #1A1818;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            margin-right: 16px;
          }

          .status {
            ul {
              display: flex;
              justify-content: space-between;
              //gap: 20px;

              li {
                position: relative;
                color: #1A1818;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0;

                  &::before {
                    position: absolute;
                    content: "";
                    top: -5px;
                    left: -25px;
                    border-radius: 50%;
                    background-color: rgb(90, 209, 209);
                    opacity: 100%;
                  }
                }

                &::before {
                  position: absolute;
                  content: "";
                  top: -5px;
                  left: -25px;
                  padding: 10px !important;
                  //left: -40px;
                  display: inline-block;
                  width: 5px !important;
                  height: 5px !important;
                  //margin-left: 20px;
                  margin-left: 0 !important;
                  border-radius: 50%;
                  opacity: 50%;
                  background-color: #EC1B34;
                }

                &:after {
                  display: none !important;
                }
              }
            }
          }
        }
      }

      .calendar {
        background-color: #fff;

        .calender-all-item {
          opacity: 1 !important;

          .react-calendar {
            width: 100% !important;
            max-width: 100% !important;
            border: none;
            padding-top: 20px;
            padding-bottom: 25px;
          }

          .react-calendar__navigation {
            //padding: 25px;
            margin: 0px 25px 20px;

            .react-calendar__navigation__prev2-button {
              display: none;
            }

            .react-calendar__navigation__next2-button {
              display: none;
            }
          }
        }

        .react-calendar__navigation__label__labelText {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 32px;
          }

        }

        .react-calendar__navigation__prev-button {
          font-size: 0;
          background: none;
          border: none;
          cursor: pointer;
          position: relative;
        }

        .react-calendar__navigation__prev-button::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0px 15px rgba(0, 0, 0, 0.4);
        }


        .react-calendar__navigation__prev-button::after {
          content: "";
          display: inline-block;
          height: 13px;
          width: 8px;
          background-image: url('/images/static/prev.svg');
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-60%, -50%);
        }

        .react-calendar__navigation__next-button {
          font-size: 0;
          background: none;
          border: none;
          cursor: pointer;
          position: relative;
        }

        .react-calendar__navigation__next-button::before {
          content: "";
          display: inline-block;
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0px 15px rgba(0, 0, 0, 0.4);
        }

        .react-calendar__navigation__next-button::after {
          content: "";
          display: inline-block;
          height: 13px;
          width: 8px;
          background-image: url('/images/static/next.svg');
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-60%, -50%);
        }


        .react-calendar__month-view__weekdays {
          color: #1A1818 !important;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
        }

        .react-calendar__tile--now {
          color: #989898 !important;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        react-calendar__month-view__days__day {
          color: #989898 !important;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
        

        .react-calendar__month-view__days__day {
          color: #434343;
          font-size: 24px;
          line-height: 29px;
          font-weight: 400;
          font-family: 'Bonn BF', sans-serif !important;
          padding: 13px !important;
          margin-bottom: 15px;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .react-calendar__month-view__days__day:disabled {
          color: #ffffff !important;
          font-size: 24px;
          line-height: 29px;
          position: relative;
          z-index: 5;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .react-calendar__month-view__days__day:disabled::after {
          content: "";
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 50%;
          z-index: -1;
          @media (max-width: 1100px) {
            width: 45px;
            height: 45px;
          }
          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
          }
        }
        
        .react-calendar__month-view__days {
          //gap: 16px;

          .react-calendar__month-view__weekdays__weekday {
            //padding: 35px 58px;
          }

          .react-calendar__tile {
            background-color: #fff;
          }

          .react-calendar__tile:disabled {
            opacity: unset;
            border-radius: unset;
            padding: 0;
            //color: unset;
            background-color: unset !important;
            color: rgba(221, 221, 221, 0.7) !important;
          }

          .react-calendar__tile--active {
            color: #fff !important;
            font-size: 24px;
            line-height: 29px;
            position: relative;
            z-index: 5;
            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 14px;
            }
          }

          .react-calendar__tile--active::after {
            content: "";
            display: inline-block;
            width: 50px;
            height: 50px;
            background-color: rgb(90, 209, 209);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            //opacity: 50%;
            color: #fff;
            z-index: -1;
            @media (max-width: 1100px) {
              width: 45px;
              height: 45px;
            }
            @media (max-width: 767px) {
              width: 30px;
              height: 30px;
            }
            //box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);/* Move the pseudo-element to center it properly */
          }

          .react-calendar__navigation button:enabled:hover {
            background: none !important;
          }
        }

        abbr[title] {
          text-decoration: none;
        }

        .react-calendar__navigation button:enabled:hover {
          background: none;
        }
      }
    }
  }
}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .price-box {
    gap: 30px;
  }

  .circle-wrap {
    width: 100%;
  }
}


/* small mobile :320px. */
@media (max-width: 767px) {
  .price-box {
    gap: 30px;
  }

  .circle-wrap {
    width: 100%;
  }

  .detail {
    .visiting {
      .visiting-title {
        h3 {
          font-size: 18px !important;
          line-height: 14px !important;
          font-weight: 400 !important;
          margin-top: 10px;
        }
      }

      .visiting-data {
        span {
          p {
            display: none;
          }
        }
      }

      .status {
        li {
          position: relative;
          color: #1A1818;
          font-size: 10px !important;
          line-height: 14px !important;
          font-weight: 300;
          margin-right: 20px;
          margin-top: 15px;

          &:last-child {
            margin-right: 0;

            &::before {
              position: absolute;
              content: "";
              top: -5px;
              left: -25px;
              border-radius: 50%;
              background-color: rgb(90, 209, 209);
              opacity: 100%;
            }
          }

          &::before {
            position: absolute;
            content: "";
            top: -25px !important;
            left: 20% !important;
            padding: 10px !important;
            //left: -40px;
            display: inline-block;
            width: 5px !important;
            height: 5px !important;
            //margin-left: 20px;
            margin-left: 0 !important;
            border-radius: 50%;
            opacity: 50%;
            background-color: #EC1B34;
          }

          &:after {
            display: none !important;
          }
        }
      }
    }
  }
}


`;

export default Timeline;
