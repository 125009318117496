import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Img from "./Img";
import Button from "./Button";
import {RiEyeFill} from "react-icons/ri";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ButtonA from "./ButtonA";
import {useDispatch} from "react-redux";
import {cart, cartUpdate} from "../api/redux/checkout";
import {toast} from "react-toastify";

const MyComponent = ({
                         remove,
                         title,
                         cartData,
                         setLoading,
                         handleClose,
                         cartItems,
                         total,
                         setCartItems,
                         setTotal
                     }) => {
    const dispatch = useDispatch()


    const removeHandler = (e) => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        if (token && token !== "") {
            let params = {
                token: token,
                id: id,
                productId: e?.product_id,
                count: parseInt(e.count),
                flag: 0
            }
            dispatch(cartUpdate(params)).then((res) => {
                if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    dispatch(cart(params))
                } else {
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    dispatch(cart(params))
                }
            })
        } else {
            const updatedCart = cartItems.filter(item => item.id !== e.id);
            const removedItem = cartItems.find(item => item.id === e.id);
            const newTotal = total - (parseFloat(removedItem.price) * removedItem.cart_count);
            localStorage.setItem('addToCart', JSON.stringify(updatedCart));
            setTotal(newTotal)
            setCartItems(updatedCart)
            window.dispatchEvent(new StorageEvent('storage', {
                key: 'addToCart',
                newValue: JSON.stringify(updatedCart)
            }));
            window.dispatchEvent(new StorageEvent('storage', {key: 'total', newValue: newTotal}));
            toast.error("Product removed!", {
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    return (
        <StyledComponent>
            <Container className={"order pb-80 pt-80"}>
                <Row>
                    <Col lg={12} className={"p-0"}>
                        <div className={"order__box"}>
                            <div className={"title"}>
                                <img src={'/images/dynamic/icon.svg'}/>
                                <h3 className={"split-up"}>{title ? title : 'Order History'}</h3>
                            </div>
                            <div className={'wrapper'}>
                                <div className={'top'}>
                                    <div className="content">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Items</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                cartData ? cartData?.cart ? cartData?.cart.length > 0 ?
                                                    cartData?.cart.map((e) => {
                                                        return (
                                                            <tr>
                                                                <td className={'flex'}>{e?.product_name}
                                                                    <span onClick={() => {
                                                                        removeHandler(e)
                                                                    }}>Remove</span>
                                                                </td>
                                                                <td>X{e?.count}</td>
                                                                <td>BDT {e?.price}</td>
                                                            </tr>
                                                        )
                                                    }) : null : null : null
                                            }
                                            {
                                                cartData ? cartData.length > 0 ?
                                                    cartData.map((e) => {
                                                        return (
                                                            <tr>
                                                                <td className={'flex'}>{e?.product_name}
                                                                    <span onClick={() => {
                                                                        removeHandler(e)
                                                                    }}>Remove</span>
                                                                </td>
                                                                <td>X{e?.cart_count}</td>
                                                                <td>BDT {e?.price.toLocaleString('en-US')}</td>
                                                            </tr>
                                                        )
                                                    }) : null : null
                                            }
                                            </tbody>

                                        </table>
                                    </div>
                                    <div className="subtotal">
                                        <h6>Subtotal</h6>
                                        <h6>BDT {cartData?.subtotal ? cartData?.subtotal : total ? total.toFixed(2).toLocaleString('en-US') : 0}</h6>
                                    </div>
                                </div>
                                <div className={'bottom'}>
                                    <div className="total">
                                        <div className="total-left">
                                            <h6>Total</h6>
                                            <h4>BDT {cartData?.total ? cartData?.total : total ? total.toFixed(2).toLocaleString('en-US') : 0}</h4>
                                            <p>All taxes and fees included</p>
                                        </div>
                                        <div className="total-left">
                                            <Button src={'/checkout'} text={'Checkout'} borderColor={'#92278F'}
                                                    color={'#92278F'} hoverfill={'#92278F'} onClick={handleClose}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .order {
    max-width: 100%;

    &__box {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
      padding: 0;
      height: 100vh;
      width: auto;

      h3 {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
      }

      .title {
        position: relative;
        padding: 45px 0 40px 30px;
        background-color: #92278F;

        img {
          width: 100%;
          position: absolute;
          bottom: -15px;
          left: 0;
        }

      }


      .content {
        margin: 50px 30px 0 30px;
        //padding: 0 30px;
        overflow-y: scroll;
        height: calc(100vh - 375px);
        //background: red;

        table {
          thead {

            border-bottom: 1px solid #E8E8E8;

            tr {
              th {
                font-size: 16px;
                line-height: 32px;
                color: #434343;
                font-weight: 400;
                padding: 20px 0;

                &:nth-of-type(3) {
                  text-align: end;
                }
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #E8E8E8;

              .flex {
                display: flex;
                flex-direction: column;
              }

              span {
                cursor: pointer;
                margin: 10px 0;
                font-size: 12px;
                line-height: 14px;
                color: #EC1B34;
                font-weight: 300;
              }

              td {
                padding: 20px 0;
                font-size: 14px;
                line-height: 16px;
                font-weight: 300;
                border-color: #DFE6E5;
                color: #1A1818;

                &:nth-of-type(3) {
                  text-align: end;
                }

              }
            }
          }
        }
      }

      .content::-webkit-scrollbar {
        display: none;
      }

      .subtotal {
        display: flex;
        justify-content: space-between;
        padding: 30px;

        h6 {
          color: #1A1818;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        background-color: rgb(146, 39, 143, 0.1);

        .total-left {
          text-align: start;
        }

        h4 {
          color: #92278F;
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
        }

        p {
          font-size: 12px;
          line-height: 16px;
          color: #434343;
          font-weight: 300;
        }

        h6 {
          font-size: 12px;
          line-height: 16px;
          color: #434343;
          font-weight: 300;
        }
      }

    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84%;
  }
`;

export default MyComponent;
