import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import {useState} from "react";
import Address from "../ticket/Address";
import Checkout from "./Checkout";
import {cart, cartUpdate} from "../../api/redux/checkout";
import {toast} from "react-toastify";
import ReactHtmlParser from "react-html-parser";

const BuyNowPopup = ({label, img, id, price, description, type, qty}) => {
    let userData = useSelector(state => state?.auth?.userData?.data);
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [address, setAddress] = useState()
    const [postcode, setPostcode] = useState()
    const dispatch = useDispatch()
    const [otp, setOtp] = useState()
    const [isOtp, setIsOtp] = useState(false)


    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => {
        setShow(true)
    };

    const [adultCounter, setAdultCounter] = useState(1);


    const handleIncrease = () => {
        setAdultCounter(adultCounter + 1)
    };

    const handleDecrease = () => {
        setAdultCounter(adultCounter - 1)
    };

    console.log(price)

    return (
        <div style={{ marginBottom: 12}}>
            {
                qty && parseInt(qty) !== 0 ? price[0] === "Complimentary Entry" ?
                        <Button text={'Free'} color={'#ddd'} borderColor={'#F06482'} hoverfill={'#F06482'}
                                hoverBackground={'#F06482'} cursor={'default'} background={'#F06482'}/>
                        : <Button text={'Buy Now'} color={'#000'} borderColor={'#000'} hoverfill={'#000'}
                                 hoverBackground={'#fff'} onClick={handleShow}/> :
                    <Button text={'Sold Out'} color={'#ddd'} borderColor={'#F06482'} hoverfill={'#F06482'}
                            hoverBackground={'#F06482'} cursor={'default'} background={'#F06482'}/>
            }

            <Modal
                show={show}
                className="guest-event-modal"
            >
                <Modal.Header>
                    <div className="header">
                        <div className="header-left">
                            <h4>Purchase Ticket</h4>
                        </div>

                        <div className="header-right">
                            <img src={'/images/static/modal-close.svg'} alt="modal-close" onClick={handleClose}/>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <StyledItem>
                        <div className={'ticketing'}>
                            <div className={'ticketing__img'}>
                                <img src={img}/>
                            </div>
                            <div className={'ticketing__info'}>
                                <div className={'ticketing__info__details'}>
                                    <div className={'ticketing__info__details__list'}>
                                        <div className={'ticketing__info__details__list__type d-flex'}>
                                                                    <span style={{width: "100%"}}>
                                                                        <h4 style={{width: "100%"}}>{label}</h4>
                                                                        <br/>
                                                                        <p style={{width: "100%"}}>{ReactHtmlParser(description)}</p>
                                                                    </span>
                                            <p className={'price'}>BDT{price ? price.toLocaleString('en-US') : 0}</p>
                                        </div>
                                    </div>

                                    <div className={'ticketing__info__details__btn'}>
                                        <div className="increase-section__button">
                                            <input type="button" value="-"
                                                   onClick={() => handleDecrease()}
                                                   disabled={adultCounter === 1}/>
                                            <span>{adultCounter}</span>
                                            <input type="button" value="+"
                                                   onClick={() => handleIncrease()}
                                                // disabled={adultCounter === 10}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </StyledItem>


                    <Checkout
                        userData={userData}
                        // handleContinue={handleContinue}
                        firstName={firstName} lastName={lastName}
                        email={email} phoneNumber={phoneNumber}
                        postcode={postcode} address={address}
                        setFirstName={setFirstName} setLastName={setLastName}
                        setEmail={setEmail} setPhoneNumber={setPhoneNumber}
                        setPostcode={setPostcode} setAddress={setAddress}
                        otp={otp} setOtp={setOtp} isOtp={isOtp} setIsOtp={setIsOtp}
                        title={label} type={type} adultCounter={adultCounter}
                        eventId={id}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BuyNowPopup;

const StyledItem = styled.div`
  .ticketing {
    left: 15%;
    right: 15%;
    display: inline-flex;
    background-color: #ffffff;
    padding: 20px 30px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0 5px 30px;
    width: 100%;
    @media (max-width: 767px) {
      padding: 20px 10px;
    }


    &__img {
      margin-right: 20px;


      img {
        height: 100%;
        width: 110px;
      }
    }

    &__info {
      //width: 100% !important;
      width: calc(100% - 80px);

      &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #F9F9F9;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          padding-top: 15px;
        }

        &__list {
          &__type {
            span {
              display: block;
              //margin-right: 50px;

              h4 {
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                color: #1A1818;
                width: 200px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }

              p {
                color: #989898;
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                width: 130px;
                @media (max-width: 767px) {
                  width: 95px;
                }
              }
            }

            @media (max-width: 1150px) {
              display: block !important;
              .price {
                margin: 0;
              }
            }
          }
        }

        &__btn {
          align-self: flex-end;
          align-items: center;
          display: contents;

          input {
            border-radius: 50%;
            border: none;
            height: 35px;
            width: 35px;
          }

          .increase-section__button {
            width: 150px;
            justify-content: space-between;
            padding: 0 2px;
            background-color: white;
            border: 1px solid #E8E8E8;
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
              width: 108px;
              height: 40px;
            }

            span {
              color: #1A1818;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
            }

          }
        }

        .price {
          color: #92278F;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          margin-left: 50px;
          margin-right: 50px;
          display: flex;
          align-items: center;
          @media (max-width: 1150px) {
            margin: 10px 0;
          }
        }
      }
    }
  }

`
