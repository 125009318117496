import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import Img from "../Img";
import Button from "../Button";
import {RiEyeFill, RiEyeCloseFill, RiEye2Fill} from 'react-icons/ri';
import {useDispatch} from "react-redux";
import {submitOtpSignIn, submitOtpSignInVerify, submitSignIn} from "../../api/redux/auth";
import {toast, ToastContainer} from "react-toastify";
import {Loading} from "../Loading";
import moment from "moment";
import {addToCart, cart} from "../../api/redux/checkout";


const MyComponent = () => {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const [isOtp, setIsOtp] = useState(false)

    const [offset, setOffset] = useState(0)
    useEffect(() => {
        let token = localStorage.getItem('token')
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })

        if (token) {
            setTimeout(() => {
                history.push('/account')
            }, 400)
        }
    }, [])

    const parseQueryString = (queryString) => {
        const params = {};
        const [, data] = queryString.split('?');
        const [adult, child, date] = data.split('&');
        params['adult'] = parseInt(adult);
        params['child'] = parseInt(child);
        params['date'] = date;
        return params;
    };


    // password hidden
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // const submitHandler = () => {
    //     setLoading(true)
    //     let formData = new FormData()
    //
    //     formData.append('user_login', email)
    //     formData.append('user_pass', password)
    //
    //     dispatch(submitSignIn(formData)).then(res => {
    //         if (res?.payload?.status === 200) {
    //             toast.success(res?.payload?.message, {
    //                 autoClose: 5000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "dark",
    //             });
    //             setLoading(false)
    //             localStorage.setItem('token', res?.payload?.data?.token)
    //             localStorage.setItem('id', res?.payload?.data?.id)
    //             setTimeout(() => {
    //                 if (location && location?.search) {
    //                     const queryParams = parseQueryString(location.search);
    //                     if (queryParams && queryParams?.adult && queryParams?.child && queryParams?.date) {
    //                         history.push(`/ticket/${queryParams?.adult}/${queryParams?.child}/${queryParams?.date}`)
    //                     } else {
    //                         history.push('/account');
    //                     }
    //                 } else {
    //                     history.push('/account');
    //                 }
    //
    //
    //             }, 2000)
    //         } else {
    //             setLoading(false)
    //             toast.error(res?.payload?.response?.data?.message, {
    //                 autoClose: 5000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "dark",
    //             });
    //         }
    //     })
    //
    //
    // }

    const handleOtpSend = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('user_email', email)
        dispatch(submitOtpSignIn(formData)).then(res => {
            if (res?.payload?.status === 200) {
                toast.success(res?.payload?.message, {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setIsOtp(true)
            } else {
                toast.error(res?.payload?.response?.data?.message, {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        })
    }

    const handleOtpVerify = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('user_email_phone', email)
        formData.append('otp', password)

        dispatch(submitOtpSignInVerify(formData)).then(res => {
            if (res?.payload?.status === 200) {
                toast.success(res?.payload?.message, {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                let localCartData = JSON.parse(localStorage.getItem('addToCart'))
                let token = localStorage.getItem('token')
                let id = localStorage.getItem('id')
                localCartData && localCartData.length > 0 && localCartData.map((item, index) => {
                    let params = {
                        token: token,
                        id: id,
                        count: item.cart_count,
                        productId: item?.id
                    }
                    dispatch(addToCart(params)).then((res) => {
                        if (res?.payload?.status === 200) {
                            dispatch(cart(params)).then(() => {
                            })
                            if(localCartData.length === (index+1)){
                                localStorage.removeItem('addToCart')
                                localStorage.removeItem('total')
                                setTimeout(()=>{
                                    window.dispatchEvent(new StorageEvent('storage', { key: 'addToCart', newValue: JSON.stringify([]) }));
                                    window.dispatchEvent(new StorageEvent('storage', { key: 'total', newValue: 0 }));

                                }, 800)
                            }
                        } else {
                            localStorage.removeItem('addToCart')
                            localStorage.removeItem('total')
                        }
                    })
                })
                setTimeout(() => {
                    setIsOtp(false)
                    history.push('/')
                }, 800)
            } else {
                setLoading(false)
                toast.error(res?.payload?.response?.data?.message, {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        })
    }


    return (
        <StyledLogIn className='login'>
            <Container fluid style={{paddingLeft: offset + 15 + 'px'}}>
                <Row>
                    <Col md={5}>
                        <div className="login__form">
                            <h2>Sign In</h2>
                            <div className="login__form__create-line">
                                <p>If you are New to Mana Bay, &nbsp;</p>
                                <Link to={'/sign_up'}>Create Your Account</Link>
                            </div>

                            <div className="login__form__container">
                                <div className="form-group-wrap">
                                    <Form className={'form'} onSubmit={(e)=>{e.preventDefault()}}>
                                        <div className="flex">
                                            <div className="form-group">

                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Control type="email" placeholder="Email"
                                                                  name='email'
                                                                  className="number-input"
                                                                  onChange={e => {
                                                                      setEmail(e.target.value)
                                                                  }}
                                                                  onFocus={(e) => e.target.classList.add('focused')}
                                                                  onBlur={(e) => e.target.classList.remove('focused')}
                                                    />
                                                </Form.Group>

                                            </div>

                                            {
                                                isOtp ?
                                                    <div className="form-group">

                                                        <Form.Group controlId="formBasicOtp">
                                                            <Form.Control type="otp" placeholder="OTP"
                                                                          name='otp'
                                                                          className={`number-input ${isOtp ? 'slide-animation' : ''}`}
                                                                          onChange={e => {
                                                                              setPassword(e.target.value)
                                                                          }}
                                                                          onFocus={(e) => e.target.classList.add('focused')}
                                                                          onBlur={(e) => e.target.classList.remove('focused')}
                                                            />
                                                        </Form.Group>
                                                    </div> : null
                                            }

                                            {/*<div className="form-group">*/}
                                            {/*    <Form.Group controlId="formBasicEmail">*/}
                                            {/*        <Form.Control*/}
                                            {/*            type={showPassword ? 'text' : 'password'}*/}
                                            {/*            placeholder="Enter Password"*/}
                                            {/*            name='password'*/}
                                            {/*            className="password-input"*/}
                                            {/*            onChange={e => {*/}
                                            {/*                setPassword(e.target.value)*/}
                                            {/*            }}*/}
                                            {/*            onFocus={(e) => e.target.classList.add('focused')}*/}
                                            {/*            onBlur={(e) => e.target.classList.remove('focused')}*/}
                                            {/*        />*/}
                                            {/*        <button*/}
                                            {/*            type='button'*/}
                                            {/*            onClick={togglePasswordVisibility}*/}
                                            {/*            className="password-toggle-button"*/}
                                            {/*        >*/}
                                            {/*            {showPassword ?*/}

                                            {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16"*/}
                                            {/*                     height="13.711" viewBox="0 0 16 13.711">*/}
                                            {/*                    <path id="Icon_ionic-md-eye-off"*/}
                                            {/*                          data-name="Icon ionic-md-eye-off"*/}
                                            {/*                          d="M10.252,7.385a3.627,3.627,0,0,1,3.638,3.61,3.473,3.473,0,0,1-.261,1.321l2.125,2.107a8.536,8.536,0,0,0,2.5-3.428,8.617,8.617,0,0,0-10.9-4.91L8.92,7.646A3.6,3.6,0,0,1,10.252,7.385ZM2.978,5.418l1.66,1.646.336.332a8.5,8.5,0,0,0-2.724,3.6A8.629,8.629,0,0,0,13.437,15.8l.307.3,2.132,2.107.925-.918L3.9,4.5ZM7,9.406l1.128,1.118a2.045,2.045,0,0,0-.057.468,2.171,2.171,0,0,0,2.182,2.164,2.052,2.052,0,0,0,.471-.057l1.128,1.118a3.619,3.619,0,0,1-5.238-3.228A3.567,3.567,0,0,1,7,9.406Zm3.135-.561,2.292,2.274.014-.114a2.171,2.171,0,0,0-2.182-2.164Z"*/}
                                            {/*                          transform="translate(-2.25 -4.5)" fill="#ccc"/>*/}
                                            {/*                </svg>*/}
                                            {/*                : <RiEyeFill/>}*/}
                                            {/*        </button>*/}
                                            {/*    </Form.Group>*/}

                                            {/*</div>*/}
                                        </div>
                                        <div className="form__bottom">
                                            {/*<Link className={'link'} to={'/forgetpass'}>Reset your password</Link>*/}
                                            <Button text={'Sign In'} borderColor={'#92278F'}
                                                    color={'#92278F'}

                                                    hoverfill={'#92278F'}
                                                    src={'/login'}
                                                    onClick={isOtp ? handleOtpVerify : handleOtpSend}
                                            />
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}} className={'pr-0 pl-0'}>
                        <div className="login__image">
                            <Img src={'/images/dynamic/login/login.jpg'}/>
                        </div>
                    </Col>
                </Row>
                {/*<ToastContainer*/}
                {/*    position="top-right"*/}
                {/*    autoClose={5000}*/}
                {/*    hideProgressBar={false}*/}
                {/*    newestOnTop={false}*/}
                {/*    closeOnClick*/}
                {/*    rtl={false}*/}
                {/*    pauseOnFocusLoss*/}
                {/*    draggable*/}
                {/*    pauseOnHover*/}
                {/*    theme="light"*/}
                {/*/>*/}
            </Container>
        </StyledLogIn>
    )
        ;
};
const StyledLogIn = styled.section`
  .login__form {
    padding: 100px 0 50px 0;

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 300;
      color: #1A1818;
    }

    &__create-line {
      display: flex;
      margin: 15px 0 40px 0;

      p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #1A1818;
      }

      a {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #92278F;

      }
    }
  }

  .login__image {
    position: relative;
    padding-top: calc(651 / 598 * 100%);
    height: 100%;
  }

  .login__form__container {
    width: 100%;
    //height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //gap: 170px;
    .form__bottom {
      display: grid;

      .link {
        color: #1A1818;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }

    a {
      color: #1A1818;
      margin-bottom: 40px;
      text-align: end;

    }
  }


  .password-input {
    position: relative;
  }

  .password-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }

  .password-toggle-button {
    position: absolute;
    top: 45%;
    right: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-animation {
    animation: slideIn 0.5s ease forwards;
  }

  .number-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }


  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }

    .login__form {
      padding: 30px 0 50px 0;

      .login__form__container {
        .form__bottom {
          display: grid;

        }
      }

    }
  }

  .password-toggle-button {
    svg {
      color: #CCCCCC;
    }
  }

`;
export default MyComponent;
