import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['submitSignup/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['submitSignIn/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['getProfile/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['updateProfile/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['clearToken/fulfilled']: (state, action) => {
            state.globalLoader = false
        },


        ['forgotPassword/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['passwordSet/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['addToCart/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['cart/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['cartUpdate/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['placeOrder/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['orderList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['orderDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['fetchCategories/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['fetchProductList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['postTicketOrder/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

    }
})


export default globalSlice.reducer
export const {loading} = globalSlice.actions
