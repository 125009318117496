import {Col} from "react-bootstrap";
import {useEffect} from "react";

const OrderSummary = ({cartItems, cartData, total, removeHandler, adultCounter, setCartItems, setTotal}) => {

    useEffect(() => {
        // Function to handle storage changes
        const handleStorageChange = (event) => {
            if (event.key === 'addToCart') {
                const storedCart = JSON.parse(event.newValue) || [];
                setCartItems(storedCart);
            } else if (event.key === 'total') {
                const storedTotal = parseFloat(event.newValue) || 0;
                setTotal(storedTotal);
            }
        };

        // Add event listener to handle changes to localStorage
        window.addEventListener('storage', handleStorageChange);

        // Retrieve initial data from localStorage
        const storedCart = JSON.parse(localStorage.getItem('addToCart')) || [];
        const storedTotal = parseFloat(localStorage.getItem('total')) || 0;

        // Set initial state with retrieved data
        setCartItems(storedCart);
        setTotal(storedTotal);

        // Cleanup: remove the event listener when component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    return (
        <Col lg={{span: 5, offset: 1}}>
            <div className={"order__box"}>
                <div className={"title"}>
                    <img src={'/images/dynamic/icon.svg'}/>
                    <h3>Order Summary</h3>
                </div>
                <div className="order__box__content">
                    <table>
                        <thead>
                        <tr>
                            <th>Items</th>
                            <th>Qty</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            cartData ? cartData?.cart ? cartData?.cart.length > 0 ?
                                cartData?.cart.map((e) => {
                                    return (
                                        <tr>
                                            <td className={'flex'}>{e?.product_name}
                                                <span onClick={() => {
                                                    removeHandler(e)
                                                }}>Remove</span>

                                            </td>
                                            <td>X{e?.count}</td>
                                            <td>BDT {e?.price.toLocaleString('en-US')}</td>
                                        </tr>
                                    )
                                }) : null : null : null
                        }

                        {
                            cartItems ? cartItems.length > 0 ?
                                cartItems.map((e) => {
                                    return (
                                        <tr>
                                            <td className={'flex'}>{e?.product_name}
                                                <span onClick={() => {
                                                    removeHandler(e)
                                                }}>Remove</span>
                                            </td>
                                            <td>X{e?.cart_count}</td>
                                            <td>BDT {e?.price.toLocaleString('en-US')}</td>
                                        </tr>
                                    )
                                }) : null : null
                        }

                        </tbody>

                    </table>
                </div>

                <div className="subtotal">
                    <h6>Subtotal</h6>
                    <h6>BDT {cartData?.subtotal ? cartData?.subtotal.toLocaleString('en-US') : total ? total.toFixed(2).toLocaleString('en-US') : 0}</h6>
                </div>

                <div className="total">
                    <div className="total-left">
                        <h4>Total</h4>
                    </div>
                    <div className="total-left">
                        <h4>BDT {cartData?.total ? cartData?.total.toLocaleString('en-US') : total ? total.toFixed(2).toLocaleString('en-US') : 0}</h4>
                        <p>All taxes and fees included</p>
                    </div>

                </div>

            </div>
        </Col>
    )
}

export default OrderSummary
