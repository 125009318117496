import React, {useEffect, useLayoutEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import Button from "../../components/Button";
import DetailsSlider from "../../components/momento/DetailsSlider";
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {fetchProductDetail} from "../../api/redux/products";
import {addToCart, cart, setCartData} from "../../api/redux/checkout";
import {toast, ToastContainer} from "react-toastify";

const MyComponent = () => {
    const [loading, setLoading] = useState(false)
    const [productStatus, setProductStatus] = useState(false)

    const dispatch = useDispatch()
    const product = useSelector(store => store.products?.productDetail)
    const cartData = useSelector(store => store.checkout?.cartData)
    let {slug} = useParams();
    const history = useHistory()

    useEffect(() => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        setLoading(true)
        dispatch(fetchProductDetail(slug)).then(() => {
            setLoading(false)
        })
        if (token && token !== '') {
            let params = {
                token: token,
                id: id,
            }
            dispatch(cart(params))
        }

    }, [])


    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])


    gsap.registerPlugin(ScrollTrigger);


    useLayoutEffect(() => {
        const col4Element = document.querySelectorAll('.cart');
        gsap.to(col4Element, {
            scrollTrigger: {
                trigger: col4Element,
                start: 'top +100', // Pin the element when the top of the element reaches the top of the viewport.
                end: 'bottom +650', // Unpin the element when the top of the element reaches the top of the viewport again.
                pin: true, // Pin the element at its current position.
                pinSpacing: false // Disable automatic adjustment of the pinned element's position.
            }
        });

    }, []);

    const addToCardHandler = (isBuyNow) => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let count = 1
        let params = {}

        if (token && token !== '') {
            setLoading(true)
            if (cartData && cartData?.cart && cartData?.cart.length > 0) {

                let x = cartData?.cart.find((element) => element.product_id == product.id)
                if (x && x?.count) {
                    params = {
                        token: token,
                        id: id,
                        count: count + parseInt(x?.count),
                        productId: product?.id
                    }
                } else {
                    params = {
                        token: token,
                        id: id,
                        count: count,
                        productId: product?.id
                    }
                }
            } else {
                params = {
                    token: token,
                    id: id,
                    count: count,
                    productId: product?.id
                }
            }
            dispatch(addToCart(params)).then((res) => {
                setLoading(false)
                if (res?.payload?.status === 200) {
                    dispatch(cart(params)).then(() => {
                        if (isBuyNow) {
                            setTimeout(() => {
                                history.push('/checkout')
                            }, 800)
                        }
                    })
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })


        } else {
            // setTimeout(() => {
            //     history.push('/login')
            // }, 400)
            // console.log(product)
            const localCart = JSON.parse(localStorage.getItem('addToCart')) || [];
            const localTotal = JSON.parse(localStorage.getItem('total')) || 0;
            const isProductInCart = localCart.some(e => e.id === product?.id);

            if (!isProductInCart) {
                const newCartItem = {
                    id: product?.id,
                    price: product?.price,
                    product_name: product?.name,
                    image_id: product?.image_id,
                    cart_count: 1
                };

                let total = localTotal + parseFloat(product?.price)
                localCart.push(newCartItem);
                localStorage.setItem('addToCart', JSON.stringify(localCart));
                localStorage.setItem('total', JSON.stringify(localTotal + parseFloat(product?.price)));
                window.dispatchEvent(new StorageEvent('storage', { key: 'addToCart', newValue: JSON.stringify(localCart) }));
                window.dispatchEvent(new StorageEvent('storage', { key: 'total', newValue: localTotal + parseFloat(product?.price) }));

                toast.success('Product Added! ✅', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }

            if (isBuyNow) {
                setTimeout(() => {
                    history.push('/checkout')
                }, 800)
            }
        }
    }

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{product?.name ? product?.name + ' | Mana Bay water park' : 'Mana Bay water park'}</title>
                <meta name="description"
                      content={product?.description !== "" ? product?.description : product?.short_description}/>
            </Helmet>

            <StyledComponent className={'product-details'}>

                <Container>
                    <Row>
                        <Col md={5}>
                            <DetailsSlider images={product?.gallery_image_ids} thumb={product.image_id}/>
                        </Col>
                        <Col md={{span: 6, offset: 1}}>
                            <div className="product-details__right pb-80 ">
                                <p className={''}>Momento {
                                    product ? product?.category_ids ? product?.category_ids.length > 0 ?
                                        product?.category_ids.map((e) => {
                                            if (e.slug !== 'all') {
                                                return `> ${e.name}`
                                            }
                                        }) : null : null : null
                                } </p>
                                <h3>{product?.name}</h3>
                                <h2 className={'price'}>৳{product?.price}</h2>
                                <h6>{product?.description !== "" ? product?.description : product?.short_description}</h6>

                                <div className="details-btn">
                                    <Button text={'Add to Cart'} borderColor={'#1A1818'} color={'#1A1818'}
                                            hoverfill={'#1A1818'}
                                            hoverborder={'#1A1818'} onClick={() => {
                                        addToCardHandler(false)
                                    }}/>

                                    <Button text={'Buy Now'} borderColor={'#1A1818'} color={'#1A1818'}
                                            hoverfill={'#1A1818'}
                                            hoverborder={'#1A1818'}
                                            onClick={() => {
                                                addToCardHandler(true)
                                            }}/>

                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
                {/*<ToastContainer*/}
                {/*    position="top-right"*/}
                {/*    autoClose={5000}*/}
                {/*    hideProgressBar={false}*/}
                {/*    newestOnTop={false}*/}
                {/*    closeOnClick*/}
                {/*    rtl={false}*/}
                {/*    pauseOnFocusLoss*/}
                {/*    draggable*/}
                {/*    pauseOnHover*/}
                {/*    theme="light"*/}
                {/*/>*/}
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  //background: #f9f9f9;
  position: relative;

  .product-details__right {
    padding-top: 60px;

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin: 20px 0 30px 0;
      color: #1A1818;
    }

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 300;
      color: #92278F;
      padding: 30px 0;
      border-top: 1px solid #EFEFEF;
      border-bottom: 1px solid #EFEFEF;
    }

    h6 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: #1A1818;
      margin: 30px 0 40px 0;

    }

    p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #989898;
    }

    .details-btn {
      .dc-btn {
        margin-right: 30px;
      }
    }


  }

  @media (max-width: 767px) {

    .product-details__right {
      h3 {
        margin: 20px 0px 20px;
      }

      h2 {
        margin: 20px 0;
      }

      h6 {
        margin: 20px 0 20px 0;
      }


    }

    .cart {
      top: 10% !important;
    }
  }
  @media (max-width: 991px) {
    .product-details__right {
      padding-top: 0 !important;
    }
  }



`;

export default MyComponent;
