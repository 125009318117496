import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import RideZigzak from "../../components/ride/RideZigzak";
import {useDispatch, useSelector} from "react-redux";
import {fetchCategories} from "../../api/redux/products";
import TriangleInnerPage from '../../components/TringleInnerPage';
import Banner from "../../components/attractions/Banner";

const MyComponent = () => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let categories = useSelector(state => state?.products?.categories);

    useEffect(() => {
        setLoading(true)
        dispatch(fetchCategories()).then(() => {
            setLoading(false)
        })
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Momento | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                {
                    categories ? categories.length > 0 ?
                        categories.map((e) => {
                            if (e.name === 'Top Banner') {
                                // return <InnerBanner img={e.image_url} title={e.description}/>
                                return <Banner img={e.image_url} title={e.description}/>
                            }
                        }) : null : null
                }
                {
                    categories ? categories.length > 0 ?
                        categories.map((e) => {

                            if (e.name === 'Middle Banner') {
                                return <TriangleInnerPage data={e}/>
                            }
                        }) : null : null
                }

                <RideZigzak data={categories} loading={loading}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
