import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Img from "../Img";
import Button from "../Button";
import {RiEyeFill} from "react-icons/ri";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getProfile, updateProfile} from "../../api/redux/auth";
import {Loading} from "../Loading";
import {toast} from "react-toastify";

const MyComponent = () => {
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    let userData = useSelector(state => state?.auth?.userData?.data);
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [password, setPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [address, setAddress] = useState()
    const [postcode, setPostcode] = useState()

    const [formData, setFormData] = useState({
        firstName: userData?.first_name || '',
        lastName: userData?.last_name || '',
        email: userData?.user_email || '',
        phoneNumber: userData?.user_mobile || '',
        address: userData?.shipping_address?.address_1 || '',
        postcode: userData?.shipping_address?.postcode || '',

    });


    const [errors, setErrors] = useState({});

    const validateField = (fieldName, value) => {
        switch (fieldName) {
            case 'firstName':
                if (value.trim() === '') {
                    return 'First name is required';
                }
                break;
            case 'lastName':
                if (value.trim() === '') {
                    return 'Last name is required';
                }
                break;
            case 'email':
                if (!value.match(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/)) {
                    return 'Invalid email address';
                }
                break;
            case 'phoneNumber':
                if (!value.match(/^(019|018|017|013|014|015|011|016)\d{8}$/)) {
                    return 'Invalid phone number';
                }
                break;
            case 'address':
                if (value.trim() === '') {
                    return 'Address is required';
                }
                break;
            case 'postcode':
                if (value.trim() === '') {
                    return 'Postcode is required';
                }
                break;
            default:
                break;
        }
        return '';
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const fieldError = validateField(name, value);

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: fieldError,
        });
    };

    useEffect(() => {
        if (userData) {
            setFirstName(userData?.first_name)
            setLastName(userData?.last_name)
            setEmail(userData?.user_email)
            setPhoneNumber(userData?.user_mobile)
            setPassword('')
            setOldPassword('')
            setAddress(userData?.shipping_address?.address_1)
            setPostcode(userData?.shipping_address?.postcode)

        }
        setFormData({
            firstName: userData?.first_name || '',
            lastName: userData?.last_name || '',
            email: userData?.user_email || '',
            phoneNumber: userData?.user_mobile || '',
            oldPassword: '',
            password: '',
            address: userData?.shipping_address?.address_1 || '',
            postcode: userData?.shipping_address?.postcode || '',
        })
    }, [userData])

    const submitHandler = () => {
        let formIsValid = true;
        for (const fieldName in formData) {
            const fieldError = validateField(fieldName, formData[fieldName]);
            if (fieldError) {
                formIsValid = false;
                setErrors({
                    ...errors,
                    [fieldName]: fieldError,
                });
            }
        }

        if (formIsValid) {
            // Proceed with form submission logic here
            let token = localStorage.getItem('token')
            let id = localStorage.getItem('id')
            let formData = new FormData()

            formData.append('user_first_name', firstName)
            formData.append('user_last_name', lastName)
            formData.append('user_email', email)
            formData.append('user_login', email)
            formData.append('user_mobile', phoneNumber)
            formData.append('token', token)
            formData.append('user_id', id)
            formData.append('del_addr_text', address)
            formData.append('new_password', password)
            formData.append('old_password', oldPassword)
            formData.append('del_addr_country', 'Bangladesh')
            formData.append('del_addr_state', 'Dhaka')
            formData.append('del_addr_city', 'Dhaka')
            formData.append('del_addr_post_code', postcode)

            dispatch(updateProfile(formData)).then(res => {
                if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    localStorage.setItem('token', res?.payload?.data?.token)
                    localStorage.setItem('id', res?.payload?.data?.id)
                    setLoading(false)
                    setTimeout(() => {
                        history.push('/user/info');
                    }, 800)
                } else {
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
        }


    }


    useEffect(() => {
        setLoading(true)
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')

        if (!token) {
            history.push('/login')
        }

        let params = {
            id: id,
            token: token
        }

        dispatch(getProfile(params)).then(res => {
            setLoading(false)
        })

    }, [])


    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [showPassword2, setShowPassword2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <StyledComponent>
            <Container className={"overview pb-80 pt-80 "}>
                <Row>
                    <Col lg={{span: 6, offset: 3}} className={"overview__right"}>
                        <div className={"overview__right__box"}>
                            <div className={"title"}>
                                <img src={'/images/dynamic/icon.svg'}/>
                                <h3 className={"split-up"}>User Information</h3>
                            </div>
                            <div className="account__form">
                                <div className="form-group-wrap">
                                    <Form className={'form'}>
                                        <div className="flex">
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="firstName"
                                                        value={formData.firstName}
                                                        onChange={e => {
                                                            setFirstName(e.target.value)
                                                            handleChange(e)
                                                        }}
                                                        className={errors.firstName ? 'form-control-lg has-error' : 'form-control-lg'}
                                                    />
                                                    {errors.firstName && (
                                                        <div className="error-message">{errors.firstName}</div>
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Last name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="lastName"
                                                        value={formData.lastName}
                                                        onChange={e => {
                                                            setLastName(e.target.value)
                                                            handleChange(e)
                                                        }}
                                                        className={errors.lastName ? 'form-control-lg has-error' : 'form-control-lg'}
                                                    />
                                                    {errors.lastName && (
                                                        <div className="error-message">{errors.lastName}</div>
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Mail Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={e => {
                                                            setEmail(e.target.value)
                                                            handleChange(e)
                                                        }}
                                                        className={errors.email ? 'form-control-lg has-error' : 'form-control-lg'}
                                                    />
                                                    {errors.email && (
                                                        <div className="error-message">{errors.email}</div>
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="phoneNumber"
                                                        value={formData.phoneNumber}
                                                        placeholder="01XXXXXXXXX"
                                                        onChange={e => {
                                                            setPhoneNumber(e.target.value)
                                                            handleChange(e)
                                                        }}
                                                        className={errors.phoneNumber ? 'form-control-lg has-error' : 'form-control-lg'}
                                                    />
                                                    {errors.phoneNumber && (
                                                        <div className="error-message">{errors.phoneNumber}</div>
                                                    )}
                                                </Form.Group>
                                            </div>
                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={e => {
                                                            setAddress(e.target.value)
                                                            handleChange(e)
                                                        }}
                                                        className={errors.address ? 'form-control-lg has-error' : 'form-control-lg'}
                                                    />
                                                    {errors.address && (
                                                        <div className="error-message">{errors.address}</div>
                                                    )}
                                                </Form.Group>
                                            </div>

                                            <div className="form-group">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Postcode</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="postcode"
                                                        value={formData.postcode}
                                                        onChange={e => {
                                                            setPostcode(e.target.value)
                                                            handleChange(e)
                                                        }}
                                                        className={errors.postcode ? 'form-control-lg has-error' : 'form-control-lg'}
                                                    />
                                                    {errors.postcode && (
                                                        <div className="error-message">{errors.postcode}</div>
                                                    )}
                                                </Form.Group>
                                            </div>


                                        </div>

                                        <Form.Group>
                                            <Button text={'Update Information'} borderColor={'#92278F'}
                                                    color={'#92278F'}
                                                    hoverfill={'#92278F'}
                                                    margin={'15px 0 0 0'}
                                                    src={'/user/update'}
                                                    onClick={submitHandler}
                                            />
                                        </Form.Group>
                                    </Form>


                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .overview {
    height: 100vh;

    &__right {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
      padding: 0;

      &__box {

        h3 {
          color: #fff;
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
        }

        .title {
          position: relative;
          padding: 45px 0 40px 30px;
          background-color: #92278F;
          margin-bottom: 50px;

          img {
            width: 100%;
            position: absolute;
            bottom: -15px;
            left: 0;
          }

        }

        .account__form {
          padding: 0 30px 40px 30px;

        }

        .password-input {
          position: relative;
        }

        .password-input.focused {
          /* Define the focused border color */
          border-color: #92278F;
        }

        .password-toggle-button {
          position: absolute;
          top: 65%;
          right: 15px;
          transform: translateY(-50%);
          background: none;
          border: none;
          cursor: pointer;
          outline: none;
        }

        .number-input.focused {
          /* Define the focused border color */
          border-color: #92278F;
        }
      }
    }
  }
  
  .error-message{
    color: #e74c3c;
    top: -20px;
    font-size: 12px;
    
  }

  .password-toggle-button {
    svg {
      color: #CCCCCC;
    }
  }
`;

export default MyComponent;

