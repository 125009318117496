import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from '../../styles/globalStyleVars'
import {Img} from "../Img";
import Button from "../Button";
import React, {useEffect, useRef} from "react";
import {TweenMax, Power0} from 'gsap';

const SpecialtyLube = ({data}) => {

    const imageRef = useRef(null);



    return (
        <StyledLube className='specialty-lube pt-120 '>

            <div className="icon">
                {/*<img ref={imageRef} src={'/images/static/roundbig.png'} className='calender-shadow' alt=""/>*/}
            </div>

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="specialty-lube__image">
                            <Img src={data?.image}/>

                        </div>
                    </Col>

                    <div className='bottom-text-wrap'>
                        <Col md={3}>
                            <div className="bottom-text-wrap__box">
                                <div className="bottom-text-wrap__box__icon">
                                    <img src="/images/static/mapicon.svg" alt=""/>
                                </div>
                                <h4>Location <br/> Address</h4>
                                <Button externals target={'_blank'} text={'Get Distance'}
                                        background={'#fff'}
                                        borderColor={'none'}
                                        color={'#434343'}
                                        fontSize={'14'}
                                        fontWeight={'500'}
                                        src={data?.url}
                                />
                            </div>
                        </Col>

                    </div>


                </Row>


            </Container>


        </StyledLube>
    );
};
const StyledLube = styled.section`
  //padding-top: 120px;
  padding-bottom: 254px;
  position: relative;
  z-index: 5;
  overflow: hidden;


  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(40%, 45%);
    overflow: hidden;
    z-index: 1;

    img {
      height: 350px;
    }
  }

  .specialty-lube__image {
    position: relative;
    padding-top: calc(500 / 1170 * 100%);
  }

  .bottom-text-wrap {
    position: relative;
    width: 100%;

    .col-md-3 {
      position: absolute;
      transform: translate(30%, -50%);
    }

    &__box {
      //background-color: #5AD1D1;
      padding: 60px 30px;
      border-radius: 10px;
      position: relative;
      background: rgb(73, 141, 203);
      background: linear-gradient(360deg, rgba(73, 141, 203, 1) 0%, rgba(90, 209, 209, 1) 100%);
      height: 268px;
      width: 300px;

      &__icon {

        img {
          position: absolute;
          left: 0;
          right: 0;
          transform: translate(-20%, 0%);
          opacity: .1;
        }
      }

      h4 {
        color: #ffffff;
        font-size: 40px;
        line-height: 40px;
        font-weight: 300;
        margin-bottom: 18px;
      }
    }
  }

  @media (max-width: 1150px) {
    .bottom-text-wrap__box {

      h4 {
        font-size: 25px;
        line-height: 28px;
      }
    }
  }


  @media (max-width: 767px) {
    padding-top: 0;
    .bottom-text-wrap__box {
      width: 100%;
      height: 222px;
      top: -55px;
      border-radius: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .specialty-lube__image {
      padding-top: calc(350 / 345 * 100%);

      img {
        border-radius: 10px;
      }
    }

    padding-bottom: 150px;

    .col-md-3 {
      transform: translate(0, -10%) !important;

    }

    .icon {
      display: none;
    }

  }

`;
export default SpecialtyLube;
