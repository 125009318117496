import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, xmlPost } from "../../network/axiosServices";
import xmljs from "xml-js";

const initialState = {
  loading: false,
  posts: [],
  error: "",
  detail: [],
  detailLoading: false,
  detailError: "",
  ticket: {},
  ticketInfo: {},
  ticketStatus: {},
  ticketStatusAll: {},
};

export const postTicket = createAsyncThunk("postTicket", (params) => {
  return xmlPost(params);
});

export const getTicketInfo = createAsyncThunk("getTicket", (params) => {
  return xmlPost(params);
});

export const postTicketOrder = createAsyncThunk("postTicketOrder", (params) => {
  return xmlPost(params);
});

export const checkTicketStatusAll = createAsyncThunk(
  "checkTicketStatusAll",
  (params) => {
    return xmlPost(params);
  }
);

export const checkTicketStatus = createAsyncThunk(
  "checkTicketStatus",
  (params) => {
    return xmlPost(params);
  }
);

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(postTicket.pending, (state, action) => {
      state.detailLoading = true;
      state.ticket = {};
      state.error = "";
    });
    builder.addCase(postTicket.fulfilled, (state, action) => {
      state.detailLoading = false;
      let xmlData;
      try {
        const jsonData = xmljs.xml2json(action?.payload, {
          compact: true,
          spaces: 4,
        });
        xmlData = jsonData;
      } catch (error) {
        console.error("Error converting XML to JSON:", error);
        // setJsonOutput('Error converting XML to JSON');
      }

      let obj = JSON.parse(xmlData);
      sessionStorage.setItem(
        "ticketToken",
        obj?.Envelope?.Header?.SessionID?._text
      );
      state.ticket = JSON.parse(xmlData);
      state.error = "";
    });
    builder.addCase(postTicket.rejected, (state, action) => {
      state.detailLoading = false;
      state.ticket = {};
      state.error = action;
    });
    builder.addCase(getTicketInfo.pending, (state, action) => {
      state.detailLoading = true;
      state.ticket = {};
      state.error = "";
    });
    builder.addCase(getTicketInfo.fulfilled, (state, action) => {
      state.detailLoading = false;
      let xmlData;
      try {
        const jsonData = xmljs.xml2json(action?.payload, {
          compact: true,
          spaces: 4,
        });
        xmlData = jsonData;
        // console.log(xmlData);
      } catch (error) {
        console.error("Error converting XML to JSON:", error);
        // setJsonOutput('Error converting XML to JSON');
      }

      let obj = JSON.parse(xmlData);
      state.ticketInfo = JSON.parse(xmlData);
      sessionStorage.setItem("ticketData", JSON.parse(JSON.stringify(xmlData)));
      state.error = "";
    });
    builder.addCase(getTicketInfo.rejected, (state, action) => {
      state.detailLoading = false;
      state.ticketInfo = {};
      state.error = action;
    });
    builder.addCase(checkTicketStatus.pending, (state, action) => {
      state.detailLoading = true;
      state.ticketStatus = {};
      state.error = "";
    });
    builder.addCase(checkTicketStatus.fulfilled, (state, action) => {
      state.detailLoading = false;
      let xmlData;
      try {
        const jsonData = xmljs.xml2json(action?.payload, {
          compact: true,
          spaces: 4,
        });
        xmlData = jsonData;
      } catch (error) {
        console.error("Error converting XML to JSON:", error);
        // setJsonOutput('Error converting XML to JSON');
      }

      let obj = JSON.parse(xmlData);
      state.ticketStatus = JSON.parse(xmlData);
      state.error = "";
    });
    builder.addCase(checkTicketStatus.rejected, (state, action) => {
      state.detailLoading = false;
      state.ticketStatus = {};
      state.error = action;
    });
    builder.addCase(checkTicketStatusAll.pending, (state, action) => {
      state.detailLoading = true;
      state.ticketStatusAll = {};
      state.error = "";
    });
    builder.addCase(checkTicketStatusAll.fulfilled, (state, action) => {
      state.detailLoading = false;
      let xmlData;
      try {
        const jsonData = xmljs.xml2json(action?.payload, {
          compact: true,
          spaces: 4,
        });
        xmlData = jsonData;
      } catch (error) {
        console.error("Error converting XML to JSON:", error);
        // setJsonOutput('Error converting XML to JSON');
      }

      let obj = JSON.parse(xmlData);
      state.ticketStatusAll = JSON.parse(xmlData);
      state.error = "";
    });
    builder.addCase(checkTicketStatusAll.rejected, (state, action) => {
      state.detailLoading = false;
      state.ticketStatusAll = {};
      state.error = action;
    });
    builder.addCase(postTicketOrder.pending, (state, action) => {
      state.detailLoading = true;
      state.ticket = {};
      state.error = "";
    });
    builder.addCase(postTicketOrder.fulfilled, (state, action) => {
      state.detailLoading = false;
      let xmlData;
      try {
        const jsonData = xmljs.xml2json(action?.payload, {
          compact: true,
          spaces: 4,
        });
        xmlData = jsonData;
      } catch (error) {
        console.error("Error converting XML to JSON:", error);
        // setJsonOutput('Error converting XML to JSON');
      }

      let obj = JSON.parse(xmlData);
      state.ticket = JSON.parse(xmlData);
      state.error = "";
    });
    builder.addCase(postTicketOrder.rejected, (state, action) => {
      state.detailLoading = false;
      state.ticket = {};
      state.error = action;
    });
  },
});

export default ticketSlice.reducer;
