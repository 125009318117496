import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Test from '../components/Test'
import Timeline from "../components/ticket/Timeline";
import SimpleBar from "simplebar-react";
import Button from "../components/Button";
import {Col, Container, Row} from "react-bootstrap";



const MyComponent = () => {
    const detailRef = useRef(null);
    const closeRef = useRef(null);


    useEffect(() => {


        const handleCloseClick = () => {
            detailRef.current.classList.remove("show-detail");
        };

        closeRef.current?.addEventListener("click", handleCloseClick);

        return () => {
            closeRef.current?.removeEventListener("click", handleCloseClick);
        };
    }, []);
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>


            <StyledComponent>
               <Test/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
`;

export default MyComponent;
