import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import Button from "../Button";
import styled from "styled-components";
import ticketdetails from "./Ticketdetails";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {checkTicketStatus, checkTicketStatusAll} from "../../api/redux/ticket";
import xmljs from "xml-js";

const CabanaModal = ({
                         modalShow,
                         setModalShow,
                         cabanaType,
                         setCabanaType,
                         ticketDetails,
                         date,
                         selectedCabana,
                         setSelectedCabana,
                         availabilityCheck,
                         cabanaConfirmHandler
                     }) => {

    const [show, setShow] = useState(false);
    const ticketStatus = useSelector(state => state?.ticket?.ticketStatusAll?.Envelope?.Body?.Events?.Event);


    const dispatch = useDispatch()
    const handleClose = () => {
        setShow(false)
        setTimeout(() => {
            setCabanaType('')
            setModalShow(false)
        }, 300)

    };
    const handleShow = () => {
        setShow(true)
    };

    useEffect(() => {
        handleShow(modalShow)
    }, [modalShow])

    // disable cabana based on availablity
    useEffect(() => {
        const session = sessionStorage.getItem('ticketToken')
        const now = new Date()
        const localDate = sessionStorage.getItem('date')

        const events = ticketDetails ? ticketDetails.map((e) => {
                const nameText = e?.Name?._text.toLowerCase();
                if (nameText.includes(cabanaType)) {
                    return (
                        `<QueryEvent>
                                    <EventRangeBeginDate>${moment(localDate).format('yyyy-MM-DD')} 00:00:00</EventRangeBeginDate>
                                    <EventRangeEndDate>${moment(localDate).format('yyyy-MM-DD')} 23:59:59</EventRangeEndDate>
                                    <EventTypeID>${e?.EventTypeID?._text}</EventTypeID>
                                    <ResourceID>${e?.SectionID?._text}</ResourceID>
                                    <Quantity>1</Quantity>
                                    <PLU>${e?.PLU?._text}</PLU>
                                    <EventGroupID>${e?.PLU?._text}</EventGroupID>
                                </QueryEvent>`
                    );
                } else {
                    return null;
                }
            }).join('')
            : null

        const param = ['', `<?xml version="1.0" encoding="UTF-8"?>
                <Envelope>
                    <Header>
                        <SourceID>1</SourceID>
                        <MessageID/>
                        <MessageType>QueryEvents</MessageType>
                        <SessionID>${session}</SessionID>
                        <TimeStamp>${moment(now).format('yyyy-MM-DD hh:mm:ss')}</TimeStamp>
                    </Header>
                    <Body>
                        <QueryEvents>
                            <TranslationLanguageID>0</TranslationLanguageID>
                            <GroupEventsByEventID>YES</GroupEventsByEventID>
                            <QueryEventGroup>
                            ${events}
                            </QueryEventGroup>
                        </QueryEvents>
                    </Body>
                </Envelope>`]
        dispatch(checkTicketStatusAll(param))
    }, [])



    return (
        <Modal
            show={show}
            className="guest-entry-modal"
            // onHide={handleClose}
        >
            <img className={'avatar'} src={'/images/static/avatar.png'} alt={'avatar'}/>
            <Modal.Header>
                <div className="header">
                    <div className="header-left">
                        <h4>{cabanaType === 'vip' ? 'VIP Cabana' : 'Premium Cabana'}</h4>
                    </div>
                    <div className="header-right" onClick={handleClose}>
                        {/*<img src={'/images/static/modal-close.svg'} alt="modal-close" onClick={handleClose}/>*/}
                        <Button borderColor={'#FFF'} color={'#FFF'} text={'Continue'} src={'#'}/>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <StyledChips>
                    <h3>{cabanaType === 'vip' ? 'Pool Front Cabana' : 'Pool Side Cabana'}</h3>
                    <p className={'top-text'}>**For groups of 1 to 8 people, you can add one cabana. Additionally, for
                        every 4-8 people in your
                        group, you can add one more cabana.</p>


                    <div className="chip-container">
                        {ticketDetails
                            ? ticketDetails.map((e) => {
                                const nameText = e?.Name?._text.toLowerCase();
                                if (nameText.includes(cabanaType)) {
                                    // Check if the item is selected
                                    const isSelected = selectedCabana.some((item) => item?.PLU === e?.PLU?._text);
                                    const isAvailable = ticketStatus && ticketStatus.length > 0 ? ticketStatus.some((item) => item?.EventGroups?.EventGroup?.EventGroupID?._text === e?.PLU?._text) : false;


                                    // Define the class name based on selection status
                                    const className = isSelected ? "cabana-chips-selected" : "cabana-chips";

                                    return (
                                        <div
                                            className={`col-6 col-sm-4`}
                                            onClick={() => {
                                                if (isAvailable) {
                                                    availabilityCheck(e, isSelected);
                                                }

                                            }}
                                        >
                                            <div
                                                className={`${className} cabana-item-inner ${!isAvailable ? "disable-box" : ""}`}>
                                                <img src="/images/static/c-check.svg" alt=""/>
                                                <h4>{e?.Name?._text}</h4>
                                                <p>{e?.Description?._text}</p>
                                                <h6>BDT{parseInt(e?.Price?._text).toLocaleString('en-US')}</h6>
                                            </div>

                                        </div>
                                    );
                                } else {
                                    return null; // Skip rendering for items with 'adult' or 'child'
                                }
                            })
                            : null}
                    </div>
                </StyledChips>

            </Modal.Body>
        </Modal>
    );
};

export default CabanaModal;


const StyledChips = styled.div`
  margin: 40px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }

  .chip-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .col-sm-4 {
      margin-bottom: 30px;
    }

    .cabana-item-inner {
      padding: 35px 20px;
      min-height: 100%;
      border: 1px solid #989898;
      border-radius: 10px;
      position: relative;
      cursor: pointer;

      &.disable-box {
        opacity: .5;
        pointer-events: none;
      }

      h4 {
        font-size: 16px;
        font-weight: 300;
        line-height: 21px;
        text-transform: uppercase;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        margin-top: 10px;

      }

      h6 {
        font-size: 16px;
        font-weight: 300;
        line-height: 21px;
        margin-top: 20px;
      }

      img {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    .cabana-chips {
      img {
        display: none;
      }

    }

    .cabana-chips-selected {
      background-color: #92278F;
      border-color: transparent;

      h4, p, h6 {
        color: #ffffff;
      }

      img {
        display: block;
      }
    }
  }

`
