import {useEffect} from "react";
import ErrorPage from "../components/error/404";
import {HelmetProvider, Helmet} from 'react-helmet-async'

const Home = () => {


    useEffect(() => {

    }, [])

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>404 | Mana Bay water park</title>
                    <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
                </Helmet>
                <ErrorPage/>
            </HelmetProvider>
        </>
    )


}


export default Home;



