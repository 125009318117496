import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Img from "../Img";
import Button from "../Button";
import {RiEyeFill, RiEyeCloseFill} from 'react-icons/ri';
import {useDispatch} from "react-redux";
import {forgotPassword} from "../../api/redux/auth";
import {Loading} from "../Loading";
import {toast, ToastContainer} from "react-toastify";


const MyComponent = () => {
    const [email, setEmail] = useState()
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])

    // password hidden
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlerSubmit = () => {
        if (email && email !== '') {
            setLoading(true)
            let formData = new FormData()
            formData.append('user_email', email)
            dispatch(forgotPassword(formData)).then((res) => {
                if (res?.payload?.status === 200) {
                    toast.success(res?.payload?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setLoading(false)
                    localStorage.setItem('email', email)
                    setTimeout(() => {
                        history.push('/otp')
                    }, 1400)
                } else {
                    setLoading(false)
                    toast.error(res?.payload?.response?.data?.message, {
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
        }
    }


    return (
        <StyledLogIn className='login'>
            <Container fluid style={{paddingLeft: offset + 15 + 'px'}}>
                <Row>
                    <Col md={5}>
                        <div className="login__form">
                            <h2>Reset Password</h2>
                            <p>Enter your phone number and you can reset your password.</p>
                            <div className="form-group-wrap">
                                <Form className={'form'}>
                                    <div className="flex">
                                        <div className="form-group">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Email Address"
                                                              name='email'
                                                              className="number-input"
                                                              value={email}
                                                              onChange={(e) => {
                                                                  setEmail(e.target.value)
                                                              }}
                                                              onFocus={(e) => e.target.classList.add('focused')}
                                                              onBlur={(e) => e.target.classList.remove('focused')}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Form>
                                <div className="form__bottom">
                                    <Button text={'Send Verification code'}
                                            onClick={handlerSubmit}
                                            borderColor={'#92278F'}
                                            color={'#92278F'}
                                            hoverfill={'#92278F'}/>
                                </div>

                                <div className="login__form__create-line">
                                    <p>Back to &nbsp;</p>
                                    <Link to={'/login'}>Sign In</Link>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}} className={'pr-0 pl-0'}>
                        <div className="login__image">
                            <Img src={'/images/dynamic/login/login.jpg'}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/*<ToastContainer*/}
            {/*    position="top-right"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="light"*/}
            {/*/>*/}
        </StyledLogIn>
    )
        ;
};
const StyledLogIn = styled.section`
  .login__form {
    padding: 100px 0 50px 0;

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 300;
      color: #1A1818;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      color: #1A1818;
      margin-bottom: 40px;
    }

    &__create-line {
      display: flex;
      margin: 20px 0 0 0;

      p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #1A1818;
      }

      a {
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        color: #92278F;

      }
    }
  }

  .form__bottom {
    margin: 40px 0 20px 0;
  }

  .login__image {
    position: relative;
    padding-top: calc(651 / 598 * 100%);
    height: 100%;
  }

  .password-input {
    position: relative;
  }

  .password-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }

  .password-toggle-button {
    position: absolute;
    top: 45%;
    right: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .number-input.focused {
    /* Define the focused border color */
    border-color: #92278F;
  }


  @media (max-width: 767px) {
    .row {
      flex-direction: column-reverse;
    }

    .login__form {
      padding: 30px 0 50px 0;
    }
  }



`;
export default MyComponent;
