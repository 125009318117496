import React, {useEffect} from 'react';
import styled from "styled-components";
import ProductDetails from "../../components/checkout/ProductDetails";
import {useDispatch, useSelector} from "react-redux";
import {cart} from "../../api/redux/checkout";
import {getProfile} from "../../api/redux/auth";
import {HelmetProvider, Helmet} from 'react-helmet-async'

const MyComponent = () => {
    const dispatch = useDispatch()
    const cartData = useSelector(state => state?.checkout?.cartData);
    const cartTotal = useSelector(state => state?.checkout?.cartTotal);
    const userData = useSelector(state => state?.auth?.userData?.data);

    useEffect(() => {
        let token = localStorage.getItem('token')
        let id = localStorage.getItem('id')
        let params = {
            token: token,
            id: id,
        }
        if (token && token !== '') {
            dispatch(cart(params))
            dispatch(getProfile(params))
        }
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Checkout | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>
            <StyledComponent>
                <ProductDetails cartData={cartData} userData={userData} cartTotal={cartTotal}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
