import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import OrderList from "../../components/order/OrderList";

const MyComponent = () => {

    const text = `   Uncover the Perfect Toys <br/>
                            for Every Little Explorer! `

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Order | Mana Bay water park</title>
                <meta name="description" content="In a world that is always on, Mana Bay offers sanctuary."/>
            </Helmet>

            <StyledComponent>
                <OrderList/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
