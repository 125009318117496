import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import TimelineProduct from "./TimelineProduct";
import TimelineSlug from "../ticket/TimelineSlug";

const MyComponent = () => {
    const [adultCounter, setAdultCounter] = useState(0);
    const [childCounter, setChildCounter] = useState(0);

    return (
        <StyledComponent>
            <Container className={'pt-120 pb-120'}>
                <Row>
                    <Col md={6}>
                        <TimelineSlug/>
                    </Col>
                    <Col lg={{span: 5, offset: 1}}>
                        <div className={"order__box"}>
                            <div className={"title"}>
                                <img src={'/images/dynamic/icon.svg'}/>
                                <h3 className={"split-up"}>Order Summary</h3>
                            </div>
                            <div className="order__box__content">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Items</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'flex'}>Mana Bay Full Day Entry - Adults
                                            <span><a href="">Remove</a></span>

                                        </td>
                                        <td>X5</td>
                                        <td>BDT 1000</td>
                                    </tr>
                                    <tr>
                                        <td className={'flex'}>Mana Bay Full Day Entry - Child
                                            <span><a href="">Remove</a></span>
                                        </td>
                                        <td>X1</td>
                                        <td>BDT 0</td>
                                    </tr>
                                    <tr>
                                        <td className={'flex'}>Cavana - Regular
                                            <span><a href="">Remove</a></span>
                                        </td>
                                        <td>X1</td>
                                        <td>BDT 3000</td>
                                    </tr>

                                    </tbody>

                                </table>
                            </div>

                            <div className="subtotal">
                                <h6>Subtotal</h6>
                                <h6>BDT 3000</h6>
                            </div>

                            <div className="total">
                                <div className="total-left">
                                    <h4>Total</h4>
                                </div>
                                <div className="total-left">
                                    <h4>BDT 5000</h4>
                                    <p>All taxes and fees included</p>
                                </div>

                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #f9f9f9;

  .timeline {
    margin-bottom: 40px;

    ul {
      li {
        a {
          color: #1A1818;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
        }

        display: inline-flex;
        margin-right: 60px;
        position: relative;
        counter-increment: count;

        &::before {
          cursor: pointer;
          content: counter(count, Numeric);
          position: absolute;
          padding: 13px 18px;
          border: 1px solid #92278F;
          border-radius: 50%;
          color: #92278F;
          top: -55px;
          left: 0;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-size: 12px;
          font-weight: 300;
          line-height: 14px;
          overflow: hidden;
          background-color: #ffffff;
          z-index: 2;
        }

        &::after {
          content: "";
          position: absolute;
          top: -35px;
          right: -95px;
          transform: translateX(-50%);
          border-top: 1px solid rgb(146, 39, 143);
          width: 60px;
          z-index: 1;
        }

      }

      li:last-child::after {
        display: none;
      }
    }
  }

  .tc {
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;

    &:last-child {
      border-bottom: 0;
    }

    .ticket__title {
      h3 {
        color: #1A1818;
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        padding-top: 17px;
        padding-bottom: 19px;
        padding-left: 20px;
        //background-color: #F9F9F9;
      }
    }

    .ticket {
      display: flex;
      padding: 20px;
      background-color: #FFFFFF;
      margin-bottom: 20px;
      width: 100%;
      justify-content: space-around;

      &__img {
        img {
          height: 110px;
          width: 90px;
        }
      }

      &__buy {
        display: block;

        .type {
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          span {
            display: block;
            margin-right: 50px;

            h4 {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              color: #1A1818;
            }

            p {
              width: max-content;
              color: #989898;
              font-size: 12px;
              line-height: 14px;
              font-weight: 300;
            }
          }
        }

        .btn {
          align-self: flex-end;

          input {
            border-radius: 50%;
            border: none;
            height: 25px;
            width: 25px;
          }

          .increase-section__button {
            width: 150px;
            justify-content: space-between;
            padding: 0 2px;
            background-color: white;
            border: 1px solid #E8E8E8;
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;

            span {
              color: #1A1818;
              font-weight: 300;
              font-size: 14px;
              line-height: 19px;
            }

          }
        }

        p {
          color: #92278F;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .details {
    margin-top: 30px;
    background-color: #f9f9f9;


    h3 {
      color: #1A1818;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      padding-top: 17px;
      padding-bottom: 19px;
      padding-left: 20px;
      background-color: #f9f9f9;
      border: 1px solid #E9E9E9;
    }

    .account__form {
      padding: 20px 30px 40px 30px;
      background-color: #ffffff;
      //box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;

      .checkbox-single {
        margin-bottom: 25px;
        padding-top: 25px;

        .container {
          padding-bottom: 25px;
          border-bottom: 1px solid #E8E8E8;
          margin-bottom: 25px;
        }
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;

        .form-group {
          &:last-child {
            flex: 0 0 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .detail {
    margin-top: 20px;

    .visiting {
      justify-content: space-between;
      background-color: #E9E9E9;
      padding: 20px 17px;

      .visiting-title {
        h3 {
          color: #1A1818;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
        }
      }

      .visiting-data {
        display: flex;
        justify-content: space-between;
        align-self: center;
        gap: 20px;

        p {
          color: #1A1818;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          margin-right: 16px;
        }

        ul {
          display: flex;
          justify-content: space-between;
          gap: 20px;

          li {
            position: relative;
            color: #1A1818;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            margin-right: 20px;

            &:last-child {
              margin-right: 0;

              &::before {
                position: absolute;
                content: "";
                top: -1px;
                left: -40px;
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-left: 20px;
                border-radius: 50%;
                background-color: rgb(90, 209, 209);
                opacity: 100%;
              }
            }

            &::before {
              position: absolute;
              content: "";
              top: -1px;
              left: -40px;
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-left: 20px;
              border-radius: 50%;
              opacity: 50%;
              background-color: #EC1B34;
            }
          }
        }
      }
    }
  }

  .order__box {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.08);
    padding: 0;

    h3 {
      color: #fff;
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
    }

    .title {
      position: relative;
      padding: 37px 80px 32px 30px;
      background-color: #92278F;

      img {
        width: 100%;
        position: absolute;
        bottom: -15px;
        left: 0;
      }

    }


    &__content {
      margin: 50px 30px 0 30px;
      //padding: 0 30px;

      table {
        thead {

          border-bottom: 1px solid #E8E8E8;

          tr {
            th {
              font-size: 16px;
              line-height: 32px;
              color: #434343;
              font-weight: 400;
              padding: 20px 0;

              &:nth-of-type(3) {
                text-align: end;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #E8E8E8;

            .flex {
              display: flex;
              flex-direction: column;
            }

            span {
              a {
                margin: 10px 0;
                font-size: 12px;
                line-height: 14px;
                color: #EC1B34;
                font-weight: 300;
              }
            }

            td {
              padding: 20px 0;
              font-size: 14px;
              line-height: 16px;
              font-weight: 300;
              border-color: #DFE6E5;
              color: #1A1818;

              &:nth-of-type(3) {
                text-align: end;
              }

            }
          }
        }
      }


    }

    .subtotal {
      display: flex;
      justify-content: space-between;
      padding: 30px;

      h6 {
        color: #1A1818;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      padding: 30px;
      background-color: rgb(146, 39, 143, 0.1);

      .total-left {
        text-align: end;
      }

      h4 {
        color: #92278F;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        color: #434343;
        font-weight: 300;
      }
    }

  }
`;

export default MyComponent;
