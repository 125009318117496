import axios from "axios";
import baseUrl, {URL_LIVE} from './baseUrl'


export async function get(params) {
    try {
        const result = await axios({
            method: 'get',
            url: `${baseUrl.BASE_URL}${params[0] ? params[0] : ''}`,
            params: params[1] ? params[1] : '',
            headers: params[2] ? params[2] : {'Content-Type': 'application/json'}
        })
        return result.data;
    } catch (err) {
        if (err.response.data.message !== "Product removed from cart") {
            localStorage.removeItem('token')
            localStorage.removeItem('id')
        }
        return err
    }
}


export async function post(params) {
    try {
        const result = await axios({
            method: 'post',
            url: `${baseUrl.BASE_URL}${params[0]}`,
            data: params[1],
            headers: params[2] ? params[2] : {'Content-Type': 'application/json'}
        })
        return result.data;

    } catch (err) {
        if(err.response.data.message !== "Product removed from cart"){
            localStorage.removeItem('token')
            localStorage.removeItem('id')
        }
        return err
    }
}

export async function xmlPost(params) {
    try {
        const result = await axios({
            method: 'post',
            url: `/api/${params[0]}`,
            data: params[1],
            headers: params[2] ? params[2] : {
                // 'Access-Control-Allow-Origin': '*',
                // 'Accept' : 'application/xml',
                'x-egalaxy-source-id': '1',
                'origin': URL_LIVE,
                'control': 'access-control-allow-methods',
                'Content-Type': 'application/xml'}
        })
        return result.data;

    } catch (err) {
        return err
    }
}

export async function postFile(params) {
    try {
        const result = await axios({
            method: 'post',
            url: `${baseUrl.BASE_URL}${params[0]}`,
            data: params[1],
            headers: {"Content-Type": "multipart/form-data"}
        })

        return result.data;

    } catch (err) {
        throw err;
    }
}
